import React from "react";
import { useListing } from "../../providers";

export const AccomodationDetails = () => {

    const { listingDetails } = useListing();

    const {
        bedrooms,
    } = listingDetails;

    return (
        <div className="block-body !px-0 flex flex-col">
            <div className="block-left mb-4">
                <h3 className="text-lg font-bold text-start pb-4">Accommodations</h3>
            </div>
            {/* block-left */}
            <div className="block-right grid grid-cols-1 md:grid-cols-3 gap-6">
                {bedrooms?.map((room) => (
                    <div
                        key={room.id}
                        className="block-col p-4 border rounded-lg shadow-lg"
                    >
                        <div className="block-icon mb-2">
                            {/* Add an optional icon here */}
                        </div>
                        <dl>
                            <dt className="font-bold text-lg mb-2">{room.name}</dt>
                            <dd className="text-gray-700">
                                {room.no_of_bed} {room.bed_type} Bed{room.no_of_bed > 1 ? "s" : ""}
                            </dd>
                            <dd className="text-gray-700">
                                {room.no_of_guest} Guest{room.no_of_guest > 1 ? "s" : ""}
                            </dd>
                        </dl>
                    </div>
                ))}
            </div>
            {/* block-right */}
        </div>
    );
};
