import React, { useEffect, useState } from "react";
import { FormProvider } from "../../providers";
import { addListingValidationSchema } from "../../utils";
import { ADD_LISTING_DEFAULT_VALUES } from "../../constants";
import { useLocation } from "react-router-dom";
import { ListingTypeForm } from "../../Components/Add";
import { axiosInstanceWithToken } from "../../helpers/axios";

export const AddListingFragment = () => {
    const location = useLocation();
    const { listing_type, listId = "", isDuplicate = false } = location.state || {};

    const [defaultValues, setDefaultValues] = useState({
        ...ADD_LISTING_DEFAULT_VALUES,
        listing_type,
    });

    const [isLoading, setIsLoading] = useState(!!listId);

    useEffect(() => {
        if (listId) {
            setIsLoading(true);
            axiosInstanceWithToken.get(`/listing/${listId}`)
                .then((res) => {
                    const fetchedData = res?.data?.listing;
                    if (isDuplicate) {
                        delete fetchedData?.id;
                        fetchedData.blocked_dates = [];
                    }

                    // console.log(fetchedData);
                    if (fetchedData) {
                        setDefaultValues((prevValues) => ({
                            ...prevValues,
                            ...fetchedData,
                            features: fetchedData.features.map((feature) => ({
                                id: feature.id,
                                feature_id: feature.feature_id,
                                name: feature.name,
                                sub_features: feature.sub_features.map((subFeature) => subFeature.id),
                            })),
                            airports: fetchedData.airports?.map((airport) => ({
                                ...airport,
                                fuel: (() => {
                                    try {
                                        // Parse fuel as an array
                                        return Array.isArray(airport.fuel)
                                            ? airport.fuel
                                            : JSON.parse(airport.fuel || '[]');
                                    } catch (error) {
                                        console.error("Error parsing fuel:", airport.fuel, error);
                                        return [];
                                    }
                                })(),
                                surface: (() => {
                                    try {
                                        // Parse surface as an array
                                        return Array.isArray(airport.surface)
                                            ? airport.surface
                                            : JSON.parse(airport.surface || '[]');
                                    } catch (error) {
                                        console.error("Error parsing surface:", airport.surface, error);
                                        return [];
                                    }
                                })(),
                            })) || [],
                        }));
                    }
                })
                .catch((error) => {
                    console.error("Error fetching listing data:", error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [isDuplicate, listId]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen flex-col gap-5">
                <img
                    className="w-36 h-auto md:w-44" // Responsive sizes
                    src="/image/fly-inn-logo.svg"
                    alt="Fly Inn Logo"
                />
                <div className="text-2xl text-bold"> Please Wait while we load your data!</div>
            </div>
        );
    }

    return (
        <FormProvider
            validationSchema={addListingValidationSchema}
            defaultValues={defaultValues}
        >
            <ListingTypeForm />
        </FormProvider>
    );
};
