import React from 'react';
import { useListing } from '../../providers';

export const DetailsTerms = () => {
    const { listingDetails } = useListing();

    const {
        pet_allowed,
        cleaning_fee,
        city_fee,
        tax_percentage,
        cancellation_policy_short,
        party_allowed,
        children_allowed,
        rules,
        no_of_pets,
        price_per_pet,
        city_fee_freq,
        smoking_allowed
    } = listingDetails;

    return (
        <>
            {/* Terms & Rules Section */}
            <div className="features-section">
                <div className="block-section flex flex-col">
                    <div className="block-body !px-0 flex flex-col">
                        <div>
                            <ul className="detail-list">
                                <h3 className="title text-start sub-title-head" style={{ marginBottom: "10px" }}>
                                    Terms & Rules
                                </h3>


                                {/* Cancellation Policy Section */}
                                {cancellation_policy_short && (
                                    <div className="features-section">
                                        <div className="block-section">
                                            <div className="block-body">
                                                <div >
                                                    <ul className="detail-list">
                                                        <h3 className="sub-title-bold text-start" style={{ marginBottom: "10px" }}>
                                                            Cancellation Policy
                                                        </h3>
                                                        <p className="sub-title">
                                                            Before Check-In: {cancellation_policy_short?.before_check_in}
                                                        </p>
                                                        <p className="sub-title">
                                                            After Check-In: {cancellation_policy_short?.after_check_in}
                                                        </p>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Smoking Allowed */}
                                <div className="d-flex justify-content-between">
                                    <li>
                                        <i className="fa-thin fa-greater-than"></i>{" "}
                                        Smoking Allowed:
                                    </li>
                                    <li className="font-bold">
                                        {smoking_allowed === 1 ? "Yes" : "No"}
                                    </li>
                                </div>

                                {/* Pets Allowed */}
                                <div className="d-flex justify-content-between">
                                    <li>
                                        <i className="fa-thin fa-greater-than"></i>{" "}
                                        Pets Allowed:
                                    </li>
                                    <li className="font-bold">
                                        {pet_allowed === 1 ? "Yes" : "No"}
                                    </li>
                                </div>

                                {/* Party Allowed */}
                                <div className="d-flex justify-content-between">
                                    <li>
                                        <i className="fa-thin fa-greater-than"></i>{" "}
                                        Party Allowed:
                                    </li>
                                    <li className="font-bold">
                                        {party_allowed === 1 ? "Yes" : "No"}
                                    </li>
                                </div>

                                {/* Children Allowed */}
                                <div className="d-flex justify-content-between">
                                    <li>
                                        <i className="fa-thin fa-greater-than"></i>{" "}
                                        Children Allowed:
                                    </li>
                                    <li className="font-bold">
                                        {children_allowed === 1 ? "Yes" : "No"}
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            {/* Additional Information Section */}
            {rules && (
                <div className="features-section">
                    <div className="block-section">
                        <div className="block-body !px-0">
                            <div>
                                <ul className="detail-list">
                                    <h3 className="sub-title-bold text-start" style={{ marginBottom: "10px" }}>
                                        House Rules / Additional Information
                                    </h3>
                                    <p className="sub-title">{rules}</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Additional Fees Section */}
            <div className="features-section">
                <div className="block-section">
                    <div className="block-body">
                        <div >
                            <ul className="detail-list">
                                <h3 className="title text-start sub-title-head" style={{ marginBottom: "10px" }}>
                                    Additional Fees
                                </h3>

                                {cleaning_fee && (
                                    <div className="d-flex justify-content-between">
                                        <li>
                                            <i className="fa-thin fa-greater-than"></i>{" "}
                                            Cleaning Fee:
                                        </li>
                                        <li className="font-bold">${cleaning_fee}</li>
                                    </div>
                                )}
                                {city_fee && (
                                    <div className="d-flex justify-content-between">
                                        <li>
                                            <i className="fa-thin fa-greater-than"></i>{" "}
                                            City Fee:
                                        </li>
                                        <li className="font-bold">
                                            ${city_fee} ({city_fee_freq})
                                        </li>
                                    </div>
                                )}
                                {tax_percentage && (
                                    <div className="d-flex justify-content-between">
                                        <li>
                                            <i className="fa-thin fa-greater-than"></i>{" "}
                                            Tax:
                                        </li>
                                        <li className="font-bold">{tax_percentage}%</li>
                                    </div>
                                )}
                                {(price_per_pet && +no_of_pets !== 0) && (
                                    <div className="d-flex justify-content-between">
                                        <li>
                                            <i className="fa-thin fa-greater-than"></i>{" "}
                                            Pet Fee:
                                        </li>
                                        <li className="font-bold">
                                            ${price_per_pet} (for {no_of_pets} pets)
                                        </li>
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
