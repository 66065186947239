import React from "react";
import Layout from "../../Components/layout/Layout";
// import { Link } from "react-router-dom";

const InovatedIdea = () => {


  return (
    <Layout>
      <div className="content">
        <section id="sec1" data-scrollax-parent="true">
          <div className="container">
            <div className="section-title">
              <h2> Innovative Ideas for a Hangar Home</h2>
              {/* <div className="section-subtitle">who we are</div> */}
              <span className="section-separator" />
              <p>Aviation Homes, Your account</p>
            </div>
            {/*about-wrap */}
            <div className="about-wrap">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img src="/image/inovate1.webp" className="respimg" alt="" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      {/* <h3>In order of popularity…</h3> */}

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Hangar homes, ahhh the convenience! How we all love our
                      hangar homes! For those of you new to aviation, hangar
                      homes are are found in airpark communities, or fly-in
                      communities. These residences combine living quarters with
                      aircraft hangar space and provide access to a runway (or a
                      waterway, in the case of seaplanes). Here are some great
                      ideas we have seen in hangar homes around the world–ideas
                      that contribute to creative design, luxury, and
                      innovation.
                    </p>
                    <p>
                      Here’s a list of features that define these homes as well
                      as some groovy, innovative ideas that we have been seeing
                      more and more often:
                    </p>
                    <h3 style={{ display: "flex" }}>
                      Defining Features of Hangar Homes:
                    </h3>
                    <p>
                      <b>Hangar:</b> This defining feature is what makes a home
                      a hangar home in the first place! An attached hangar
                      allows us aviation lovers to literally live with our
                      aircraft so we can fly whenever we wish! Built-in storage
                      in the hangar also makes on-site maintenance, restoration
                      and even building new aircraft a breeze!
                    </p>
                    <p>
                      <b>Private Taxiways and Runways:</b> convenient, if not
                      direct, access to the airstrip from the hangar.
                    </p>
                    <h3 style={{ display: "flex" }}>
                      Innovative Features of Hangar Homes:
                    </h3>

                    <p>
                      <b>Custom Design:</b> Often architect-designed, you will
                      see floor plans that seamlessly integrate living spaces
                      with the hangar. The best thing about a custom design is
                      that the owners get to make their vision a reality!
                    </p>

                    <p>
                      <b>Glass Walls: </b> Many luxury hangar homes boast glass
                      walls separating the living area from the hangar, allowing
                      for a clear view of the aircraft from various parts of the
                      home. What pilot in love with his or her aircraft wouldn’t
                      want to see a beautiful helicopter like this one from the
                      comfort of the home?
                    </p>
                    <p>
                      <b>Lofted Living Spaces: </b>Overlooking the hangar, lofts
                      provide a cozy living area while maximizing hangar floor
                      space. They are also often used for creating offices or
                      lounges. In any case, going up means you don’t waste
                      valuable floor space that can be used to store more
                      aircraft or can serve as dedicated space for a workshop.
                    </p>
                  </div>
                  <div className="col-md-3">
                    <img src="/image/inovate2.jpg" className="respimg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="/image/inovate3.jpg" className="respimg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="/image/inovate4.jpg" className="respimg" alt="" />
                  </div>
                  <div className="col-md-3">
                    <img src="/image/inovate5.jpg" className="respimg" alt="" />
                  </div>
                  <p>
                    <b>Integrated Workshops: </b>For those who love to work on their aircraft, having a workshop within the hangar that is equipped with all the necessary tools is a dream come true! Repairs, maintenance, restoration and even building become extremely convenient
                  </p>

                  <p>
                    <b>Climate-Controlled Hangars: </b> Not only does climate control make working on aircraft very comfortable, this feature is vital for those who own vintage or delicate aircraft that require specific storage conditions. Temperature and humidity controls protect the aircraft.
                  </p>

                  <p>
                    <b>High-Tech Security:</b> Given the value of the aircraft and the fact that the location of these homes is often in secluded areas, state-of-the-art security systems are a common feature.
                  </p>

                  <p>
                    <b>High-Clearance Doors:</b>Almost gone are the days of manually operated doors. Owners now opt for specially-designed doors that accommodate aircraft of varying sizes. Hydraulic and bifold doors are, of course, the most popular, but mechanical bottom-rolling doors, both bi-parting and unidirectional, are gaining popularity due to their sleek look and their ability to withstand extremely strong wind and weather conditions.<br />
                    Stack doors are also becoming more popular, especially in areas where consistent power is an issue. Stack doors can be operated manually with ease and are not power-dependent. Also, unlike vertical lift doors that consume up to 2 feet of overhead space, the height of the framed opening for a stack door is the same as the height of the clear opening, eliminating that 2-foot waste.
                  </p>


                  <p>
                    <b>Lighting:</b> Proper lighting has two criteria–reducing energy consumption while increasing visibility. Having good visibility is crucial in order to find issues, eliminate mistakes, and increase safety. An average-sized private hangar should have no less than 500-750LUX illumination while larger hangars’ requirements can go up to 2,000LUX! LED lighting is the lighting of choice not only for its cost effectiveness, but much more importantly, for the way the light travels from the high ceilings of a hangar to the floor. Other types of lighting can make it difficult to tell colors apart, crucial when working with the color-coded parts of an aircraft such as wires! Needless to say, only the best lighting will do.
                  </p>
                  <p>
                    <b>Epoxy Flooring:</b>Epoxy flooring is the most desirable type of flooring for a hangar due to its functionality and aesthetics. A high-quality epoxy will make cleaning easy and will protect the floor from wear for decades. Most epoxy comes with a lifetime warranty for a reason–it lasts and lasts.
                  </p>

                  <p>
                    <b>Views:</b>Most pilots would agree that the best entertainment is relaxing on a deck or by a large window that overlooks the landing strip (or waterway) and just watching aircraft come and go! Patios and decks that offer views of the runway and skies beyond are common in hangar homes.
                  </p>
                  <h3 style={{ display: 'flex' }}>Look at this beautiful glass wall showcasing this gorgeous aircraft!
                  </h3>

                </div>
              </div>
            </div>

            <div className="about-wrap">
              <div className="row">
                <div className="col-md-12">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      {/* <h3>In order of popularity…</h3> */}

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    {/* <h4 className=" text-start">
                      Spruce Creek Fly-In Community, Florida:
                    </h4> */}

                    <p>
                      What do you think of the list of innovative features above? Pretty groovy, huh? Have we missed anything? Do you have a feature you would like to see in this blog, or better yet, a photo?! Squawk us and share!
                    </p>

                    <p>
                      May we showcase your favorite hangar home? We will be spotlighting one hangar home per month starting the spring of 2024. Fill out the contact form on the contact page and let us know you’re interested in nominating one for the upcoming spotlights!
                    </p>

                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img src="/image/inovate6.jpg" className="respimg" alt="" />
                  </div>
                </div>
              </div>
            </div>


            {/* about-wrap end  */}
            <span className="fw-separator" />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InovatedIdea;
