import React from 'react'
import { PasswordSection } from '../../../Components'

export const ResetPasswordFragment = () => {
    return (
        <>
            <h2 className="text-2xl font-bold mb-4">Change Password</h2>
            <PasswordSection />
        </>
    )
}

