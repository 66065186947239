/* eslint-disable */
import React from "react";
import { AuthModal } from "./Authentication/AuthModal";
import { RegisterForm } from "./Authentication/RegisterForm";

const RegisterModal = ({ setIsModalOpen, setIsRegister }) => {
    return (
        <>
            <AuthModal setIsModalOpen={setIsRegister}>
                <RegisterForm setIsModalOpen={setIsModalOpen} setIsRegister={setIsRegister} />
            </AuthModal>
        </>
    );
};

export default RegisterModal;
