import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import Layout from "../Components/layout/Layout";
import { FormProvider, ListingProvider, useListing } from "../providers";
import { ListingFilters } from "../Components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import '../styles/CustomTooltip.css';
import ListingOutOverlay from "../Components/ui/ListingOutOverlay";
import Skeleton from "react-loading-skeleton";
import { SNAZZY_MAP_STYLES, SERVER_IMAGES_URL } from "../constants";

function NewMapChange({ filteredListings }) {
  const { name, key } = useParams();
  const { getFilteredListings } = useListing();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const getCurrentListingType = useCallback(async (pageNum = 1) => {
    try {
      setLoading(true);

      const keyValueObject = {
        [name]: decodeURIComponent(key),
        page: pageNum,
      };

      await getFilteredListings(keyValueObject, true);
    } catch (error) {
      console.error("Error fetching listings:", error);
    } finally {
      setLoading(false);
    }
  }, [name, key, getFilteredListings]);

  useEffect(() => {
    getCurrentListingType(page);
  }, [name, key, page, getCurrentListingType]);


  const handleShowMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const getLabelColor = (distance) => {
    const location = parseInt(distance);
    if (location === 0) return "8ec639";
    if (location <= 1) return "ffff00";
    if (location <= 3) return "f17489";
    if (location <= 7) return "ff9900";
    return "52c4d7";
  };

  const createSVGIcon = (color, price) => {
    const svgTemplate = `
      <svg width="100" height="50" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
        <rect x="10" y="5" width="60" height="30" rx="16" ry="16" fill="${color}" />
        <text x="40" y="25" text-anchor="middle" font-size="12" font-weight="bold" fill="black" font-family="Arial, sans-serif">
          $${Math.ceil(price)}
        </text>
      </svg>
    `;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgTemplate)}`;
  };

  const listingsContent = useMemo(() => {

    // console.log(filteredListings)
    if (loading) {
      return <Skeleton count={6} height={150} width="100%" />;
    }

    if (filteredListings?.length > 0) {
      return filteredListings.map((listing) => (
        <ListingOutOverlay e={listing} type="page" key={listing.id} />
      ));
    }

    return <h2 className="text-center w-full">No Listings Found</h2>;
  }, [loading, filteredListings]);

  return (
    <Layout>
      <ListingProvider>
        <FormProvider>
          <div className="container mx-auto md:px-20 my-4 px-4" style={{ overflow: "hidden" }}>
            <div className="bg-white py-4">
              <div className="flex justify-center items-center">
                <div className="grid md:grid-cols-7 grid-cols-3 bg-[#ffffffcf] rounded-lg md:gap-0 gap-2 px-2">
                  <ListingFilters />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4 w-full overflow-scroll h-[576px]">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-4">
                  {listingsContent}
                </div>

                {!loading && filteredListings?.length > 0 && (
                  <div className="flex justify-center mb-8 w-full">
                    <button
                      className="color2-bg my-3 text-white bg-[#8EC639] rounded-md font-medium w-28 h-10"
                      onClick={handleShowMore}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Show More"}
                    </button>
                  </div>
                )}
              </div>

              <div>
                <div
                  style={{
                    height: "70vh",
                    width: "100%",
                    position: "relative",
                    top: "10px",
                    background: "none",
                  }}
                >
                  <GoogleMap
                    mapContainerStyle={{ height: "80vh", width: "100%" }}
                    center={{ lat: 39.5, lng: -100.0 }}
                    zoom={3}
                    apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
                    className="custom-map-type-control"
                    options={{
                      styles: SNAZZY_MAP_STYLES, // Apply the Airbnb-like style
                    }}
                  >
                    {filteredListings?.map((item, index) => (
                      <MarkerF
                        key={index}
                        position={{
                          lat: parseFloat(item?.fake_latitude || item.latitude),
                          lng: parseFloat(item?.fake_longitude || item.longitude)
                        }}
                        onClick={() => setSelectedMarker(item)}
                        icon={{
                          url: createSVGIcon(`#${getLabelColor(item.closest_runway_distance || "0")}`, item.nightly_price),
                          scaledSize: new window.google.maps.Size(80, 50),
                        }}
                      />
                    ))}

                    {selectedMarker && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selectedMarker?.fake_latitude || selectedMarker.latitude),
                          lng: parseFloat(selectedMarker?.fake_longitude || selectedMarker.longitude)
                        }}
                        options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                        onCloseClick={() => setSelectedMarker(null)}
                      >
                        <div className="w-72 rounded-xl overflow-hidden shadow-lg bg-white">
                          <div className="w-full h-44">
                            <Swiper
                              modules={[Navigation]}
                              navigation
                              pagination={{ clickable: true }}
                              className="w-full h-44"
                            >
                              {selectedMarker.images?.map((image, index) => (
                                <SwiperSlide key={index}>
                                  <img
                                    src={`${SERVER_IMAGES_URL}/listing/${image.image || "/image/inovate6.jpg"}`}
                                    alt={`${index + 1}`}
                                    className="w-full h-full object-cover"
                                  />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>

                          <div className="p-4">
                            <h4 className="text-lg font-semibold text-gray-800">
                              {selectedMarker.city}, {selectedMarker.country}
                            </h4>
                            <p className="text-sm text-gray-500 mt-1 truncate">
                              Stay with {selectedMarker.title} · Actor / entrepreneur
                            </p>
                            <div className="flex items-center justify-between mt-3">
                              <div className="text-lg font-bold text-gray-800">
                                ${selectedMarker.nightly_price}
                                <span className="text-sm text-gray-500 font-medium"> / night</span>
                              </div>
                              <div className="text-sm text-gray-500">Jan 2 – 7</div>
                            </div>
                          </div>

                          <button
                            className="w-8 h-8 mx-auto mb-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 flex items-center justify-center"
                            onClick={() => setSelectedMarker(null)}
                          >
                            <i className="fas fa-times text-gray-600"></i>
                          </button>
                        </div>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </FormProvider>
      </ListingProvider>
    </Layout>
  );
}

export default NewMapChange;
