import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Swiper } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';
import ReactStars from "react-rating-stars-component";
import { useReactToPrint } from "react-to-print";
import moment from 'moment';
// -------------------------Custom Imports
import { useListing } from '../../providers';
import Layout from '../../Components/layout/Layout';
import { DetailsHeaderModal } from '../../Components/Details/DetailsHeaderModal';
import { DetailsImages } from '../../Components/Details/DetailsImages';
import { LodgingHostDetails } from '../../Components/Details/LodgingHostDetails';
import { AirportInfo } from '../../Components/Details/AirportInfo';
import { LodgingDetails } from '../../Components/Details/LodgingDetails';
import { DetailsHeader } from '../../Components/Details/DetailsHeader';
import { PricingDetails } from '../../Components/Details/PricingDetails';
import { AccomodationDetails } from '../../Components/Details/AccomodationDetails';
import { FeatureDetails } from '../../Components/Details/FeatureDetails';
import { DetailsMap } from '../../Components/Details/DeatilsMap';
import { DetailsTerms } from '../../Components/Details/DetailsTerms';
import { BookingForm } from '../../Components/Details/BookingForm';
import WPCalender from '../../Components/Calenders/WPCalender';
// ------------------------------
import "swiper/css";
import "./detail.css";
import "swiper/css/pagination";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import "../../styles/ListingDetails.css";
import { SERVER_IMAGES_URL } from '../../constants';

export const DetailsPage = ({ listingDetails }) => {
    const { favoriteListings } = useListing();
    const [isPaymentBoxModal, setIsPaymentBoxModal] = useState(false);
    const [modal, setModal] = useState(false);
    const copyTextRef = useRef(null);


    const {
        id = 0,
        nightly_price,
        min_day_booking,
        max_day_booking,
        host,
        no_of_guest
    } = listingDetails;

    const [guest, setGuest] = useState({
        adults: 0,
        children: 0,
        infants: 0,
        pets: 0,
    });

    const maxGuests = parseInt(no_of_guest); // Example limit
    const totalGuests = Object.values(guest).reduce((acc, curr) => acc + curr, 0);

    const handleIncrement = (event, type) => {
        event.preventDefault();

        // if (totalGuests < maxGuests) {

        setGuest((prevGuest) => ({
            ...prevGuest,
            [type]: prevGuest[type] + 1,
        }));
        // }
        // console.log(type)
    };

    const handleDecrement = (event, type) => {
        event.preventDefault();
        setGuest((prevGuest) => ({
            ...prevGuest,
            [type]: prevGuest[type] > 0 ? prevGuest[type] - 1 : 0,
        }));
    };

    const is_favourite = favoriteListings.some(listing => listing?.listing_id === id);

    // -------------------------------------------Temp Variables ---------------------------------------------------------
    const booking = false;

    const [startDate, setStartDate] = useState(Date.now());
    const [endDate, setEndDate] = useState(startDate + (listingDetails.nightly_price * 24 * 60));
    const [range, setRange] = useState([]);

    const mergeRanges = useCallback((ranges) => {
        // Sort ranges by startDate
        const sortedRanges = ranges.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );

        const merged = [];
        let currentRange = sortedRanges[0];

        for (let i = 1; i < sortedRanges.length; i++) {
            const nextRange = sortedRanges[i];

            // Check for overlap
            if (currentRange.endDate >= nextRange.startDate) {
                // Merge ranges
                currentRange = {
                    startDate: currentRange.startDate,
                    endDate: new Date(
                        Math.max(currentRange.endDate, nextRange.endDate)
                    ),
                    key: `selection`,
                };
            } else {
                merged.push(currentRange);
                currentRange = nextRange;
            }
        }

        // Push the last range
        merged.push(currentRange);

        return merged;
    }, []);

    const parseBlockedDates = useCallback(
        (blockedDates) => {
            // Convert blocked_dates to non-overlapping ranges
            const ranges = blockedDates.map(({ start_date, end_date }) => ({
                startDate: moment(start_date).toDate(),
                endDate: moment(end_date).toDate(),
                key: `selection`,
            }));

            // Merge overlapping ranges
            return mergeRanges(ranges);
        },
        [mergeRanges]
    );

    useEffect(() => {
        if (listingDetails && listingDetails.blocked_dates) {
            const parsedRanges = parseBlockedDates(listingDetails.blocked_dates);
            setRange(parsedRanges);
        }
    }, [listingDetails, parseBlockedDates]);


    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    // ----------------------------------------------------------------------------------------------------

    return (
        <>
            <Layout>
                <div id="wrapper" className="!pt-0">
                    <div className="content bg-color !p-0">
                        <section id="elem" className="bg-color px-2 md:py-5 py-5">
                            <div className="container !w-[auto]" ref={contentRef}>
                                <div className="page-container">
                                    {modal ? (
                                        <>
                                            <DetailsHeaderModal modal={modal} setModal={setModal} copyTextRef={copyTextRef} />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <div className="row">
                                        <DetailsHeader setModal={setModal} is_favourite={is_favourite} booking={booking} reactToPrintFn={reactToPrintFn} />

                                        <DetailsImages />

                                    </div>

                                    <div className="content-wrapper relative">
                                        <div className="col-md-6 main-content">
                                            <div className="about-section">
                                                <div className="">
                                                    <LodgingHostDetails />
                                                </div>
                                                <div className="block !bg-transparent">
                                                    <div className="block-body !px-0">
                                                        <h2 className="title">About this stay</h2>
                                                        <p className="sub-title p-0">
                                                            Welcome to your picturesque retreat nestled
                                                            between the mountains and Palisades Reservoir in
                                                            the private Alpine Airport community. Step in
                                                            the front door and be captivated by its
                                                            open-concept design, where spaciousness meets
                                                            cozy elegance. The home boasts a charming
                                                            fireplace, inviting you to unwind in its warm
                                                            glow after a day of adventures.
                                                        </p>
                                                        <AirportInfo />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="detail-section">
                                                <LodgingDetails />
                                            </div>
                                            <div id="price-section" className="price-section">
                                                <PricingDetails />
                                            </div>
                                            <div
                                                id="accomodation-section"
                                                data-path="single-listing-to-accomodation-file"
                                                className="accomodation-section"
                                            >
                                                <div className="block-section flex flex-col">
                                                    <AccomodationDetails />
                                                </div>
                                            </div>
                                            <div className="features-section flex flex-col">
                                                <FeatureDetails />
                                            </div>
                                            <div className="features-section no-print flex flex-col">
                                                <DetailsMap />
                                            </div>
                                            <DetailsTerms />

                                            <>
                                                <div id="host-section" className="host-section">
                                                    <div className="block !bg-transparent">
                                                        <>
                                                            <div className="flex justify-between items-center gap-3 mb-30 px-3 flex-wrap">
                                                                <div className="text-left">
                                                                    <h3 className="sub-title-head">Availability</h3>
                                                                </div>
                                                                <div>
                                                                    <i className="fa-solid fa-calendar-days"></i>
                                                                    The minimum stay is{" "}
                                                                    <strong style={{ color: "gray" }}>{min_day_booking}</strong>
                                                                </div>
                                                                <div>
                                                                    <i className="fa-solid fa-calendar-days"></i>
                                                                    The maximum stay is{" "}
                                                                    <strong style={{ color: "gray" }}>{max_day_booking}</strong>
                                                                </div>
                                                            </div>
                                                            {/* <div className="calender-box mb-30 no-print calendar-container"> */}
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <WPCalender type={'display'} />
                                                                </div>
                                                            </div>
                                                            {/* </div> */}
                                                        </>

                                                        <div style={{ marginTop: "40px" }} className="block-head">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <img
                                                                        width={70}
                                                                        height={70}
                                                                        alt=""
                                                                        src={host?.image !== null ? `${SERVER_IMAGES_URL}/profile/${host?.image}` : "images/avatar/avatar-bg.png"}
                                                                        className="img-circle w-[70px] h-[70px] lazyloaded host-image"
                                                                    />
                                                                </div>
                                                                <div className="media-body">
                                                                    <h2 className="block-head">
                                                                        Hosted by {host?.display_name}
                                                                    </h2>
                                                                    <ul className="list-inline profile-host-info sub-title">
                                                                        <li>
                                                                            <address>
                                                                                <i
                                                                                    className="homey-icon homey-icon-style-two-pin-marker"
                                                                                    aria-hidden="true"
                                                                                />{" "}
                                                                                {host.country || 'United States'}
                                                                            </address>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* /.block-head */}
                                                        <div className="block-body">
                                                            <div className="row">
                                                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-left">
                                                                    <dl>
                                                                        <dt>Languages</dt>
                                                                        <dd>{host?.languages?.join(', ')}</dd>
                                                                    </dl>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                                    <dl>
                                                                        <dt>Profile Status</dt>
                                                                        <dd className="text-danger">
                                                                            <i className="homey-icon homey-icon-uncheck-circle-1" />{" "}
                                                                            {host?.status === 1
                                                                                ? "Verified"
                                                                                : "Not Verified"}
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                            {/* /.row */}
                                                            <div className="host-section-buttons text-left">
                                                                <Link
                                                                    to={"/pic"}
                                                                    state={{
                                                                        host_id: listingDetails?.host_id,
                                                                        email: host?.email,
                                                                    }}
                                                                    className="btn btn-half-width"
                                                                >
                                                                    View Profile
                                                                </Link>
                                                            </div>


                                                            <h3 className="title text-start " style={{ marginTop: "40px", display: "flex", alignItems: "center" }}>
                                                                Reviews:
                                                                <ReactStars
                                                                    count={1}
                                                                    value={1}
                                                                    //  onChange={ratingChanged}
                                                                    size={30}
                                                                    activeColor="#ffd700"

                                                                />
                                                                {/* {average_review?.overall_average} ({booking_reviews?.length}) */}
                                                            </h3>
                                                            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                All reviews are from verified guests.
                                                            </p>
                                                            <div style={{ marginBottom: "50px" }} className="box-widget-item fl-wrap block_box">

                                                                <div className="box-widget  fl-wrap">
                                                                    <div className="box-widget-content">
                                                                        {/*widget-posts*/}
                                                                        <div className="widget-posts  fl-wrap">

                                                                            {/* {booking_reviews?.slice(0, 8)?.map((e, index) => (
                                        <div
                                          key={index}
                                          className="d-flex item-center gap-3 row"
                                        >
                                          <div className="col-3 d-flex items-center text-center">
                                            <GoVerified size={20} />
                                          </div>
                                          <div className="col-9 d-flex items-center text-center">
                                            <p className="mb-2">{e?.host_username || ""} - {e?.comment || ""}</p>
                                          </div>
                                        </div>
                                      ))} */}
                                                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                                                {/* <button onClick={() => setReviewOpen(!reviewOpen)} className="logout_btn color2-bg"> */}
                                                                                {/* show all ({booking_reviews.length}) reviews */}
                                                                                {/* </button> */}
                                                                            </div>
                                                                        </div>
                                                                        {/* widget-posts end*/}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="container_similar">
                                                                <h3 className="title text-start pt-30">
                                                                    Similar Stays
                                                                </h3>

                                                                <div className="content-container mt-5">
                                                                    <Swiper
                                                                        slidesPerView={3}
                                                                        spaceBetween={30}
                                                                        freeMode={true}
                                                                        pagination={{
                                                                            clickable: true,
                                                                        }}
                                                                        modules={[FreeMode, Pagination]}
                                                                        className="mySwiper"
                                                                    >
                                                                        {/* need this from api */}
                                                                        {/* {innerData?.listings?.map((e) => (
                                                                        <SwiperSlide>

                                                                            <ListingOverlayChild

                                                                            e={e}
                                                                            handleFav={() => { }}
                                                                            handleCompare={() => { }}
                                                                            handleRemove={() => { }}
                                                                            />
                                                                        </SwiperSlide>
                                                                        ))} */}
                                                                    </Swiper>
                                                                </div>
                                                            </div>
                                                            <div className="container_similar">

                                                                <h3 className="title text-start  pt-30">
                                                                    {/* {console.log("HOSt =======>", host)} */}
                                                                    {host?.given_name || "Host"}'s Other Stays
                                                                </h3>
                                                                <div className="content-container mt-5">
                                                                    <Swiper
                                                                        slidesPerView={3}
                                                                        spaceBetween={30}
                                                                        freeMode={true}
                                                                        pagination={{
                                                                            clickable: true,
                                                                        }}
                                                                        modules={[FreeMode, Pagination]}
                                                                        className="mySwiper"
                                                                    >
                                                                        {/* need this from api */}
                                                                        {/* {innerData?.listings?.map((e) => (
                                                                        <SwiperSlide>

                                                                            <ListingOverlayChild

                                                                            e={e}
                                                                            handleFav={() => { }}
                                                                            handleCompare={() => { }}
                                                                            handleRemove={() => { }}
                                                                            />

                                                                        </SwiperSlide>

                                                                        ))} */}
                                                                    </Swiper>
                                                                </div>
                                                            </div>
                                                            {/* /.host-section-buttons */}


                                                        </div>

                                                        {/* /.block-body */}
                                                    </div>
                                                    {/* /.block */}
                                                </div>
                                            </>
                                        </div>
                                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                                        <div className="hidden lg:block h-[fit-content] col-md-6 mt-6 sticky-container sticky top-0">
                                            <div className="sticky top-[30px]">
                                                <BookingForm setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} handleDecrement={handleDecrement} handleIncrement={handleIncrement} totalGuests={totalGuests} guest={guest} maxGuests={maxGuests} />
                                            </div>
                                        </div>
                                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */}

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>


                {isPaymentBoxModal && (
                    <div style={{ zIndex: 10000000, height: '100vh' }} className="fixed flex items-center justify-center bg-black bg-opacity-50 w-full">
                        <div className="bg-white w-11/12 max-w-md mx-auto rounded-lg shadow-lg p-6 relative !top-[-2rem] h-[600px] overflow-scroll">
                            <button
                                onClick={() => setIsPaymentBoxModal(false)}
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-xl"
                            >
                                ✕
                            </button>
                            <BookingForm setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} handleDecrement={handleDecrement} handleIncrement={handleIncrement} totalGuests={totalGuests} guest={guest} maxGuests={maxGuests} />

                        </div>
                    </div>
                )}

            </Layout>

            <div style={{ zIndex: 100000 }} className="sticky bottom-0 left-0 w-full bg-white border-t border-gray-300 shadow-lg py-4 px-6 flex justify-between items-center lg:hidden">
                <div>
                    <span className="text-lg font-semibold">${nightly_price}</span>
                    <span className="text-sm text-gray-500">/Night</span>
                </div>
                <button
                    className="bg-[#8ec639]  hover:bg-green-600 text-white text-sm font-semibold py-2 px-4 rounded-lg"
                    onClick={() => setIsPaymentBoxModal(true)}
                >
                    Request to Book
                </button>
            </div>
        </>
    )
}
