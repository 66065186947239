import React from "react";
import { Payments } from "../../Pages";

export * from './BookingStatus';

export const PaymentFragment = () => {

    return (
        <Payments />
    );
};
