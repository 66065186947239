import React from 'react';
import { RxCross2 } from 'react-icons/rx';

export const AuthModal = ({ children, setIsModalOpen }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000] h-screen lg:top-[-120px]">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl relative">
                <button
                    className="absolute z-[100] top-4 right-4 text-gray-500 hover:text-gray-800"
                    onClick={() => setIsModalOpen(false)}
                >
                    <RxCross2 className="w-6 h-6" />
                </button>
                <div className="flex">
                    <div className="hidden md:block md:w-1/2">
                        <img
                            src="/css/loginimage.png"
                            alt="Login"
                            className="w-full h-auto rounded-l-lg"
                        />
                    </div>
                    <div className="w-full md:w-1/2 bg-white p-6 flex flex-col items-center">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
