
export const mapDataToFlatFormat = (listing) => {
  return {
    is_draft: listing.is_draft || 0,
    host_id: listing.host_id || "",
    listing_type: listing.listing_type || "",
    address: listing.address || "",
    apt_suite: listing.unit_no || "",
    city: listing.city || "",
    state: listing.state || "",
    zipcode: listing.zipcode || "",
    area: listing.area || "",
    country: listing.country || "",
    latitude: listing.latitude || 0,
    longitude: listing.longitude || 0,
    images: listing.images?.map((img, index) => `Image ${index + 1}: ${img.image}`) || [],
    title: listing.title || "",
    type_of_space: listing.type_of_space || "",
    lodging_type: listing.lodging_type || "",
    no_of_bedrooms: listing.no_of_bedrooms || 0,
    no_of_guest: listing.no_of_guest || 1,
    no_of_beds: listing.no_of_beds || 0,
    no_of_bathrooms: listing.no_of_bathrooms || 1,
    no_of_rooms: listing.no_of_rooms || 0,
    size: listing.size || 1,
    unit_of_measure: listing.unit_of_measure || "ft",
    description: listing.description || "",
    bedrooms: listing.bedrooms?.map((bedroom, index) => ({
      [`Bedroom ${index + 1} Name`]: bedroom.name || "",
      [`Bedroom ${index + 1} Guests`]: bedroom.no_of_guest || "",
      [`Bedroom ${index + 1} Beds`]: bedroom.no_of_bed || "",
      [`Bedroom ${index + 1} Bed Type`]: bedroom.bed_type || "",
    })) || [],
    airports: listing.airports?.map((airport, index) => ({
      [`Airport ${index + 1} Identifier`]: airport.airport_identifier || "",
      [`Airport ${index + 1} Name`]: airport.airport_name || "",
      [`Airport ${index + 1} Use`]: airport.airport_use || "",
      [`Airport ${index + 1} CTAF/UNICOM`]: airport.ctaf_unicom || "",
      [`Airport ${index + 1} Dimensions (Feet)`]: airport.dimension_feets || "",
      [`Airport ${index + 1} Dimensions (Meters)`]: airport.dimension_meters || "",
      [`Airport ${index + 1} Elevation (Feet)`]: airport.elevation_feets || 0,
      [`Airport ${index + 1} Elevation (Meters)`]: airport.elevation_meters || 0,
      [`Airport ${index + 1} Fuel`]: airport.fuel?.join(", ") || "",
      [`Airport ${index + 1} Lighting`]: airport.lighting || 0,
      [`Airport ${index + 1} Operation Hours`]: airport.operation_hours || "",
      [`Airport ${index + 1} Orientation`]: airport.orientation || "",
      [`Airport ${index + 1} Runway Condition`]: airport.runway_condition || "",
      [`Airport ${index + 1} Parking`]: airport.parking || "",
      [`Airport ${index + 1} Surface`]: airport.surface?.join(", ") || "",
      [`Airport ${index + 1} Air Navigation`]: airport.air_nav || "",
      [`Airport ${index + 1} Ground Transportation`]: airport.ground_transportation || "",
      [`Airport ${index + 1} Distance from Runway`]: airport.distance_from_runway || "",
      [`Airport ${index + 1} Additional Info`]: airport.additional_info || "",
    })) || [],
    instant_booking: listing.instant_booking || 0,
    nightly_price: listing.nightly_price || 0,
    apply_weekend_price: listing.apply_weekend_price || "None",
    weekend_nightly_price: listing.weekend_nightly_price || 0,
    night_price_seven_plus: listing.nightly_price_seven_plus || 0,
    night_price_thirty_plus: listing.nightly_price_thirty_plus || 0,
    additional_guest: listing.additional_guest || 0,
    no_of_additional_guest: listing.no_of_additional_guest || 0,
    additional_guest_price: listing.additional_guest_price || 0,
    pet_allowed: listing.pet_allowed || 0,
    no_of_pets: listing.no_of_pets || 0,
    price_per_pet: listing.price_per_pet || 0,
    cleaning_fee: listing.cleaning_fee || 0,
    cleaning_freq: listing.cleaning_freq || "Daily",
    city_fee: listing.city_fee || 0,
    city_fee_freq: listing.city_fee_freq || "Daily",
    tax_percentage: listing.tax_percentage || 0,
    custom_periods: listing.custom_periods?.map((period, index) => ({
      [`Custom Period ${index + 1} Start Date`]: period.start_date || "",
      [`Custom Period ${index + 1} End Date`]: period.end_date || "",
      [`Custom Period ${index + 1} Nightly Price`]: period.nightly_price || "",
      [`Custom Period ${index + 1} Weekend Price`]: period.weekend_price || "",
      [`Custom Period ${index + 1} Additional Guest Price`]: period.price_add_guest || "",
    })) || [],
    extra_services: listing.extra_service?.map((service, index) => ({
      [`Extra Service ${index + 1} Name`]: service.name || "",
      [`Extra Service ${index + 1} Price`]: service.price || "",
      [`Extra Service ${index + 1} Type`]: service.type || "",
      [`Extra Service ${index + 1} Quantity`]: service.quantity || "",
    })) || [],
    features: listing.features?.map((feature) => ({
      [`Feature ${feature.id}`]: feature.name,
      [`Feature ${feature.id} Sub-Features`]: feature.sub_features?.map((sub) => sub.sub_heading).join(", ") || "",
    })) || [],
    cancellation_policy_short: listing.cancellation_policy_short?.id || 0,
    cancellation_policy_long: listing.cancellation_policy_long?.id || 0,
    min_day_booking: listing.min_day_booking || 0,
    max_day_booking: listing.max_day_booking || 10,
    check_in_after: listing.check_in_after || "",
    check_out_before: listing.check_out_before || "",
    smoking_allowed: listing.smoking_allowed || 0,
    smoking_rules: listing.smoking_rules || "",
    rules_pet_allowed: listing.rules_pet_allowed || 0,
    pet_rules: listing.pet_rules || "",
    party_allowed: listing.party_allowed || 0,
    party_rules: listing.party_rules || "",
    children_allowed: listing.children_allowed || 0,
    children_rules: listing.children_rules || "",
    infant_start_age: listing.infant_start_age || "",
    infant_end_age: listing.infant_end_age || "",
    child_start_age: listing.child_start_age || "",
    child_end_age: listing.child_end_age || "",
    rules: listing.rules || "",
    welcome_message: listing.welcome_message || "",
    blocked_dates: listing.blocked_dates || [],
  };
};
