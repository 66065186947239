import React, { useCallback } from 'react';
import { useFormContext } from '../../providers';
import { EmergencyContact, ImageSection, MailingAddressSection, ProfileSection, SocialMediaForm, SubmitAndDraftSection } from './components';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useAuthHandler, useProfile } from '../../hooks';

export const ProfileForm = () => {
    const { onProfileSubmit, watch } = useFormContext();
    const { profileCompleteness } = useProfile();
    const { getCurrentUser } = useAuthHandler();

    const submitForm = useCallback((event) => {
        // event.preventDefault();
        console.log('submiiting form')
        onProfileSubmit(watch(), event).then(() => {
            getCurrentUser();
        });

    }, [getCurrentUser, onProfileSubmit, watch]);

    return (
        <div style={{ marginTop: "30px" }} className="col-md-12 !p-8 profile-form-container">
            <div className="dashboard-title fl-wrap flex flex-col flex-start gap-5">
                <h3 style={{ color: "#3B4249" }}>Profile</h3>
                <h4 style={{ textDecoration: "underline", paddingTop: "6px" }}>
                    Note : Please complete your profile to 100% by completing all
                    required fields. You will not be allowed to host or be a guest
                    unless the profile is 100% complete.
                </h4>
            </div>
            <div className="row">
                {/* First Column: Full width on small devices */}
                <div className="col-md-8 col-12">
                    <form onSubmit={submitForm} className="p-6 space-y-4">
                        <ImageSection />
                        <ProfileSection />
                        <MailingAddressSection />
                        <EmergencyContact />
                        <SocialMediaForm />
                        <SubmitAndDraftSection />
                    </form>
                </div>

                {/* Second Column: Hidden on small devices */}
                <div className="col-md-4 d-none d-md-block pt-6">
                    <div className="profile-edit-container fl-wrap block_box">
                        <div style={{ marginBottom: "0px" }} className="dashboard-title fl-wrap">
                            <h3
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "#af2322",
                                }}
                            >
                                Profile Completed
                            </h3>
                            <h1
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "#8ec639",
                                }}
                            >
                                {profileCompleteness + '% complete' || 100}
                            </h1>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <h4>Profile Picture</h4>
                                    <p style={{ paddingTop: watch('image') ? "10px" : "0px" }}>
                                        {watch('image') ? (
                                            <FaCheck style={{ color: "#8ec639", fontSize: "17px" }} />
                                        ) : (
                                            <MdClose
                                                style={{
                                                    color: "#8ec639",
                                                    fontSize: "20px",
                                                    paddingTop: "3px",
                                                }}
                                            />
                                        )}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <h4>Email Address</h4>
                                    <p
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                            color: "#8ec639",
                                        }}
                                    >
                                        {parseInt(watch('status')) === 1 ? (
                                            <FaCheck style={{ color: "#8ec639", fontSize: "17px" }} />
                                        ) : (
                                            <MdClose
                                                style={{
                                                    color: "#8ec639",
                                                    fontSize: "20px",
                                                    paddingTop: "3px",
                                                }}
                                            />
                                        )}
                                        VERIFIED
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
