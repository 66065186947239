import React, { useEffect, useRef } from 'react';
import { useFormContext } from '../../../../providers';

export const ListingTypeSelect = () => {
    const { register, errors } = useFormContext();
    const fieldRefs = useRef({});

    useEffect(() => {
        if (errors) {
            const firstErrorField = Object.keys(errors)[0];
            if (fieldRefs.current[firstErrorField]) {
                fieldRefs.current[firstErrorField].scrollIntoView({ behavior: "smooth", block: "center" });
                fieldRefs.current[firstErrorField].focus();
            }
        }
    }, [errors]);

    return (
        <div className="grid grid-cols-1 bg-white p-4 rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Select Listing Type</h2>

            <div ref={(el) => (fieldRefs.current["listing_type"] = el)}>
                <label className="block font-medium mb-2">Listing Type</label>
                <select {...register('listing_type')} className="input w-full p-2 border border-gray-300 rounded">
                    <option value="">Select a listing type</option>
                    <option value="Short-Term Rental">Short-Term Rental</option>
                    {/* <option value="Long-Term Rental">Long-Term Rental</option> */}
                    <option value="Adventures" disabled>Adventures (Coming Soon)</option>
                </select>
                {errors.listing_type && <p className="text-red-500 mt-1">{errors.listing_type.message}</p>}
            </div>
        </div>
    );
};
