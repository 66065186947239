import DatePicker from "react-datepicker";
import { useFormContext } from '../../../../../providers';

export const CustomPeriod = () => {
    const { errors, setValue, watch, fieldRefs } = useFormContext();

    const custom_periods = watch('custom_periods');

    const handleCustomPeriodChange = (index, field, value) => {


        const newCustomPeriods = [...custom_periods];
        newCustomPeriods[index][field] = value;
        setValue('custom_periods', newCustomPeriods);
    };

    // const handleAddCustomPeriod = (e) => {
    //     e.preventDefault();

    //     setValue('custom_periods', [
    //         ...custom_periods,
    //         {
    //             start_date: null,
    //             end_date: null,
    //             nightly_price: "",
    //             weekend_price: "",
    //             price_add_guest: "",
    //         },
    //     ]);
    // };

    const handleMinusClickOne = (index) => {
        if (custom_periods.length > 1) {
            const newExtraServicePrices = [...custom_periods];
            newExtraServicePrices.splice(index, 1);
            setValue('custom_periods', newExtraServicePrices);
        }
    };

    return (
        <>
            {custom_periods?.map((period, index) => (
                <>
                    <div className="md:col-span-6 text-left">
                        <label>Start Date</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center">
                            <i className="fal fa-calendar-day" />
                            <DatePicker
                                selected={period.start_date}
                                onChange={(date) => {
                                    handleCustomPeriodChange(index, "start_date", date);
                                }}
                                ref={(el) => (fieldRefs.current[`custom_periods.${index}.start_date`] = el)}
                                className="react-datepicker-wrapper"
                                placeholderText="Start Date"
                            />
                        </div>
                        {errors?.custom_periods?.[index]?.start_date && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-6 text-left">
                        <label>End Date</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center">
                            <i className="fal fa-calendar-day" />
                            <DatePicker
                                selected={period.end_date}
                                onChange={(date) =>
                                    handleCustomPeriodChange(index, "end_date", date)
                                }
                                ref={(el) => (fieldRefs.current[`custom_periods.${index}.end_date`] = el)}
                                placeholderText="End Date"
                                minDate={period.start_date}
                                className="react-datepicker-wrapper"
                            />
                        </div>
                        {errors?.custom_periods?.[index]?.end_date && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-4 text-left">
                        <label>Nightly Price</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center">
                            <i className="fal fa-dollar-sign" />
                            <input
                                type="number"
                                placeholder="Nightly Price"
                                name="nightly_price"
                                ref={(el) => (fieldRefs.current[`custom_periods.${index}.nightly_price`] = el)}
                                value={period.nightly_price}
                                onChange={(e) =>
                                    handleCustomPeriodChange(
                                        index,
                                        "nightly_price",
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        {errors?.custom_periods?.[index]?.nightly_price && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-4 text-left">
                        <label>Weekend Price</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center">
                            <i className="fal fa-dollar-sign" />
                            <input
                                type="number"
                                placeholder="Weekend Price"
                                value={period.weekend_price}
                                ref={(el) => (fieldRefs.current[`custom_periods.${index}.weekend_price`] = el)}
                                name="weekend_price"
                                onChange={(e) =>
                                    handleCustomPeriodChange(
                                        index,
                                        "weekend_price",
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        {errors?.custom_periods?.[index]?.weekend_price && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-4 text-left">
                        <label>Price Per Additional Guest</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center">
                            <i className="fal fa-dollar-sign" />
                            <input
                                type="number"
                                placeholder="Price Per Additional Guest"
                                value={period.price_add_guest}
                                ref={(el) => (fieldRefs.current[`custom_periods.${index}.price_add_guest`] = el)}
                                name="price_add_guest"
                                onChange={(e) =>
                                    handleCustomPeriodChange(
                                        index,
                                        "price_add_guest",
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        {errors?.custom_periods?.[index]?.price_add_guest && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-12 flex justify-start items-start">
                        {custom_periods?.length > 1 && (
                            <button
                                className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                                onClick={() => handleMinusClickOne(index)}
                            >
                                Remove Custom
                            </button>
                        )}
                    </div>
                </>
            ))}

            {/* <div className="md:col-span-12 text-left flex justify-end items-end">
                <button
                    onClick={handleAddCustomPeriod}
                    className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                >
                    Add Custom Period
                </button>
            </div> */}
        </>
    );
};
