import React from 'react';
import '../css/css-styling-options.css';
import '../css/css-post-8728.css';
import '../css/cirt.css';
import Layout from '../../../../Components/layout/Layout';

export const TrademarkPolicy = () => {
    return (<Layout>
        <div id="section-body">
            <div id="has_social_account" style={{ display: "none" }} data-has-social-account=""></div>
            <section class="main-content-area">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                            <div class="page-title">
                                <div class="block-top-title">
                                    <h1 class="listing-title">Trademark Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="page-wrap">
                                <div class="article-main">
                                    <article id="post-7733" class="single-page-article block post-7733 page type-page status-publish hentry">
                                        <div class="article-detail block-body">
                                            <div data-elementor-type="wp-page" data-elementor-id="7733" class="elementor elementor-7733" data-elementor-post-type="page">
                                                <div class="elementor-element elementor-element-38c74140 e-flex e-con-boxed e-con e-parent" data-id="38c74140" data-element_type="container">
                                                    <div class="e-con-inner">
                                                        <div class="elementor-element elementor-element-4acce0d0 elementor-widget elementor-widget-text-editor" data-id="4acce0d0" data-element_type="widget" data-widget_type="text-editor.default">
                                                            <div class="elementor-widget-container">
                                                                <p>This policy is part of our Terms of Service. By using or accessing the Platform, you agree to our Terms of Service as well as the rest of our Policies. We reserve the right to amend this Trademark Policy at any time including any permissions contained herein.</p>
                                                                <h2 class="wp-block-heading">PURPOSE</h2>
                                                                <p>We support our tight-knit Community of aviation enthusiasts. To keep our Community thriving, we need to safeguard the proper use of our Trademarks. Our Trademarks represent us and help maintain FlyInn&rsquo;s strong association with quality and dependability. Our Trademarks symbolize our identity and our reputation for quality and dependability and carry with them the substantial goodwill generated by our Community. Therefore, this policy sets out general guidelines for using our trademarks to facilitate and guarantee their correct use by others to prevent confusion in the public.</p>
                                                                <h2 class="wp-block-heading">DEFINITIONS</h2>
                                                                <p>According to US Patent and Trademark law, a trademark is &ldquo;any word, phrase, symbol, design, or a combination of these things that identifies your goods or services. It is how customers recognize you in the marketplace and distinguish you from your competitors.&rdquo; Accordingly, and for the purpose of simplicity, FlyInn, LLC defines our Trademarks as our names (FlyInn, LLC; FlyInn, Fly-Inn); our logos (the red biplane with FLY-INN on the attitude indicator and any part thereof); Squawks (blog); AlphaHost and the AlphaHost Ribbon; as well as any other identifiers and branding including, but not limited to, our slogans, taglines, songs, sounds, icons, packaging, color palette, mascots, typography, design elements, voice, tone, designs, graphics, videos, and specially designed photography and presets, collectively, &ldquo;Trademarks.&rdquo;</p>
                                                                <h2 class="wp-block-heading">What is not allowed:</h2>
                                                                <ol>
                                                                    <li>Using any identifiers that are confusingly similar to FlyInn&rsquo;s Trademarks</li>
                                                                    <li>
                                                                        Using any of our Trademarks or any part of our Trademarks (including style and design elements) in combination with
                                                                        <ol className='!mb-0'>
                                                                            <li>Any of your trademarks, service marks or logos, or any part thereof.</li>
                                                                            <li>The name of your business</li>
                                                                            <li>The name of your website or domain registration, second or third level domain names, such as &ldquo;flyinnrentals.com&rdquo;</li>
                                                                            <li>The names or handles of any of your social media accounts, profiles or aliases, such as &ldquo;FlyInn Bob&rdquo;</li>
                                                                            <li>Your business purpose, such as &ldquo;Fly-Inn Cleaning Service&rdquo;</li>
                                                                            <li>Any other generic, common or descriptive term</li>
                                                                        </ol>
                                                                    </li>
                                                                    <li>Using our Trademarks as keywords, metatags, triggers, hashtags, or any web link that doesn&rsquo;t resolve to https://fly-inn.com</li>
                                                                    <li>Changing, modifying or altering any part of our Trademarks or branding including, but not limited to, hyphenating, abbreviating, shortening, truncating or creating acronyms; adding or deleting words; lengthening; altering designs; altering colors, backgrounds, angles, or any other quality; reducing to a size where the Trademark is no longer easy to identify</li>
                                                                    <li>Using our Trademarks as part of a larger mark</li>
                                                                    <li>Using our Trademarks in plural or possessive forms</li>
                                                                    <li>Using variations of our Trademarks</li>
                                                                    <li>Using translations of our Trademarks into foreign languages</li>
                                                                    <li>Using old versions of our Trademarks</li>
                                                                    <li>Using &ldquo;FlyInn&rdquo; or &rdquo;Fly-Inn&rdquo; in lieu of &ldquo;vacation rental,&rdquo; &ldquo;short-term accommodations,&rdquo; et al</li>
                                                                    <li>Using &ldquo;FlyInn&rdquo; or &rdquo;Fly-Inn&rdquo; in a way that suggests to others that we are in a partnership or sponsorship relationship, or that we are affiliated with or endorse you, your business, products or services, unless we have specifically authorized it</li>
                                                                    <li>Using our Trademarks in advertising that is false or misleading, or carries a similar intention</li>
                                                                    <li>Using our Trademarks to, in our sole discretion, maliciously, illegally or unethically defame or disparage FlyInn or our Community</li>
                                                                    <li>Using our Trademarks in a way that they are displayed more prominently than your own trademarks including, but not limited to, your names, product or service names</li>
                                                                    <li>Using our Trademarks, especially word marks, more frequently than necessary</li>
                                                                    <li>Displaying our Trademarks in any manner that violates any law, regulation, rule or ordinance</li>
                                                                    <li>Copying or appropriating our trade dress including, but not limited to, our colors and color combinations; typography and fonts and combinations thereof; icons and graphics; design and layout; specially designed photography and presets; and any other of our brand elements and brand identity</li>
                                                                </ol>
                                                                <p>If you would like permission to use our Trademarks, please email us at legal@fly-inn.com. With the exceptions outlined below, you may only use our Trademarks with our express written consent. Any consent granted will be predicated upon your agreement to follow any the guidelines set forth in this document as well as any further guidelines we require regarding the use of our Trademarks and your agreement to follow within a reasonable amount of time future guidelines we may issue.You may use FlyInn&rsquo;s Trademarks to refer to our company, product or service only if you follow the guidelines established herein and if you use them in a manner that is in line with maintaining the mark&rsquo;s value and goodwill and that is accurate, not disparaging, not misleading and fair to FlyInn and our Community.</p>
                                                                <h2 class="wp-block-heading">What is allowed and/or expected:</h2>
                                                                <ol>
                                                                    <li>Using our name correctly. FlyInn, Fly-Inn, and FlyInn, LLC are all appropriate</li>
                                                                    <li>Using our Trademarks only as adjectives modifying a generic name or noun. They may not be used as a noun on their own or as a verb</li>
                                                                    <li>Using our name to describe your relationship with FlyInn, such as &ldquo;FlyInn Host&rdquo;</li>
                                                                    <li>Using our name to talk about products or services offered on the Platform, such as &ldquo;FlyInn Listing,&rdquo; or &ldquo;FlyInn Squawks&rdquo;</li>
                                                                    <li>Using the most current versions of our Trademarks to identify and hyperlink to our home page, https://fly-inn.com. No separate written authorization is required</li>
                                                                    <li>Using and displaying our attribution statement prominently. It reads, &ldquo;FlyInn, LLC&rsquo;s [Mark] and the [Logo name or description] are trademarks and/or service marks of FlyInn, LLC.&rdquo;</li>
                                                                </ol>
                                                                <p>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>
                                                                <h2 class="wp-block-heading">COMMUNITY</h2>
                                                                <p>Maintaining our Trademark&rsquo;s value and goodwill benefits our whole Community. Please support your Community by emailing us at <a href="mailto:legal@fly-inn.com">legal@fly-inn.com</a> if you notice any violation of the guidelines set forth in this Trademark Policy.</p>
                                                                <p>&nbsp;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
    )
}
