import React from 'react';
import { useFormContext } from '../../../providers';

export const SocialMediaForm = () => {

  const {
    register,
    errors,
  } = useFormContext();


  return (
    <div className="profile-edit-container fl-wrap block_box">
      <div className="custom-form">
        <div style={{ display: 'flex', justifyContent: 'center' }} className="dashboard-title">
          <h3>Social Media</h3>
        </div>
        <div className="row">
          {['facebook', 'instagram', 'airbnb', 'vrbo', 'youtube', 'twitter', 'linkedin', 'pinterest', 'vimeo', 'top_advisor'].map((platform, idx) => (
            <div className="col-sm-6" key={idx} style={{ marginTop: '12px' }}>
              <label>
                {`${platform.charAt(0).toUpperCase() + platform.slice(1)} URL`} {/* Capitalize the first letter */}
                <i className={`icon-${platform}`} style={{ position: 'absolute', top: '40px', left: '14px', fontSize: '14px' }} />
              </label>
              <input
                type="text"
                placeholder={`${platform.charAt(0).toUpperCase() + platform.slice(1)} URL`}
                {...register(`${platform}_url`)}
              />
              {errors[`${platform}_url`] && (
                <span style={{ color: 'red' }}>{errors[`${platform}_url`]?.message}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
