import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { PASSWORD_VALIDATION_SCHEMA } from '../../utils/schema';
import { axiosInstanceWithToken } from '../../helpers/axios';


export const PasswordSection = ({ data }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({
        resolver: yupResolver(PASSWORD_VALIDATION_SCHEMA),
    });

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const currentPassword = watch('currentPassword');
    // const newPassword = watch('newPassword');

    // Toggle visibility functions
    const toggleCurrentVisibility = () => setShowCurrentPassword(!showCurrentPassword);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const togglePasswordConfirm = () => setShowConfirmPassword(!showConfirmPassword);

    // Handle form submission
    const onSubmit = useCallback(async (formData) => {
        console.log('Form Submitted:', formData);

        console.log(formData)

        try {
            const response = await axiosInstanceWithToken.post('/auth/change-password', formData);
            console.log(response)
        } catch (err) {
            console.log(err)
        }
        // Add logic to handle password update
    }, []);

    return (
        <div className="profile-edit-container fl-wrap block_box">
            <div className="custom-form">
                <div className="row">
                    {/* Current Password Field */}
                    <div className="col-sm-12 relative">
                        <label>
                            Current Password <i className="fal fa-lock" />
                        </label>
                        <input
                            type={showCurrentPassword ? 'text' : 'password'}
                            placeholder="Current Password"
                            {...register('current_password')}
                        />
                        <span className="absolute md:right-8 top-12 right-8" onClick={toggleCurrentVisibility}>
                            {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                        {errors.current_password && <p className="error-message" style={{ color: 'red' }}>{errors.current_password.message}</p>}
                        {currentPassword !== data?.password && currentPassword && (
                            <p className="error-message" style={{ color: 'red' }}>
                                Please make sure your current password is correct.
                            </p>
                        )}
                    </div>

                    {/* New Password Field */}
                    <div className="col-sm-6 relative">
                        <label>
                            New Password <i className="fal fa-lock" />
                        </label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="New Password"
                            {...register('password')}
                        />
                        <span className="absolute md:top-[3.3rem] md:right-8 top-[8.3rem] right-8" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                        {errors.password && <p className="error-message" style={{ color: 'red' }}>{errors.password.message}</p>}
                    </div>

                    {/* Confirm Password Field */}
                    <div className="col-sm-6 relative">
                        <label>
                            Confirm Password <i className="fal fa-lock" />
                        </label>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            {...register('password_confirmation')}
                        />
                        <span className="absolute md:top-[3.3rem] md:right-8 top-[13.3rem]  right-8" onClick={togglePasswordConfirm}>
                            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                        {errors.password_confirmation && <p className="error-message" style={{ color: 'red' }}>{errors.password_confirmation.message}</p>}
                    </div>
                </div>

                {/* Submit Button */}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button type="button" className="btn" onClick={handleSubmit(onSubmit)}>
                        <span style={{ color: 'white', fontWeight: '700' }}>Update Password</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
