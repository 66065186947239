import React from 'react';

export const SubmitAndDraftSection = () => {
    return (
        <div className="flex justify-end gap-4 p-6 bg-white sticky bottom-0 w-full">
            <button
                type='submit'
                className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
            >
                <i className="fas fa-file mr-2" />Save
            </button>
        </div>
    )
}
