import TailwindHeader from "../layout/TailwindHeader";
import Sidebar from "./Sidebar";

export default function SidebarLayout({ children }) {
  return (
    <>
      <div className="row">
        <div className="col-md-12" style={{ borderBottom: 'solid 1px #d8dce1' }}>
          <TailwindHeader logo={true} profile={true} />
        </div>

        <hr />
        <div className="col-md-2 md:w-1/5 lg:h-screen bg-gray-100 ">
          <Sidebar />
        </div>
        <div className="col-md-10 md:w-4/5 lg:h-screen pt-[3rem] lg:pt-[unset]">
          {children}
        </div>
      </div>

    </>
  );
}
