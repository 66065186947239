import React from "react";
import { useProfile } from "../../hooks/useProfile";

export const Button = ({ children, onClick, type = "button" }) => {
    const { profileCompleteness } = useProfile();
    return (
        <div className="w-full flex justify-end items-center  bg-white p-4">
            <button
                type={type}
                onClick={onClick}
                className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3 mt-4"
                disabled={profileCompleteness !== 100}
            >
                {children}
            </button>
        </div>
    );
}
