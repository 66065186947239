import React, { useEffect } from "react";
import Layout from "../Components/layout/Layout";
import HeroSection from "../Components/HeroSection";
import { useListing } from "../providers";

const Home = () => {

  const { getAllListings } = useListing();

  useEffect(() => {
    getAllListings();
  }, [getAllListings])


  return (
    <Layout>
      <HeroSection />
    </Layout>
  );
};

export default Home;
