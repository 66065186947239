import React from "react";
import Bannerfour from "./Bannerfour";
import LocationMap from "./map/LocationMap";
import { FormProvider } from "../providers";
import { SEARCH_MAP_DEFAULT_VALUES } from "../constants";
import { SEARCH_MAP_VALIDATION } from "../utils";
import DwellingImg from "./DwellingImg";
import Feature from "../Pages/Feature";
import FindUsImage from "./ui/FindUsImage";

function HeroSection() {



  return (
    <FormProvider validationSchema={SEARCH_MAP_VALIDATION} defaultValues={SEARCH_MAP_DEFAULT_VALUES}>
      <LocationMap />
      <Feature />
      <DwellingImg />
      <FindUsImage />
      <Bannerfour />
    </FormProvider>
  );
}

export default HeroSection;
