import React, { useState } from "react";
import { useAuthHandler } from "../../hooks";
import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

export const ModalPayment = ({ show, onClose, title, userData }) => {

    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const { getCurrentUser } = useAuthHandler();


    const handleEmailChange = (e) => {
        setEmail(e?.target?.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            setMessage("Stripe has not loaded yet.");
            return;
        }

        setIsProcessing(true);

        // Confirm the payment with saved details
        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/account-settings/payments-success`,
                payment_method_data: {
                    billing_details: {
                        name: `${userData?.first_name} ${userData?.last_name}`, // Full name
                        email: email || userData?.email, // Email address
                        address: {
                            line1: userData?.address,
                            line2: userData?.unit_no,
                            city: userData?.city,
                            state: userData?.state,
                            postal_code: userData?.zip_code,
                            country: 'US',
                        },
                    },
                },
            },
        });

        if (error) {
            setMessage(error.message);
            setIsProcessing(false);
        } else {
            setMessage("Payment details successfully saved!");
            getCurrentUser().then(() => {
                setIsProcessing(false);
            });
        }
    };

    if (!show) return <></>;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-[100]">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">{title}</h2>

                    <button onClick={onClose} className="text-gray-600 p-3">
                        ✕
                    </button>
                </div>
                <hr />
                <form onSubmit={handleSubmit} className="p-6 border rounded-md max-w-lg mx-auto h-[500px] overflow-scroll">

                    <div className="mb-4">
                        <LinkAuthenticationElement
                            id="link-authentication-element"
                            onChange={(e) => handleEmailChange(e)}
                            className="w-full border rounded-md"
                            options={{
                                defaultValues: { email: userData?.email },
                            }}
                        />
                    </div>

                    <div className="mb-4">
                        <PaymentElement
                            id="payment-element"
                            options={{
                                layout: "tabs",
                                style: {
                                    base: {
                                        fontSize: "16px",
                                        fontFamily: "Arial, sans-serif",
                                        color: "#31325F",
                                        "::placeholder": { color: "#CFD7E0" },
                                    },
                                    invalid: { color: "#e25950" },
                                },
                            }}
                        />
                    </div>

                    <button
                        type="submit"
                        className="bg-[#8EC639] text-white py-2 px-4 rounded-md hover:bg-green-600 w-full"
                        disabled={!stripe || isProcessing}
                    >
                        {isProcessing ? "Processing..." : "Save Payment Method"}
                    </button>

                    {message && <p className="text-red-500 mt-4">{message}</p>}
                </form>
            </div>
        </div>
    );
}

