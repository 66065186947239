
// export const SERVER_URL = 'https://flyinn-backend-i8oh5.kinsta.app';
export const SERVER_URL = `https://flyinn-backend-development-g6l85.kinsta.app`;
// export const SERVER_URL = `http://127.0.0.1:8000`;

export const SERVER_IMAGES_URL = `${SERVER_URL}/storage`;

export const ADD_LISTING_DEFAULT_VALUES = {
    is_draft: 0,
    host_id: '',
    listing_type: '',
    address: "",
    apt_suite: "",
    city: "",
    state: "",
    zipcode: "",
    area: "",
    country: "",
    latitude: 0,
    longitude: 0,
    images: [],
    title: "",
    type_of_space: "",
    lodging_type: "",
    no_of_bedrooms: 0,
    no_of_guest: 1,
    no_of_beds: 0,
    no_of_bathrooms: 1,
    no_of_rooms: 0,
    size: 1,
    unit_of_measure: 'ft',
    description: '',
    bedrooms: [],
    airports: [
        {
            airport_identifier: '',
            airport_name: '',
            airport_use: "",
            ctaf_unicom: "",
            dimension_feets: "",
            dimension_meters: "",
            elevation_feets: 0,
            elevation_meters: 0,
            fuel: [],
            lighting: 0,
            operation_hours: "",
            orientation: "",
            runway_condition: "",
            parking: "",
            pattern: "",
            surface: [],
            condition: "",
            air_nav: '',
            ground_transportation: "",
            distance_from_runway: "",
            additional_info: ''
        }
    ],
    instant_booking: 0,
    nightly_price: 0,
    apply_weekend_price: "None",
    weekend_nightly_price: 0,
    night_price_seven_plus: 0,
    night_price_thirty_plus: 0,
    additional_guest: 0,
    no_of_additional_guest: 0,
    additional_guest_price: 0,
    pet_allowed: 0,
    no_of_pets: 0,
    price_per_pet: 0,
    cleaning_fee: 0,
    cleaning_freq: "Daily",
    city_fee: 0,
    city_fee_freq: "Daily",
    tax_percentage: 0,
    custom_period_pricing: 0,
    custom_periods: [

    ],
    extra_services: 0,
    extra_service: [

    ],
    features: [
        {
            id: '',
            sub_features: [],
        }
    ],
    cancellation_policy_short: null,
    cancellation_policy_long: null,
    min_day_booking: 0,
    max_day_booking: 10,
    check_in_after: '',
    check_out_before: '',
    smoking_allowed: 0,
    smoking_rules: '',
    rules_pet_allowed: 0,
    pet_rules: '',
    party_allowed: 0,
    party_rules: '',
    children_allowed: 0,
    children_rules: '',
    infant_start_age: '',
    infant_end_age: '',
    child_start_age: '',
    child_end_age: '',
    rules: '',
    welcome_message: '',
    blocked_dates: [],
    priority: 200
};

export const SAMPLE_LISTING_DATA = {
    blocked_dates: [
        {
            type: "blocked",
            end_date: "2025-01-14T19:00:00.000Z",
            start_date: "2025-01-14T19:00:00.000Z"
        }
    ],
    welcome_message: `<p><span style="color: rgb(59, 66, 73); font-family: Quicksand, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(247, 248, 249); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">Check in check out times can be flexible with requests depending on availability.</span></p>`,
    rules: `<p>Check in check out times can be flexible with requests depending on availability.</p>`,
    child_end_age: 14,
    child_start_age: 5,
    infant_end_age: 21,
    infant_start_age: 2,
    children_rules: "no",
    children_allowed: 1,
    party_rules: "no",
    party_allowed: "0",
    pet_rules: "",
    rules_pet_allowed: 0,
    smoking_rules: "no",
    smoking_allowed: "0",
    check_out_before: "11:00 AM",
    check_in_after: "03:00 PM",
    max_day_booking: "7",
    min_day_booking: 1,
    cancellation_policy_long: 6,
    cancellation_policy_short: 1,
    custom_period_pricing: 0,
    custom_periods: [

    ],
    extra_services: 0,
    extra_service: [
    ],
    features: [
        {
            id: '',
            sub_features: [],
        }
    ],
    tax_percentage: 5,
    city_fee_freq: "Daily",
    city_fee: "10",
    cleaning_freq: "Daily",
    cleaning_fee: "10",
    price_per_pet: "0",
    no_of_pets: 0,
    pet_allowed: "0",
    additional_guest_price: "175",
    no_of_additional_guest: "2",
    additional_guest: "1",
    night_price_thirty_plus: "175",
    night_price_seven_plus: "175",
    weekend_nightly_price: "175",
    apply_weekend_price: "Saturday and Sunday",
    nightly_price: "175",
    instant_booking: 0,
    airports: [
        {
            distance_from_runway: "8",
            additional_info: "asdasd",
            ground_transportation: "We have a courtesy car waiting for you!",
            runway_condition: " in excellent condition",
            surface: ["Ice"],
            pattern: "left, left",
            parking: "Tie-downs",
            orientation: "06/24",
            operation_hours: "24",
            lighting: 0,
            fuel: ["other"],
            elevation_meters: 177,
            elevation_feets: 580,
            dimension_meters: "586X30m",
            dimension_feets: "1924X100ft",
            ctaf_unicom: 122.9,
            air_nav: "https://www.airnav.com/airport/LL77",
            airport_name: "Herrens Bess Hollow Airfield",
            airport_use: "Private",
            airport_identifier: "LL77",
            condition: ""
        }
    ],
    bedrooms: [
        { bed_type: "Queen", no_of_bed: 1, no_of_guest: 2, name: "Master" },
        { bed_type: "Queen", no_of_bed: 1, no_of_guest: 2, name: "Loft 1" },
        { bed_type: "Queen", no_of_bed: 1, no_of_guest: 2, name: "Loft 2" },
        {
            name: "Sofa sleeper",
            no_of_guest: "2",
            no_of_bed: "1",
            bed_type: "Queen"
        }
    ],
    description: `<p>Welcome to the rolling green hills of the Kingdom of Calhoun County in southwest Illinois. The cabin is nestled in the woods, overlooking the pond and valley of a working exotic animal farm. Upon arriving, guests and their gear will be carried up to the cabin in a Polaris ATV. The cabin has heat/AC, stove, refrigerator, microwave, toaster, all kitchenware, bathroom with shower, propane BBQ grill on deck, and firepit. It comfortably sleeps 6. Each end of the cabin has a private room with a queen-sized bed and a loft with a queen-sized mattress suitable for young adults and children. A sofa sleeper is also there for extra guests. Hiking trails through the woods lead to “the old abandoned gold mine” where kids can search the sandpit for diamonds, gold, and arrowheads.</p><p>The well-maintained grass airstrip LL77 is actually 3400’ x 100’ with FAA reduced threshold. Grass is typically 3-4” high. Info can be found on AirNav. Please get in touch with your host after booking for strip conditions.</p><p>Please let us know if you need anything here before your arrival!</p><p>Price is based on double occupancy.</p>`,
    unit_of_measure: "ft",
    size: "540",
    no_of_rooms: "2",
    no_of_bathrooms: "1",
    no_of_beds: 3,
    no_of_guest: 6,
    no_of_bedrooms: "1",
    lodging_type: "Cabin",
    type_of_space: "Entire Place",
    title: "Herrens Bess Hollow ",
    images: [],
    longitude: -90.6928624,
    latitude: 39.3073074,
    country: "United States",
    zipcode: "62053",
    state: "IL",
    city: "Illinois ",
    address: "Bess Hollow Rd, Illinois 62053, USA",
    listing_type: "Short-Term Rental",
    host_id: "",
    is_draft: 0,
    apt_suite: "",
    area: "Bess Hollow Road"
};

export const PROFILE_FORM_DEFAULT_VALUES = {
    // Personal Information
    first_name: '',
    last_name: '',
    username: '',
    given_name: '',
    native_language: '',
    other_language: '',
    phone: '',
    other_phone: '',
    email: '',
    additional_email: '',
    bio: '',

    // Documents
    air_men: null,
    air_men_back: null,
    driving_license: null,
    driving_license_back: null,

    // Address Information
    address: '',
    unit_no: '',
    city: '',
    state: '',
    zip_code: '',
    neighbourhood: '',
    country: '',
    same_as_mailing: false,

    // Emergency Contact
    contact_name: '',
    contact_relationship: '',
    contact_email: '',
    contact_phone: '',

    // Social Media URLs
    facebook_url: '',
    instagram_url: '',
    airbnb_url: '',
    vrbo_url: '',
    youtube_url: '',
    twitter_url: '',
    linkedin_url: '',
    pinterest_url: '',
    vimeo_url: '',
    top_advisor_url: '',

    // Image Upload
    image: null,
};

export const SEARCH_MAP_DEFAULT_VALUES = {
    destination: "",
    arrival_date: null,
    departure_date: null,
    no_of_guest: '',
    no_of_rooms: '',
    no_of_bedrooms: '',
    lodging_type: '',
    type_of_space: '',
    closest_runway: '',
    price_min: '',
    price_max: '',
    features: [],
    sub_features: []
}

// Reserved words array for username validation
export const RESERVED_WORDS = [
    "admin", "support", "moderator", "owner", "root", "superuser", "alphahost", "administrator",
    "user", "guest", "developer", "webmaster", "manager", "api", "login", "logout", "system",
    "bot", "server", "adminpanel", "database", "dashboard", "backend", "frontend", "secure",
    "security", "password", "ssl", "privacy", "encryption", "www", "http", "https", "html", "ftp",
    "smtp", "FlyInn", "Fly-Inn", "test", "sample", "placeholder", "example", "demo", "support",
    "team", "helpdesk", "customerservice", "terms", "policy", "legal", "privacy", "contactus",
    "billing", "payment", "online", "offline", "active", "inactive", "banned", "verified", "chat",
    "inbox", "notification", "message", "messenger"
];

export const RESTRICTED_DOMAINS = [
    'mailinator.com',
    'tempmail.com',
    'trashmail.com',
    '10minutemail.com',
    'guerrillamail.com',
    'maildrop.cc',
    'yopmail.com',
    'throwawaymail.com',
    'getnada.com',
    'temp-mail.org',
    'dispostable.com',
    'fakeinbox.com',
    'emailondeck.com',
    'mohmal.com',
    'spamgourmet.com',
    'burnermail.io',
    'spambog.com',
    'discard.email',
    'sharklasers.com',
    'mintemail.com',
    'mytemp.email',
    'fakemail.net',
    'anonbox.net',
    'jetable.org',
    'wegwerfemail.de',
    'mailcatch.com',
    'emlpro.com',
    'owlymail.com',
    'tempmailo.com',
    'easytrashmail.com',
    'e4ward.com',
    'spam4.me',
    'eyepaste.com',
    'boun.cr',
    'opayq.com',
    'fastmailbox.net',
    'tempail.com',
    'mail.tm',
    'mailnesia.com',
    'altmails.com',
    'inboxkitten.com',
    'trashmail.de',
    'hushmail.com',
    'vip-mail.net',
    'mailsac.com',
    'moakt.com',
    'luxusmail.org',
    'cyberfear.com',
    'relay.firefox.com',
    '24hourmail.com',
    'shortmail.net'
];

export const LODGING_TYPES = [
    "Apt. / Condo / Loft",
    "Bed & Breakfast",
    "Cabin",
    "House",
    "Campsite",
    "Farm",
    "Hangar",
    "Hangar Home",
    "Mansion",
    "Novelty",
    "RV",
    "RV Pad",
    "Tiny Home",
];

export const LODGING_TYPES_DROPDOWN = [
    { text: "Apartment", name: "Apartment_Condo_Loft", type: "lodging_type", link: "lodging_type/Apt_Condo_Loft", image: "images/all/homedwelling11.webp", },
    { name: "Bed & Breakfast", type: "lodging_type", link: "lodging_type/Bed_&_Breakfast", image: "images/all/homedwelling2.webp", },
    { name: "Cabin", type: "lodging_type", link: "lodging_type/Cabin", image: "images/all/homedwelling3.webp", },
    { name: "Campsite", type: "lodging_type", link: "lodging_type/Campsite", image: "images/all/homedwelling4.webp", },
    { name: "Farm", type: "lodging_type", link: "lodging_type/Farm", image: "images/all/homedwelling6.webp", },
    { name: "Hangar", type: "lodging_type", link: "lodging_type/Hangar", image: "images/all/homedwelling7.webp", },
    { name: "Hangar Home", type: "lodging_type", link: "lodging_type/Hangar_Home", image: "images/all/homedwelling8.webp", },
    { name: "House", type: "lodging_type", link: "lodging_type/House", image: "images/all/homedwelling9.webp", },
    { name: "Mansion", type: "lodging_type", link: "lodging_type/Mansion", image: "images/all/homedwelling12.webp", },
    { name: "Novelty", type: "lodging_type", link: "lodging_type/Novelty", image: "images/all/homedwelling14.webp", },
    { name: "RV", type: "lodging_type", link: "lodging_type/RV", image: "images/all/rv.png", },
    { name: "RV Pad", type: "lodging_type", link: "lodging_type/RV_Pad", image: "images/all/rvpad.png", },
    { name: "Tiny Home", type: "lodging_type", link: "lodging_type/Tiny_Home", image: "images/all/homedwelling14.webp", }
];

export const SPACE_TYPES_DROPDOWN = [
    { name: "Entire Place", type: "type_of_space", link: "type_of_space/Entire_Place" },
    { name: "Private Room", type: "type_of_space", link: "type_of_space/Private_Room" },
    { name: "Shared Room", type: "type_of_space", link: "type_of_space/Shared_Room" }
];

export const SNAZZY_MAP_STYLES = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#edebe4"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d1ecc7"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#bddddd"
            },
            {
                "lightness": 17
            }
        ]
    }
]


