import React, { createContext, useState, useContext, useCallback } from "react";
import { axiosInstanceWithToken } from "../../helpers/axios";
import { errorPopup } from "../../helpers";

const PaymentContext = createContext();

export const usePayment = () => {
    return useContext(PaymentContext);
};

export const PaymentProvider = ({ children }) => {
    const [paymentMethod, setPaymentMethod] = useState(null);

    const getPaymentMethods = useCallback(async () => {
        try {
            const storedData = JSON.parse(localStorage.getItem("data"));
            if (storedData?.payment_customer_id !== null) {
                const response = await axiosInstanceWithToken.get(`/payment/method?customer_id=${storedData?.payment_customer_id}`);

                if (!response?.data?.status) {
                    errorPopup('', 'Unable to retrieve your payment details!');
                    return;
                }

                setPaymentMethod(response?.data?.payment_methods)
            }
        } catch (error) {
            console.error("Error fetching payment methods:", error.message);
            throw error;
        }
    }, []);

    const updatePaymentMethods = useCallback(async (id) => {
        try {
            // Create form data
            let data = new FormData();
            data.append('_method', 'PUT');
            data.append('type', 'card');
            data.append('default', 1);

            // Send the request
            const response = await axiosInstanceWithToken.post(`/payment/method/${id}`, data);

            // Check the response status
            if (!response?.data?.status) {
                errorPopup('', 'Unable to retrieve your payment details!');
                return;
            }

            // Refresh payment methods
            getPaymentMethods();
        } catch (error) {
            console.error("Error changing payment methods:", error.message);
            throw error;
        }
    }, [getPaymentMethods]);

    const deletePaymentMethods = useCallback(async (id) => {
        try {
            const response = await axiosInstanceWithToken.delete(`/payment/method/${id}`);

            if (!response?.data?.status) {
                errorPopup('', 'Unable to retrieve your payment details!');
                return;
            }

            getPaymentMethods();
        } catch (error) {
            console.error("Error fetching payment methods:", error.message);
            throw error;
        }
    }, [getPaymentMethods]);

    return (
        <PaymentContext.Provider value={{ paymentMethod, getPaymentMethods, deletePaymentMethods, updatePaymentMethods }}>
            {children}
        </PaymentContext.Provider>
    );
};
