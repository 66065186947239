import React, { useEffect, useState } from 'react';
import { ProfileForm } from '../../Components/Profile';
import { FormProvider } from '../../providers';
import { PROFILE_VALIDATION_SCHEMA } from '../../utils';
import { PROFILE_FORM_DEFAULT_VALUES } from '../../constants';
import { axiosInstanceWithToken } from '../../helpers/axios';
import { infoPopupMessage, warningPopup } from '../../helpers';

export const ProfileFragment = () => {
    const [profileData, setProfileData] = useState(PROFILE_FORM_DEFAULT_VALUES);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        try {
            axiosInstanceWithToken.get('/current-user').then(res => {
                setProfileData({
                    ...PROFILE_FORM_DEFAULT_VALUES,
                    ...res?.data?.user
                })
                if (res?.data?.user) {
                    if (res?.data?.user?.profile_completeness === 99) {
                        infoPopupMessage(`
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                <p style="text-align: center">Your account is now under review. Please give the system some time to verify.</p>
                                <p style="text-align: center">
                                  Verification completes your profile to 100%. The process may take up to 60 minutes. 
                                </p>
                                <p style="text-align: center">
                                You will receive an email notification when the process is complete. </p>
                                </div>
                            `)
                    }
                    else if (res?.data?.user?.profile_completeness < 99) {
                        warningPopup(`
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                <p style="text-align: center">Please complete your profile to 100%. You will not be able to host or book until it is complete.</p>
                                <p style="text-align: center">
                                 Once we have verified your identity, you will receive a message letting you know you are all set.
                                </p>
                                <p style="text-align: center">
                                 To complete your profile properly, watch the Registration video on our <br/> <a  target="_blank" style="color: #af2322; text-decoration: underline;" href="https://youtube.com/@fly-inn">YouTube Channel</a>.
                                </p>
                                </div>
                            `)

                    }
                    setIsLoading(false);
                }
            });
        } catch (err) {
            console.log(err)
        }
    }, []);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen flex-col gap-5">
                <img
                    className="w-36 h-auto md:w-44" // Responsive sizes
                    src="/image/fly-inn-logo.svg"
                    alt="Fly Inn Logo"
                />
                <div className="text-2xl text-bold"> Please Wait while we load your data!</div>
            </div>
        );
    }

    return (
        <FormProvider type={'profile'} validationSchema={PROFILE_VALIDATION_SCHEMA} defaultValues={profileData}>
            <ProfileForm />
        </FormProvider>
    )
}
