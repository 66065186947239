import React from 'react'
import PhoneInput from 'react-phone-number-input';
import { getIconClass } from '../../../helpers'
import 'react-phone-number-input/style.css' // Don't forget to import the styles
import { useFormContext } from '../../../providers'


export const EmergencyContact = () => {
    // Set up the form with react-hook-form and validation
    const { register, errors, setValue, getValues, fieldRefs } = useFormContext();

    return (
        <div className="profile-edit-container fl-wrap block_box">
            <div className="custom-form">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                    className="dashboard-title"
                >
                    <h3>Emergency Contact</h3>
                </div>
                <div className="row">
                    <div className="col-sm-6" ref={(el) => (fieldRefs.current["contact_name"] = el)}>
                        <label>
                            Contact Name{" "}
                            <i className={getIconClass("Contact Name")} />
                        </label>
                        <input
                            type="text"
                            {...register('contact_name', { onChange: (e) => setValue('contact_name', e.target.value, { shouldValidate: true }) })}
                            placeholder="Contact Name"
                        />
                        {errors.contact_name && (
                            <span style={{ color: "red" }}>{errors.contact_name.message}</span>
                        )}
                    </div>

                    <div className="col-sm-6" ref={(el) => (fieldRefs.current["contact_relationship"] = el)}>
                        <label>
                            Relationship{" "}
                            <i className={getIconClass("Relationship")} />
                        </label>
                        <input
                            type="text"
                            {...register('contact_relationship', { onChange: (e) => setValue('contact_relationship', e.target.value, { shouldValidate: true }) })}
                            placeholder="Relationship"
                        />
                        {errors.contact_relationship && (
                            <span style={{ color: "red" }}>{errors.contact_relationship.message}</span>
                        )}
                    </div>

                    <div className="col-sm-6" style={{ marginTop: "12px" }} ref={(el) => (fieldRefs.current["contact_email"] = el)}>
                        <label>
                            Email <i className={getIconClass("Email")} />
                        </label>
                        <input
                            type="email"
                            {...register('contact_email', { onChange: (e) => setValue('contact_email', e.target.value, { shouldValidate: true }) })}
                            placeholder="Email"
                        />
                        {errors.contact_email && (
                            <span style={{ color: "red" }}>{errors.contact_email.message}</span>
                        )}
                    </div>

                    <div className="col-sm-6" style={{ marginTop: "12px" }} ref={(el) => (fieldRefs.current["contact_phone"] = el)}>
                        <label>
                            Phone
                        </label>
                        <PhoneInput
                            {...register('contact_phone')}
                            placeholder="Enter phone number"
                            defaultCountry="US"
                            value={getValues('contact_phone') || ''}
                            onChange={(value) => {
                                if (!value || value.length <= 15) {
                                    setValue('contact_phone', value, { shouldValidate: true });
                                }
                            }}
                        />
                        {errors.contact_phone && <span style={{ color: 'red' }}>{errors.contact_phone.message}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
