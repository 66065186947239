import React from "react";
import Swal from "sweetalert2";
import { MdQuestionMark } from "react-icons/md";
import { useFormContext } from "../../../../providers";
import { getIconClass } from "../../../../helpers/ListingIcons";
import { CustomPeriod, ExtraService } from "./PricingComponents";


export const PricingForm = () => {
    const { errors, handleChange, watch, setValue, fieldRefs } = useFormContext();

    const custom_period_pricing = watch('custom_period_pricing');

    const extra_service = watch('extra_service');
    const instant_booking = watch('instant_booking');
    const custom_periods = watch('custom_periods');


    const handleAddCustomPeriod = (e) => {
        e.preventDefault();

        setValue('custom_periods', [
            ...custom_periods,
            {
                start_date: null,
                end_date: null,
                nightly_price: "",
                weekend_price: "",
                price_add_guest: "",
            },
        ]);
    };

    const handlePlusClickOne = (e) => {
        e.preventDefault();
        setValue('extra_service', [
            ...extra_service,
            { name: "", price: "", type: "", quantity: "" },
        ]);
    };

    return (
        <div className="grid md:grid-cols-12 bg-white p-4 rounded-lg gap-4">
            <h3 className="text-xl font-bold text-center md:col-span-12">
                Pricing
            </h3>
            <div className="md:col-span-6 text-left">
                <ul className="flex border border-input px-3 py-3 mt-5 rounded-md gap-4 items-center">
                    <input
                        className="instant_check_box"
                        ref={(el) => (fieldRefs.current[`instant_booking`] = el)}
                        id="check-aaa5"
                        type="checkbox"
                        style={{ padding: '10px' }}
                        name="instant_booking"
                        value={instant_booking === 1 ? 1 : 0}
                        checked={instant_booking === 1}
                        onChange={handleChange}
                    />
                    <label htmlFor="check-aaa5">
                        Allow <strong>Touch & Go</strong>{" "}
                        <span style={{ fontSize: "11px" }}>
                            (book instantly)?
                        </span>
                    </label>
                </ul>
                {errors.instant_booking && (
                    <span style={{ color: "red" }}>
                        {errors.instant_booking?.message}
                    </span>
                )}
            </div>

            <div className="md:col-span-6 text-left">
                <label>Nightly Price (N)</label>
                <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                    <i className={getIconClass("Nightly Price (N)")} />
                    <input
                        type="number"
                        placeholder="Nightly Price"
                        name="nightly_price"
                        value={watch('nightly_price')}
                        ref={(el) => (fieldRefs.current[`nightly_price`] = el)}
                        onChange={handleChange}
                    />
                </div>
                {errors.nightly && (
                    <span style={{ color: "red" }}>{errors.nightly?.message}</span>
                )}
            </div>

            <div className="md:col-span-6 text-left">
                <label>Apply Weekend Price</label>
                <select
                    value={watch('apply_weekend_price')}
                    onChange={handleChange}
                    name="apply_weekend_price"
                    ref={(el) => (fieldRefs.current[`apply_weekend_price`] = el)}
                    className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full`}
                >
                    <option value={"None"}>
                        None
                    </option>
                    <option value={"Friday and Saturday"}>
                        Friday and Saturday
                    </option>
                    <option value={"Saturday and Sunday"}>
                        Saturday and Sunday
                    </option>
                    <option value={"Friday, Saturday and Sunday"}>
                        Friday, Saturday and Sunday
                    </option>
                </select>
                {errors.apply_weekend_price && (
                    <span style={{ color: "red" }}>
                        {errors.apply_weekend_price?.message}
                    </span>
                )}
            </div>


            {watch('apply_weekend_price') !== 'None' ?
                <div className="md:col-span-6 text-left">
                    <label className="inline-flex items-center">
                        Weekend Nightly Price
                        <MdQuestionMark
                            onClick={() =>
                                Swal.fire({
                                    text: "Enter what price you will charge on weekends, if different from your regular nightly rate.",
                                    icon: "question",
                                })
                            }
                            style={{ color: "#8ec639", cursor: "pointer" }}
                        />
                    </label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                        <i className={getIconClass("Weekend Nightly Price")} />
                        <input
                            type="number"
                            placeholder="Weekend Nightly Price"
                            name="weekend_nightly_price"
                            ref={(el) => (fieldRefs.current[`weekend_nightly_price`] = el)}
                            value={watch('weekend_nightly_price')}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.weekend_nightly_price && (
                        <span style={{ color: "red" }}>
                            {errors.weekend_nightly_price?.message}
                        </span>
                    )}
                </div>

                :
                <div className="md:col-span-6 text-left"></div>
            }


            <div className="md:col-span-6 text-left">
                <label className="inline-flex items-center">
                    Nightly Price (if 7+ nights)
                    <MdQuestionMark
                        onClick={() =>
                            Swal.fire({
                                text: "Enter what price you will charge per night if a guest stays for seven or more nights.",
                                icon: "question",
                            })
                        }
                        style={{ color: "#8ec639", cursor: "pointer" }}
                    />
                </label>
                <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                    <i
                        className={getIconClass("Nightly Price (if 7+ nights)")}
                    />
                    <input
                        type="number"
                        placeholder="Nightly Price (if 7+ nights)"
                        name="night_price_seven_plus"
                        ref={(el) => (fieldRefs.current[`night_price_seven_plus`] = el)}
                        value={watch('night_price_seven_plus')}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="md:col-span-6 text-left">
                <label className="inline-flex items-center">
                    Nightly Price (if 30+ nights)
                    <MdQuestionMark
                        onClick={() =>
                            Swal.fire({
                                text: "Enter what price you will charge per night if a guest stays for 30 or more nights.",
                                icon: "question",
                            })
                        }
                        style={{ color: "#8ec639", cursor: "pointer" }}
                    />
                </label>
                <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                    <i
                        className={getIconClass("Nightly Price (if 30+ nights)")}
                    />
                    <input
                        type="number"
                        placeholder="Nightly Price (if 30+ nights)"
                        name="night_price_thirty_plus"
                        ref={(el) => (fieldRefs.current[`night_price_thirty_plus`] = el)}
                        value={watch('night_price_thirty_plus')}
                        onChange={handleChange}
                    />
                </div>
            </div>
            {/* Guests Section */}
            <div className="md:col-span-4 text-left">
                <label>Additional Guests Allowed </label>
                <select
                    value={watch('additional_guest')}
                    onChange={handleChange}
                    name="additional_guest"
                    ref={(el) => (fieldRefs.current[`additional_guest`] = el)}
                    // className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full ${errors.additional_guest ? "error" : ""
                    //     }`}
                    className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full`}
                >
                    <option disabled value="">
                        Select
                    </option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                </select>
                {errors.additional_guest && (
                    <span style={{ color: "red" }}>
                        {errors.additional_guest?.message}
                    </span>
                )}
            </div>

            {+watch('additional_guest') === 1 ?
                <>
                    <div className="md:col-span-4 text-left">
                        <label>No. of Additional Guests</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                            <i className="fal fa-users" />
                            <input
                                type="number"
                                placeholder="No. of Addt'l Guests"
                                name="no_of_additional_guest"
                                ref={(el) => (fieldRefs.current[`no_of_additional_guest`] = el)}
                                value={watch('no_of_additional_guest')}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.no_of_additional_guest && (
                            <span style={{ color: "red" }}>
                                {errors.no_of_additional_guest?.message}
                            </span>
                        )}
                    </div>
                    <div className="md:col-span-4 text-left">
                        <label>Price Per Additional Guest</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                            <i className="fal fa-dollar-sign" />
                            <input
                                type="number"
                                placeholder="Price Per Addt'l Guest"
                                name="additional_guest_price"
                                ref={(el) => (fieldRefs.current[`additional_guest_price`] = el)}
                                value={watch('additional_guest_price')}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.additional_guest_price && (
                            <span style={{ color: "red" }}>
                                {errors.additional_guest_price?.message}
                            </span>
                        )}
                    </div>
                </>

                :
                <>
                    <div className="md:col-span-4 text-left"></div>
                    <div className="md:col-span-4 text-left"></div>
                </>

            }


            {/* Pets Section */}
            <div className="md:col-span-4 text-left">
                <label>
                    Pets Allowed
                    <i className="fal fa-clock" />
                </label>
                <div className="listsearch-input-item">
                    <select
                        value={watch('pet_allowed')}
                        name="pet_allowed"
                        ref={(el) => (fieldRefs.current[`pet_allowed`] = el)}
                        onChange={handleChange}
                        data-placeholder="Apartments"
                        // className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full ${errors.pet_allowed ? "error" : ""
                        //     }`}
                        className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full`}
                    >
                        <option disabled value={""}>
                            Select
                        </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                    {errors.pet_allowed && (
                        <span style={{ color: "red" }}>
                            {errors.pet_allowed?.message}
                        </span>
                    )}
                </div>
            </div>


            {+watch('pet_allowed') === 1 ?
                <>

                    <div className="md:col-span-4 text-left">
                        <label>No. of Pets</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                            <i className="fal fa-users" />
                            <input
                                type="number"
                                placeholder="No. of pets"
                                name="no_of_pets"
                                ref={(el) => (fieldRefs.current[`no_of_pets`] = el)}
                                value={watch('no_of_pets')}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.no_of_pets && (
                            <span style={{ color: "red" }}>{errors.no_of_pets?.message}</span>
                        )}
                    </div>
                    <div className="md:col-span-4 text-left">
                        <label>Price Per Pet</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                            <i className="fal fa-dollar-sign" />
                            <input
                                type="number"
                                placeholder="Price Per Pet"
                                name="price_per_pet"
                                ref={(el) => (fieldRefs.current[`price_per_pet`] = el)}
                                value={watch('price_per_pet')}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.price_per_pet && (
                            <span style={{ color: "red" }}>
                                {errors.price_per_pet?.message}
                            </span>
                        )}
                    </div>
                </>

                :
                <>

                    <div className="md:col-span-4 text-left"></div>
                    <div className="md:col-span-4 text-left"></div>
                </>

            }

            <div className="md:col-span-6 text-left">
                <label>Cleaning Fee (N)</label>
                <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                    <i className="fal fa-broom" />
                    <input
                        type="number"
                        placeholder="Cleaning Fee"
                        name="cleaning_fee"
                        ref={(el) => (fieldRefs.current[`cleaning_fee`] = el)}
                        value={watch('cleaning_fee')}
                        onChange={handleChange}
                    />
                </div>
                {errors.cleaning_fee && (
                    <span style={{ color: "red" }}>{errors.cleaning_fee?.message}</span>
                )}
            </div>
            <div className="md:col-span-6 text-left">
                <label>Per stay / Daily </label>
                <select
                    value={watch('cleaning_freq')}
                    name="cleaning_freq"
                    ref={(el) => (fieldRefs.current[`cleaning_freq`] = el)}
                    onChange={handleChange}
                    className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full`}>
                    <option disabled value={""}>
                        Select
                    </option>
                    <option value={"Per stay"}>Per stay </option>
                    <option value={"Daily"}>Daily</option>
                </select>
                {errors.cleaning_freq && (
                    <span style={{ color: "red" }}>
                        {errors.cleaning_freq?.message}
                    </span>
                )}
            </div>
            <div className="md:col-span-6 text-left">
                <label className="inline-flex items-center">
                    City Fee (N)
                    <MdQuestionMark
                        onClick={() =>
                            Swal.fire({
                                text: "Very rare. Please check with your city to see if they have one. In New York City, for example, Guests pay an occupancy tax of 5.875% along with a flat 'hotel unit fee' of $1.50 per unit per day. It is this flat fee that we are referring to when we ask if a City Fee applies. If there isn't one, leave this blank.",
                                icon: "question",
                            })
                        }
                        style={{
                            color: "#8ec639",
                            cursor: "pointer",
                        }}
                    />
                </label>
                <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                    <i className="fal fa-city" />
                    <input
                        type="number"
                        placeholder="City Fee"
                        name="city_fee"
                        ref={(el) => (fieldRefs.current[`city_fee`] = el)}
                        value={watch('city_fee')}
                        onChange={handleChange}
                    />
                </div>
                {errors.city_fee && (
                    <span style={{ color: "red" }}>{errors.city_fee?.message}</span>
                )}
            </div>

            <div className="md:col-span-6 text-left">
                <label>Per stay / Daily</label>
                <select
                    value={watch('city_fee_freq')}
                    name="city_fee_freq"
                    ref={(el) => (fieldRefs.current[`city_fee_freq`] = el)}
                    onChange={handleChange}
                    className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full `}>
                    <option disabled value={""}>
                        Select
                    </option>
                    <option value={"Per stay"}>Per stay </option>
                    <option value={"Daily"}>Daily</option>
                </select>
                {errors.city_fee_freq && (
                    <span style={{ color: "red" }}>{errors.city_fee_freq?.message}</span>
                )}
            </div>

            <div className="md:col-span-12 text-left">
                <label className="inline-flex items-center">
                    Tax %
                    <MdQuestionMark
                        onClick={() =>
                            Swal.fire({
                                text: "Please check with your State, County, and City to see what sales tax rates and lodging tax rates, if any, are applied to short-term rentals. FYI: Lodging tax is also known as Hotel Tax, Occupancy Tax, Tourist Tax, Transient Occupancy Tax (TOT), Bed Tax, Visitor Tax, Accommodation Tax, Resort Tax, City Tax, Tourism Levy, and Hospitality Tax, among other names.",
                                icon: "question",
                            })
                        }
                        style={{ color: "#8ec639", cursor: "pointer" }}
                    />
                </label>
                <input
                    className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                    type="number"
                    name="tax_percentage"
                    ref={(el) => (fieldRefs.current[`tax_percentage`] = el)}
                    value={watch('tax_percentage')}
                    onChange={handleChange}
                    placeholder="Combined State, County, City & Lodging Tax"
                />
                {errors.tax_percentage && (
                    <span style={{ color: "red" }}>{errors.tax_percentage?.message}</span>
                )}
            </div>
            {
                (+custom_period_pricing === 1 || custom_periods?.length > 0) &&
                <h3 className="text-xl font-bold text-center md:col-span-12">
                    Custom Period Pricing
                </h3>

            }
            <h4 className="inline-flex items-center md:col-span-12">
                Set Up Custom Period Pricing{" "}
                <MdQuestionMark
                    onClick={() =>
                        Swal.fire({
                            text: "This section is for specifying dates when you want to charge a different price than your regular nightly rate. For example, some Hosts may increase rates during peak seasons like Christmas, while others may offer discounted rates during off-peak periods, such as winter, to attract more guests.",
                            icon: "question",
                        })
                    }
                    style={{ color: "#8ec639", cursor: "pointer" }}
                />
            </h4>

            {/* <div className="md:col-span-12 text-left">
                <div className="listsearch-input-item">
                    <select
                        value={custom_period_pricing}
                        name="custom_period_pricing"
                        onChange={handleChange}
                        data-placeholder="Do you want to set up custom period pricing?"
                        className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full`}
                    >
                        <option disabled value={""}>
                            Select
                        </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                </div>
            </div> */}

            {
                (custom_periods?.length > 0) ?

                    <>
                        <CustomPeriod />

                    </>
                    :
                    <></>
            }
            <div className="md:col-span-12 text-left flex justify-end items-end">
                <button
                    onClick={handleAddCustomPeriod}
                    className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                >
                    Add Custom Period
                </button>
            </div>

            {
                +extra_service.length > 0 &&
                <h3 className="text-xl font-bold text-center md:col-span-12">
                    Extra Services
                </h3>
            }

            <div className="md:col-span-12 text-left">
                <h4 className="inline-flex items-center md:col-span-12">
                    Set Up Extra services
                    <MdQuestionMark
                        onClick={() =>
                            Swal.fire({
                                text: `
                              This section allows you to define additional charges for specific services outside of your regular nightly rate. For example, you may want to offer extra amenities, cleaning services, or special requests that come with an added cost. Some Hosts may also offer discounted rates or additional services during specific seasons or events to enhance the guest experience.`,
                                icon: "question",
                            })
                        }
                        style={{ color: "#8ec639", cursor: "pointer" }}
                    />
                </h4>
                {/* <div className="listsearch-input-item">
                    <select
                        value={extra_services}
                        name="extra_services"
                        onChange={handleChange}
                        data-placeholder="Do you have any extra services you would like to offer?"
                        className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full`}
                    >
                        <option disabled value={""}>
                            Select
                        </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                </div> */}
            </div>
            {
                (extra_service?.length > 0) &&
                <>
                    <ExtraService />
                </>
            }

            <div className="md:col-span-12 text-left flex justify-end items-end">
                <button
                    onClick={handlePlusClickOne}
                    className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                >
                    Add Service
                </button>
            </div>
        </div>
    );
};
