import React from "react";
import { MdQuestionMark } from "react-icons/md";
import Swal from "sweetalert2";
import { useFormContext } from "../../../../providers";
import { getIconClass } from "../../../../helpers";

import { TextEditor } from "./Editor";

export const InformationForm = () => {

  const { errors, getValues, handleChange, fieldRefs } = useFormContext();

  return (
    <div className="grid md:grid-cols-12 bg-white p-4 rounded-lg gap-4">
      <h3 className="text-xl font-bold text-center md:col-span-12">
        Information
      </h3>
      <div className="md:col-span-6 text-left">
        <label>Type of Space</label> <br />
        <select
          ref={(el) => (fieldRefs.current["type_of_space"] = el)}
          value={getValues('type_of_space')}
          name="type_of_space"
          onChange={handleChange}
          // className={`border border-input px-3 py-3 w-full rounded-md gap-4 items-center ${errors.type_of_space ? "error" : ""
          //   }`}
          className={`border border-input px-3 py-3 w-full rounded-md gap-4 items-center`}
        >
          <option disable="true" value="">
            Select{" "}
          </option>{" "}
          <option value="Entire Place">Entire Place</option>
          <option value="Private Room">Private Room</option>
          <option value="Shared Room (Requires Admin Approval)">
            Shared Room (Requires Admin Approval)
          </option>
        </select>{" "}
        <br />
        {errors.type_of_space && (
          <span style={{ color: "red" }}>
            Please select a type of space
          </span>
        )}
      </div>
      <div className="md:col-span-6 text-left">
        <label>Lodging Type</label> <br />
        <select
          ref={(el) => (fieldRefs.current["lodging_type"] = el)}
          value={getValues('lodging_type')}
          name="lodging_type"
          onChange={handleChange}
          // className={`border border-input px-3 py-3 w-full rounded-md gap-4 items-center ${errors.dwelling ? "error" : ""
          //   }`}
          className={`border border-input px-3 py-3 w-full rounded-md gap-4 items-center`}
        >
          <option disabled value={""}>
            Lodging Type
          </option>
          <option value={"Apt. / Condo / Loft"}>
            Apt. / Condo / Loft
          </option>
          <option value={"Bed & Breakfast"}>Bed & Breakfast</option>
          <option value={"Cabin"}>Cabin</option>
          <option value={"Campsite"}>Campsite</option>
          <option value={"Farm"}>Farm</option>
          <option value={"Hangar"}>Hangar</option>
          <option value={"Hangar Home"}>Hangar Home</option>

          <option value={"House"}>House</option>
          <option value={"Mansion"}>Mansion</option>
          <option value={"Novelty"}>Novelty</option>
          <option value={"RV"}>RV</option>
          <option value={"RV Pad"}>RV Pad</option>
          <option value={"Tiny Home"}>Tiny Home</option>
        </select>{" "}
        <br />
        {errors.dwelling && (
          <span style={{ color: "red" }}>
            Please select a Lodging
          </span>
        )}
      </div>

      <div className="md:col-span-12 text-left">
        <label>Title</label> <br />
        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
          ref={(el) => (fieldRefs.current["title"] = el)}
        >
          <i className={getIconClass("Title")} />
          <input
            type="text"
            placeholder="Ex. (KSME) Airport Name - Title"
            name="title"
            value={getValues('title')}
            onChange={handleChange}
          />
        </div>
        {errors.title && (
          <span style={{ color: "red" }}>{errors.title.message}</span>
        )}
      </div>

      <div className="md:col-span-4 text-left">
        <label>Number of Guests (N)</label>
        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
          ref={(el) => (fieldRefs.current["no_of_guest"] = el)}
        >
          <i className={getIconClass("Number of Guests (N)")} />
          <input
            type="number"
            placeholder="Number of Guests"
            name="no_of_guest"
            value={getValues('no_of_guest')}
            onChange={handleChange}
          />
        </div>
        {errors.no_of_guest && (
          <span style={{ color: "red" }}>{errors.no_of_guest.message}</span>
        )}
      </div>

      <div className="md:col-span-4 text-left">
        <label className="inline-flex items-center !m-0">
          Number of Bedrooms
          <MdQuestionMark
            onClick={() =>
              Swal.fire({
                text: "This is only the bedrooms you are making available to your guests",
                icon: "question",
              })
            }
            style={{ color: "#8ec639", cursor: "pointer" }}
          />
        </label>
        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
          ref={(el) => (fieldRefs.current["no_of_bedrooms"] = el)}
        >
          <i className={getIconClass("Number of Bedrooms")} />
          <input
            type="number"
            placeholder="Number of Bedrooms"
            name="no_of_bedrooms"
            value={getValues('no_of_bedrooms')}
            onChange={handleChange}
          />
        </div>
        {errors.no_of_bedrooms && (
          <span style={{ color: "red" }}>{errors.no_of_bedrooms.message}</span>
        )}
      </div>

      <div className="md:col-span-4 text-left">
        <label>Number of Beds (N)</label> <br />
        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
          ref={(el) => (fieldRefs.current["no_of_beds"] = el)}
        >
          <i className={getIconClass("Number of Beds (N)")} />
          <input
            id="no_of_beds"
            type="number"
            placeholder="Number of Beds"
            name="no_of_beds"
            value={getValues('no_of_beds')}
            onChange={handleChange}
          />
        </div>
        {errors.no_of_beds && (
          <span style={{ color: "red" }}>{errors.no_of_beds.message}</span>
        )}
      </div>

      <div className="md:col-span-6 text-left">
        <label className="inline-flex items-center">
          Number of Bathrooms
          <MdQuestionMark
            onClick={() =>
              Swal.fire({
                text: "You can use whole numbers, or decimals for partial bathrooms. Example 2.5 means two and a half bathrooms. A half (.5) bathroom contains a toilet and a sink. A three-quarters (.75) bathroom contains a toilet, sink, and shower without a bathtub.",
                icon: "question",
              })
            }
            style={{ color: "#8ec639", cursor: "pointer" }}
          />
        </label>
        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
          ref={(el) => (fieldRefs.current["no_of_bathrooms"] = el)}
        >
          <i className={getIconClass("Number of Bathrooms")} />
          <input
            type="number"
            placeholder="Number of Bathrooms"
            name="no_of_bathrooms"
            value={getValues('no_of_bathrooms')}
            onChange={handleChange}
          />
        </div>
        {errors.no_of_bathrooms && (
          <span style={{ color: "red" }}>
            {errors.no_of_bathrooms.message}
          </span>
        )}
      </div>

      <div className="md:col-span-6 text-left">
        <label className="inline-flex items-center">
          Number of Rooms
          <MdQuestionMark
            onClick={() =>
              Swal.fire({
                text: "Rooms = bedrooms, living rooms, studies, dens, kitchens, and dining rooms that do not share the space with a living room",
                icon: "question",
              })
            }
            style={{ color: "#8ec639", cursor: "pointer" }}
          />
        </label>
        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
          ref={(el) => (fieldRefs.current["no_of_rooms"] = el)}
        >
          <i className={getIconClass("Number of Rooms")} />
          <input
            type="number"
            placeholder="Number of Rooms"
            name="no_of_rooms"
            value={getValues('no_of_rooms')}
            onChange={handleChange}
          />
        </div>
        {errors.no_of_rooms && (
          <span style={{ color: "red" }}>{errors.no_of_rooms.message}</span>
        )}
      </div>

      <div className="md:col-span-6 text-left">
        <label>Size (N)</label>
        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
          ref={(el) => (fieldRefs.current["size"] = el)}
        >
          <i className={getIconClass("Size (N)")} />
          <input
            type="number"
            placeholder="Enter numbers only."
            name="size"
            value={getValues('size')}
            onChange={handleChange}
          />
        </div>
        {errors.size && (
          <span style={{ color: "red" }}>{errors.size.message}</span>
        )}
      </div>

      <div className="md:col-span-6 text-left"
        ref={(el) => (fieldRefs.current["unit_of_measure"] = el)}
      >
        <label>Unit of Measure (N)</label>
        <div className="listsearch-input-item">
          <select
            value={getValues('unit_of_measure')}
            name="unit_of_measure"
            onChange={handleChange}
            // className={`border border-input px-3 py-3 rounded-md gap-4 w-full items-center${errors.unit_of_measure ? "error" : ""
            //   }`}
            className={`border border-input px-3 py-3 rounded-md gap-4 w-full items-center`}
          >
            <option value={"ft²"}>ft²</option>
            <option value={"m²"}>m²</option>
          </select>{" "}
          <br />
          {errors.unit_of_measure && (
            <span style={{ color: "red" }}>
              {errors.unit_of_measure.message}
            </span>
          )}
        </div>
      </div>
      <div className="md:col-span-12 text-left">
        <label>
          Description
          <i className="fal fa-info-circle" />
        </label>
      </div>
      <div className="md:col-span-12 text-left"
        ref={(el) => (fieldRefs.current["description"] = el)}
      >
        <TextEditor
          field={'description'}
        />
        {errors.description && (
          <span style={{ color: "red" }}>
            {errors.description.message}
          </span>
        )}
      </div>


    </div>
  );
};
