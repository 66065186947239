import React, { useState, useRef, useEffect } from "react";
import { useFormContext } from "../../../../../providers";
import { useLocation } from "react-router-dom";

export const DimensionInput = ({ index }) => {
    const { getValues, setValue, errors, watch } = useFormContext();
    const location = useLocation();
    const { listId = "" } = location.state || {};
    const airports = watch('airports');

    const [feetX, setFeetX] = useState("");
    const [feetY, setFeetY] = useState("");
    const [metersX, setMetersX] = useState("");
    const [metersY, setMetersY] = useState("");

    const feetYRef = useRef(null);
    const metersXRef = useRef(null);
    const metersYRef = useRef(null);

    // Fetch data and populate input fields if listId is present
    useEffect(() => {
        if (listId) {
            if (airports[index]) {
                const dimensionsFeet = airports[index].dimension_feets || "";
                const dimensionsMeters = airports[index].dimension_meters || "";

                const [fX = "", fY = ""] = dimensionsFeet.replace("ft", "").split("X");
                const [mX = "", mY = ""] = dimensionsMeters.replace("m", "").split("X");

                setFeetX(fX.trim());
                setFeetY(fY.trim());
                setMetersX(mX.trim());
                setMetersY(mY.trim());
            }
        }
    }, [listId, index, airports]);

    const handleFeetXChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,5}$/.test(value)) {
            setFeetX(value);
            if (value.length === 5) {
                feetYRef.current.focus();
            }
        }
    };

    const handleFeetYChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,3}$/.test(value)) {
            setFeetY(value);
            if (value.length === 3) {
                metersXRef.current.focus();
            }
        }
    };

    const handleMetersXChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setMetersX(value);
            if (value.length === 4) {
                metersYRef.current.focus();
            }
        }
    };

    const handleMetersYChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setMetersY(value);
            updateFinalValue(feetX, feetY, metersX, value);
        } else {
            updateFinalValue(feetX, feetY, metersX, metersY);
        }
    };

    const updateFinalValue = (feetX, feetY, metersX, metersY) => {
        const finalFeetValue = `${feetX}X${feetY}`;
        const finalMeterValue = `${metersX}X${metersY}`;

        const fields = [...getValues("airports")];
        fields[index]["dimension_feets"] = finalFeetValue;
        fields[index]["dimension_meters"] = finalMeterValue;

        setValue("airports", fields);
    };

    return (
        <>
            <label>Dimensions</label>
            <div className="flex border border-input rounded-md gap-4 items-center px-2">
                <input
                    type="text"
                    value={feetX}
                    onChange={handleFeetXChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>X</span>
                <input
                    type="text"
                    ref={feetYRef}
                    value={feetY}
                    onChange={handleFeetYChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>ft./</span>
                <input
                    type="text"
                    ref={metersXRef}
                    value={metersX}
                    onChange={handleMetersXChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>X</span>
                <input
                    type="text"
                    ref={metersYRef}
                    value={metersY}
                    onChange={handleMetersYChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>m</span>
            </div>
            {(errors?.airports?.[index]?.dimension_feets || errors?.airports?.[index]?.dimension_meters) && (
                <p style={{ color: "red" }}>
                    {errors?.airports?.[index]?.dimension_feets?.message || errors?.airports?.[index]?.dimension_meters?.message}
                </p>
            )}
        </>
    );
};
