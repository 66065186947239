/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Listen from "./Pages/Listen";
import Booking from "./Pages/Booking";
import Profile from "./Pages/Profile";
import Changepassword from "./Pages/Changepassword";
import MyListing from "./Pages/MyListing";
import EditListing from "./Pages/EditListing";
import ThemProvider from "./context/ContextFile";
import Messages from "./Pages/Message";
import AddListing from "./Pages/AddListing";
import About from "./Pages/About";
import Favorite from "./Pages/Favorite";
import MyDetails from "./Pages/MyDetails";
import Invoice from "./Pages/Invoice";
import BookingD from "./Pages/BookingD";
import Wallet from "./Pages/Wallet";
import Payout from "./Pages/Payout";
import PayoutDetails from "./Pages/PayoutDetails";
import Request from "./Pages/Request";
import Login from "./Pages/Login";
import Contact from "./Pages/Contact";
import State from "./Pages/State";
import StateDetail from "./Pages/StateDetail";
import ApartmentDetail from "./Pages/ApartmentDetail";
import BySpace from "./Pages/BySpace";
import Listing from "./Pages/Listing";
import Squawks from "./Pages/Squawks";
import MostFun from "./Pages/StaticPages/MostFun";
import TermService from "./Pages/term/TermService";
import Privacy from "./Pages/term/Privacy";
import Gallery from "./Pages/Gallery";
import Testimonials from "./Pages/Testimonials";
import Mostpopular from "./Pages/StaticPages/Mostpopular";
import AirparkCommunities from "./Pages/StaticPages/AirparkCommunities";
import InovatedIdea from "./Pages/StaticPages/InovatedIdea";
import PackingListLadies from "./Pages/StaticPages/PackingListladies";
import PicProfile from "./Pages/PicProfile";
import Bannerthree from "./Components/Bannerthree";
import Permission from "./Pages/Permission";
import BigMap from "./Pages/BigMap";
import PhilanthropyPage from "./Pages/PhilanthropyPage";
import SquawksDetail from "./Pages/SquawksDetail";
import FAQ from "./Pages/FAQ";
import ShortTermRental from "./Pages/StaticPages/ShortTermRental";
import Compare from "./Pages/Compare";
import Trips from "./Pages/Trips";
import BookingView from "./Pages/BookingView";
import MyTrip from "./Pages/MyTrip";
import Review from "./Pages/Review";
import { ResetPassword } from "./Pages";
import { FeaturesProvider, ListingProvider, PaymentProvider } from "./providers";
import { ScrollToPreviousPosition } from "./hooks";
import { CirtPolicy, ContentAndCopyrightPolicy, FairHousingPolicy, NeighborhoodNuisancePolicy, OffThePlatFormFeePolicy, ServiceFeePolicy, TrademarkPolicy } from "./Pages/StaticPages/Agreements";
import { VerifyAccount } from "./Components/Authentication/VerifyAccount";
import { PaymentSuccessPage } from "./Pages/accounts/payments/PaymentMaethodSuccess";
import { DetailsFragment, MapListingFragment, PaymentFragment, BookingStatusFragment, RestaurantsFragment } from "./fragments";

function App({ children }) {


  return (
    <>

      <PaymentProvider>
        <ListingProvider>
          <FeaturesProvider>
            <ThemProvider>
              {children}
              <BrowserRouter >

                <ScrollToPreviousPosition>
                  <Routes >
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/verify" element={<VerifyAccount />} />
                    <Route path="/map/:id" element={<Bannerthree />} />
                    <Route path="/map_listing/:name/:key" element={<MapListingFragment />} />
                    <Route path="/map" element={<Bannerthree />} />
                    <Route path="/bigmap" element={<BigMap />} />
                    <Route path="/terms-of-service" element={<TermService />} />
                    <Route path="/compare" element={<Compare />} />
                    <Route path="/privacy_policy" element={<Privacy />} />
                    <Route path="/short_term_rental" element={<ShortTermRental />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/testimonials" element={<Testimonials />} />
                    <Route
                      path="/the-most-fun-fly-ins-in-america"
                      element={<MostFun />}
                    />
                    <Route
                      path="/most-popular-air-shows-in-the-united-states"
                      element={<Mostpopular />}
                    />
                    <Route
                      path="/airpark-communities-in-the-usa"
                      element={<AirparkCommunities />}
                    />
                    <Route
                      path="/innovative-ideas-for-a-hangar-home"
                      element={<InovatedIdea />}
                    />
                    <Route
                      path="/ultimate-travel-packing-list-for-ladies"
                      element={<PackingListLadies />}
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/listing" element={<Listing />} />
                    <Route path="/squawks" element={<Squawks />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/squawks/:id" element={<SquawksDetail />} />
                    <Route path="/philanthropy" element={<PhilanthropyPage />} />
                    <Route path="/dashboard" element={<Listen />} />
                    <Route path="/booking" element={<Booking />} />
                    <Route path="/booking_view" element={<BookingView />} />
                    <Route path="/trip_view" element={<MyTrip />} />

                    <Route path="/trip" element={<Trips />} />
                    <Route path="/my_listing" element={<MyListing />} />
                    <Route path="/invoice_details" element={<BookingD />} />
                    <Route path="/my_wallets" element={<Wallet />} />
                    <Route path="/payout" element={<Payout />} />
                    <Route path="/request" element={<Request />} />
                    <Route path="/payoutdetails" element={<PayoutDetails />} />
                    <Route path="/favorite" element={<Favorite />} />
                    <Route path="/invoice" element={<Invoice />} />
                    <Route path="/review" element={<Review />} />
                    <Route path="/addlisting" element={<AddListing />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/agreements" element={<Permission />} />
                    <Route path="/pic" element={<PicProfile />} />
                    <Route path="/air_mail" element={<Messages />} />
                    <Route path="/details/:id" element={<DetailsFragment />} />
                    <Route path="/detail-my-listing/:id" element={<MyDetails />} />
                    <Route path="/EditListing/:id" element={<EditListing />} />
                    <Route path="/changepassword" element={<Changepassword />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/by-state-airport" element={<State />} />
                    <Route
                      path="/by-state-airport/detail/:location"
                      element={<StateDetail />}
                    />
                    <Route
                      path="/by-dwelling/details/:location"
                      element={<ApartmentDetail />}
                    />
                    <Route path="/by-space/details/:location" element={<BySpace />} />
                    <Route path="/account-settings/password" element={<ResetPassword />} />
                    <Route path="/account-settings/payments" element={<PaymentFragment />} />
                    <Route path="/account-settings/payments-success" element={<PaymentSuccessPage />} />

                    <Route path="/booking-status" element={

                      <BookingStatusFragment />
                    } />

                    <Route path="/restaurants" element={

                      <RestaurantsFragment />
                    } />

                    <Route path="/cirt-policy" element={<CirtPolicy />} />
                    <Route path="/fair-housing-policy" element={<FairHousingPolicy />} />
                    <Route path="/trademark-policy" element={<TrademarkPolicy />} />
                    <Route path="/content-and-copyright-policy" element={<ContentAndCopyrightPolicy />} />
                    <Route path="/neighborhood-nuisance-and-disruptive-behavior-policy" element={<NeighborhoodNuisancePolicy />} />
                    <Route path="/off-the-platform-fees-policy" element={<OffThePlatFormFeePolicy />} />
                    <Route path="/service-fees-policy" element={<ServiceFeePolicy />} />

                  </Routes>
                </ScrollToPreviousPosition>
              </BrowserRouter>
            </ThemProvider>
          </FeaturesProvider>
        </ListingProvider>
      </PaymentProvider>
    </>
  );
}

export default App;