import React from 'react';
import { BiSolidMessage } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { EmailShareButton } from 'react-share';

export const DetailsHeaderModal = ({ modal, setModal, copyTextRef }) => {

    const emailUrl = `https://flyinnew.dev-bt.xyz${window.location.pathname}`;

    const handleCopy = () => {
        const input = copyTextRef.current;
        input.select();
        document.execCommand("copy");
        input.parentNode.classList.add("active");
        window.getSelection().removeAllRanges();
        setTimeout(() => {
            input.parentNode.classList.remove("active");
            setModal(false);
        }, 2500);
    };

    return (
        <div
            style={{
                display: modal ? "flex" : "none",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent background
                zIndex: 9999,
            }}
        >
            <div
                className="modal__content !top-[0] !absolute"
                style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "20px",
                    width: "80%",
                    maxWidth: "500px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    overflowY: "auto",
                }}
            >
                <div className="container-detail">
                    <div className="label-detail">Copy Link</div>
                    <div className="copy-text">
                        <button onClick={handleCopy}>
                            <i className="fa fa-clone" />
                        </button>
                        <input
                            type="text"
                            className="text"
                            defaultValue={emailUrl}
                            ref={copyTextRef}
                        />
                    </div>

                    <Link to={`sms:?body=Click the link below to view the page:\n ${emailUrl} \n Fly-Inn and Stay a While!`}>
                        <div className="label-detail">Text</div>
                        <div className="email_btn_modal">
                            <button style={{ backgroundColor: "#54c4d9" }} className="copy-text button">
                                <BiSolidMessage style={{ color: "white" }} size={25} />
                            </button>
                        </div>
                    </Link>

                    <div className="label-detail">Email</div>
                    <EmailShareButton
                        url={emailUrl}
                        subject="Check out this great property!"
                        body={`Click the link below to view the page:\n ${emailUrl} \n Fly-Inn and Stay a While!`}
                    >
                        <div style={{ width: "275px" }} className="email_btn_modal">
                            <button
                                style={{ backgroundColor: "#54c4d9" }}
                                className="copy-text button"
                            >
                                <MdEmail style={{ color: "white" }} size={25} />
                            </button>
                        </div>
                    </EmailShareButton>
                </div>
                <div
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        fontSize: "24px",
                    }}
                    className="modal__close"
                    onClick={() => setModal(false)}
                >
                    ×
                </div>
            </div>
        </div>
    );
};
