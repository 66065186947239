/* eslint-disable */
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { fetchData, ThemeContext } from "../context/ContextFile";
import Swal from "sweetalert2";
import axios from "axios";
import BASE_URL from "./auth/Baseurl";
import { GoogleMap, MarkerF, InfoWindow } from "@react-google-maps/api";
import Skeleton from "react-loading-skeleton";
import { FaShower } from "react-icons/fa";
import { MdBorderClear, MdGroup, MdOutlineBedroomParent } from "react-icons/md";
import "react-loading-skeleton/dist/skeleton.css";
import { SNAZZY_MAP_STYLES } from "../constants";

function New({ data }) {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterItem, setFilterItem] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [center, setCenter] = useState({
    lat: 39.5,
    lng: -100.0,
  });
  const [zoom, setZoom] = useState(3.2); // Initial zoom level
  const [visibleRegions, setVisibleRegions] = useState({
    alaska: false,
    canada: false,
    us: false,
    mexico: false,
  });

  useEffect(() => {
    fetchData().then((res) => {
      setFilteredData(res.data.data)
      console.log(res.data.data, "mapValues")
    })
  }, [])
  const mapRef = useRef(null);


  const { theme } = useContext(ThemeContext);
  // console.log(data,"response");
  const fetchData012 = async (lat, lng, radius) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/listing-filter-bymap`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { lat, lng, radius },
      });
      setLoading(false);
      setDatas(response.data?.data);
      console.log(response.data?.data, "zoomdata");
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleFav = async (id) => {
    const formData = new FormData();
    formData.append("listing_id", id);
    try {
      const response = await axios.post(
        `${BASE_URL}/add-to-favourite`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchData012(center.lat, center.lng, 1500);
      Swal.fire({
        position: "center",
        html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
        title: response?.data?.message,
        showConfirmButton: true,
        timer: 1500,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (center) {
      setFilteredData(
        data
          ?.filter((item) => {
            if (visibleRegions.alaska && isWithinBounds(item, "alaska"))
              return true;
            if (visibleRegions.canada && isWithinBounds(item, "canada"))
              return true;
            if (visibleRegions.us && isWithinBounds(item, "us")) return true;
            if (visibleRegions.mexico && isWithinBounds(item, "mexico"))
              return true;
            return true; // Show other listings by default
          })
          .filter((item) => {
            if (!filterItem) return true;
            return item.distance_from_runway === filterItem;
          })
      );
    }
    setDatas(data);
  }, [center, data, visibleRegions, filterItem]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const isWithinBounds = (item, region) => {
    const bounds = {
      us: {
        latMin: 24.396308,
        latMax: 49.384358,
        lngMin: -125.0,
        lngMax: -66.93457,
      },
      canada: { latMin: 42.0, latMax: 83.0, lngMin: -141.0, lngMax: -52.0 },
      mexico: {
        latMin: 14.532866,
        latMax: 32.718655,
        lngMin: -118.455717,
        lngMax: -86.710571,
      },
      alaska: {
        latMin: 54.0,
        latMax: 71.5388001,
        lngMin: -179.148909,
        lngMax: -129.97957,
      },
    };

    return (
      item.lat >= bounds[region].latMin &&
      item.lat <= bounds[region].latMax &&
      item.lng >= bounds[region].lngMin &&
      item.lng <= bounds[region].lngMax
    );
  };

  const getLabelColor = (distance) => {
    if (distance === "0 miles") return "8ec639";
    if (distance === "0-1 miles") return "ffff00";
    if (distance === "1.1-3 miles") return "f17489";
    if (distance === "3.1-7 miles") return "ff9900";
    if (distance === "7 miles") return "52c4d7";
    return "defaultColor"; // Fallback color
  };

  const createSVGIcon = (color, price) => {
    const svgTemplate = `
     <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25" r="10" fill="${color}" stroke="white" stroke-width="2" />
  <text x="25" y="28" alignment-baseline="middle" text-anchor="middle" font-size="8" fill="black">
    <!-- Add text content here if needed -->
  </text>
</svg>
    `;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
      svgTemplate
    )}`;
  };
  //   <svg width="50" height="30" viewBox="0 0 50 60" xmlns="http://www.w3.org/2000/svg">
  //   <rect width="50" height="30" rx="10" ry="10" fill="${color}" stroke="white" stroke-width="2" />
  //   <text x="50%" y="30%" alignment-baseline="middle" text-anchor="middle" font-size="20" fill="white">
  //     $${price}
  //   </text>
  // </svg>
  const debounce = (func, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const calculateRadius = (center, ne) => {
    const lat = center.lat();
    const lng = center.lng();
    const neLat = ne.lat();
    const neLng = ne.lng();
    const R = 6371; // Radius of the Earth in km
    const dLat = ((neLat - lat) * Math.PI) / 180;
    const dLng = ((neLng - lng) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat * Math.PI) / 180) *
      Math.cos((neLat * Math.PI) / 180) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance; // Convert to meters
  };

  const handleBoundsChanged = debounce(() => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      const ne = bounds?.getNorthEast();
      const center = mapRef.current.getCenter();
      const radius = calculateRadius(center, ne);
      console.log(radius, "radius");
      setCenter({ lat: center.lat(), lng: center.lng() });
      fetchData012(center.lat(), center.lng(), radius);
    }
  }, 300); // Adjust delay as needed

  useEffect(() => {
    // Cleanup function to clear debounce timer on unmount
    return () => clearTimeout(handleBoundsChanged);
  }, []);

  console.log(theme, "theme");

  // console.log(theme?.listings?.lat);
  return (
    <>
      <div style={{ width: "100%" }} className="row">
        <div className="hotels col-md-6">
          {theme?.length === 0
            ? datas?.map((e) => (
              <div className="gallery-item hotels col-md-6">
                <div className="listing-item">
                  <article className="geodir-category-listing fl-wrap container">
                    <div className="geodir-category-img">
                      <Link
                        to={`/details/${e.id}`}
                        className="geodir-category-img-wrap fl-wrap"
                      >
                        {loading ? (
                          <Skeleton width={300} height={250} />
                        ) : (
                          <img
                            style={{
                              width: "300px",
                              height: "250px",
                            }}
                            src={e?.images[0]?.image}
                            alt=""
                          />
                        )}
                      </Link>

                      <div
                        onClick={() => handleFav(e.id)}
                        className={
                          e?.is_favourite === 1
                            ? "geodir-js-favorite_btn1"
                            : "geodir-js-favorite_btn"
                        }
                      >
                        <i className="fal fa-heart" />
                      </div>
                      <div className="listing-price">
                        <h3
                          className="title-sin_map"
                          style={{ fontSize: "18px" }}
                        >
                          <Link
                            to={`/details/${e.id}`}
                            style={{ color: "white" }}
                          >
                            {loading ? (
                              <Skeleton width={50} />
                            ) : (
                              `$ ${e?.additional_guest_price}`
                            )}
                          </Link>
                        </h3>
                      </div>
                      <div className="geodir-category-opt">
                        <div className="listing-rating-count-wrap">
                          <div
                            className="listing-rating card-popup-rainingvis"
                            data-starrating2={5}
                          />
                          <h3
                            className="title-sin_map"
                            style={{ fontSize: "18px" }}
                          >
                            <Link
                              to={`/details/${e.id}`}
                              style={{ color: "white" }}
                            >
                              {loading ? (
                                <Skeleton width={100} />
                              ) : (
                                `${e?.host?.first_name} ${e?.address}`
                              )}
                            </Link>
                          </h3>
                        </div>
                      </div>
                      <div
                        className="geodir-category-location"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "absolute",
                          bottom: "30px",
                          zIndex: 3,
                          width: "100%",
                          padding: "0px 10px",
                        }}
                      >
                        <Link
                          to={`/details/${e.id}`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <MdOutlineBedroomParent size={20} color="white" />
                          <p className="text-white">
                            {loading ? (
                              <Skeleton width={20} />
                            ) : (
                              e?.no_of_bedrooms
                            )}
                          </p>
                        </Link>
                        <Link
                          to={`/details/${e.id}`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <FaShower size={20} color="white" />
                          <p className="text-white">
                            {loading ? (
                              <Skeleton width={20} />
                            ) : (
                              e?.no_of_bathrooms
                            )}
                          </p>
                        </Link>
                        <Link
                          to={`/details/${e.id}`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <MdGroup size={20} color="white" />
                          <p className="text-white">
                            {loading ? (
                              <Skeleton width={20} />
                            ) : (
                              e?.no_of_guest
                            )}
                          </p>
                        </Link>
                        <div
                          className="btn btn-primary cursor-pointer"
                          style={{ padding: "6px 10px" }}
                        >
                          {loading ? <Skeleton width={50} /> : "Compare"}
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            ))
            : theme?.listings?.map((e) => (
              <div className="gallery-item hotels col-md-6">
                <div className="listing-item">
                  <article className="geodir-category-listing fl-wrap container">
                    <div className="geodir-category-img">
                      <Link
                        to={`/details/${e.id}`}
                        className="geodir-category-img-wrap fl-wrap"
                      >
                        {loading ? (
                          <Skeleton width={300} height={250} />
                        ) : (
                          <img
                            style={{
                              width: "300px",
                              height: "250px",
                            }}
                            src={e?.images[0]?.image}
                            alt=""
                          />
                        )}
                      </Link>

                      <div
                        onClick={() => handleFav(e.id)}
                        className={
                          e?.is_favourite === 1
                            ? "geodir-js-favorite_btn1"
                            : "geodir-js-favorite_btn"
                        }
                      >
                        <i className="fal fa-heart" />
                      </div>
                      <div className="listing-price">
                        <h3
                          className="title-sin_map"
                          style={{ fontSize: "18px" }}
                        >
                          <Link
                            to={`/details/${e.id}`}
                            style={{ color: "white" }}
                          >
                            {loading ? (
                              <Skeleton width={50} />
                            ) : (
                              `$ ${e?.additional_guest_price}`
                            )}
                          </Link>
                        </h3>
                      </div>
                      <div className="geodir-category-opt">
                        <div className="listing-rating-count-wrap">
                          <div
                            className="listing-rating card-popup-rainingvis"
                            data-starrating2={5}
                          />
                          <h3
                            className="title-sin_map"
                            style={{ fontSize: "18px" }}
                          >
                            <Link
                              to={`/details/${e.id}`}
                              style={{ color: "white" }}
                            >
                              {loading ? (
                                <Skeleton width={100} />
                              ) : (
                                `${e?.host?.first_name} ${e?.address}`
                              )}
                            </Link>
                          </h3>
                        </div>
                      </div>
                      <div
                        className="geodir-category-location"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "absolute",
                          bottom: "30px",
                          zIndex: 3,
                          width: "100%",
                          padding: "0px 10px",
                        }}
                      >
                        <Link
                          to={`/details/${e.id}`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <MdOutlineBedroomParent size={20} color="white" />
                          <p className="text-white">
                            {loading ? (
                              <Skeleton width={20} />
                            ) : (
                              e?.no_of_bedrooms
                            )}
                          </p>
                        </Link>
                        <Link
                          to={`/details/${e.id}`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <FaShower size={20} color="white" />
                          <p className="text-white">
                            {loading ? (
                              <Skeleton width={20} />
                            ) : (
                              e?.no_of_bathrooms
                            )}
                          </p>
                        </Link>
                        <Link
                          to={`/details/${e.id}`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <MdGroup size={20} color="white" />
                          <p className="text-white">
                            {loading ? (
                              <Skeleton width={20} />
                            ) : (
                              e?.no_of_guest
                            )}
                          </p>
                        </Link>
                        <div
                          className="btn btn-primary cursor-pointer"
                          style={{ padding: "6px 10px" }}
                        >
                          {loading ? <Skeleton width={50} /> : "Compare"}
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            ))}
        </div>
        <div className="col-md-6">
          <GoogleMap
            mapContainerStyle={{ height: "80vh", width: "100%" }}
            center={center}
            zoom={zoom}
            apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
            onLoad={(map) => (mapRef.current = map)}
            onZoomChanged={handleBoundsChanged}
            onDragEnd={handleBoundsChanged}
            options={{
              styles: SNAZZY_MAP_STYLES, // Apply the Airbnb-like style
            }}
          >
            {theme.length === 0
              ? filteredData?.map((item) => {
                return (
                  <MarkerF
                    key={item.id}
                    position={{
                      lat: Number(item.lat),
                      lng: Number(item.lng),
                    }}
                    onClick={() => setSelectedMarker(item)}
                    icon={{
                      url: createSVGIcon(
                        `#${getLabelColor(
                          item.distance_from_runway === ""
                            ? 0
                            : item.distance_from_runway
                        )}`,
                        item.additional_guest_price
                      ),
                      scaledSize: new window.google.maps.Size(80, 50),
                    }}
                  />
                );
              })
              : theme.listings?.map((listing) => {

                return (
                  <MarkerF
                    key={listing.id}
                    position={{
                      lat: Number(listing.lat),
                      lng: Number(listing.lng),
                    }}
                    onClick={() => setSelectedMarker(listing)}
                    icon={{
                      url: createSVGIcon(
                        `#${getLabelColor(
                          listing.distance_from_runway === ""
                            ? 0
                            : listing.distance_from_runway
                        )}`,
                        listing.additional_guest_price
                      ),
                      scaledSize: new window.google.maps.Size(80, 50),
                    }}
                  />
                );
              })}

            {selectedMarker && (
              <InfoWindow
                position={{
                  lat: Number(selectedMarker.lat),
                  lng: Number(selectedMarker.lng),
                }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div className="map-box">
                  <div className="map-popup-wrap">
                    <div className="map-popup">
                      <Link
                        to={`/details/${selectedMarker?.id}`}
                        className="listing-img-content fl-wrap"
                      >
                        <div className="infobox-status open">
                          {selectedMarker?.description}
                        </div>
                        {selectedMarker?.images?.[0] && (
                          <img
                            src={selectedMarker?.images[0].image}
                            alt=""
                          />
                        )}
                      </Link>
                      <div className="listing-content">
                        <div className="listing-content-item fl-wrap">
                          <div className="map-popup-location-category gym-cat" />
                          <div className="listing-title fl-wrap">
                            <h4>
                              <Link to={`/details/${selectedMarker?.id}`}>
                                {selectedMarker?.title}
                              </Link>
                            </h4>
                            <div className="map-popup-location-info">
                              <i className="fas fa-map-marker-alt" />
                              {selectedMarker?.address}
                            </div>
                          </div>
                          <div className="map-popup-footer">
                            <Link
                              to={`/details/${selectedMarker?.id}`}
                              className="main-link"
                            >
                              Details <i className="fal fa-long-arrow-right" />
                            </Link>
                            <Link to="#" className="infowindow_wishlist-btn">
                              <i className="fal fa-heart" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
      </div>
    </>
  );
}
export default New;
