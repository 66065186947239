import React, { useState, useEffect } from 'react';
import { useFormContext } from '../../../../../../providers';
import axios from 'axios';
import BASE_URL from '../../../../../auth/Baseurl';

const CancellationPolicies = ({ field }) => {
    const [policies, setPolicies] = useState([]);
    const { errors, handleChange, watch } = useFormContext();

    // Fetch the data from the API on component mount
    useEffect(() => {
        const fetchPolicies = async () => {
            try {
                axios
                    .get(`${BASE_URL}/cancellation-policy`, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((res) => {
                        setPolicies(res.data?.data || []);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                // if (result.status) {
                //   setPolicies(result.data);
                // }
            } catch (error) {
                console.error('Error fetching cancellation policies:', error);
            }
        };

        fetchPolicies();
    }, []);

    return (
        <div className="md:col-span-12 text-left mt-2">

            <select
                name={field}
                placeholder="Cancellation Policies"
                value={watch(field)}
                onChange={handleChange}
                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
            >
                <option value="">
                    Choose a cancellation policy
                </option>

                {/* Group options by type */}
                {policies.length > 0 && (
                    <>
                        {field === 'cancellation_policy_short' &&
                            <optgroup label="Short Term Policies">
                                {policies
                                    .filter((policy) => policy.type === 'short')
                                    .map((policy) => (
                                        <option key={policy.id} value={policy.id}>
                                            {policy.group_name}: {policy.before_check_in}
                                        </option>
                                    ))}
                            </optgroup>
                        }
                        {field === 'cancellation_policy_long' &&
                            <optgroup label="Long Term Policies">
                                {policies
                                    .filter((policy) => policy.type === 'long')
                                    .map((policy) => (
                                        <option key={policy.id} value={policy.id}>
                                            {policy.group_name}: {policy.before_check_in}
                                        </option>
                                    ))}
                            </optgroup>
                        }
                    </>
                )}
            </select>

            {(errors?.cancellation_policy || errors?.cancellation_policy_short) && (
                <p style={{ color: 'red' }}>
                    {errors?.cancellation_policy?.message || errors?.cancellation_policy_short?.message}
                </p>
            )}
        </div>
    );
};

export default CancellationPolicies;
