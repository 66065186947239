/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useFormContext } from "../../../../providers";
import { SNAZZY_MAP_STYLES } from "../../../../constants";


export const LocationForm = ({
    autoCompleteRef,
    setDrag,
    drag,
    setSelectedLocation,
    selectedLocation
}) => {

    const fieldRefs = useRef({});

    const { errors, setValue, handleChange, formData, watch } = useFormContext();

    const formatPrecision = (value, precision = 2) => {
        return parseFloat(parseFloat(value).toFixed(precision));
    };

    const mapRef = useRef();
    let autoComplete;

    const handlePutAddress = (event) => {
        const { name, value } = event.target;
        const formattedValue = formatPrecision(value);

        setDrag((prev) => ({
            ...prev,
            [name]: formattedValue,
        }));
        setSelectedLocation((prev) => ({
            ...prev,
            [name]: formattedValue,
        }));

        handleInputChangeMap(event);
    };

    const handleMarkerDragEnd = (event) => {
        const lat = formatPrecision(event.latLng.lat());
        const lng = formatPrecision(event.latLng.lng());

        setDrag({ lat, lng });
        setValue('latitude', lat);
        setValue('longitude', lng);
    };

    const handleInputChangeMap = useCallback((e) => {
        if (e.target instanceof HTMLInputElement) {
            handleChange(e);  // Only call handleChange if it's an input element
        }
    }, []);

    const handlePlaceSelect = async () => {
        const addressObject = await autoComplete?.getPlace();

        const query = addressObject?.formatted_address;
        setValue('address', query);
        if (addressObject?.geometry) {
            const latLng = {
                lat: addressObject.geometry.location.lat(),
                lng: addressObject.geometry.location.lng(),
            };
            setSelectedLocation(latLng);
            let updatedFormData = {};
            addressObject.address_components.forEach((component) => {
                const types = component.types;

                if (types.includes("street_number") || types.includes("route")) {
                    updatedFormData.area = `${updatedFormData.area ? updatedFormData.area + " " : ""
                        }${component.long_name}`;
                }
                if (types.includes("locality")) {
                    updatedFormData.city = component.long_name;
                }
                if (types.includes("administrative_area_level_1")) {
                    updatedFormData.state = component.short_name;
                }
                if (types.includes("postal_code")) {
                    updatedFormData.zipcode = component.long_name;
                }
                if (types.includes("country")) {
                    updatedFormData.country = component.long_name;
                }
            });

            // setValue('address', updatedFormData.address)
            setValue('area', updatedFormData.area)
            setValue('city', updatedFormData.city)
            setValue('state', updatedFormData.state)
            setValue('zipcode', updatedFormData.zipcode)
            setValue('country', updatedFormData.country)

        }
    };

    const handleFindAddressClick = () => {
        if (drag.lat && drag.lng) {
            const lat = parseFloat(drag.lat);
            const lng = parseFloat(drag.lng);
            setSelectedLocation({ lat, lng });

            const geocoder = new window.google.maps.Geocoder();
            const latLng = { lat, lng };

            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === "OK" && results[0]) {
                    const address = results[0].formatted_address;

                    const updatedFormData = {
                        ...formData,
                        address, // explicitly add address here
                    };

                    results[0].address_components.forEach((component) => {
                        const types = component.types;

                        if (types.includes("street_number") || types.includes("route")) {
                            updatedFormData.area = `${updatedFormData.area ? updatedFormData.area + " " : ""
                                }${component.long_name}`;
                        }
                        if (types.includes("locality")) {
                            updatedFormData.city = component.long_name;
                        }
                        if (types.includes("administrative_area_level_1")) {
                            updatedFormData.state = component.short_name;
                        }
                        if (types.includes("postal_code")) {
                            updatedFormData.zipcode = component.long_name;
                        }
                        if (types.includes("country")) {
                            updatedFormData.country = component.long_name;
                        }
                    });

                    // setValue('address', updatedFormData.address)
                    setValue('area', updatedFormData.area)
                    setValue('city', updatedFormData.city)
                    setValue('state', updatedFormData.state)
                    setValue('zipcode', updatedFormData.zipcode)
                    setValue('country', updatedFormData.country)
                } else {
                    console.log("Geocoder failed or no results found.");
                }
            });
        } else {
            console.log("Latitude and Longitude are not set.");
        }
    };

    // Update handlePlaceSelect for consistent state update
    const handleScriptLoad = useCallback(() => {
        autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, { types: ['geocode'] });
        autoComplete.addListener('place_changed', handlePlaceSelect);
    }, []);

    useEffect(() => {
        handleScriptLoad();
    }, []);

    const onMapLoad = (map) => {
        mapRef.current = map;
    };


    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //             const { latitude, longitude } = position.coords;
    //             const lat = formatPrecision(latitude);
    //             const lng = formatPrecision(longitude);

    //             setSelectedLocation({ lat, lng });
    //             setValue('latitude', lat);
    //             setValue('longitude', lng);
    //         },
    //         (err) => {
    //             console.log(`Error: ${err.message}`);
    //         },
    //         {
    //             enableHighAccuracy: true,
    //             timeout: 10000,
    //             maximumAge: 0
    //         }
    //     );
    // }, []);


    useEffect(() => {
        if (errors) {
            const firstErrorField = Object.keys(errors)[0];
            if (fieldRefs.current[firstErrorField]) {
                fieldRefs.current[firstErrorField].scrollIntoView({ behavior: "smooth", block: "center" });
                fieldRefs.current[firstErrorField].focus();
            }
        }
    }, [errors]);


    return (
        <>
            <div className="grid md:grid-cols-12 gap-2">
                <div className="md:col-span-8 text-left">
                    <label>Address</label> <br />
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["address"] = el)}
                    >
                        <i className="fal fa-map-marker-alt" />
                        <input
                            type="text"
                            ref={autoCompleteRef}
                            name="address"
                            onChange={handleInputChangeMap}
                            placeholder="Enter the listing address."
                            value={watch('address')}
                            className="w-full"
                        />
                    </div>
                    {errors.address && (
                        <span className="error-message">{errors.address.message}</span>
                    )}
                </div>
                <div className="md:col-span-4 text-left">
                    <label>Apartment/Suite (optional)</label> <br />
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["apt_suite"] = el)}
                    >
                        <i className="fal fa-building" />
                        <input
                            type="text"
                            name="apt_suite"
                            value={watch('apt_suite') || ""}
                            onChange={handleInputChangeMap}
                            placeholder="Apartment/Suite"
                        />
                    </div>
                </div>
                <div className="md:col-span-4 text-left">
                    <label>City</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["city"] = el)}
                    >
                        <i className="fal fa-city" />
                        <input
                            type="text"
                            name="city"
                            value={watch('city') || ""}
                            onChange={handleInputChangeMap}
                            placeholder="City"
                        />
                    </div>
                    {errors.city && (
                        <span className="error-message">{errors.city.message}</span>
                    )}
                </div>
                <div className="md:col-span-4 text-left">
                    <label>State</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["state"] = el)}
                    >
                        <i className="fal fa-map" />
                        <input
                            type="text"
                            name="state"
                            value={watch('state') || ""}
                            onChange={handleInputChangeMap}
                            placeholder="State"
                        />
                    </div>
                    {errors.state && (
                        <span className="error-message">{errors.state.message}</span>
                    )}
                </div>
                <div className="md:col-span-4 text-left">
                    <label>ZIP / Postal Code</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["zipcode"] = el)}
                    >
                        <i className="fal fa-mail-bulk" />
                        <input
                            type="text"
                            name="zipcode"
                            value={watch('zipcode') || ""}
                            onChange={handleInputChangeMap}
                            placeholder="ZIP / Postal Code"
                        />
                    </div>
                    {errors.zipcode && (
                        <span className="error-message">{errors.zipcode.message}</span>
                    )}
                </div>
                <div className="md:col-span-4 text-left">
                    <label>Area (optional)</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["area"] = el)}
                    >
                        <i className="fal fa-map-marked-alt" />
                        <input
                            type="text"
                            name="area"
                            value={watch('area') || ""}
                            onChange={handleInputChangeMap}
                            placeholder="Area"
                        />
                    </div>
                </div>
                <div className="md:col-span-4 text-left">
                    <label>Country</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["country"] = el)}
                    >
                        <i className="fal fa-globe" />
                        <input
                            type="text"
                            name="country"
                            value={watch('country') || ""}
                            onChange={handleInputChangeMap}
                            placeholder="Country"
                        />
                    </div>
                    {errors.country && (
                        <span className="error-message">{errors.country.message}</span>
                    )}
                </div>

                <div className="md:col-span-12">
                    <GoogleMap
                        mapContainerStyle={{ height: "500px" }}
                        center={selectedLocation}
                        zoom={10}
                        onLoad={onMapLoad}
                        options={{
                            styles: SNAZZY_MAP_STYLES, // Apply the Airbnb-like style
                        }}
                    >
                        <MarkerF
                            draggable={true}
                            onDragEnd={(e) => handleMarkerDragEnd(e)}
                            position={selectedLocation}
                        />
                    </GoogleMap>
                </div>

                <div className="md:col-span-12 text-left">
                    <h3>Find the address on map.</h3>
                </div>
                <div className="md:col-span-4 text-left">
                    <label>Lattitude</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["latitude"] = el)}
                    >
                        <i className="fal fa-globe" />
                        <input
                            type="number"
                            name="latitude"
                            min="-90"
                            max="90"
                            step="any"
                            value={selectedLocation?.lat || drag?.lat || ""}
                            placeholder="Latitude"
                            onChange={handlePutAddress}
                        />
                    </div>
                    {errors.lat && (
                        <span className="error-message">{errors.lat.message}</span>
                    )}
                </div>
                <div className="md:col-span-4 text-left">
                    <label>Longitude</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center"
                        ref={(el) => (fieldRefs.current["longitude"] = el)}
                    >
                        <i className="fal fa-globe" />
                        <input
                            type="number"
                            name="longitude"
                            min="-180"
                            max="180"
                            step="any"
                            value={selectedLocation?.lng || drag?.lng || ""}
                            placeholder="Longitude"
                            onChange={handlePutAddress}
                        />
                    </div>
                    {errors.lng && (
                        <span className="error-message">{errors.lng.message}</span>
                    )}
                </div>
                <div
                    onClick={handleFindAddressClick}
                    className="md:col-span-4 text-left border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white
          h-10 mt-5 flex justify-center items-center"
                >
                    Find Address
                </div>

            </div>
        </>
    );
};
