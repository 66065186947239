import React, { useEffect, useRef, useState } from "react";
import { FaCreditCard, FaUniversity, FaEllipsisV } from "react-icons/fa";
import SidebarLayout from "../../../Components/sidebar/SidebarLayout";
import { Button } from "../../../Components/accounts/Button";
import { ModalPayment } from "../../../Components/accounts/PaymentModal";
import Swal from "sweetalert2";
import { usePayment } from "../../../providers";
import { axiosInstanceWithToken } from "../../../helpers/axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

export const Payments = () => {
    const { paymentMethod, getPaymentMethods, deletePaymentMethods, updatePaymentMethods } = usePayment();

    const [showBankAccountModal, setShowBankAccountModal] = useState(false);
    const [activePopover, setActivePopover] = useState(null);
    const popoverRef = useRef(null);

    const [userData, setUserData] = useState(null);

    // Fetch user data from localStorage
    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("data"));
        setUserData(storedData);
        getPaymentMethods();
    }, [getPaymentMethods]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setActivePopover(null);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    // Payment Element Loader
    const togglePopover = (id) => {
        setActivePopover(activePopover === id ? null : id);
    };

    const handleUpdateWithSwal = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Update it!",
        }).then((result) => {
            if (result.isConfirmed) {
                updatePaymentMethods(id); // Call your delete function here
                togglePopover(id);
                Swal.fire("Update!", "Your item has been Updated.", "success");
            }
        });
    };

    const handleDeleteWithSwal = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deletePaymentMethods(id); // Call your delete function here
                togglePopover(id);
                Swal.fire("Deleted!", "Your item has been deleted.", "success");
            }
        });
    };

    // --------------------------------------------------------------------------------
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const response = await axiosInstanceWithToken.post("/payment/method");
                const secret = response?.data?.setup_intent?.client_secret;
                setClientSecret(secret);
            } catch (error) {
                console.error("Error fetching client secret:", error);
            }
        };

        fetchClientSecret();
    }, []);
    // ----------------------------------------------------------------------------------


    return (
        <SidebarLayout>
            <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset] !p-8">
                <h2 className="text-2xl font-bold mb-4">Payment Settings</h2>

                {/* Payment Details Section */}
                <div className="mb-8">
                    <ul className="p-4 bg-white !mb-0">
                        {paymentMethod?.length > 0 ? paymentMethod.map((method) => method.type === "us_bank_account" ? (
                            <li key={method.id} className="flex justify-between items-center mb-2 p-5 border border-b hover:bg-gray-200">
                                <span>
                                    <FaUniversity className="inline mr-2" />
                                    **** **** **** {method?.us_bank_account?.last4} - {method?.us_bank_account?.bank_name}
                                </span>
                                <div className="relative" ref={null}>
                                    <button onClick={() => togglePopover(method.id)}>
                                        <FaEllipsisV />
                                    </button>
                                    {activePopover === method.id && (
                                        <div className="absolute right-0 bg-white shadow-md rounded p-2 w-max z-[1]">
                                            <button className="w-full text-left p-1 hover:bg-gray-200">
                                                Set as Default
                                            </button>
                                            <button className="w-full text-left p-1 hover:bg-gray-200" onClick={() => handleDeleteWithSwal(method.id)}>
                                                Delete
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </li>
                        ) :
                            (
                                <li key={method.id} className="flex justify-between items-center mb-2 p-5 border border-b hover:bg-gray-200">
                                    <span>
                                        <FaCreditCard className="inline mr-2" />
                                        **** **** **** {method?.card?.last4} - {method?.billing_details?.name}
                                    </span>
                                    <div className="relative" ref={null}>
                                        <button onClick={() => togglePopover(method.id)}>
                                            <FaEllipsisV />
                                        </button>
                                        {activePopover === method.id && (
                                            <div className="absolute right-0 bg-white shadow-lg rounded p-2 w-max z-[1]">
                                                <button className="w-full text-left p-1 hover:bg-gray-200"
                                                    onClick={() => handleUpdateWithSwal(method.id)}
                                                >
                                                    Set as Default
                                                </button>
                                                <button className="w-full text-left p-1 hover:bg-gray-200" onClick={() => handleDeleteWithSwal(method.id)}>
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            )
                        )
                            :

                            <h3 className="text-xl font-semibold mb-2 text-left">No Payment Method Found!</h3>

                        }
                    </ul>


                    <Button onClick={() => setShowBankAccountModal(true)}>Add Payment Method</Button>
                </div>
                {showBankAccountModal && stripePromise && clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <ModalPayment
                            show={showBankAccountModal}
                            onClose={() => setShowBankAccountModal(false)}
                            title="Add Payment Method"
                            userData={userData}
                        />
                    </Elements>
                )
                }
            </div>


        </SidebarLayout>
    );
};

export default Payments;
