import * as Yup from 'yup';
import isCommonPassword from 'common-password-checker';

// Define validation schema with Yup
export const PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
    current_password: Yup.string().required('Current password is required'),
    password: Yup
        .string()
        .required('New password is required')
        .min(8, 'New password must be at least 8 characters')
        .matches(/[a-z]/, 'New password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'New password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'New password must contain at least one number')
        .matches(/[@$!%*?&#]/, 'New password must contain at least one special character')
        .test(
            'not-common-password',
            'This password is too common. Please choose a more secure password.',
            (value) => !isCommonPassword(value)
        ),
    password_confirmation: Yup
        .string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required'),
});


export const REGISTER_VALIDATION_SCHEMA = Yup.object().shape({
    user: Yup.string().required("User name is required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup
        .string()
        .required('New password is required')
        .min(8, 'New password must be at least 8 characters')
        .matches(/[a-z]/, 'New password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'New password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'New password must contain at least one number')
        .matches(/[@$!%*?&#]/, 'New password must contain at least one special character')
        .test(
            'not-common-password',
            'This password is too common. Please choose a more secure password.',
            (value) => !isCommonPassword(value)
        ),
    repeat_password: Yup
        .string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required'),
});


export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup
        .string()
        .required('New password is required')
        .min(8, 'New password must be at least 8 characters')
    // .matches(/[a-z]/, 'New password must contain at least one lowercase letter')
    // .matches(/[A-Z]/, 'New password must contain at least one uppercase letter')
    // .matches(/[0-9]/, 'New password must contain at least one number')
    // .matches(/[@$!%*?&#]/, 'New password must contain at least one special character')
    // .test(
    //     'not-common-password',
    //     'This password is too common. Please choose a more secure password.',
    //     (value) => !isCommonPassword(value)
    // )
});