import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useState } from "react";
import BASE_URL from "../Components/auth/Baseurl";
import { errorPopupWithModal, successPopup } from "../helpers";
import { axiosInstance, axiosInstanceWithToken } from "../helpers/axios";

export const useAuthHandler = () => {

    const login = async (data) => {
        try {
            // Create a new FormData object for login
            const formData = new FormData();
            // Append form data fields to the FormData object
            formData.append("login", data.email);
            formData.append("password", data.password);

            // Send the FormData to the API using axios
            const response = await axiosInstance.post("/auth/login", formData);

            return response;
        } catch (error) {
            // Handle any errors that occur during the submission
            console.error("Error submitting login form:", error);
        }
    }

    const register = async (data) => {
        try {
            // Create a new FormData object
            const formData = new FormData();

            console.log("Register", data)
            // Append form data fields to the FormData object
            formData.append("username", data.user);
            formData.append("email", data.email);
            formData.append("password", data.password);
            formData.append("repeat_password", data.repeat_password);
            formData.append("agree", data.agree);

            // Send the FormData to the API using fetch
            const response = await axiosInstance.post("/auth/register",
                formData,
            );

            return response;

        } catch (error) {
            // Handle any errors that occur during the submission
            console.error("Error submitting form:", error);
        }
    }

    const forgetpassword = async (data, emailSent, setEmailSent) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        try {
            if (!emailSent) {
                // console.log('Email data:', data);
                const response = await axiosInstance.post('/auth/forgot-password', formData);
                // console.log('Email sent response:', response);
                setEmailSent(true); // Simulate email sending
                return response;
            } else {
                // console.log('OTP and password data:', data);
                const response = await axiosInstance.post('/auth/reset-password', formData);
                // console.log('Password reset response:', response);
                return response;
                // Handle successful password reset
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            // Handle error
        }
    }

    // -------------------------------------------------------------------------------------------------


    const getCurrentUser = async () => {
        try {
            const response = await axiosInstanceWithToken.get('/current-user');
            localStorage.setItem('data', JSON.stringify(response?.data?.user));
            return response;
        } catch (err) {
            console.log(err)
        }
    }


    // ------------------------------------------------------------------------------------------------------

    // sign in google start //

    const [user, setUser] = useState([]);

    const GetGoogle = (codeResponse) => {
        axios
            .get(
                ` https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                UserData
                    (res?.data);
            })
            .catch((err) => console.log(err));
    };


    const loginWithGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            if (codeResponse) {
                setUser(codeResponse);
                GetGoogle(codeResponse);
            }
        },
        onError: (error) => console.log("Login Failed:", error),
    });


    const UserData = async (newdata) => {
        const data = new FormData();
        data.append("email", newdata?.email);
        data.append("family_name", newdata?.family_name);
        data.append("given_name", newdata?.given_name);
        data.append("id", newdata?.id);
        data.append("name", newdata?.name);
        data.append("username", newdata?.name);
        data.append("picture", newdata?.picture);
        data.append("verified_email", newdata?.verified_email);
        data.append("role", "user");

        try {
            const res = await axios.post(`${BASE_URL}/google/login`, data);
            console.log(res, "login res");

            // Debugging response structure
            console.log(res.data, "res.data");
            console.log(res.data.data, "res.data.data");
            console.log(res.data.data.status, "res.data.data.status");

            if (res?.data?.data?.status) {
                localStorage.setItem("token", res?.data?.data?.accessToken);
                console.log("token", res?.data?.data?.accessToken);

                localStorage.setItem(
                    "data",
                    JSON.stringify(res?.data?.data?.user)
                );
                console.log(JSON.stringify(res?.data?.data?.user), "token");
                successPopup("Login", "Login Successful..!");
                window.location.reload();
            }
        } catch (err) {
            console.error("API Error:", err);

            // Debugging error response
            console.log(err.response?.data?.message, "Error Message");
            // Error Prompt
            errorPopupWithModal("Error", `${err?.res?.data?.message || "Something went wrong"}`, () => { })
            // window.location.reload();
        }
    };

    // sign in google start //


    return {
        login,
        loginWithGoogle,
        register,
        forgetpassword,
        getCurrentUser
    }
}
