import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useListing } from '../../providers';
import { DetailsPage } from '../../Pages/details/DetailsPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

export const DetailsFragment = () => {
    const { id } = useParams();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const [pageLoading, setPageLoading] = useState(true);
    const { getListingById, listingDetails } = useListing();

    useEffect(() => {
        // fetchData();
        getListingById(id).then(() => setPageLoading(false));
    }, [getListingById, id]);

    if (pageLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Elements stripe={stripePromise}>
            <DetailsPage listingDetails={listingDetails} />
        </Elements>
    )
}
