import React, { useMemo } from 'react'
import { useListing } from '../../providers';
import NewMapChange from '../../Pages/NewMapChange';

export const MapListingFragment = () => {
    const { filteredListings } = useListing();

    const memoizedFilteredListings = useMemo(() => filteredListings, [filteredListings]);


    return (
        <NewMapChange filteredListings={memoizedFilteredListings} />
    )
}
