import React, { useState } from "react";
import { useListing } from "../../providers";

export const AirportInfo = () => {
    const { listingDetails } = useListing();

    const {
        airports,
    } = listingDetails;

    const [showAll, setShowAll] = useState(false);

    // Show the first airport initially
    const initialAirports = showAll ? airports : [airports[0]];

    return (
        <div>
            {initialAirports.map((airport, index) => (
                <div key={index} className="airport-section">
                    <div className="airport-info">
                        <h3 className="text-lg font-semibold border-b pb-2 mb-4 text-gray-800 uppercase">
                            {airport?.airport_name} Information ( Airport )
                        </h3>
                        <ul className="space-y-3">
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-plane text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Airport Use:</span>
                                </div>
                                <span className="text-gray-800">{airport.airport_use || "---"}</span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-compass text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Orientation:</span>
                                </div>
                                <span className="text-gray-800">{airport.orientation || "---"}</span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-road text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Surface:</span>
                                </div>
                                <span className="text-gray-800">{airport.surface || "---"}</span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-ruler text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Dimensions:</span>
                                </div>
                                <span className="text-gray-800">
                                    {airport.dimension_feets || "---"} / {airport.dimension_meters || "---"}
                                </span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-mountain text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Elevation:</span>
                                </div>
                                <span className="text-gray-800">
                                    {airport.elevation_feets || "---"} ft / {airport.elevation_meters || "---"} m
                                </span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-lightbulb text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Lighting:</span>
                                </div>
                                <span className="text-gray-800">{airport.lighting ? "Yes" : "No"}</span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-gas-pump text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Fuel:</span>
                                </div>
                                <span className="text-gray-800">{airport.fuel || "---"}</span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-parking text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Parking:</span>
                                </div>
                                <span className="text-gray-800">{airport.parking || "---"}</span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-broadcast-tower text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">CTAF/UNICOM:</span>
                                </div>
                                <span className="text-gray-800">{airport.ctaf_unicom || "---"}</span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-map-signs text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Pattern:</span>
                                </div>
                                <span className="text-gray-800">{airport.pattern || "---"}</span>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <i className="fas fa-clock text-gray-500"></i>
                                    <span className="font-semibold text-gray-600">Operation Hours:</span>
                                </div>
                                <span className="text-gray-800">{airport.operation_hours || "---"}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="ground-transportation">
                        <p className="text-decoration-underline p-0">
                            <strong>GROUND TRANSPORTATION</strong>
                        </p>
                        <p className="sub-title p-0">{airport?.ground_transportation}</p>
                    </div>
                </div>
            ))}
            {!showAll && airports.length > 1 && (
                <div className="more-airports flex justify-end">
                    <button className="bg-[#8EC639] text-white rounded-md py-2 px-4" onClick={() => setShowAll(true)}>
                        More Airports
                    </button>
                </div>
            )}
        </div>
    );
};
