import React from 'react'
import { useListing } from '../../providers';
import { SERVER_IMAGES_URL } from '../../constants';

export const LodgingHostDetails = () => {

    const { listingDetails } = useListing();

    const {
        type_of_space,
        lodging_type,
        no_of_guest,
        no_of_bedrooms,
        no_of_beds,
        no_of_bathrooms,
        host,
    } = listingDetails;

    return (
        <div className="block-body border-bottom-gray !p-0">
            <div className="d-flex justify-content-between">
                <div>
                    <h2 className="title">
                        {type_of_space} -  {lodging_type} <br /> Hosted by{" "}
                        {host?.username}
                    </h2>

                    <div style={{ paddingTop: "10px" }} className="property-details text-start sub-title">
                        {no_of_guest + " "} Guests{" "}
                        <span>•</span> {no_of_bedrooms + " "}
                        Bedrooms  <span>•</span>{" " + no_of_beds + " "}
                        Beds <span>•</span> {no_of_bathrooms + " "} Baths
                    </div>
                </div>

                <div style={{ width: "150px" }} className="host-avatar no-print">
                    <img
                        src={host?.image !== null ? `${SERVER_IMAGES_URL}/profile/${host?.image}` : "images/avatar/avatar-bg.png"}
                        alt="Host Avatar"
                    />
                </div>
            </div>
        </div>
    )
}
