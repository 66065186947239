import axios from "axios";
import React, { createContext, useState } from "react";
import BASE_URL from "../Components/auth/Baseurl";

export const ThemeContext = createContext();

export const fetchData = async (params = {}) => {
  try {
    const response = await axios.get(`${BASE_URL}/listing`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: params,
    });
    console.log(response, "values");
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


export default function ThemeProvider({ children }) {
  const [theme, SetTheme] = useState([]);
  const [shuffledDatas, setShuffledDatas] = useState([]);
  const [filterListing, setFilterListing] = useState([]);
  const [compareData, setCompareData] = useState([]);
  const [data, setData] = useState([]);
  const [mainAmen, setMainAmen] = useState([]);
  const [mainFaci, setMainFaci] = useState([]);
  const [selectedLocation2, setSelectedLocation2] = useState({
    lat1: "",
    lng1: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [quilValue, setQuilValue] = useState("");
  const [listingValue, setListingValue] = useState("");


  return (
    <ThemeContext.Provider value={{
      theme, SetTheme, selectedLocation2, setSelectedLocation2, data, setData, listingValue, setListingValue
      , filterListing, setFilterListing, compareData, setCompareData, mainAmen, setMainAmen, mainFaci, setMainFaci
      , shuffledDatas, setShuffledDatas, isModalOpen, setIsModalOpen, isRegister, setIsRegister, quilValue, setQuilValue
    }}>
      {children}
    </ThemeContext.Provider>
  );
}
