import React, { useEffect, useState } from "react";
import Layout from "../Components/layout/Layout";
import { Link, useParams } from "react-router-dom";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import Loading from "../Components/loader/Loading";
import { MdGroup, MdOutlineBedroomParent } from "react-icons/md";
import { FaShower } from "react-icons/fa6";

const ApartmentDetail = () => {
  const { location } = useParams();
  const [loading, setLoading] = useState(false);
  const [Theme, SetTheme] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("byListingType", location.replace(/_/g, " "));

        const response = await axios.post(
          `${BASE_URL}/listing-search`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        SetTheme(response?.data?.listings);
        setLoading(false);
        console.log(response.data.listings, "values inner");
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location]);

  return loading ? (
    <>
      <Loading />
    </>
  ) : (
    <>
      <Layout>
        {Theme.length === 0 ? (
          <>
            <div
              style={{
                paddingTop: "100px",
                height: "90vh",
                justifyContent: "center",
                alignItems: " center",
                display: "flex",
              }}
              className="container big-container"
            >
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h3 style={{ fontSize: "20px" }}>Oh oh! Page not found. </h3>
                <p style={{ fontSize: "15px" }}>
                  We're sorry, but the page you are looking for doesn't exist.
                  Click below to return to the homepage.
                </p>
                <p style={{ fontSize: "15px" }}>Back to the homepage</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <section>
              <div className="container big-container">
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "20px",
                    fontSize: "20px",
                  }}
                >
                  HomeUnited States of America (Page 1)
                </p>
                <h1
                  style={{
                    textAlign: "left",
                    paddingLeft: "20px",
                    fontSize: "20px",
                  }}
                  className="mt-5"
                >
                  {location.replace(/_/g, " ")}
                </h1>
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "20px",
                    fontSize: "20px",
                  }}
                >
                  United States of America
                </p>

                <div className="grid-item-holder gallery-items fl-wrap">
                  <div
                    style={{ display: "flex", flexWrap: "wrap" }}
                    className="row"
                  >
                    {/* Mapping over Theme array to render multiple items */}
                    {Theme?.map((e) => {
                      return (
                        <div className="gallery-item hotels col-md-3">
                          <div className="listing-item">
                            <article className="geodir-category-listing fl-wrap container">
                              <div className="geodir-category-img">
                                <Link
                                  to={`/details/${e.id}`}
                                  className="geodir-category-img-wrap fl-wrap"
                                >
                                  <img
                                    style={{
                                      width: "300px",
                                      height: "250px",
                                    }}
                                    src={e?.images[0]?.image}
                                    alt=""
                                  />
                                </Link>
                                <div className="listing-price">
                                  <h3
                                    className="title-sin_map"
                                    style={{ fontSize: "18px" }}
                                  >
                                    <Link
                                      to={`/details/${e.id}`}
                                      style={{ color: "white" }}
                                    >
                                      $ {e?.additional_guest_price}
                                    </Link>
                                  </h3>
                                </div>
                                <div className="geodir-category-opt">
                                  <div className="listing-rating-count-wrap">
                                    <div
                                      className="listing-rating card-popup-rainingvis"
                                      data-starrating2={5}
                                    />
                                    <h3
                                      className="title-sin_map"
                                      style={{ fontSize: "18px" }}
                                    >
                                      <Link
                                        to={`/details/${e.id}`}
                                        style={{ color: "white" }}
                                      >
                                        {e?.host?.first_name + " " + e?.address}
                                      </Link>
                                    </h3>
                                  </div>
                                </div>
                                <div
                                  className="geodir-category-location"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "absolute",
                                    bottom: "30px",
                                    zIndex: 3,
                                    width: "100%",
                                    padding: "0px 10px",
                                  }}
                                >
                                  <Link
                                    to={`/details/${e.id}`}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <MdOutlineBedroomParent
                                      size={20}
                                      color="white"
                                    />{" "}
                                    <p className="text-white">
                                      {e?.no_of_bedrooms}
                                    </p>
                                  </Link>
                                  <Link
                                    to={`/details/${e.id}`}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FaShower size={20} color="white" />
                                    <p className="text-white">
                                      {e?.no_of_bathrooms}
                                    </p>
                                  </Link>
                                  <Link
                                    to={`/details/${e.id}`}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <MdGroup size={20} color="white" />
                                    <p className="text-white">
                                      {e?.no_of_guest}
                                    </p>
                                  </Link>
                                  <div
                                    className="btn btn-primary cursor-pointer"
                                    style={{ padding: "6px 10px" }}
                                  >
                                    Compare
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </Layout>
    </>
  );
};

export default ApartmentDetail;
