import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { ADD_LISTING_DEFAULT_VALUES } from "../../constants";
import { axiosInstance, axiosInstanceWithToken } from "../../helpers/axios";
import { warningPopup } from "../../helpers";
import axios from "axios";

// Create context
const ListingContext = createContext();

// Provider component
export const ListingProvider = ({ children }) => {
    const [listingData, setListingData] = useState([ADD_LISTING_DEFAULT_VALUES]);
    const [allListings, setAllListings] = useState([]);
    const [userListings, setUserListings] = useState([]);
    const [listingDetails, setListingDetails] = useState(null);
    const [filteredListings, setFilteredListings] = useState([]);
    const [featuredListings, setFeaturedListings] = useState([]);
    const [favoriteListings, setFavoriteListings] = useState([]);
    const [compareList, setCompareList] = useState([]);


    // Functions to fetch data
    const getAllListings = useCallback(async (params = {}) => {
        try {
            console.log("Getting All Listing")
            const response = await axiosInstance.get("/listing", { params });
            setAllListings(response?.data?.data);
            setFilteredListings(response?.data?.data?.listings);
            return response?.data?.data;
        } catch (error) {
            console.error("Error fetching all listings:", error);
            throw error;
        }
    }, []);

    const getFeaturedListings = useCallback(async (params = {}) => {
        try {
            console.log("Getting Featured Listing", params)
            const response = await axiosInstance.get("/listing", { params });
            setFeaturedListings(response?.data?.data);
            return response?.data?.data;
        } catch (error) {
            console.error("Error fetching all listings:", error);
            throw error;
        }
    }, []);


    const getFilteredListings = useCallback(async (params = {}, fromFilter = false) => {
        try {
            console.log("Getting Filtered Listing")
            const response = await axiosInstance.get("/listing", { params });
            setFilteredListings((prevListings) => {
                const newListings = [...(prevListings || []), ...response?.data?.data.listings];
                if (fromFilter)
                    return response?.data?.data.listings;
                return Array.from(new Map(newListings.map((item) => [item?.id, item])).values());
            });
            return response?.data?.data;
        } catch (error) {
            console.error("Error fetching all listings:", error);
            throw error;
        }
    }, []);

    const getFavoriteListings = useCallback(async (params = {}) => {
        try {
            console.log("Getting Filtered Listing")
            const response = await axiosInstanceWithToken.get("/favourite", { params });
            setFavoriteListings(response?.data?.data);
            return response?.data?.data;
        } catch (error) {
            console.error("Error fetching all listings:", error);
            throw error;
        }
    }, []);

    const getListingsByUser = useCallback(async (params = {}) => {
        try {
            const response = await axiosInstanceWithToken.get(`/listing`, { params });
            setUserListings(response.data);
            return response.data;
        } catch (error) {
            console.error(`Error fetching listings`, error);
            throw error;
        }
    }, []);

    const getListingById = useCallback(async (listingId) => {
        try {
            const response = await axiosInstanceWithToken.get(`/listing/${listingId}`);
            setListingDetails(response?.data?.listing);
            return response.data;
        } catch (error) {
            console.error(`Error fetching listing with ID ${listingId}:`, error);
            throw error;
        }
    }, []);

    // Function to update listing data
    const updateListing = useCallback((key, value) => {
        setListingData((prev) => ({ ...prev, [key]: value }));
    }, []);

    // Function to reset listing data
    const resetListing = useCallback(() => {
        setListingData([ADD_LISTING_DEFAULT_VALUES]);
    }, []);

    const toggleCompare = useCallback((item) => {
        // Limit the number of items in the compare list to 4
        if (compareList?.length >= 4 && !compareList.some((compareItem) => compareItem.id === item.id)) {
            warningPopup("You can compare up to 4 items");
            return;
        }

        // Update the compare list
        setCompareList((prev) => {
            // Check if the item already exists in the compare list
            const isAlreadyInList = prev.some((compareItem) => compareItem.id === item.id);

            // If it's in the list, remove it; otherwise, add it
            return isAlreadyInList
                ? prev.filter((compareItem) => compareItem.id !== item.id) // Remove item
                : [...prev, item]; // Add item
        });
    }, [compareList]);


    const handleFavorite = useCallback(async (id, is_favorite) => {
        try {
            // Prepare the API URL
            const url = is_favorite
                ? `https://flyinn-backend-i8oh5.kinsta.app/api/favourite/${id}`
                : 'https://flyinn-backend-i8oh5.kinsta.app/api/favourite';

            // Initialize FormData and append the listing ID for POST requests
            const data = new FormData();
            data.append("listing_id", id);

            // Configure the request
            const config = {
                method: is_favorite ? "delete" : "post", // DELETE for unfavorite, POST for favorite
                url,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`, // Retrieve token from localStorage
                    ...(is_favorite ? {} : data.getHeaders?.()), // Include FormData headers for POST
                },
                ...(is_favorite ? {} : { data }), // Attach FormData only for POST
                maxBodyLength: Infinity, // Ensure no size limitation
            };

            // Make the API call
            const response = await axios.request(config);

            // Handle the response
            if (response.data) {
                console.log("Favorite updated successfully:", response.data);
                getFavoriteListings(); // Refresh the favorite listings
            } else {
                console.error("Unexpected response structure:", response);
            }
        } catch (error) {
            // Log any errors
            console.error("Error updating favorite:", error.message || error);
        }
    }, [getFavoriteListings]);


    const handleCompare = useCallback(async () => {
        try {
            const allIDs = compareList?.map((item) => item?.id).join(',');
            await axiosInstance(`/compare?listings=${allIDs}`).then((response) => {
                setCompareList(response?.data?.data);
            });
        } catch (ex) {
            console.log(ex)
        }
    }, [compareList]);

    useEffect(() => {
        const token = localStorage.getItem("token"); // Retrieve token from localStorage
        if (token) {
            getFavoriteListings();
        }
    }, [getFavoriteListings])


    return (
        <ListingContext.Provider
            value={{
                listingData,
                allListings,
                userListings,
                listingDetails,
                getAllListings,
                getListingsByUser,
                getListingById,
                updateListing,
                resetListing,
                setCompareList,
                compareList,
                toggleCompare,
                filteredListings,
                getFilteredListings,
                featuredListings,
                getFeaturedListings,
                handleFavorite,
                handleCompare,
                getFavoriteListings,
                favoriteListings
            }}
        >
            {children}
        </ListingContext.Provider>
    );
};

// Custom hook to use the context
export const useListing = () => {
    const context = useContext(ListingContext);
    if (!context) {
        throw new Error("useListing must be used within a ListingProvider");
    }
    return context;
};