import React from 'react';
import '../css/css-styling-options.css';
import '../css/css-post-8728.css';
import '../css/cirt.css';
import Layout from '../../../../Components/layout/Layout';


export const CirtPolicy = () => {
    return (
        <Layout>
            <div id="section-body">
                <div id="has_social_account" style={{ display: 'none' }} data-has-social-account="">
                </div>
                <section class="main-content-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <div class="page-title">
                                    <div class="block-top-title">
                                        <h1 class="listing-title">CIRT Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container bg-white">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="page-wrap">
                                    <div class="article-main">
                                        <article id="post-8728" class="single-page-article block post-8728 page type-page status-publish hentry">
                                            <div class="article-detail block-body">
                                                <div data-elementor-type="wp-page" data-elementor-id="8728" class="elementor elementor-8728" data-elementor-post-type="page">
                                                    <div class="elementor-element elementor-element-3a71b800 e-flex e-con-boxed e-con e-parent" data-id="3a71b800" data-element_type="container">
                                                        <div class="e-con-inner">
                                                            <div class="elementor-element elementor-element-54e43d8c elementor-widget elementor-widget-text-editor" data-id="54e43d8c" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p><strong>CIRTainly</strong></p>
                                                                    <p>&ldquo;Without integrity, nothing works.&rdquo;</p>
                                                                    <p>While it is unclear who exactly came up with the phrase above, Werner Erhard, Professor Michael Jensen, or Steve Zaffron, it is the motto we live by at FlyInn. That and something more&hellip;</p>
                                                                    <p>We would rephrase it to, &ldquo;Without Common Sense, Integrity, Respect, and Trust, Nothing Works.&rdquo;</p>
                                                                    <p>It takes common sense to come up with the rules that make our Community thrive. It takes integrity to stand behind those rules and follow them. Common sense and integrity are the keys to building a solid foundation of Respect among Members, a Respect that people can count on and Trust.</p>
                                                                    <p>We are a stand for creating this to be your Community, a Community where every one of you feels like a very important Member. Being able to count on that level of integrity and respect for the rules, guidelines, standards, and policies that FlyInn establishes by listening to its Members is what makes our Community and marketplace thrive.</p>
                                                                    <p>We encourage you to be an active Member of your Community by sharing your opinions, feedback, and suggestions in the spirit of making the Community better.</p>
                                                                    <p>&nbsp;</p>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-element elementor-element-18bc6b4 elementor-widget elementor-widget-image" data-id="18bc6b4" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container w-full flex justify-center">
                                                                    <img fetchpriority="high" decoding="async" width="2560" height="464" src="images/11-cirt-scaled.jpg" class="attachment-full size-full wp-image-8814" alt="" srcset="https://fly-inn.com/wp-content/uploads/2023/11/cirt-scaled.jpg 2560w, https://fly-inn.com/wp-content/uploads/2023/11/cirt-300x54.jpg 300w, https://fly-inn.com/wp-content/uploads/2023/11/cirt-1024x186.jpg 1024w, https://fly-inn.com/wp-content/uploads/2023/11/cirt-768x139.jpg 768w, https://fly-inn.com/wp-content/uploads/2023/11/cirt-1536x278.jpg 1536w, https://fly-inn.com/wp-content/uploads/2023/11/cirt-2048x371.jpg 2048w, https://fly-inn.com/wp-content/uploads/2023/11/cirt-600x109.jpg 600w" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

