import React from 'react';
import '../css/css-styling-options.css';
import '../css/css-post-8728.css';
import '../css/cirt.css';
import Layout from '../../../../Components/layout/Layout';

export const FairHousingPolicy = () => {
    return (

        <Layout>
            <div id="section-body">

                <div id="has_social_account" style={{ display: 'none' }} data-has-social-account=""></div>
                <section class="main-content-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <div class="page-title">
                                    <div class="block-top-title">
                                        <h1 class="listing-title">Fair Housing Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container bg-white">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="page-wrap">
                                    <div class="article-main">
                                        <article id="post-8715" class="single-page-article block post-8715 page type-page status-publish hentry">
                                            <div class="article-detail block-body">
                                                <div data-elementor-type="wp-page" data-elementor-id="8715" class="elementor elementor-8715" data-elementor-post-type="page">
                                                    <div class="elementor-element elementor-element-3a71b800 e-flex e-con-boxed e-con e-parent" data-id="3a71b800" data-element_type="container">
                                                        <div class="e-con-inner">
                                                            <div class="elementor-element elementor-element-54e43d8c elementor-widget elementor-widget-text-editor" data-id="54e43d8c" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        Our Fair Housing Policy goes hand in hand with our CIRT Policy.<br />Our&nbsp;
                                                                        <a href="cirt-policy.html" target="_blank" rel="noopener">CIRT Policy</a>&nbsp;states that<br /><u><b> C</b>ommon Sense</u> in creating rules leads to<br /><u><b> I</b>ntegrity</u> in following those rules which leads to<br /><u><b> R</b>espect</u> toward Members which leads to<br /><u><b> T</b>rust</u> in the Community.
                                                                    </p>
                                                                    <p>Some of the most Common Sense rules in our community are those of inclusion for our fellow Members. One of the things we value most as Members of this Community is the opportunity to meet and make friends with fellow aviation enthusiasts. Our fellows come from all different backgrounds and we wouldn&rsquo;t have a real Community if we didn&rsquo;t all welcome each other. We are here to respect each other, treat each other fairly, and be good Members of our Community.</p>
                                                                    <p>We take the opportunity here to introduce the Fair Housing Act of 1968. The purpose of this Act is to prohibit discrimination when it comes to the financing, sale, and rental of housing based on six protected classes which are race, religion, national origin, sex, handicap, and familial status. Though most of the rentals that happen on our site are transitory, we still adopt this policy of inclusion for our Community and you must conduct your business on the Platform following it. By using the Platform you are demonstrating that you agree to this Policy. You hereby agree to familiarize yourself fully with the&nbsp;<a href="https://www.justice.gov/crt/fair-housing-act-1" target="_blank" rel="noopener noreferrer">Fair Housing Act</a>&nbsp;which can be found on the&nbsp;<a href="https://www.justice.gov/crt/fair-housing-act-1" target="_blank" rel="noopener noreferrer">Department of Justice&rsquo;s website</a>, and with any other state or local Fair Housing laws that may apply to your Listing. You agree to proactively search which laws apply to you or email us for assistance finding any&nbsp;information, at <a href="mailto:help@fly-inn.com">help@fly-inn.com</a> with the subject line reading, &ldquo;Fair Housing.&rdquo;</p>
                                                                    <p>Ask your lawyer for assistance interpreting the Fair Housing Act of 1968 and any other state or local Fair Housing laws that may apply to your Listing. We cannot offer legal advice.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

