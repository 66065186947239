import React from 'react';
import { TbBrandGoogle } from 'react-icons/tb';
import { useAuthHandler } from '../../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGISTER_VALIDATION_SCHEMA } from '../../../utils/schema/password_schema';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { errorPopup, successPopup } from '../../../helpers';

export const RegisterForm = ({ setIsModalOpen, setIsRegister }) => {
    const { loginWithGoogle, register: formSubmit } = useAuthHandler();
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: yupResolver(REGISTER_VALIDATION_SCHEMA),
    });


    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const togglePasswordVisibility1 = () => setShowPassword1(!showPassword1);

    const onSubmit = async (data) => {
        try {
            await formSubmit(data).then(response => {
                if (response.data.status) {
                    successPopup('', response?.data?.message + '\n Please check your email and verify your account.');
                }
            });
        } catch (ex) {
            errorPopup('', 'Registration Faiiled!')
        }
    };
    return (
        <div className="login-column bg-white z-50">
            <div className="main-register-holder tabs-act m-0">
                <div className="main-register fl-wrap">
                    <ul className="tabs-menu fl-wrap no-list-style flex items-center justify-between">
                        <h4 className="text-sm">Register</h4>
                    </ul>

                    {/* Tabs Container */}
                    <div className="tabs-container">
                        {/* Tab 1: Register with Google */}
                        <button
                            className="flex items-center justify-center bg-gray-100 py-3 px-4 rounded-lg shadow-md hover:bg-gray-200 w-full"
                            onClick={() => loginWithGoogle()}
                        >
                            <TbBrandGoogle className="mr-2 w-6 h-6" />
                            Register with Google
                        </button>

                        <div className="flex justify-center py-2">
                            <h3 className="text-gray-500 text-xs">OR</h3>
                        </div>
                        <div className="relative flex justify-center my-2">
                            <div className="absolute inset-0 flex justify-between items-center">
                                <div className="w-1/3 h-px bg-gray-300"></div>
                                <div className="w-1/3 h-px bg-gray-300"></div>
                            </div>
                        </div>



                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                            {/* Username Input */}
                            <div>
                                <input
                                    {...register('user')}
                                    type="text"
                                    className={`custom-input_change ${errors.user ? 'error' : ''} p-3 w-full border border-gray-200`}
                                    placeholder={errors.user ? errors.user.message : 'Username'}
                                />
                                {errors.user && <p className="text-red-500 text-sm">{errors.user.message}</p>}
                            </div>

                            {/* Email Input */}
                            <div>
                                <input
                                    {...register('email')}
                                    type="text"
                                    className={`custom-input_change ${errors.email ? 'error' : ''} p-3 w-full border border-gray-200`}
                                    placeholder={errors.email ? errors.email.message : 'Email'}
                                />
                                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                            </div>

                            {/* Password Input */}
                            <div className="relative">
                                <input
                                    {...register('password')}
                                    type={showPassword ? 'text' : 'password'}
                                    className={`custom-input_change ${errors.password ? 'error' : ''} p-3 w-full border border-gray-200`}
                                    placeholder={errors.password ? errors.password.message : 'Password'}
                                />
                                <span
                                    onClick={togglePasswordVisibility}
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                >
                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                                {errors.password && <p className="text-red-500 text-sm">{errors.password.message}</p>}
                            </div>

                            {/* Repeat Password Input */}
                            <div className="relative">
                                <input
                                    {...register('repeat_password')}
                                    type={showPassword1 ? 'text' : 'password'}
                                    className={`custom-input_change ${errors.repeat_password ? 'error' : ''} p-3 w-full border border-gray-200`}
                                    placeholder={errors.repeat_password ? errors.repeat_password.message : 'Repeat Password'}
                                />
                                <span
                                    onClick={togglePasswordVisibility1}
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                >
                                    {showPassword1 ? <FaEye /> : <FaEyeSlash />}
                                </span>
                                {errors.repeat_password && <p className="text-red-500 text-sm">{errors.repeat_password.message}</p>}
                            </div>

                            {/* Terms & Conditions Checkbox */}
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    {...register('agree')}
                                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                />
                                <label htmlFor="agree" className="ml-2 text-sm leading-5 text-gray-900">
                                    I agree to the{' '}
                                    <span className="text-red-600">
                                        <Link to="/terms-of-service">Terms of Service</Link>
                                    </span>
                                </label>
                            </div>

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="bg-[#8EC639] text-white font-bold py-2 px-4 rounded w-full hover:bg-green-600"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Register'}
                            </button>
                        </form>


                        {/* Login Link */}
                        <p className="mt-4 text-center">
                            Already have an account?{' '}
                            <span
                                className="cursor-pointer text-red-600"
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setIsRegister(false);
                                }}
                            >
                                Log In
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}
