/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import Layout from "../Components/layout/Layout";
import { FaList, FaMap } from "react-icons/fa";
import { FormProvider, useListing } from "../providers";
import { ListingFilters } from "../Components/Listings/Filter";
import { SEARCH_MAP_VALIDATION } from "../utils/schema/search-map-schema";
import { SEARCH_MAP_DEFAULT_VALUES } from "../constants";
import LocationMap from "../Components/map/LocationMap";
import ListingOutOverlay from "../Components/ui/ListingOutOverlay";

const Listing = () => {
  const { getFilteredListings, filteredListings } = useListing();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sort, setSort] = useState("default_random");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadListings = useCallback(async () => {
    if (!hasMore) return;
    setLoading(true);
    try {
      let params = {
        page,
        per_page: 20,
        sort_by: sort,
      };

      if (sort === "default_random") {
        params.is_random = 1;
        delete params.sort_by;
      } else {
        delete params?.is_random;
      }

      const res = await getFilteredListings(params);
      if (res?.listings?.length > 0) {
        // setListings((prevListings) => {
        //   const newListings = [...prevListings, ...res.listings];
        //   return Array.from(new Map(newListings.map((item) => [item?.id, item])).values());
        // });
        setPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching listings:", error);
    } finally {
      setLoading(false);
    }
  }, [page, sort, hasMore]);

  useEffect(() => {
    // setListings([]);
    setPage(1);
    setHasMore(true);
    loadListings();
  }, [sort]);

  return (
    <Layout>
      <FormProvider defaultValues={SEARCH_MAP_DEFAULT_VALUES} validationSchema={SEARCH_MAP_VALIDATION}>
        <div className="bg-[#F7F8F9]">
          <div className="bg-white py-4">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  setIsModalOpen((open) => !open);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                className="fixed z-[9999] p-4 rounded-full bg-red-600 text-white font-medium text-sm bottom-4 flex items-center justify-center shadow-lg hover:bg-red-700 transition"
              >
                {isModalOpen ? (
                  <>Show Listings <FaList className="ml-2" /></>
                ) : (
                  <> Show Map <FaMap className="ml-2" /> </>
                )}
              </button>
            </div>

            {isModalOpen ? (
              <LocationMap />
            ) : (
              <>
                <div className="flex justify-center items-center">
                  <div className="grid md:grid-cols-7 grid-cols-3 bg-[#ffffffcf] rounded-lg md:gap-0 gap-2 px-2">
                    <ListingFilters />
                  </div>
                </div>
                <div className="section-title lg:px-[4rem] px-4" style={{ paddingTop: "50px" }}>
                  <h2 className="!text-start">Stays</h2>
                </div>

                <div className="section-title flex justify-between flex-wrap lg:px-[4.5rem] px-4">
                  <h3 className="text-sm font-normal">
                    {filteredListings.length + " "}Rentals
                  </h3>
                  <div className="flex items-center flex-wrap">
                    <h4 className="text-md text-nowrap font-bold">Sort by:</h4>
                    <div className="main-search-input-item">
                      <select
                        className="nice-select chosen-select no-search-select !z-[99]"
                        value={sort}
                        onChange={(e) => {
                          setPage(1);
                          setSort(e.target.value);
                        }}
                      >
                        <option value="default_random">Default (Random)</option>
                        <option value="price_low_to_high">Price (Low to High)</option>
                        <option value="price_high_to_low">Price (High to Low)</option>
                        <option value="featured">Featured First</option>
                        <option value="oldest">Date (Old to New)</option>
                        <option value="newest">Date (New to Old)</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="container mx-auto">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-3 px-3">
                    {
                      filteredListings?.length > 0 &&
                      filteredListings.map((listing, index) => (
                        <ListingOutOverlay
                          key={listing.id}
                          e={listing}
                        />
                      ))
                    }
                  </div>
                </div>

                {loading &&
                  <div className="flex items-center justify-center h-screen flex-col gap-5">
                    <img
                      className="w-36 h-auto md:w-44" // Responsive sizes
                      src="/image/fly-inn-logo.svg"
                      alt="Fly Inn Logo"
                    />
                    <div className="text-2xl text-bold"> Please Wait while we load your data!</div>
                  </div>
                }

                {!loading && hasMore && (
                  <div className="flex justify-center mt-4 mb-4">
                    <button
                      onClick={loadListings}
                      className="color2-bg my-3 text-white bg-[#8EC639] rounded-md font-medium w-28 h-10"
                    >
                      Show More
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </FormProvider>
    </Layout>
  );
};

export default Listing;
