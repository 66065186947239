import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToPreviousPosition = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const saveScrollPosition = () => {
            const scrollPosition = {
                x: window.scrollX,
                y: window.scrollY,
            };
            sessionStorage.setItem(`scroll-position-${location.pathname}`, JSON.stringify(scrollPosition));
        };

        const restoreScrollPosition = () => {
            const savedPosition = sessionStorage.getItem(`scroll-position-${location.pathname}`);
            const previousPositionKey = sessionStorage.getItem('previous-position-key');

            if (location.pathname === '/' && previousPositionKey) {
                const previousPosition = JSON.parse(sessionStorage.getItem(previousPositionKey));
                const newY = previousPosition?.y ? Math.max(previousPosition.y - 80, 0) : 0;

                window.scrollTo({
                    top: newY,
                    left: 0,
                    behavior: 'smooth',
                });
            } else if (savedPosition) {
                const { x, y } = JSON.parse(savedPosition);
                window.scrollTo({
                    top: y,
                    left: x,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        };

        // Save the previous page key
        const savePreviousPageKey = () => {
            sessionStorage.setItem('previous-position-key', `scroll-position-${location.pathname}`);
        };

        // Save the scroll position before navigating away
        window.addEventListener('beforeunload', saveScrollPosition);
        window.addEventListener('popstate', restoreScrollPosition);

        // Save previous page key and restore scroll position
        savePreviousPageKey();
        setTimeout(() => {
            restoreScrollPosition();
        }, 500);

        return () => {
            saveScrollPosition();
            window.removeEventListener('beforeunload', saveScrollPosition);
            window.removeEventListener('popstate', restoreScrollPosition);
        };
    }, [location]);

    return <>{children}</>;
};

export default ScrollToPreviousPosition;
