import React, { useRef } from 'react';
import { getIconClass } from '../../../helpers';
import { Autocomplete } from '@react-google-maps/api';
import { useFormContext } from '../../../providers';


export const MailingAddressSection = () => {
    // Use React Hook Form with Yup validation
    const {
        register,
        setValue,
        errors,
        getValues,
        fieldRefs
    } = useFormContext();

    const autocompleteRef = useRef(null);

    // Handle place selection
    // Handle place selection
    const handlePlaceSelect = () => {
        const place = autocompleteRef.current.getPlace();

        if (place && place.address_components) {
            const address = place.address_components;
            const streetAddress = place.formatted_address;

            // Only update if the value is different to prevent infinite loop
            if (streetAddress !== getValues('address')) {
                setValue('address', streetAddress);
            }

            address.forEach((component) => {
                if (component.types.includes('locality')) {
                    // Only set value if it's different to avoid unnecessary re-renders
                    if (component.long_name !== getValues('city')) {
                        setValue('city', component.long_name);
                    }
                }
                if (component.types.includes('administrative_area_level_1')) {
                    if (component.long_name !== getValues('state')) {
                        setValue('state', component.long_name);
                    }
                }
                if (component.types.includes('postal_code')) {
                    if (component.long_name !== getValues('zip_code')) {
                        setValue('zip_code', component.long_name);
                    }
                }
                if (component.types.includes('country')) {
                    if (component.long_name !== getValues('country')) {
                        setValue('country', component.long_name);
                    }
                }
            });
        }
    };

    return (
        <div className="profile-edit-container fl-wrap block_box">
            <div className="custom-form">
                <div className="dashboard-title" style={{ display: 'flex', justifyContent: 'center' }}>
                    <h3>Mailing Address</h3>
                </div>

                <div>
                    <div className="row">
                        <div className="col-sm-8" ref={(el) => (fieldRefs.current["address"] = el)}>
                            <label>
                                Street Address <i className={getIconClass('Street Address')} />
                            </label>
                            <Autocomplete
                                onLoad={(ref) => (autocompleteRef.current = ref)}
                                onPlaceChanged={handlePlaceSelect}
                            >
                                <input
                                    type="text"
                                    placeholder="Enter street address"
                                    {...register('address', { onChange: (e) => setValue('address', e.target.value, { shouldValidate: true }) })}

                                />
                            </Autocomplete>
                            {errors.address && (
                                <span style={{ color: 'red' }}>{errors.address.message}</span>
                            )}
                        </div>

                        <div className="col-sm-4">
                            <label>
                                Apartment/Suite (optional) <i className={getIconClass('Apt, Suite')} />
                            </label>
                            <input
                                type="text"
                                placeholder="Apartment/Suite (optional)"
                                {...register('unit_no')}
                            />
                        </div>

                        <div className="col-sm-4" style={{ marginTop: '12px' }} ref={(el) => (fieldRefs.current["city"] = el)}>
                            <label>
                                City <i className={getIconClass('City')} />
                            </label>
                            <input type="text" placeholder="City"
                                {...register('city', { onChange: (e) => setValue('city', e.target.value, { shouldValidate: true }) })}
                            />
                            {errors.city && <span style={{ color: 'red' }}>{errors.city.message}</span>}
                        </div>

                        <div className="col-sm-4" style={{ marginTop: '12px' }} ref={(el) => (fieldRefs.current["state"] = el)}>
                            <label>
                                State <i className={getIconClass('State')} />
                            </label>
                            <input type="text" placeholder="State"
                                {...register('state', { onChange: (e) => setValue('state', e.target.value, { shouldValidate: true }) })}
                            />
                            {errors.state && <span style={{ color: 'red' }}>{errors.state.message}</span>}
                        </div>

                        <div className="col-sm-4" style={{ marginTop: '12px' }} ref={(el) => (fieldRefs.current["zip_code"] = el)}>
                            <label>
                                ZIP / Postal code <i className={getIconClass('ZIP / Postal code')} />
                            </label>
                            <input type="text" placeholder="ZIP / Postal code"
                                {...register('zip_code', { onChange: (e) => setValue('zip_code', e.target.value, { shouldValidate: true }) })}
                            />
                            {errors.zip_code && <span style={{ color: 'red' }}>{errors.zip_code.message}</span>}
                        </div>

                        <div className="col-sm-6" style={{ marginTop: '12px' }}>
                            <label>
                                Area (optional) <i className={getIconClass('Neighborhood')} />
                            </label>
                            <input type="text" placeholder="Area (optional)"
                                {...register('neighbourhood')}

                            />
                        </div>

                        <div className="col-sm-6" style={{ marginTop: '12px' }} ref={(el) => (fieldRefs.current["country"] = el)}>
                            <label>
                                Country <i className={getIconClass('Country')} />
                            </label>
                            <input type="text" placeholder="Country"
                                {...register('country', { onChange: (e) => setValue('country', e.target.value, { shouldValidate: true }) })}
                            />
                            {errors.country && <span style={{ color: 'red' }}>{errors.country.message}</span>}
                        </div>
                    </div>

                    {/* <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            paddingTop: '20px',
                            alignItems: 'center',
                        }}
                    >
                        <input type="checkbox" {...register('same_as_mailing')} />
                        <p>Same as billing address.</p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
