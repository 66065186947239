import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const PaymentSuccessPage = () => {
    const [searchParams] = useSearchParams();
    // const setupIntentId = searchParams.get('setup_intent');
    // const setupIntentSecret = searchParams.get('setup_intent_client_secret');
    const setupIntentStatus = searchParams.get('redirect_status');

    console.log(setupIntentStatus)

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-md text-center">
                {setupIntentStatus === 'succeeded' ? (
                    <>
                        <h1 className="text-2xl font-semibold text-green-600 mb-4">Payment Method Added Successfully!</h1>
                        <p className="text-gray-700">
                            Your payment method has been added and is ready for use.
                        </p>
                        {/* <p className="mt-4 text-sm text-gray-500">Setup Intent ID: {setupIntentId}</p> */}
                        <button
                            className="bg-[#8EC639] text-white py-2 px-4 rounded-md hover:bg-green-600 w-full"
                            onClick={() => window.location.href = '/dashboard'} // Redirect to dashboard or any other page
                        >
                            Go to Dashboard
                        </button>
                    </>
                ) : (
                    <>
                        <h1 className="text-2xl font-semibold text-red-600 mb-4">Payment Method Setup Failed</h1>
                        <p className="text-gray-700">Unfortunately, we couldn't add your payment method. Please try again.</p>
                        {/* <p className="mt-4 text-sm text-gray-500">Setup Intent ID: {setupIntentId}</p> */}
                        <button
                            className="mt-6 bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
                            onClick={() => window.location.href = '/account-settings/payments'} // Redirect back to payment form
                        >
                            Try Again
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
