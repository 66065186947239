import React, { useMemo } from "react";

export const CheckboxList = ({
    subFeaturesList,
    featuresBlock,
    index,
    handleSubblockChange,
    errors,
}) => {
    const renderedSubFeatures = useMemo(() => {
        const subFeatures = subFeaturesList[index] || [];
        return subFeatures.map((item) => {
            const featureId = featuresBlock[index]?.feature_id;

            return (
                <li key={item.id} className="border border-gray-100 p-3">
                    <input
                        type="checkbox"
                        id={item.id}
                        data-feature-id={featureId} // Pass the feature_id to handleSubblockChange
                        checked={featuresBlock?.[index]?.sub_features.includes(item.id)}
                        onChange={(e) => handleSubblockChange(e, index)}
                    />
                    <label htmlFor={item.id} style={{ padding: "0px 20px 0px 6px", top: 0 }}>
                        {item.sub_heading}
                    </label>
                </li>
            );
        });
    }, [subFeaturesList, featuresBlock, index, handleSubblockChange]);

    return (
        <div className="md:col-span-12 d-flex flex-wrap gap-3">
            <ul className="fl-wrap filter-tags no-list-style ds-tg" style={{ paddingTop: "10px" }}>
                {renderedSubFeatures}
            </ul>
            {errors?.features?.[index]?.sub_features && (
                <p className="text-red-500">{errors?.features?.[index]?.sub_features?.message}</p>
            )}
        </div>
    );
};
