/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./detail.css";
import { axiosInstanceWithToken } from "../helpers/axios";
import ListingOutOverlay from "../Components/ui/ListingOutOverlay";
import Layout from "../Components/layout/Layout";
// ---------------------------------------------
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import { SERVER_IMAGES_URL } from "../constants";


const PicProfile = () => {
  const { state } = useLocation();
  const host_id = state?.host_id;
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axiosInstanceWithToken(`/user/${host_id}`)
      console.log(response, "responsePIC");
      setUser(response?.data?.data);
      setIsLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen flex-col gap-5">
        <img
          className="w-36 h-auto md:w-44" // Responsive sizes
          src="/image/fly-inn-logo.svg"
          alt="Fly Inn Logo"
        />
        <div className="text-2xl text-bold"> Please Wait while we load your data!</div>
      </div>
    );
  }

  return (
    <Layout>
      <section
        className="main-content-area !p-10"
        style={{ backgroundColor: "rgb(247, 248, 249)" }}
      >
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-sm-12 col-md-12 bg-white"
            >
              <div className="page-wrap">
                <div className="article-main">
                  <article
                    id="post-7550"
                    className="single-page-article block post-7550 page type-page status-publish hentry"
                  >
                    <div className="block p-8">
                      <div className="block-head">
                        <div className="media">
                          <div className="media-left">
                            <img
                              width={70}
                              height={70}
                              alt=""
                              src={user?.image !== null ? `${SERVER_IMAGES_URL}/profile/${user?.image}` : "images/avatar/avatar-bg.png"}
                              className="img-circle w-[70px] h-[70px] lazyloaded"
                            />
                          </div>
                          <div className="media-body">
                            <h2 className="title">
                              {user?.username || user?.given_name}
                            </h2>
                            <ul className="list-inline profile-host-info">
                              <li>
                                <address>
                                  <i
                                    className="homey-icon homey-icon-style-two-pin-marker"
                                    aria-hidden="true"
                                  />{" "}
                                  {user?.country || user?.country}
                                </address>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <h3 style={{ display: "flex", textAlign: "left", marginTop: "30px" }}>Bio</h3>
                      <p style={{ padding: "0px" }}>
                        {user?.bio || user?.bio}
                      </p>
                      <div className="">
                        <div className="row">

                          <div className="col-md-3 text-left">
                            <dl>
                              <dt>Native / Fluent Languages</dt>
                              <dd>{user?.native_language || user?.native_language}</dd>
                            </dl>
                          </div>
                          <div className="col-md-3 text-left">
                            <dl>
                              <dt>Other Languages</dt>
                              <dd>{user?.other_language || user?.other_language}</dd>
                            </dl>
                          </div>
                          <div className="col-md-3 text-left">
                            <dl>
                              <dt>Profile Status</dt>
                              <dd className="text-danger">
                                <i className="homey-icon homey-icon-uncheck-circle-1" />{" "}
                                {user?.status || user?.status === 1
                                  ? "Verified"
                                  : "Not Verified"}
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="row bg-white px-8 pb-10">
            <h2 className="my-30">Listings</h2>

            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              navigation
              modules={[Navigation]}
              breakpoints={{
                // Adjust the number of slides per view for different screen sizes
                640: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
              }}
            >
              {user?.listings?.map((e, index) => (
                <SwiperSlide key={index}>
                  <ListingOutOverlay e={e} />
                </SwiperSlide>
              ))}
            </Swiper>

          </div>
        </div>{" "}
      </section>
    </Layout>
  );
};

export default PicProfile;
