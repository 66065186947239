import React, { useState, useEffect, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { createEvents } from 'ics';
import ICAL from 'ical.js';
import { saveAs } from 'file-saver';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Importing caret icons
import './Custom-Calendar.css';
import { useFormContext } from "../../../../providers";
import { useLocation } from "react-router-dom";

const localizer = momentLocalizer(moment);

const CustomToolbar = ({ label, onNavigate }) => {
    return (
        <div className="rbc-toolbar flex justify-between items-center">
            <button
                className="rbc-btn p-2"
                onClick={() => onNavigate('PREV')}
                title="Previous Month"
            >
                <FaChevronLeft />
            </button>

            <span className="rbc-toolbar-label text-2xl text-bold">{label}</span>

            <button
                className="rbc-btn p-2"
                onClick={() => onNavigate('NEXT')}
                title="Next Month"
            >
                <FaChevronRight />
            </button>
        </div>
    );
};

export const CustomDateSelectionCalendar = () => {
    const location = useLocation();
    const { listId = "" } = location.state || {};
    const { setValue, errors, fieldRefs, watch } = useFormContext();
    const [events, setEvents] = useState([]);
    const [disabledDates, setDisabledDates] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const blocked_dates = watch('blocked_dates')

    useEffect(() => {
        const pastDates = [];
        let date = moment().startOf('day');

        while (date.isBefore(new Date())) {
            pastDates.push(date.toDate());
            date.add(1, 'day');
        }

        const blockedDateRanges = [];

        if (listId && blocked_dates) {
            // Sort and merge overlapping or contiguous ranges
            const sortedDates = blocked_dates.map(({ start_date, end_date }) => ({
                start_date: moment(start_date).startOf('day'),
                end_date: moment(end_date).startOf('day'),
            })).sort((a, b) => a.start_date - b.start_date);

            const mergedRanges = [];
            let currentRange = sortedDates[0];

            for (let i = 1; i < sortedDates.length; i++) {
                const nextRange = sortedDates[i];

                if (currentRange.end_date.isSameOrAfter(nextRange.start_date)) {
                    currentRange.end_date = moment.max(currentRange.end_date, nextRange.end_date);
                } else {
                    mergedRanges.push(currentRange);
                    currentRange = nextRange;
                }
            }
            mergedRanges.push(currentRange);

            // Generate disabled dates from merged ranges
            mergedRanges.forEach(({ start_date, end_date }) => {
                let currentDate = start_date.clone();
                while (currentDate.isSameOrBefore(end_date)) {
                    blockedDateRanges.push(currentDate.toDate());
                    currentDate.add(1, 'day');
                }
            });
        }

        setDisabledDates([...pastDates, ...blockedDateRanges]);
    }, [listId]);

    // Utility function to get all the dates between two dates
    const getDatesInRange = useCallback((start, end) => {
        const dates = [];
        let currentDate = moment(start).startOf('day'); // Start from the next day
        while (currentDate.isBefore(end)) {
            dates.push(currentDate.toDate());
            currentDate.add(1, 'day');
        }
        dates.push(moment(end).startOf('day').toDate()); // Include the end date
        return dates;
    }, []);

    // Refactored handleExportICS to use useCallback
    const handleExportICS = useCallback(() => {
        const icsEvents = events.map(event => ({
            start: [event.start.getFullYear(), event.start.getMonth() + 1, event.start.getDate()],
            end: [event.end.getFullYear(), event.end.getMonth() + 1, event.end.getDate()],
            title: event.title,
            status: 'CONFIRMED',
        }));

        createEvents(icsEvents, (error, value) => {
            if (error) {
                console.error('ICS Generation Error:', error);
                return;
            }
            const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
            saveAs(blob, 'calendar-events.ics');
        });
    }, [events]);

    // Refactored handleImportICS to use useCallback
    const handleImportICS = useCallback((event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const parsed = ICAL.parse(e.target.result);
            const component = new ICAL.Component(parsed);
            const vevents = component.getAllSubcomponents('vevent');

            const importedEvents = vevents.map(vevent => {
                const event = new ICAL.Event(vevent);
                return {
                    start: event.startDate.toJSDate(),
                    end: event.endDate.toJSDate(),
                    title: event.summary,
                };
            });

            setEvents(importedEvents);
            const importedDates = importedEvents.map(e => e.start);
            setDisabledDates(importedDates);
        };
        reader.readAsText(file);
    }, []);

    // Refactored customDayPropGetter to use useCallback
    const customDayPropGetter = useCallback((date) => {
        const isPastDate = moment(date).isBefore(moment().startOf('day'), 'day');
        const isBlocked = disabledDates.some((disabledDate) =>
            moment(disabledDate).isSame(date, "day")
        );

        return {
            className: isBlocked ? "blocked-day" : "",
            style: {
                backgroundColor: isPastDate ? "#f0f0f0" : isBlocked ? "#f8d7da" : "#cce5ff",
                pointerEvents: isPastDate ? "none" : "auto",
                cursor: isPastDate ? "not-allowed" : "pointer",
                position: "relative"
            }
        };
    }, [disabledDates]);

    // Refactored divideIntoRangesAndSingularDates to use useCallback
    const divideIntoRangesAndSingularDates = useCallback((dates) => {
        let ranges = [];
        let currentStartDate = moment(dates[0]);
        let currentEndDate = moment(dates[0]);

        for (let i = 1; i < dates.length; i++) {
            let currentDate = moment(dates[i]);

            if (currentDate.diff(currentEndDate, 'days') === 1) {
                currentEndDate = currentDate;
            } else {
                ranges.push({
                    start_date: currentStartDate.format("YYYY-MM-DD"),
                    end_date: currentEndDate.format("YYYY-MM-DD"),
                    type: 'blocked'
                });
                currentStartDate = currentDate;
                currentEndDate = currentDate;
            }
        }

        ranges.push({
            start_date: currentStartDate.format("YYYY-MM-DD"),
            end_date: currentEndDate.format("YYYY-MM-DD"),
            type: 'blocked'
        });

        return { ranges };
    }, []);

    // Refactored handleDateSelection to use useCallback
    const handleDateSelection = useCallback(() => {
        const sortedDates = disabledDates.sort((a, b) => new Date(a) - new Date(b));
        const { ranges } = divideIntoRangesAndSingularDates(sortedDates);

        setValue('blocked_dates', ranges);
    }, [disabledDates, divideIntoRangesAndSingularDates, setValue]);

    const handleSelectSlot = useCallback(({ start, end }) => {
        if (moment(start).isBefore(moment().startOf('day'), 'day')) {
            return;
        }

        const isRangeSelected = end && !moment(start).isSame(end, "day");
        let selectedDates = [];

        // If it's a range, generate all dates in the range
        if (isRangeSelected) {
            selectedDates = getDatesInRange(start, end); // Assuming this returns an array of dates
        } else {
            selectedDates = [start];
        }

        // Check if all selected dates are already disabled
        const allSelected = selectedDates.every(date =>
            disabledDates.some(disabledDate => moment(disabledDate).isSame(date, "day"))
        );

        if (allSelected) {
            // Remove selected dates from disabledDates and events
            const newDisabledDates = disabledDates.filter(date =>
                !selectedDates.some(selectedDate => moment(date).isSame(selectedDate, "day"))
            );
            const newEvents = events.filter(event =>
                !selectedDates.some(selectedDate => moment(event.start).isSame(selectedDate, "day"))
            );
            setDisabledDates(newDisabledDates);
            setEvents(newEvents);
        } else {
            // Add new events for the selected dates
            const newEvents = selectedDates.map(date => ({
                start: date,
                end: date,
                title: "Blocked Date",
            }));
            setEvents(prevEvents => [...prevEvents, ...newEvents]);

            // Add selected dates to disabledDates
            setDisabledDates(prevDisabledDates => [...prevDisabledDates, ...selectedDates]);
        }

        handleDateSelection();
    }, [getDatesInRange, handleDateSelection, disabledDates, events]);

    // Refactored toggleDropdown to use useCallback
    const toggleDropdown = useCallback((e) => {
        e.preventDefault();
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen]);


    const isRangeBoundary = (date) => {
        // Convert the date to moment for consistency in comparison
        const testDate = moment(date).startOf('day');  // Ensure it's at the start of the day
        const sortedDates = disabledDates.sort((a, b) => new Date(a) - new Date(b));
        const { ranges } = divideIntoRangesAndSingularDates(sortedDates);
        // Check if the date is the start of any range
        const isStart = ranges.some((blocked) =>
            testDate.isSame(moment(blocked.start_date).startOf('day'), "day")
        );

        // Check if the date is the end of any range
        const isEnd = ranges.some((blocked) =>
            testDate.isSame(moment(blocked.end_date).startOf('day'), "day")
        );

        // Check if the date is inside any blocked range (not including the start or end)
        const isInRange = ranges.some((blocked) =>
            testDate.isBetween(moment(blocked.start_date).startOf('day'), moment(blocked.end_date).startOf('day'), "day", "[]")
        );

        // Check if the date is inside a blocked range but not the start or end
        const isInRangeNotBoundary = ranges.some((blocked) =>
            testDate.isBetween(moment(blocked.start_date).startOf('day'), moment(blocked.end_date).startOf('day'), "day", "[]") &&
            !testDate.isSame(moment(blocked.start_date).startOf('day'), "day") &&
            !testDate.isSame(moment(blocked.end_date).startOf('day'), "day")
        );

        return { isStart, isEnd, isInRange, isInRangeNotBoundary };
    };



    return (
        <div className="bg-white">
            <div className="mt-4 relative text-left w-full flex justify-end my-3 p-5">
                <button
                    onClick={toggleDropdown}
                    className="inline-flex justify-center rounded-md border border-gray-300 shadow-lg px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                >
                    Sync iCal
                </button>
                {isDropdownOpen && (
                    <div className="origin-top-right absolute right-[100px] top-[45px] mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[99]">
                        <div className="py-1">
                            <button
                                onClick={handleExportICS}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                            >
                                Export to ICS
                            </button>
                            <label
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left cursor-pointer"
                            >
                                Import from ICS
                                <input
                                    type="file"
                                    accept=".ics"
                                    onChange={handleImportICS}
                                    className="hidden"
                                />
                            </label>
                        </div>
                    </div>
                )}
            </div>

            <Calendar
                localizer={localizer}
                // events={events}
                ref={(el) => (fieldRefs.current[`blocked_dates`] = el)}
                selectable
                onSelectSlot={handleSelectSlot}
                dayPropGetter={customDayPropGetter}
                style={{ height: 500, margin: '25px' }}
                defaultView="month"
                components={{
                    toolbar: CustomToolbar,
                    dateCellWrapper: ({ value }) => {
                        const isBlocked = disabledDates.some((disabledDate) =>
                            moment(disabledDate).isSame(value, "day")
                        );

                        const isToday = moment(value).isSame(moment(), "day");
                        const isAvailable = moment(value).isSameOrAfter(moment().startOf('day'), "day");
                        const isInPast = moment(value).isBefore(moment().startOf('day'), "day");

                        const { isStart, isEnd } = isRangeBoundary(value);

                        if ((isStart || isEnd) && !isToday)
                            // console.log(isInRangeNotBoundary, value);



                            return (
                                <div style={{ position: "relative", height: "100%", width: "100%" }}>
                                    {/* Skewed background for available dates */}
                                    {isAvailable && !isBlocked && !isToday && !isInPast && (
                                        <>
                                            <div
                                                className="available-bg"
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "#8ec639",
                                                    transformOrigin: "top left",
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: "4px",
                                                    left: "4px",
                                                    fontSize: "0.75rem",
                                                    fontWeight: "bold",
                                                    padding: "2px 6px",
                                                    borderRadius: "4px",
                                                    zIndex: 2,
                                                }}
                                            >
                                                Available
                                            </div>
                                        </>
                                    )}
                                    {/* Skewed background and label for blocked dates */}
                                    {isBlocked && !isToday && !isStart && !isEnd && !isInPast && (
                                        <>
                                            <div
                                                className={`blocked-bg`} // Add classes based on isStart or isEnd
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    // backgroundColor: "red", // Red for blocked
                                                    opacity: 0.3, // Fully opaque for start/end, 30% for in-between
                                                    // transformOrigin: "top left",
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: "4px",
                                                    left: "4px",
                                                    fontSize: "0.75rem",
                                                    fontWeight: "bold",
                                                    padding: "2px 6px",
                                                    borderRadius: "4px",
                                                    zIndex: 2,
                                                }}
                                            >
                                                Blocked
                                            </div>
                                        </>
                                    )}

                                    {/* Skewed background and label for blocked dates */}
                                    {isStart && !isToday && !isInPast && (
                                        <>
                                            <div
                                                className={`start-date`} // Add classes based on isStart or isEnd
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "red", // Red for blocked
                                                    opacity: 0.3, // Fully opaque for start/end, 30% for in-between
                                                    // transformOrigin: "top left",
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                className={`start-date-prefill`} // Add classes based on isStart or isEnd
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    opacity: 0.3, // Fully opaque for start/end, 30% for in-between
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: "4px",
                                                    left: "4px",
                                                    fontSize: "0.75rem",
                                                    fontWeight: "bold",
                                                    padding: "2px 6px",
                                                    borderRadius: "4px",
                                                    zIndex: 2,
                                                }}
                                            >
                                                Blocked
                                            </div>
                                        </>
                                    )}

                                    {/* Skewed background and label for blocked dates */}
                                    {isEnd && !isToday && !isInPast && (
                                        <>
                                            <div
                                                className={`end-date`} // Add classes based on isStart or isEnd
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "red", // Red for blocked
                                                    opacity: 0.3, // Fully opaque for start/end, 30% for in-between
                                                    // transformOrigin: "top left",
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                className={`end-date-prefill`} // Add classes based on isStart or isEnd
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    opacity: 0.3, // Fully opaque for start/end, 30% for in-between
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: "4px",
                                                    left: "4px",
                                                    fontSize: "0.75rem",
                                                    fontWeight: "bold",
                                                    padding: "2px 6px",
                                                    backgroundColor: "transparent",
                                                    borderRadius: "4px",
                                                    zIndex: 2,
                                                }}
                                            >
                                                Blocked
                                            </div>
                                        </>
                                    )}

                                    {/* Label for today's date */}
                                    {/* {isToday && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "4px",
                                            left: "4px",
                                            fontSize: "0.75rem",
                                            fontWeight: "bold",
                                            padding: "2px 6px",
                                            borderRadius: "4px",
                                            zIndex: 2,
                                        }}
                                    >
                                        Today
                                    </div>
                                )} */}
                                </div>
                            );
                    }
                }}

            />


            {errors?.blocked_dates?.map((err) => {
                return (<p style={{ color: "red" }}>{err?.message}</p>)
            })}
        </div>
    );
};
