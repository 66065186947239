import React from "react";
import { FaShower } from "react-icons/fa";
import { MdGroup, MdOutlineBedroomParent } from "react-icons/md";
import { Link } from "react-router-dom";
import { useListing } from "../../providers";
import { SERVER_IMAGES_URL } from "../../constants";

const ListingOutOverlay = ({ e }) => {
  const { toggleCompare, compareList, handleFavorite, favoriteListings } = useListing();
  return (
    // <Link to={`/details/${e.id}`} className="hover:underline text-white text-left">
    <div
      className="bg-white rounded-lg shadow-lg overflow-hidden w-full group"
      style={{ height: "280px" }}
    >
      {/* Image Section */}
      <div className="relative h-full w-full">
        <img
          className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-110"
          // Uncomment for dynamic image source
          src={`${SERVER_IMAGES_URL}/listing/${e?.images[0]?.image}` || e?.listing_images[0]?.image || ""}
          // src="images/all/homedwelling11.webp"
          alt={e?.title || "Listing Image"}
        />

        {/* Top-Left Icons with Host Image */}
        <div className="absolute top-3 px-3 flex items-start space-x-2 w-full justify-between">


          <div className="flex space-x-2">
            {/* Heart Icon */}
            <div
              onClick={() => handleFavorite(e.id, favoriteListings.some(listing => listing?.listing_id === e?.id))}
              className={`p-2 bg-white rounded-full shadow-md cursor-pointer transition duration-200 ${favoriteListings.some(listing => listing?.listing_id === e?.id)
                ? "text-red-500"
                : "text-gray-500"
                } hover:text-red-500`}
            >
              <i className="fas fa-heart w-6 h-6 flex items-center justify-center"></i>
            </div>

            {/* Compare Icon */}
            <div className="p-2 bg-white rounded-full shadow-md cursor-pointer transition duration-200" onClick={() => toggleCompare(e)}>
              <div className="w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                  className={`w-full h-full fill-current ${compareList.some(item => item.id === e.id) ? "text-blue-500" : "text-gray-500"
                    } hover:text-[#8EC639]`}
                >
                  <g transform="translate(0,500) scale(0.1,-0.1)" stroke="none">

                    <path d="M1705 4382 c-37 -23 -64 -48 -466 -443 -180 -176 -331 -331 -338
-345 -30 -66 4 -147 72 -170 31 -11 44 -11 77 1 l40 14 0 -448 0 -448 24 -22
c23 -22 26 -22 305 -19 309 3 301 1 301 65 0 31 -1 32 -47 35 l-48 3 -3 322
-2 322 146 3 c129 3 147 1 155 -14 5 -9 9 -66 9 -127 0 -120 0 -120 57 -99
l33 12 0 129 c0 123 -7 161 -34 179 -6 4 -104 8 -217 8 -202 0 -206 0 -227
-23 -21 -23 -22 -30 -24 -366 l-3 -343 -160 0 -160 0 -3 464 -2 465 285 282
c157 154 292 281 299 281 15 0 54 -38 387 -366 92 -90 171 -164 176 -164 5 0
16 18 23 41 l14 41 -180 175 c-301 294 -390 373 -420 373 -31 0 -103 -67 -486
-452 -179 -180 -243 -238 -261 -238 -30 0 -51 23 -43 48 6 19 392 405 635 635
94 88 131 117 151 117 36 0 124 -78 475 -422 98 -95 182 -169 197 -173 26 -7
68 8 68 24 0 5 -13 25 -30 46 l-30 37 6 182 c5 131 10 185 20 197 18 21 17 69
-2 94 -13 18 -29 20 -160 23 -176 4 -194 -3 -194 -73 0 -24 5 -47 10 -50 12
-7 14 -65 2 -65 -4 0 -68 56 -143 124 -75 68 -145 129 -157 135 -31 17 -93 13
-127 -7z m655 -307 c0 -85 -4 -155 -8 -155 -5 0 -35 27 -66 60 l-57 60 3 89 3
90 30 4 c16 2 45 5 63 5 l32 2 0 -155z"/>
                    <path d="M3223 4387 c-25 -14 -85 -70 -288 -267 -82 -80 -211 -202 -285 -270
-74 -69 -146 -138 -160 -154 -14 -16 -36 -41 -50 -55 -42 -43 -60 -74 -60
-104 0 -15 -8 -42 -17 -60 -12 -23 -18 -67 -21 -164 l-5 -133 51 0 c36 0 52 4
56 16 3 9 6 63 6 121 l0 105 39 -7 c27 -4 49 -2 70 10 37 19 34 29 35 -132 1
-64 6 -116 12 -122 5 -6 28 -12 50 -14 l39 -2 3 191 2 191 285 281 c191 189
291 282 305 282 15 0 114 -93 306 -284 l284 -284 0 -461 0 -461 -170 0 -170 0
0 348 c0 340 -1 349 -21 368 -18 16 -41 19 -217 21 -182 3 -199 2 -229 -17
-31 -19 -33 -23 -33 -79 0 -33 3 -62 8 -64 4 -3 25 -7 47 -9 40 -3 40 -3 45
35 l5 39 148 1 147 2 0 -322 0 -323 -137 0 c-76 0 -148 -3 -160 -6 -27 -7 -30
-32 -8 -73 l14 -29 391 0 c359 1 393 2 413 18 22 18 22 19 24 460 1 244 4 444
6 447 3 2 16 -2 31 -8 33 -15 92 1 129 35 29 27 47 81 40 118 -3 14 -51 74
-109 134 l-104 108 0 168 c0 127 4 177 15 205 8 19 15 48 15 64 0 50 -17 56
-167 62 -121 4 -141 3 -163 -12 -31 -22 -40 -84 -16 -110 11 -12 13 -25 8 -44
-7 -27 -9 -26 -135 90 -169 156 -180 163 -236 163 -25 -1 -56 -6 -68 -13z
m154 -135 c173 -149 713 -683 713 -705 0 -19 -21 -37 -45 -37 -13 0 -139 118
-368 345 -327 324 -350 345 -384 345 -39 0 -42 -3 -539 -492 -158 -156 -206
-198 -227 -198 -16 0 -30 8 -37 20 -9 18 -2 30 57 93 93 99 559 550 662 640
31 28 63 47 77 47 14 0 50 -23 91 -58z m503 -194 l0 -162 -66 64 -66 65 5 86
c2 48 7 92 11 98 4 6 32 11 62 11 l54 0 0 -162z"/>
                    <path d="M1740 3891 c-5 -11 -10 -45 -10 -75 l0 -56 -70 0 c-60 0 -71 -3 -81
-21 -15 -28 2 -66 34 -78 28 -11 151 -13 259 -5 91 7 118 26 102 72 -9 26 -13
27 -82 30 l-72 3 0 59 c0 40 -5 64 -16 74 -21 22 -51 20 -64 -3z"/>
                    <path d="M3262 3898 c-7 -7 -12 -37 -12 -69 0 -66 -6 -70 -83 -68 -51 2 -77
-13 -77 -45 0 -42 16 -50 102 -58 99 -8 268 1 286 15 15 12 16 59 0 75 -7 7
-39 12 -75 12 l-63 0 0 48 c0 78 -42 126 -78 90z"/>
                    <path d="M2963 3053 c-32 -18 -150 -133 -462 -447 -85 -86 -158 -156 -161
-156 -3 0 -28 23 -55 51 -66 67 -96 82 -147 74 -52 -9 -115 -65 -124 -110 -10
-54 21 -101 165 -247 113 -116 139 -138 164 -138 32 0 43 10 247 225 52 55
147 154 210 220 184 192 354 375 363 391 11 20 -30 102 -64 127 -38 27 -97 32
-136 10z m80 -97 c9 -7 17 -19 17 -27 0 -8 -55 -70 -121 -139 -66 -69 -196
-204 -287 -300 -249 -262 -288 -300 -304 -300 -18 0 -238 234 -238 253 0 8 9
22 19 31 24 22 33 17 114 -60 34 -34 73 -67 86 -74 26 -13 12 -26 421 394 170
176 236 233 270 235 3 0 13 -6 23 -13z"/>
                    <path d="M2353 3040 c-157 -17 -272 -78 -391 -204 -145 -156 -200 -350 -157
-554 20 -95 40 -145 92 -225 94 -142 227 -239 383 -278 77 -19 252 -17 317 3
103 33 207 101 290 192 110 120 163 267 163 454 0 102 -14 182 -32 182 -5 0
-23 -16 -40 -36 -30 -34 -31 -37 -33 -147 -2 -90 -8 -128 -28 -192 -50 -152
-143 -260 -282 -326 -124 -59 -302 -52 -441 16 -85 42 -197 153 -237 236 -59
120 -73 324 -31 449 44 135 192 266 358 318 79 25 224 21 314 -8 l73 -24 36
36 37 37 -48 20 c-112 49 -220 65 -343 51z"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>


          <div className="flex">
            {/* Host Image */}
            {e?.host?.image && (
              <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-white shadow-md">
                <img
                  src={e?.host?.image !== null ? `${SERVER_IMAGES_URL}/profile/${e?.host?.image}` : "images/avatar/avatar-bg.png"}

                  alt="Host"
                  className="w-full h-full object-cover"
                />
              </div>
            )}
          </div>

        </div>

        <Link to={`/details/${e.id}`} className="hover:underline text-white text-left">
          {/* Overlay Text Section */}
          <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-gray-900 to-transparent text-white px-4 py-3">

            {/* Price */}
            <h3 className="text-xl font-bold text-white text-left !m-0">
              ${e?.nightly_price}
              <span className="text-sm font-normal text-white text-left"> / Night</span>
            </h3>

            {/* Title */}
            <h4 className="text-lg font-bold text-white text-left !m-0">
              {/* <Link to={`/details/${e.id}`} className="hover:underline text-white text-left"> */}
              {e?.title || "(KJAS) Jasper County Airport Bell Field"}
              {/* </Link> */}
            </h4>

            {/* Location */}
            <p className="text-sm text-gray-200 text-left !mb-[0]">
              {e?.city || "City"}, {e?.state || "State"}, {e?.country || "Country"}
            </p>

            {/* Amenities */}
            <div className="flex items-center space-x-4 text-sm mt-2">
              <div className="flex items-center space-x-1">
                <MdOutlineBedroomParent />
                <span className="text-white">{e?.no_of_bedrooms || 0} Bedrooms</span>
              </div>
              <div className="flex items-center space-x-1">
                <FaShower />
                <span className="text-white">{e?.no_of_bathrooms || 0} Baths</span>
              </div>
              <div className="flex items-center space-x-1">
                <MdGroup />
                <span className="text-white">{e?.no_of_guest || 0} Guests</span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
    // </Link>
  );
};

export default ListingOutOverlay;
