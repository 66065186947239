/* eslint-disable */
import React, { useEffect } from "react";
import Layout from "../Components/layout/Layout";
import { Link } from "react-router-dom";
import { useFeatures, useListing } from "../providers";
import { mapDataToFlatFormat } from "../helpers";
import { IoIosCheckmarkCircleOutline, IoMdClose } from "react-icons/io";
import { SERVER_IMAGES_URL } from "../constants";

// Function to check if a sub-feature exists in a listing
const hasSubFeature = (listingFeatures, featureId, subFeature) => {
    if (!listingFeatures || !Array.isArray(listingFeatures)) {
        return false; // Return false if listingFeatures is not valid
    }

    for (const feature of listingFeatures) {
        const subFeatureExists = feature.feature_details.some(
            (detail) => detail.sub_heading === subFeature
        );
        if (subFeatureExists) return true;
    }

    return false;
};

const Compare = () => {
    const { compareList, handleCompare } = useListing();
    const { subFeatures } = useFeatures();

    if (!compareList || compareList.length === 0) {
        return (
            <Layout>
                <div className="container mx-auto py-10">
                    <p className="text-center text-gray-500">No listings to compare</p>
                </div>
            </Layout>
        );
    }

    const skippedKeys = ["title", "nightly_price", "size", "bedrooms", "bathrooms", "beds"];
    const formattedListings = compareList.map(mapDataToFlatFormat);
    const keys = Object.keys(formattedListings[0] || {}).filter((key) =>
        skippedKeys.includes(key)
    );

    useEffect(() => {
        handleCompare();
    }, []);

    return (
        <Layout>
            <div className="container mx-auto py-10 px-4">
                <h1 className="text-2xl font-bold mb-6 text-gray-800 text-left">Compare</h1>

                {/* Details Table */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden">
                    {/* Images Row */}
                    <div
                        key={"images-keys"}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 border-b last:border-none py-4 px-4 md:px-6 hover:bg-red-500 group transition-all duration-200"
                    >
                        <div className="font-bold text-gray-600 capitalize text-left group-hover:text-white">
                            Images
                        </div>
                        {compareList.map((listing) => {
                            return (
                                <div key={listing.id} className="border rounded-lg overflow-hidden">
                                    <Link to={`/details/${listing.id}`}>
                                        <img
                                            src={

                                                `${SERVER_IMAGES_URL}/listing/${listing?.listing_images?.[0]?.image}` ||
                                                `${SERVER_IMAGES_URL}/listing/${listing?.images?.[0]?.image}` ||
                                                "https://via.placeholder.com/150"
                                            }
                                            alt={listing.title || "Listing Image"}
                                            className="w-full h-48 object-cover"
                                        />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>

                    {/* General Details */}
                    {keys.map((key, rowIndex) => (
                        <div
                            key={rowIndex}
                            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 border-b last:border-none py-4 px-4 md:px-6 hover:bg-red-500 group transition-all duration-200"
                        >
                            <div className="font-bold text-gray-600 capitalize text-left group-hover:text-white">
                                {key.replace(/_/g, " ")}
                            </div>
                            {formattedListings.map((listing, colIndex) => (
                                <div
                                    key={colIndex}
                                    className="text-gray-800 text-left sm:text-center lg:text-left group-hover:text-white"
                                >
                                    {listing[key] ? `${listing[key]}` : "---"}
                                </div>
                            ))}
                        </div>
                    ))}

                    {/* Features */}
                    {Object.values(subFeatures).map((feature) => {
                        return (
                            <div key={feature.id} className="border-b last:border-none">
                                {feature.map((subFeature) => {
                                    return (
                                        <div
                                            key={subFeature.id}
                                            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 border-b last:border-none py-4 px-4 md:px-6 hover:bg-red-500 group transition-all duration-200"
                                        >
                                            <div className="font-bold text-gray-600 capitalize text-left group-hover:text-white">
                                                {subFeature.sub_heading}
                                            </div>
                                            {compareList.map((listing) => {
                                                return (
                                                    <div
                                                        key={listing.id}
                                                        className="text-center group-hover:text-white"
                                                    >
                                                        {hasSubFeature(listing.listing_features, subFeature.id, subFeature.sub_heading) ? (
                                                            <IoIosCheckmarkCircleOutline className="text-green-500 group-hover:text-white" />
                                                        ) : (
                                                            <IoMdClose className="text-red-500 group-hover:text-white" />
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </Layout>
    );
};

export default Compare;
