/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "./features.css";
import ListingOutOverlay from "../Components/ui/ListingOutOverlay";
import { useListing } from "../providers";

const Feature = () => {
  const { getFeaturedListings } = useListing();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [listings, setListings] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMoreListings = useCallback(async () => {
    if (!hasMore) return;
    setLoading(true);
    try {
      const res = await getFeaturedListings({ is_featured: 1, page, per_page: 20, is_random: 1 });
      if (res?.listings?.length > 0) {
        setListings((prevListings) => {
          const newListings = [...prevListings, ...res.listings];
          const uniqueListings = Array.from(new Map(newListings.map(item => [item.id, item])).values());
          return uniqueListings;
        });
        setPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more listings:", error);
    } finally {
      setLoading(false);
    }
  }, [page, hasMore]);

  useEffect(() => {
    loadMoreListings();
  }, []);

  return (
    <>
      <Link style={{ color: "#3B4249" }} to="/faq" className="flex justify-center items-center w-full">
        <div className="mb-4">
          <button className="color2-bg my-3 text-white bg-[#8EC639] rounded-md font-medium w-28 h-10">
            FAQ
          </button>
        </div>
      </Link>

      <div className="content !py-10 px-10">
        <h2 className="text-2xl font-bold">Featured Homes</h2>
        <p className="text-xl font-medium m-[10px] ">
          Great selection of quality spaces
        </p>

        <div className="container mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-3">
            {listings.length > 0 ? (
              listings.map((e, index) => (
                <ListingOutOverlay
                  key={e.id || index}
                  e={e}
                />
              ))
            ) : (
              <div className="text-center col-span-full">No items to display.</div>
            )}
          </div>
        </div>

        {loading && <div className="text-center my-3">Loading more listings...</div>}

        {hasMore && !loading && (
          <div className="text-center mt-6">
            <button className="color2-bg my-3 text-white bg-[#8EC639] rounded-md font-medium w-28 h-10"
              onClick={loadMoreListings}
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Feature;
