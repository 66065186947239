/* eslint-disable */
import React from "react";
import Layout from "../Components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";

const State = () => {
  const navigate = useNavigate();

  const GetData = (name, key) => {
    const formData = new FormData();
    formData.append(key, name);

    axios
      .post(`${BASE_URL}/listing-search`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res?.data);
        navigate(`/map_listing/${name}/${key}`);
      })
      .catch((err) => {
        SetTheme(err);
      });
  };


  return (
    <Layout>
      <div className="bg-white">
        <section style={{ backgroundColor: "#f6f8fa" }} id="sec1" data-scrollax-parent="true">
          <div className="container">
            <div style={{ paddingTop: "50px", color: "#3B4249" }} className="section-title">

              <h2 >By State / Airport</h2>
              <p style={{ fontSize: "15px", textAlign: "center", color: "#3B4249" }}>
                Browse listings that are close to your chosen airport.

              </p>

            </div>
            {/*about-wrap */}
            <div className="about-wrap">
              <div
                style={{ display: "flex", flexWrap: "wrap" }}
                className="row"
              >
                <div className="col-md-4">
                  <div style={{ paddingLeft: "0px" }} className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      <Link to={`/map_listing/United_States/byAddress`}>
                        <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline" }}>United States</h3>
                      </Link>

                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Alaska/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Alaska</h3>
                          </Link>

                        </div>

                        <Link to={`/map_listing/Chistochina_AK/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Chistochina Airport (CZO)


                          </p>
                        </Link>
                        <Link to={`/map_listing/Wasilla_AK/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Fly8ma Pilot Lodge Airport (57AK)


                          </p>
                        </Link>
                        <Link to={`/map_listing/Kasilof_AK/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Kasilof Airport (5KS)

                          </p>

                        </Link>
                        <Link to={`/map_listing/Palmer_AK/byAddress`}>
                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Palmer Municipal Airport (PAQ)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Port_Alsworth_AK/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Port Alsworth Airport (TPO)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Wasilla_AK/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Wasilla Airport (???)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Arizona/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Arizona</h3>
                          </Link>


                        </div>

                        <Link to={`/map_listing/chandler_AZ/byAddress`}>
                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Chandler Municipal Airport (KCHD)

                          </p>
                        </Link>

                        <Link to={`/map_listing/Overgaard_AZ/byAddress`}>
                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Mogollon Airpark (AZ82)

                          </p>
                        </Link>

                        <Link to={`/map_listing/Scottsdale_AZ/byAddress`}>
                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Scottsdale Municipal Airport (SDL)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/California/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>California</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Borrego_Springs_CA/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Borrego Valley Airport (L08)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Julian_CA/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Private Runway in Julian

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Colorado/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Colorado</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Elbert_CO/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Kelly Airpark (CO15)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Florida/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Florida</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Polk_City_FL/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Flanders Field (4FD7)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Lakeland_FL/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Lakeland Linder International Airport (KLAL)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Marathon_FL/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Marathon Airport (KMTH)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Naples_FL/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Naples Municipal Airport (APF)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Panama_City_Beach_FL/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            NW Florida Beaches (KECP)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Illinois/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Illinois</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Kampsville_IL/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Herrens Bess Hollow Airfield (LL77)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Kentucky/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Kentucky</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Jackson_KY/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Julian Carroll Airport (JKL)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Michigan/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Michigan</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Tecumseh_MI/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Merillat Airport (34G)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Minnesota/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Minnesota</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Faribault_MN/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Faribault Municipal Airport (KFBL)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Missouri/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Missouri</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Miller_MO/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Kingsley Airfield (MO9)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Texas/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Texas</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Comanche_TX/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Comanche County-City Airport (KMKN)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Granbury_TX/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Granbury Regional Airport (KGDJ)

                          </p>
                        </Link>
                        <Link to={`/map_listing/Fort_Worth_TX/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>Hicks Airfield (T67)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Utah/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Utah</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Fruitland_UT/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Private Runway in Fruitland

                          </p>
                        </Link>
                        <Link to={`/map_listing/Heber_City_UT/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Heber Valley Airport (KHCR)

                          </p>
                        </Link>
                      </div>
                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/West_Virginia/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>West Virginia</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/Huntington_WV/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Robert Newlon Field (I41)

                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-4">
                  <div style={{ paddingLeft: "0px" }} className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      <Link to={`/map_listing/Canada/byAddress`}>
                        <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline" }}>Canada</h3>
                      </Link>

                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/British_Columbia/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>British Columbia</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/vancouver/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Vancouver International Airport (YVR, CYVR)

                          </p>
                        </Link>
                        <Link to={`/map_listing/victoria/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Victoria International Airport (YYJ, CYYJ)

                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-4">
                  <div style={{ paddingLeft: "0px" }} className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      <Link to={`/map_listing/Mexico/byAddress`}>
                        <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline" }}>Mexico</h3>
                      </Link>


                      <div style={{ paddingLeft: "0px" }} className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          <Link to={`/map_listing/Quintana_Roo/byAddress`}>
                            <h3 style={{ marginBottom: "15px", color: "#3B4249", textDecoration: "underline", fontSize: "20px" }}>Quintana Roo</h3>
                          </Link>


                        </div>
                        <Link to={`/map_listing/cancun/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Cancún International Airport (CUN, MMUN)

                          </p>
                        </Link>
                        <Link to={`/map_listing/cozumel/byAddress`}>

                          <p style={{ fontSize: "16px", textDecoration: "underline", color: "#3B4249" }}>
                            Cozumel International Airport (CZM, MMCZ)

                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">

                </div>
                <div className="col-md-3">

                </div>
                <div className="col-md-3">

                </div>

              </div>
            </div>
            {/* about-wrap end  */}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default State;
