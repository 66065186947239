import React from 'react'
import { useListing } from '../../providers';

export const LodgingDetails = () => {
    const { listingDetails } = useListing();

    const {
        id: listing_id,
        lodging_type,
        no_of_guest,
        no_of_bedrooms,
        no_of_beds,
        no_of_bathrooms,
        no_of_rooms,
        size,
        unit_of_measure,
        check_in_after,
        check_out_before,
    } = listingDetails;

    return (
        <div className="block-body !px-0 flex flex-col">
            <div className="block-left">
                <h3 className="text-lg font-bold text-start pb-8">Details</h3>
            </div>
            <div >
                <ul className="grid grid-cols-2 gap-4">
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-id-badge text-gray-500"></i>
                        <span>
                            ID#: <strong>{listing_id}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-users text-gray-500"></i>
                        <span>
                            Guests: <strong>{no_of_guest}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-bed text-gray-500"></i>
                        <span>
                            Bedrooms: <strong>{no_of_bedrooms}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-bed text-gray-500"></i>
                        <span>
                            Beds: <strong>{no_of_beds}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-bath text-gray-500"></i>
                        <span>
                            Bathrooms: <strong>{no_of_bathrooms}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-home text-gray-500"></i>
                        <span>
                            Rooms: <strong>{no_of_rooms}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-calendar-day text-gray-500"></i>
                        <span>
                            Check-in After: <strong>{check_in_after}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-calendar-day text-gray-500"></i>
                        <span>
                            Check-out Before: <strong>{check_out_before}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-key text-gray-500"></i>
                        <span>
                            Dwelling Type: <strong>{lodging_type}</strong>
                        </span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <i className="fas fa-ruler text-gray-500"></i>
                        <span>
                            Size: <strong>{size} {unit_of_measure}</strong>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}
