import React from "react";
import { Link } from "react-router-dom";
import '../styles/DwellingImg.css';
import { LODGING_TYPES_DROPDOWN } from "../constants";

const DwellingImg = () => {

  return (
    <div className="!bg-[#f6f8fa] content !py-10 px-10">
      <h2 className="text-2xl font-bold my-10">
        Browse by Lodging Type
      </h2>
      <div className="container mx-auto">
        <div className="grid md:grid-cols-4 gap-4 px-4">
          {LODGING_TYPES_DROPDOWN?.map((e) => (
            <Link to={`/map_listing/${e.type}/${e.name}`}>
              <div className="relative card overflow-hidden rounded-md max-h-[175px]">
                <img
                  src={`${e?.image}`}
                  alt=""
                  className="rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105"
                />
                <div className="absolute left-0 bottom-0 right-0 z-20 text-center bg-gradient-to-t from-black to-transparent" style={{ height: '30%' }}>
                  <div className="p-4">
                    <h3 className="text-white taxonomy-title">{e?.text || e?.name}</h3>
                  </div>
                </div>
              </div>
            </Link>

          ))}
        </div>
      </div>

    </div>
  );
};

export default DwellingImg;
