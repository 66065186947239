import React from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { useListing } from '../../providers';

export const DetailsHeader = ({ setModal, is_favourite, booking, reactToPrintFn }) => {
    const { listingDetails, handleFavorite } = useListing();

    const {
        id: listing_id,
        address,
        country,
        state,
        city,
        airports,
        title,
    } = listingDetails;


    return (
        <div
            className='!px-10 py-4'
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
                flexWrap: 'wrap'
            }}
        >
            <div>
                <h3
                    style={{
                        display: "flex",
                        fontSize: "28px",
                        fontWeight: "700",
                        fontFamily: "Quicksand",
                    }}
                >
                    ({airports[0]?.airport_identifier}) - {title} - {airports[0]?.airport_name}
                </h3>
                <div className="d-flex justify-normal items-center m-0">
                    <FaLocationDot />
                    {
                        booking === true ? (
                            <p className="!m-0">{address}, {city}, {state}, {country}</p>
                        ) : (
                            <p className="!m-0">{city}, {state}, {country}</p>
                        )
                    }
                </div>
            </div>

            <div
                className="geo-btn-group !static !right-0 !gap-4"
            >
                {/* Heart Icon */}
                <div
                    onClick={() => handleFavorite(listing_id, is_favourite)}
                    className={`flex p-2 bg-white shadow-md cursor-pointer transition duration-200 ${is_favourite
                        ? "text-red-500 rounded-md "
                        : "text-gray-500 rounded-full"
                        } hover:text-red-500`}
                >
                    <i className="fas fa-heart w-6 h-6 flex items-center justify-center" /> <span>{is_favourite ? 'Favorite' : ''} </span>
                </div>

                <button
                    className='py-2 px-4 flex justify-center items-center bg-[#8EC639] text-white rounded-md cursor-pointer'
                    onClick={() => reactToPrintFn()}
                >
                    <div className="_5kaapu flex justify-center items-center">
                        <span className="_14tkmhr">
                            <i className="fas fa-print text-white w-6 h-6 flex items-center justify-center" />
                        </span>
                        Print
                    </div>
                </button>

                <div>
                    <button
                        className='py-2 px-4 flex justify-center items-center bg-gray-300 rounded-md cursor-pointer'
                        type="button"
                        onClick={() => { setModal(open => !open) }}
                    >
                        <div className="_5kaapu flex justify-center items-center">
                            <span className="_14tkmhr">
                                <i className="fas fa-share-alt w-6 h-6 flex items-center justify-center" />
                            </span>
                            Share
                        </div>
                    </button>
                </div>

                {/* Save Here */}
            </div>
        </div>

    )
}