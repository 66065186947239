import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Layout from '../layout/Layout';
import { useStripe } from '@stripe/react-stripe-js';
import { axiosInstanceWithToken } from '../../helpers/axios';
import { successPopup } from '../../helpers';

const BookingStatusComponent = () => {
    const [searchParams] = useSearchParams();
    const stripe = useStripe();

    const redirect_status = searchParams.get('redirect_status');
    const paymentIntent = searchParams.get('payment_intent');
    const payment_intent_client_secret = searchParams.get('payment_intent_client_secret');

    const [statusMessage, setStatusMessage] = useState('Checking payment status...');

    useEffect(() => {
        if (redirect_status === 'succeeded' && payment_intent_client_secret && stripe) {
            const formData = new FormData();
            formData.append('payment_intent_id', paymentIntent);
            axiosInstanceWithToken.post('/payment/charge/confirm', formData).then((res) => {
                if (res?.data?.status) {
                    successPopup('', res?.data?.message);
                    setTimeout(() => {
                        window.history.back(); // Navigate to the previous page
                    }, 3000); // 3 seconds delay
                }
            })
        } else {
            // Handle other redirect_status values or missing data
            switch (redirect_status) {
                case 'failed':
                    setStatusMessage('Payment failed.');
                    break;
                case 'canceled':
                    setStatusMessage('Payment was canceled.');
                    break;
                default:
                    setStatusMessage('Checking payment status....');
                    break;
            }
        }
    }, [redirect_status, payment_intent_client_secret, stripe, paymentIntent]);

    return (
        <Layout>
            <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden my-44">
                <div className="px-6 py-4">
                    <h2 className="text-2xl font-bold mb-2">Booking Status</h2>
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <span className="font-semibold">Status:</span>
                            <span>{statusMessage}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default BookingStatusComponent;
