/* eslint-disable */
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import { Link, useNavigate } from "react-router-dom";
import { fetchData, ThemeContext } from "../context/ContextFile";
import Layout from "../Components/layout/Layout";
import BigMapcomponent from "../Components/BigMapcomponent";
import { LuList } from "react-icons/lu";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import "swiper/css"; // Import Swiper core CSS
import "swiper/css/navigation"; // Import Swiper navigation styles
import "swiper/css/pagination"; // Import Swiper pagination styles
import { Navigation } from "swiper/modules";
import '../styles/CustomTooltip.css';
import { SNAZZY_MAP_STYLES } from "../constants";




const BigMap = () => {
  const navigate = useNavigate();
  const [filterItem, setFilterItem] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  let { selectedLocation2, data } = useContext(ThemeContext);
  const [center, setCenter] = useState({
    lat: 39.5,
    lng: -100.0,
  });
  const [innerData, setInnerData] = useState(data);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    fetchData().then((res) => {
      setInnerData(res.data.data); // Initialize innerData as well
      setFilteredData(res.data.data);
      console.log(res.data.data, "mapValues");
    });
  }, []);

  const [zoom, setZoom] = useState(3);
  const [zoom1, setZoom1] = useState();

  const [visibleRegions, setVisibleRegions] = useState({
    alaska: false,
    canada: false,
    us: false,
    mexico: false,
  });

  useEffect(() => {
    let filteredMarkers = innerData;

    if (selectedLocation2.lat1 && selectedLocation2.lng1) {
      setCenter({ lat: parseFloat(selectedLocation2.lat1), lng: parseFloat(selectedLocation2.lng1) });
      setZoom(9); // Adjust zoom level as needed
    } else {
      filteredMarkers = innerData?.filter((item) => {
        if (!filterItem) return true;
        const distanceOrder = ["0 miles", "0-1 miles", "1.1-3 miles", "3.1-7 miles", "7 miles"];
        const filterIndex = distanceOrder.indexOf(filterItem);
        const itemIndex = distanceOrder.indexOf(item.distance_from_runway);
        return itemIndex <= filterIndex;
      });
    }

    setFilteredData(filteredMarkers);
    console.log(filteredMarkers, "Filtered Markers");
  }, [center, innerData, filterItem, selectedLocation2]);





  const isWithinBounds = (item, region) => {
    const bounds = {
      us: { latMin: 24.396308, latMax: 49.384358, lngMin: -125.0, lngMax: -66.93457 },
      canada: { latMin: 42.0, latMax: 83.0, lngMin: -141.0, lngMax: -52.0 },
      mexico: { latMin: 14.532866, latMax: 32.718655, lngMin: -118.455717, lngMax: -86.710571 },
      alaska: { latMin: 54.0, latMax: 71.5388001, lngMin: -179.148909, lngMax: -129.97957 },
    };

    return (
      item.lat >= bounds[region].latMin &&
      item.lat <= bounds[region].latMax &&
      item.lng >= bounds[region].lngMin &&
      item.lng <= bounds[region].lngMax
    );
  };

  const getLabelColor = (distance) => {
    if (distance === "0 miles") {
      return "8ec639";
    } else if (distance === "0-1 miles") {
      return "ffff00";
    } else if (distance === "1.1-3 miles") {
      return "f17489";
    } else if (distance === "3.1-7 miles") {
      return "ff9900";
    } else if (distance === "7 miles") {
      return "52c4d7";
    } else {
      console.log("Distance", distance)
      return "ffff00";
    }
  };

  const createSVGIcon = (color, price) => {
    const svgTemplate = `
      <svg width="100" height="50" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
        <!-- Rectangular background with rounded corners -->
        <rect x="10" y="5" width="60" height="30" rx="16" ry="16" fill="${color}" />
        <!-- Text for price -->
        <text x="40" y="25" text-anchor="middle" font-size="12" font-weight="bold" fill="black" font-family="Arial, sans-serif">
          $${Math.ceil(price)}
        </text>
      </svg>
    `;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgTemplate)}`;
  };



  const mapRef = useRef(null);
  const onLoad = useCallback((map) => {
    mapRef.current = map;

    const mapTypeControl = mapRef.current.getDiv().querySelector('.gm-style-mtc');
    if (mapTypeControl) {
      mapTypeControl.style.maxWidth = '100px'; // Adjust this value as needed
    }
    // Create custom zoom controls
    const zoomControlDiv = document.createElement('div');
    zoomControlDiv.style.display = 'flex';
    zoomControlDiv.style.marginTop = '70px';
    zoomControlDiv.style.marginLeft = '-179px';

    const buttonStyle = {
      fontSize: '18px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#24393b',
      color: 'white',
      border: 'none',
      marginRight: '5px',
      cursor: 'pointer'
    };

    const zoomInButton = document.createElement('button');
    zoomInButton.textContent = '+';
    Object.assign(zoomInButton.style, buttonStyle);

    const zoomOutButton = document.createElement('button');
    zoomOutButton.textContent = '-';
    Object.assign(zoomOutButton.style, buttonStyle);

    zoomControlDiv.appendChild(zoomInButton);
    zoomControlDiv.appendChild(zoomOutButton);

    // Append custom controls to the map
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(zoomControlDiv);

    // Add event listeners for zooming
    zoomInButton.addEventListener('click', () => {
      map.setZoom(map.getZoom() + 1);
    });

    zoomOutButton.addEventListener('click', () => {
      map.setZoom(map.getZoom() - 1);
    });
  }, []);

  console.log(filteredData)

  return (
    <Layout>


      <div style={{ height: "100%", position: "relative" }}>
        <div>
          <button
            onClick={() => navigate("/listing")}
            className="absolute z-[99] px-4 py-2 rounded-full bg-red-600 text-white text-sm bottom-[100px] cursor-pointer flex items-center gap-2 ml-[50%]"
          >
            Show List
            <LuList size={20} />
          </button>
        </div>

        <GoogleMap
          mapContainerStyle={{ height: "80vh", width: "100%" }}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
          options={{
            zoomControl: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            styles: SNAZZY_MAP_STYLES, // Apply the Airbnb-like style
          }}

          ref={mapRef}
          className="custom-map-type-control"
        >
          {filteredData?.map((marker, index) => (
            <MarkerF
              key={index}
              position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
              onClick={() => setSelectedMarker(marker)}
              icon={{
                url: createSVGIcon(
                  `#${getLabelColor(
                    marker.closest_runway_distance === ""
                      ? 0
                      : marker.closest_runway_distance
                  )}`,
                  marker.nightly_price
                ),
                scaledSize: new window.google.maps.Size(80, 50),
              }}
            />
          ))}


          {selectedMarker && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedMarker.latitude),
                lng: parseFloat(selectedMarker.longitude),
              }}
              options={{
                pixelOffset: new window.google.maps.Size(0, -30), // Adjust position relative to marker
              }}
              onCloseClick={() => setSelectedMarker(null)} // If you still want the default close behavior
            >
              <div className="w-72 rounded-xl overflow-hidden shadow-lg bg-white">
                {/* Image Section */}
                <div className="w-full h-44">
                  {/* Swiper Slider */}
                  <Swiper
                    modules={[Navigation]}
                    navigation
                    pagination={{ clickable: true }}
                    className="w-full h-44"
                  >
                    {selectedMarker.images?.map((image, index) => (
                      <SwiperSlide key={index}>
                        <img
                          // src={image.url}
                          src={"/image/inovate6.jpg"}
                          alt={`Image ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>

                </div>

                {/* Info Section */}
                <div className="p-4">
                  <h4 className="text-lg font-semibold text-gray-800">
                    {selectedMarker.city}, {selectedMarker.country}
                  </h4>
                  <p className="text-sm text-gray-500 mt-1 truncate">
                    Stay with {selectedMarker.title} · Actor / entrepreneur
                  </p>
                  <div className="flex items-center justify-between mt-3">
                    <div className="text-lg font-bold text-gray-800">
                      ${selectedMarker.nightly_price}
                      <span className="text-sm text-gray-500 font-medium">
                        {" "}
                        / night
                      </span>
                    </div>
                    <div className="text-sm text-gray-500">Jan 2 – 7</div>
                  </div>
                </div>

                {/* Bottom Close Button */}
                <button
                  className="w-8 h-8 mx-auto mb-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 flex items-center justify-center"
                  onClick={() => setSelectedMarker(null)}
                >
                  <i className="fas fa-times text-gray-600"></i>
                </button>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
        <BigMapcomponent filterItem={filterItem} setFilterItem={setFilterItem} />
      </div>
    </Layout>
  );
};

export default BigMap;
