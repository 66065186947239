import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { BtnLoader1 } from '../../BtnLoader/BtnLoader';
import { useAuthHandler } from '../../../hooks';
import { errorPopup, successPopup } from '../../../helpers';
import isCommonPassword from 'common-password-checker';

export const ForgetPassword = ({ toggleForgetPasswordForm }) => {
    const [emailSent, setEmailSent] = useState(false);
    const { forgetpassword } = useAuthHandler();
    const emailValidationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email is required"),
    });

    const otpValidationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email is required"),
        otp: Yup.string().required("OTP is required").length(4, "OTP must be 6 digits"),
        password: Yup
            .string()
            .required('New password is required')
            .min(8, 'New password must be at least 8 characters')
            .matches(/[a-z]/, 'New password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'New password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'New password must contain at least one number')
            .matches(/[@$!%*?&#]/, 'New password must contain at least one special character')
            .test(
                'not-common-password',
                'This password is too common. Please choose a more secure password.',
                (value) => !isCommonPassword(value)
            ),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required("Confirm password is required"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: emailSent ? yupResolver(otpValidationSchema) : yupResolver(emailValidationSchema),
    });

    const onSubmit = async (data) => {
        try {
            forgetpassword(data, emailSent, setEmailSent).then(response => {
                if (response?.data?.status) {
                    successPopup('', response?.data?.message);
                    toggleForgetPasswordForm();
                }
            })
        } catch (error) {
            errorPopup('', 'Error resetting your password!')
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className="flex flex-col">
                <label className="text-gray-700 font-medium mb-2">
                    Enter your Email Address <span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    disabled={emailSent}
                    {...register('email')}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.email && <div className="text-red-500 text-sm mt-1">{errors.email.message}</div>}
            </div>
            {emailSent && (
                <>
                    <div className="flex flex-col mb-4">
                        <label className="text-gray-700 font-medium mb-2">
                            Enter OTP <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            {...register('otp')}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        {errors.otp && <div className="text-red-500 text-sm mt-1">{errors.otp.message}</div>}
                    </div>
                    <div className="flex flex-col mb-4">
                        <label className="text-gray-700 font-medium mb-2">
                            New Password <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="password"
                            {...register('password')}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        {errors.password && <div className="text-red-500 text-sm mt-1">{errors.password.message}</div>}
                    </div>
                    <div className="flex flex-col">
                        <label className="text-gray-700 font-medium mb-2">
                            Confirm Password <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="password"
                            {...register('password_confirmation')}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        {errors.password_confirmation && <div className="text-red-500 text-sm mt-1">{errors.password_confirmation.message}</div>}
                    </div>
                </>
            )}

            <div className="flex flex-col items-center mt-4">
                <button
                    type="submit"
                    className={`w-full bg-[#8EC639] text-white font-medium py-2 px-4 rounded shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition`}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <BtnLoader1 />
                    ) : (
                        <>
                            {emailSent ? 'Reset Password' : 'Confirm'}
                            <i className="ms-2 fas fa-envelope" />
                        </>
                    )}
                </button>
                <div className="mt-4">
                    <Link
                        to="#"
                        className="text-red-500 hover:underline"
                        onClick={toggleForgetPasswordForm}
                    >
                        Cancel
                    </Link>
                </div>
            </div>
        </form>
    );
};
