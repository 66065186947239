import * as Yup from 'yup';
// import { formCheckProhibitedPatterns } from '../../helpers';

export const ListingTypeSchema = Yup.object().shape({
    listingType: Yup.string()
        .oneOf(['Short-Term Rental', 'Long-Term Rental', 'Adventures'], 'Invalid listing type')
        .required('Listing type is required'),
});

// Yup validation schema
export const LocationSchema = Yup.object({
    address: Yup.string().required("Address is required"),
    apt_suite: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipcode: Yup.string().required("ZIP / Postal Code is required"),
    area: Yup.string(),
    country: Yup.string().required("Country is required"),
    lat: Yup.number().required("Latitude is required").typeError("Invalid latitude"),
    lng: Yup.number().required("Longitude is required").typeError("Invalid longitude"),
});

export const MediaSchema = Yup.object({
    images: Yup.array()
        .min(1, 'At least one image is required') // Ensure there is at least one image
        .required('Images are required'),
});


export const InformationSchema = Yup.object({
    type_of_space: Yup.string().required("Please select a type of space"),
    dwelling: Yup.string().required("Please select a Lodging"),
    title: Yup.string().required("Title is required"),
    no_of_guest: Yup.number().required("Number of Guests is required").positive().integer(),
    no_of_bedrooms: Yup.number().required("Number of Bedrooms is required").positive().integer(),
    no_of_beds: Yup.number().required("Number of Beds is required").positive().integer(),
    no_of_bathrooms: Yup.number().required("Number of Bathrooms is required").positive(),
    no_of_rooms: Yup.number().required("Number of Rooms is required").positive().integer(),
    size: Yup.number().required("Size is required").positive(),
    unit_of_measure: Yup.string().required("Unit of Measure is required")
});

export const BedroomSchema = Yup.object().shape({
    bedrooms: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required("Required"),
                guest: Yup.number().required("Required"),
                bed: Yup.number().required("Required"),
                type: Yup.string().required("Required"),
            })
        )
        .min(1, "At least one bedroom is required"),
});


export const AirportFormSchema = Yup.array().of(
    Yup.object({
        airport_identifier: Yup.string().required("Identifier is required."),
        airport_name: Yup.string().required("Airport name is required."),
        airport_use: Yup.string().required("Airport use is required."),
        operation_hours: Yup.string().required("Operation hours are required."),
        lighting: Yup.string().required("Lighting is required."),
        ctaf_unicom: Yup.string().required("CTAF/UNICOM is required."),
        fuel: Yup.array().min(1, "Fuel type is required."),
        parking: Yup.string().required("Parking is required."),
        orientation: Yup.string().matches(
            /^\d{2}\/\d{2}$/,
            "Orientation must be in the format XX/XX"
        ),
        surface: Yup.array().min(1, "Surface type is required."),
        condition: Yup.string().required("Condition is required."),
        pattern: Yup.string().required("Pattern is required."),
        distance_from_runway: Yup.string().required("Distance from runway is required."),
    })
);


export const PricingFormSchema = Yup.object({
    instant_booking: Yup.boolean(),
    nightly: Yup.number().required('Nightly Price is required').positive('Must be a positive number'),
    apply_weekend_price: Yup.string().required('Weekend price selection is required'),
    weekend: Yup.number().required('Weekend price is required').positive('Must be a positive number'),
    weekly_seven_plus: Yup.number().required('Weekly price is required').positive('Must be a positive number'),
    weekly_thirty_plus: Yup.number().required('30+ nights price is required').positive('Must be a positive number'),
    additional_guest: Yup.string().required('Additional guests allowed is required'),
    no_of_guest: Yup.number().required('No. of additional guests is required').positive('Must be a positive number'),
    additional_guest_price: Yup.number().required('Price per additional guest is required').positive('Must be a positive number'),
    pet_allowed: Yup.string().required('Pet allowed selection is required'),
    no_of_pets: Yup.number().required('No. of pets is required').positive('Must be a positive number'),
    price_per_pet: Yup.number().required('Price per pet is required').positive('Must be a positive number'),
    cleaning_fee: Yup.number().required('Cleaning fee is required').positive('Must be a positive number'),
    cleaning_fee_daily: Yup.string().required('Cleaning fee daily selection is required'),
    city_fee: Yup.number().required('City fee is required').positive('Must be a positive number'),
    city_fee_freq: Yup.string().required('City fee daily selection is required'),
    tax: Yup.number().required("Tax is required"),
});

export const FeaturesFormSchema = Yup.object({
    blocks: Yup.array()
        .of(
            Yup.object({
                selectedFeature: Yup.string().required("Feature is required"),
                selectedCheckboxes: Yup.array().min(1, "At least one checkbox is required")
            })
        )
        .required("Blocks are required")
});


export const addListingValidationSchema = Yup.object().shape({
    is_draft: Yup.number(),
    host_id: Yup.string(),
    listing_type: Yup.string().required('Listing type is required.'),

    address: Yup.string().required('Address is required').max(100, 'Address must be at most 100 characters'),
    unit_no: Yup.string().max(50, 'Apartment/Suite must be at most 50 characters'),
    city: Yup.string().required('City is required').max(50, 'City must be at most 50 characters'),
    state: Yup.string().required('State is required').max(50, 'State must be at most 50 characters'),
    zipcode: Yup.string()
        .required('ZIP/Postal Code is required')
        .matches(/^\d{1,10}$/, 'ZIP/Postal Code must be numeric and up to 10 characters'),
    country: Yup.string().required('Country is required').max(50, 'Country must be at most 50 characters'),
    latitude: Yup.number()
        .typeError('Latitude must be a number')
        .required('Latitude is required')
        .min(-90, 'Latitude must be between -90 and 90')
        .max(90, 'Latitude must be between -90 and 90'),
    longitude: Yup.number()
        .typeError('Longitude must be a number')
        .required('Longitude is required')
        .min(-180, 'Longitude must be between -180 and 180')
        .max(180, 'Longitude must be between -180 and 180'),

    images: Yup.array()
        .of(Yup.mixed().required('Image is required.'))
        .min(1, 'At least one image is required.'),

    title: Yup.string().required('Title is required.'),
    // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),
    type_of_space: Yup.string().required('Type of space is required.'),
    lodging_type: Yup.string().required('Dwelling type is required.'),
    no_of_bedrooms: Yup.number().required('Number of bedrooms is required.')
        .min(0, "The minimum bedrooms is at least 0")
        .max(35, "Number of bedrooms cannot exceed 35"),
    no_of_guest: Yup.number().required('Number of guests is required.')
        .min(1, 'At least 1 guest is required.')
        .max(99, 'Number of guests cannot exceed 99.'),
    no_of_beds: Yup.number().required('Number of beds is required.')
        .min(0, "The minimum beds is at least 0")
        .max(35, "Number of beds cannot exceed 35"),
    no_of_bathrooms: Yup.string()
        .required('Number of bathrooms is required.')
        .matches(
            /^(?:[0-9]|[1-9][0-9])(?:\.5|\.75)?$/,
            'Number of bathrooms must be a whole number or a decimal ending in .5 or .75, between 0 and 99 (e.g., 1.5, 1.75, 2.5, etc.).'
        )
        .test(
            'max',
            'Number of bathrooms must be between 0 and 99.',
            value => !value || (parseFloat(value) >= 0 && parseFloat(value) <= 99)
        ),

    no_of_rooms: Yup.number().required('Number of rooms is required.')
        .min(0, 'The minimum beds is at least 0')
        .max(75, 'Number of rooms cannot exceed 99.'),
    size: Yup.number().required('Size is required.'),
    unit_of_measure: Yup.string().required('Unit of measure is required.'),
    description: Yup.string().required('Description is required.'),
    // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),

    bedrooms: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Bedroom name is required.')
                .min(3, 'Bedroom name must be at least 3 characters long.')
                .max(25, 'Bedroom name cannot exceed 25 characters.'),
            // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),
            no_of_guest: Yup.number().required('Number of guests in bedroom is required.')
                .min(1, 'At least 1 guest is required.')
                .max(99, 'Number of guests cannot exceed 99.'),
            no_of_bed: Yup.number().required('Number of beds in bedroom is required.')
                .min(0, "The minimum beds is at least 0")
                .max(12, "Number of beds cannot exceed 12"),
            bed_type: Yup.string().required('Bed type is required.')
                .min(3, 'Bed type must be at least 3 characters long.')
                .max(25, 'Bed type cannot exceed 25 characters.'),
            // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),
        })
    ),

    airports: Yup.array().of(
        Yup.object().shape({
            airport_identifier: Yup.string().required('Identifier is required.')
                .min(2, 'Airport Identifier must be at least 3 characters long.')
                .max(4, 'Airport Identifier cannot exceed 4 characters.'),
            // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),
            airport_use: Yup.string().required('Airport Use is required.'),
            airport_name: Yup.string().required('Airport name is required.')
                .min(3, 'Airport name must be at least 3 characters long.')
                .max(250, 'Airport name cannot exceed 25 characters.'),
            // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),
            air_nav: Yup.string().required('Airport Navigation is required.'),
            ctaf_unicom: Yup.string().required('Airport CTAF/UNICOM is required.'),
            dimension_feets: Yup.string().required('Airport Dimensions is required.'),
            dimension_meters: Yup.string().required('Airport Dimensions is required.'),
            elevation_feets: Yup.number()
                .required('Airport Elevation is required.'),

            elevation_meters: Yup.number()
                .required('Airport Elevation is required.'),
            fuel: Yup.array().of(Yup.string()),
            lighting: Yup.number().required('Airport Lightning is required.'),
            operation_hours: Yup.string().required('Airport Operation Hours are required.'),
            orientation: Yup.string().required('Airport Orientation is required.'),
            parking: Yup.string().required('Airport Parking is required.'),
            pattern: Yup.string().required('Airport Pattern is required.'),
            surface: Yup.array().of(Yup.string().required('Airport Surface is required.')).min(1, 'At least surface is required.'),
            runway_condition: Yup.string()
                .min(3, 'Condition must be at least 3 characters long.')
                .max(50, 'Condition cannot exceed 25 characters.'),
            // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),
            ground_transportation: Yup.string().required('Airport Ground Transportation is required.'),
            additional_info: Yup.string()
                .min(3, 'Additional Information  must be at least 3 characters long.')
                .max(100, 'Additional Information  cannot exceed 25 characters.'),
            // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),
            distance_from_runway: Yup.number().required('Airport Ground Transportation is required.'),
        })
    ),

    instant_booking: Yup.number()
        .transform((value) => (isNaN(value) ? 0 : value))
        .required('Instant booking is required'),

    nightly_price: Yup.number().required('Nightly price is required.'),

    apply_weekend_price: Yup.string(),

    weekend_nightly_price: Yup.string().test(
        'weekend-price-required',
        'Weekend price is required when "Apply Weekend Price" is selected.',
        function (value) {
            const { apply_weekend_price } = this.parent;
            return apply_weekend_price !== 'None' ? !!value : true;
        }
    ),

    night_price_seven_plus: Yup.number(),

    night_price_thirty_plus: Yup.number(),


    additional_guest: Yup.string(),

    no_of_additional_guest: Yup.number()
        .test(
            'no-of-additional-guests-required',
            'Number of additional guests is required when "Additional Guest" is selected.',
            function (value) {
                const { additional_guest } = this.parent;
                return additional_guest === '1' ? !!value : true;
            }
        )
        .test(
            'no-of-additional-guests-min-max',
            'Number of additional guests must be between 1 and 99.',
            function (value) {
                const { additional_guest } = this.parent;
                return additional_guest === '1' ? value >= 1 && value <= 99 : true;
            }
        ),

    additional_guest_price: Yup.string().test(
        'additional-guest-price-required',
        'Additional guest price is required when "Additional Guest" is selected.',
        function (value) {
            const { additional_guest } = this.parent;
            return additional_guest === '1' ? !!value : true;
        }
    ),

    pet_allowed: Yup.string(),

    no_of_pets: Yup.number()
        .test(
            'no-of-pets-required',
            'Number of pets is required when pets are allowed.',
            function (value) {
                const { pet_allowed } = this.parent;
                return pet_allowed === '1' ? !!value : true;
            }
        )
        .test(
            'no-of-pets-min-max',
            'Number of pets must be between 1 and 5.',
            function (value) {
                const { pet_allowed } = this.parent;
                return pet_allowed === '1' ? value >= 1 && value <= 5 : true;
            }
        ),

    price_per_pet: Yup.string().test(
        'price-per-pet-required',
        'Price per pet is required when pets are allowed.',
        function (value) {
            const { pet_allowed } = this.parent;
            return pet_allowed === '1' ? !!value : true;
        }
    ),


    cleaning_fee: Yup.string().required('Clean Fee is required.'),
    cleaning_freq: Yup.string().required('Clean Fee Period is required.'),
    city_fee: Yup.string(),
    city_fee_freq: Yup.string(),
    tax_percentage: Yup.number().required('Tax is required.'),

    custom_period_pricing: Yup.number(),
    custom_periods: Yup.array().of(
        Yup.object().shape({
            start_date: Yup.date()
                .nullable()
                .test('start_date-required', 'Start Date is required if any field is filled', function (value) {
                    const { end_date, nightly_price, weekend_price, price_add_guest, custom_period_pricing } = this.parent;
                    return !(end_date || nightly_price || weekend_price || price_add_guest || custom_period_pricing === 1) || !!value;
                }),

            end_date: Yup.date()
                .nullable()
                .test('end_date-required', 'End Date is required if any field is filled', function (value) {
                    const { start_date, nightly_price, weekend_price, price_add_guest, custom_period_pricing } = this.parent;
                    return !(start_date || nightly_price || weekend_price || price_add_guest || custom_period_pricing === 1) || !!value;
                }),

            nightly_price: Yup.string().test(
                'nightly_price-required',
                'Custom Price is required if any field is filled',
                function (value) {
                    const { start_date, end_date, weekend_price, price_add_guest, custom_period_pricing } = this.parent;
                    return !(start_date || end_date || weekend_price || price_add_guest || custom_period_pricing === 1) || !!value;
                }
            ),

            weekend_price: Yup.string().test(
                'weekend_price-required',
                'Custom Weekly Price is required if any field is filled',
                function (value) {
                    const { start_date, end_date, nightly_price, price_add_guest, custom_period_pricing } = this.parent;
                    return !(start_date || end_date || nightly_price || price_add_guest || custom_period_pricing === 1) || !!value;
                }
            ),

            price_add_guest: Yup.string().test(
                'price_add_guest-required',
                'Custom Price Additional is required if any field is filled',
                function (value) {
                    const { start_date, end_date, nightly_price, weekend_price, custom_period_pricing } = this.parent;
                    return !(start_date || end_date || nightly_price || weekend_price || custom_period_pricing === 1) || !!value;
                }
            ),
        })
    ),

    extra_services: Yup.number(),
    extra_service: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().test(
                'name-required',
                'Service name is required if any field is filled',
                function (value) {
                    const { price, type, quantity, extra_services } = this.parent;
                    return !(price || type || quantity, extra_services === 1) || !!value;
                }
            ),
            // .test('no-prohibited-patterns', 'Prohibited characters found.', value => !formCheckProhibitedPatterns(value)),

            price: Yup.number().test(
                'price-required',
                'Service price is required if any field is filled',
                function (value) {
                    const { name, type, quantity, extra_services } = this.parent;
                    return !(name || type || quantity, extra_services === 1) || !!value;
                }
            ),

            type: Yup.string().test(
                'type-required',
                'Service type is required if any field is filled',
                function (value) {
                    const { name, price, quantity, extra_services } = this.parent;
                    return !(name || price || quantity, extra_services === 1) || !!value;
                }
            ),

            quantity: Yup.number()
                .test(
                    'quantity-min-max',
                    'Quantity must be between 1 and 99 when extra service is selected',
                    function (value) {
                        const { extra_services } = this.parent;
                        if (extra_services === 1) {
                            return value >= 1 && value <= 99;
                        }
                        return true;
                    }
                )
                .test(
                    'quantity-required',
                    'Quantity is required if any field is filled',
                    function (value) {
                        const { name, price, type, extra_services } = this.parent;
                        return !(name || price || type || extra_services === 1) || !!value;
                    }
                ),
        })
    ),



    // Check this for features
    features: Yup.array().of(
        Yup.object().shape({
            id: Yup.string(),
            sub_features: Yup.array()
                .of(Yup.string())
                .test(
                    'selectedCheckboxes-required',
                    'At least one checkbox must be selected if there are selected IDs',
                    function (value) {
                        const { id } = this.parent;
                        return !(id) || (value && value.length > 0);
                    }
                ),
        })
    ),



    cancellation_policy_short: Yup.number().required('Cancellation policy is required.'),

    cancellation_policy_long: Yup.number().test(
        'cancellation_policy_long-required',
        'Cancellation policy (long) is required for bookings longer than 30 days.',
        function (value) {
            const { max_day_booking } = this.parent;
            return !(max_day_booking > 30) || !!value;
        }
    ),

    min_day_booking: Yup.number().required('Minimum booking days are required.')
        .min(1, 'Minimum booking days must be at least 1.')
        .max(365, 'Minimum booking days cannot exceed 365.'),
    max_day_booking: Yup.number().required('Maximum booking days are required.')
        .min(1, 'Maximum booking days must be at least 1.')
        .max(365, 'Maximum booking days cannot exceed 365.'),
    check_in_after: Yup.string()
        .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
            'Check-In After (Time) must match the format h:mm AM/PM (e.g., 4:00 PM)'
        )
        .required('Check-In After (Time) is required'),
    check_out_before: Yup.string()
        .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
            'Check-Out Before (Time) must match the format h:mm AM/PM (e.g., 4:00 PM)'
        )
        .required('Check-out time is required.'),
    smoking_allowed: Yup.number().required('Smoking Allowed is required.'),
    smoking_rules: Yup.string(),
    rules_pet_allowed: Yup.number().required('Pets Allowed is required.'),
    pet_rules: Yup.string(),
    party_allowed: Yup.number().required('Parties Allowed is required.'),
    party_rules: Yup.string(),
    children_allowed: Yup.number().required('Children Allowed is required.'),
    children_rules: Yup.string(),

    infant_start_age: Yup.number()
        .min(0, 'Minimum age cannot be negative')
        .required('Minimum age is required')
        .test(
            'infant-ages-required',
            'Infant ages are required when children are allowed.',
            function (value) {
                const { children_allowed, infant_end_age } = this.parent;
                if (children_allowed === 1) {
                    return value && value !== undefined && infant_end_age !== undefined;
                }
                return true;
            }),
    infant_end_age: Yup.number()
        .min(Yup.ref('infant_start_age'), 'Maximum age cannot be less than minimum age')
        .required('Maximum age is required')
        .test(
            'infant-ages-required',
            'Infant ages are required when children are allowed.',
            function (value) {
                const { children_allowed, infant_start_age } = this.parent;
                if (children_allowed === 1) {
                    return value && value !== undefined && infant_start_age !== undefined;
                }
                return true;
            }),

    child_start_age: Yup.number()
        .min(0, 'Minimum age cannot be negative')
        .required('Minimum age is required')
        .test(
            'children-ages-required',
            'Children ages are required when children are allowed.',
            function (value) {
                const { children_allowed, child_end_age } = this.parent;
                if (children_allowed === 1) {
                    return value && value !== undefined && child_end_age !== undefined;
                }
                return true;
            }
        ),
    child_end_age: Yup.number()
        .min(Yup.ref('child_start_age'), 'Maximum age cannot be less than minimum age')
        .required('Maximum age is required')
        .test(
            'children-ages-required',
            'Children ages are required when children are allowed.',
            function (value) {
                const { children_allowed, child_start_age } = this.parent;
                if (children_allowed === 1) {
                    return value && value !== undefined && child_start_age !== undefined;
                }
                return true;
            }
        ),

    rules: Yup.string().required('Rules is required.')
        .min(3, 'Rules must be at least 3 characters long.'),
    welcome_message: Yup.string().required('Welcome Message is required.')
        .min(3, 'Welcome Message must be at least 3 characters long.'),

    blocked_dates: Yup.array().of(
        Yup.object().shape({
            start_date: Yup.date(),
            end_date: Yup.date(),
            type: Yup.string().default('blocked')
        })
    ),

});