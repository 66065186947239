import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../Components/auth/Baseurl";

// Create Context
const FeaturesContext = createContext();

// Custom Hook for accessing context
export const useFeatures = () => {
    return useContext(FeaturesContext);
};

// Provider Component
export const FeaturesProvider = ({ children }) => {
    const [features, setFeatures] = useState([]);
    const [subFeatures, setSubFeatures] = useState({});
    const [loading, setLoading] = useState(false);


    // Function to load sub-features for a specific feature ID
    const getSubFeatures = useCallback(async (featureId) => {
        try {
            const res = await axios.get(`${BASE_URL}/feature/${featureId}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            setSubFeatures((prev) => ({
                ...prev,
                [featureId]: res.data?.data?.feature_detail || [],
            }));
        } catch (err) {
            console.error(`Error fetching sub-features for feature ${featureId}:`, err);
        }
    }, []);


    // Function to load all features
    const getFeatures = useCallback(async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${BASE_URL}/feature`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const featuresData = res.data?.data || [];
            setFeatures(featuresData);

            // Load sub-features for each feature
            featuresData.forEach((feature) => {
                getSubFeatures(feature.id);
            });
        } catch (err) {
            console.error("Error fetching features:", err);
        } finally {
            setLoading(false);
        }
    }, [getSubFeatures]);

    // Load features and sub-features on mount
    useEffect(() => {
        getFeatures();
    }, [getFeatures]);

    // Context value
    const value = {
        features,
        subFeatures,
        getFeatures,
        getSubFeatures,
        loading,
    };

    return (
        <FeaturesContext.Provider value={value}>
            {children}
        </FeaturesContext.Provider>
    );
};
