import React, { useState } from 'react';
import { FaPeopleGroup } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { FaCreditCard } from 'react-icons/fa';
import moment from 'moment';
import * as Yup from "yup";
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { axiosInstanceWithToken } from '../../helpers/axios';
import { errorPopup, successPopup } from '../../helpers/swirlfire';
import { useListing, usePayment } from '../../providers';
import DetailDatePicker from '../../Pages/DetailDatePicker';
import { validateInput } from '../../helpers';
import { Link } from 'react-router-dom';


const validationSchema = Yup.object().shape({
  listing_id: Yup.string().required("Listing ID is required."),
  arrival_date: Yup.date()
    .required("Arrival date is required.")
    .min(new Date(), "Arrival date cannot be in the past."),
  departure_date: Yup.date()
    .required("Departure date is required.")
    .min(Yup.ref("arrival_date"), "Departure date cannot be before arrival date."),
  no_of_guests: Yup.number()
    .required("Number of guests is required.")
    .min(1, "At least one guest is required."),
  no_of_infants: Yup.number()
    .min(0, "Number of infants cannot be negative.")
    .required(),
  no_of_pets: Yup.number()
    .min(0, "Number of pets cannot be negative.")
    .required(),
  no_of_childrens: Yup.number()
    .min(0, "Number of children cannot be negative.")
    .required(),
  amount: Yup.number()
    .required("Total amount is required.")
    .min(1, "Amount must be greater than zero."),
  taxes: Yup.number()
    .required("Tax percentage is required.")
    .min(0, "Taxes cannot be negative."),
  note: Yup.string().max(500, "Note cannot exceed 500 characters."),
  user_id: Yup.string().required("User ID is required."),
  payment_method: Yup.string().required("Payment method is required."),
  meta_data: Yup.string().required("Meta data is required."),
});


export const BookingForm = ({ startDate, setStartDate, setEndDate, endDate, handleDecrement, handleIncrement, totalGuests, guest, maxGuests }) => {
  const { listingDetails } = useListing();
  const { paymentMethod, getPaymentMethods } = usePayment();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [extraServices, setExtraServices] = useState({});
  const [extraOpen, setExtraOpen] = useState();
  const [text, setText] = useState("");
  const [step, setStep] = useState(1);
  const stripe = useStripe();
  const elements = useElements();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [prohibitedError, setProhibitedError] = useState(false);


  const {
    id,
    instant_booking,
    nightly_price,
    apply_weekend_price,
    weekend_nightly_price,
    nightly_price_seven_plus,
    nightly_price_thirty_plus,
    additional_guest_price,
    additional_guest,
    cleaning_fee,
    cleaning_freq,
    city_fee,
    city_fee_freq,
    tax_percentage,
    extra_services,
    blocked_dates,
    custom_periods
  } = listingDetails;

  const handleValue = (e) => {
    const value = e.target.value; // Get the user input
    try {
      const result = validateInput(value);
      if (result.found) {
        setProhibitedError(true);
        Swal.fire({
          icon: "error",
          title: "Prohibited Content Detected!",
          html: `
              <strong>Detected Content:</strong> ${result.content}<br>
              <strong>Type:</strong> ${result.description}
            `,
        });
      } else {
        setProhibitedError(false);
        setText(value); // Assuming setText updates the state with valid input
      }
    } catch (ex) {
      console.error(ex);
    }
  };


  const handleServiceChange = (id, price) => {
    setExtraServices((prev) => {
      const updatedServices = { ...prev, [id]: !prev[id] };

      const newTotalPrice = Object.keys(updatedServices).reduce((acc, key) => {
        const service = extra_services.find(s => s.id === id);
        if (service) {
          return updatedServices[key] ? acc + parseFloat(service.price) : acc;
        }
        return acc;
      }, 0);

      setTotalPrice(newTotalPrice);

      return updatedServices;
    });
  };


  // -----------------------------------------------------------------------------------------------------------------------

  const rates = {
    nightly: nightly_price,
    weekend: weekend_nightly_price,
    weekly_seven_plus: nightly_price_seven_plus,
    weekly_thirty_plus: nightly_price_thirty_plus,
    city_fee: city_fee,
    cleaning_fee: cleaning_fee,
    security_deposit: 0,
    tax: tax_percentage,
    additional_guest_price: additional_guest_price,
  };

  const calculateTotalAmount = (
    startDate,
    endDate,
    rates
  ) => {
    if (startDate && endDate && rates) {
      const arrivalDate = new Date(startDate);
      const departureDate = new Date(endDate);

      const totalDays = Math.ceil((departureDate - arrivalDate) / (1000 * 60 * 60 * 24)) + 1;

      let weekdayCount = 0;
      let weekendCount = 0;

      // Iterate through each day to calculate weekday and weekend counts
      for (let i = 0; i < totalDays; i++) {
        const currentDate = new Date(arrivalDate);
        currentDate.setDate(arrivalDate.getDate() + i);
        const currentDay = currentDate.getDay();

        if (currentDay >= 1 && currentDay <= 5) {
          weekdayCount++;
        } else if (currentDay === 0 || currentDay === 6) {
          weekendCount++;
        }
      }

      let totalAmount = 0;
      let totalNightlyAmount = 0;
      let customPeriodAmount = 0;
      let additionalGuestsQuantity = 0;
      let additionalGuestsPrice = 0;



      const weekendPriceApplied = apply_weekend_price !== "" && apply_weekend_price !== null;

      // Calculate the base amount based on the total days and rates
      if (totalDays > 30) {
        totalAmount =
          weekdayCount * (nightly_price_thirty_plus || 0) + weekendCount * (weekend_nightly_price || 0);
      } else if (totalDays > 7) {
        totalAmount =
          (weekdayCount * (parseFloat(nightly_price_seven_plus) || 0)) + (weekendCount * (parseFloat(weekend_nightly_price) || 0));
      } else {
        totalAmount =
          weekdayCount * (nightly_price || 0) + weekendCount * (weekendPriceApplied ? weekend_nightly_price || 0 : nightly_price || 0);
      }

      totalNightlyAmount = totalAmount;

      // Handle custom period pricing
      custom_periods?.length > 0 && custom_periods.forEach((period) => {
        const customStartDate = new Date(period.start_date);
        const customEndDate = new Date(period.end_date);

        if (
          (arrivalDate >= customStartDate && arrivalDate <= customEndDate) ||
          (departureDate >= customStartDate && departureDate <= customEndDate)
        ) {
          const customDays = Math.ceil((customEndDate - customStartDate) / (1000 * 60 * 60 * 24)) + 1;
          totalAmount += customDays * (parseFloat(period.nightly_price) || 0);
          customPeriodAmount += customDays * (parseFloat(period.nightly_price) || 0);
          weekendCount -= customDays; // Custom period applied, reducing weekend count
        }
      });

      let totalCleaningFee = 0;
      let totalCityFee = 0;

      if (cleaning_freq === 'Daily') {
        totalCleaningFee = totalDays * (parseFloat(cleaning_fee) || 0);
      } else {
        totalCleaningFee = (parseFloat(cleaning_fee) || 0);
      }

      if (city_fee_freq === 'Daily') {
        totalCityFee = totalDays * (parseFloat(city_fee) || 0);
      } else {
        totalCityFee = (parseFloat(city_fee) || 0);
      }


      // Add additional fees like city_fee, cleaning_fee, etc.
      const additionalFees =
        (parseFloat(totalCleaningFee) || 0) + (parseFloat(totalCityFee) || 0);
      totalAmount += parseFloat(additionalFees);

      // Adjust for extra guests if any
      if (maxGuests < totalGuests && additional_guest === 1) {
        additionalGuestsQuantity = (totalGuests - maxGuests);
        additionalGuestsPrice = additionalGuestsQuantity * (parseFloat(additional_guest_price) || 0); // Optional based on requirement
        totalAmount += additionalGuestsPrice;
      }

      // Apply extra services pricing
      totalAmount += totalPrice;

      //  Apply Platform Fee
      const platformFee = totalAmount * 0.11;
      totalAmount += platformFee;

      // Apply Tax Amount
      const taxAmount = totalAmount * parseFloat(tax_percentage / 100);
      const amountAfterTax = totalAmount + taxAmount;

      // Return final amounts
      return {
        totalAmount: totalAmount.toFixed(2),
        totalNightlyAmount: totalNightlyAmount.toFixed(2),
        customPeriodAmount: customPeriodAmount.toFixed(2),
        amountAfterTax: amountAfterTax.toFixed(2),
        taxAmount: taxAmount.toFixed(2),
        platformFee: platformFee.toFixed(2),
        totalDays,
        additionalGuestsQuantity,
        additionalGuestsPrice: additionalGuestsPrice.toFixed(2)
      };
    } else {
      return {
        totalAmount: 0,
        totalNightlyAmount: 0,
        customPeriodAmount: 0,
        amountAfterTax: 0,
        taxAmount: 0,
        platformFee: 0,
        totalDays: 0,
        additionalGuestsQuantity: 0,
        additionalGuestsPrice: 0,
      };
    }
  };

  const {
    totalAmount,
    totalNightlyAmount,
    customPeriodAmount,
    amountAfterTax,
    taxAmount,
    platformFee,
    totalDays,
    additionalGuestsQuantity,
    additionalGuestsPrice
  } = calculateTotalAmount(
    startDate,
    endDate,
    rates
  );

  const handlePaymentClick = () => {
    if (localStorage.getItem('token') === null) {
      errorPopup("", "Please Login First!");
      return;
    } else if (totalGuests === 0) {
      errorPopup("", "Number of guests can not be 0!");
      return;
    }
    setIsModalOpen(true);
  }

  const handleStep = (page) => {
    setLoading(true);
    if (page === 2) {
      getPaymentMethods().then(() => {
        setStep(page);
      });
    } else
      setStep(page);
    setLoading(false);
  }

  const submitPayment = async (payment_id) => {
    setLoading(true);

    // Format dates
    const formattedStartDate = moment(startDate || "2024-06-07").format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate || "2024-06-08").format("YYYY-MM-DD");

    // Prepare data
    const data = {
      listing_id: id,
      arrival_date: formattedStartDate,
      departure_date: formattedEndDate,
      no_of_guests: guest["adults"],
      no_of_infants: guest["infants"],
      no_of_pets: guest["pets"],
      no_of_childrens: guest["children"],
      amount: totalAmount,
      taxes: tax_percentage,
      note: text,
      user_id: JSON.parse(localStorage?.getItem("data")).id,
      payment_method: payment_id,
      meta_data: JSON.stringify({
        totalAmount,
        totalNightlyAmount,
        customPeriodAmount,
        amountAfterTax,
        taxAmount,
        platformFee,
        totalDays,
        additionalGuestsQuantity,
        additionalGuestsPrice,
      }),
    };

    // Validate data with Yup
    try {
      await validationSchema.validate(data, { abortEarly: false });

      // Proceed with the API call if validation passes
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axiosInstanceWithToken.post("/booking", formData);
      if (response?.data?.status) {
        handleConfirmPayment(response?.data?.data?.payment_intent?.client_secret, payment_id);
      }
    } catch (validationError) {
      // Handle validation errors
      errorPopup("", validationError.errors);
    } finally {
      setLoading(false);
    }
  };



  const handleConfirmPayment = async (clientSecret, paymentMethodId) => {
    if (!stripe || !elements) return; // Ensure stripe and elements are loaded

    const returnUrl = `${window.location.origin}/booking-status`; // Your desired return URL after payment is confirmed

    const { error: stripeError, paymentIntent } = await stripe.confirmPayment({
      clientSecret, // PaymentIntent client secret
      payment_method: paymentMethodId, // Use the Payment Method ID that you already have
      confirmParams: {
        return_url: returnUrl, // Redirect URL after confirmation
      }
    });

    if (stripeError) {
      errorPopup('', stripeError);
      // Handle error
    } else if (paymentIntent.status === 'succeeded') {
      successPopup('Payment succeeded:', paymentIntent);
      // Handle successful payment
    } else if (paymentIntent.status === 'requires_capture') {
      // Handle capture situation
      console.log('PaymentIntent requires capture');
      // Optionally, handle server-side capture
    }


    setLoading(false);
  };


  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <div className="box-widget-item fl-wrap block_box">
      <div className="box-widget-item-header">
        <h3 style={{ display: "flex", fontSize: "20px", fontWeight: "700", fontFamily: "Quicksand" }} className='!m-0'>
          ${nightly_price}/Night
        </h3>
        {/* {startDate && endDate && (
          <h1 style={{ fontSize: "15px" }} className="dates-available">
            Your dates are available!
          </h1>
        )} */}
      </div>
      <div className="box-widget">
        <div className="box-widget-content !p-8">
          <form onSubmit={() => { }} className="add-comment custom-form">
            <fieldset>
              <DetailDatePicker setStartDate={setStartDate} setEndDate={setEndDate} disable={blocked_dates} />
              <div className="quantity fl-wrap">
                <div style={{ position: "relative" }}>
                  <FaPeopleGroup size={20} style={{ position: "absolute", left: "16px", top: "14px" }} />
                  <input onClick={() => setIsDropdownOpen(!isDropdownOpen)} type="text" value={totalGuests} readOnly className="ms-2 total-input" />
                </div>
                {isDropdownOpen && (
                  <div className="counter my-6">
                    {/* Repeat for each guest type */}
                    {['adults', 'children', 'infants', 'pets'].map((type) => (
                      <div key={type} className="counter-item d-flex justify-content-between">
                        <div className="d-flex">
                          <div>
                            <span className="count">
                              <strong style={{ marginLeft: '3px', color: "#3b4249" }}>{guest[type]}</strong>
                            </span>
                          </div>
                          <div>
                            <span style={{ fontWeight: "900", color: "#3b4249" }}>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                            {/* <br /> */}
                            {/* <span style={{ color: "#3b4249" }}>Age info here</span> */}
                          </div>
                        </div>
                        <div>
                          <button className="btn count-btn" onClick={(e) => handleDecrement(e, type)} disabled={guest[type] === 0}>-</button>
                          <button className="btn count-btn" onClick={(e) => handleIncrement(e, type)} disabled={additional_guest === 0 && totalGuests >= maxGuests}>+</button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Price and Extra Services Section */}
              <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", color: "#3b4249" }} className="box-widget-item-header">
                <h3>${nightly_price} X {totalDays} nights</h3>
                <p>${totalNightlyAmount}</p>
              </div>
              {extra_services.length > 0 &&
                <div className="extra-services fl-wrap">
                  <div style={{ position: "relative" }}>
                    <input
                      style={{ fontWeight: "600", padding: "13px 20px", color: "#3b4249" }}
                      onClick={() => setExtraOpen(!extraOpen)}
                      type="text"
                      value={extraOpen ? `$${totalPrice}` : "Extra Services"}
                      placeholder="Extra Services"
                      readOnly
                      className="total-input"
                    />
                    {totalPrice > 0 && !extraOpen && (
                      <div style={{ position: "absolute", fontSize: "14px", zIndex: "9", right: "32px", top: "13px" }}>${totalPrice}</div>
                    )}
                  </div>
                  {extraOpen && (
                    <div className="custom-form">
                      <ul className="fl-wrap filter-tags no-list-style ds-tg bg-white">
                        {extra_services?.map((service, index) => (
                          <li key={index} className='flex flex-row flex-wrap items-center justify-start' style={{ margin: "0px 10px 10px 3px" }}>
                            <input
                              style={{ marginTop: "10px" }}
                              id={index}
                              type="checkbox"
                              name={service.name}
                              checked={extraServices[service?.id]}
                              onChange={() => handleServiceChange(service?.id, service.price)}
                            />
                            <label style={{ padding: "0px 5px", fontSize: "15px", color: "#566985" }} htmlFor={index}>{service.name}</label> -
                            <label style={{ textAlign: "end", fontSize: "15px", color: "#566985", fontWeight: "400" }} htmlFor={index}>${service.price}</label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              }


              <button
                type="button"
                className="btn pay-btn"
                disabled={prohibitedError}
                onClick={handlePaymentClick}>
                {instant_booking === 1 ? "Pay now" : "Request to Book"}
              </button>

              <div className='py-3'>
                <textarea className='!mt-3' as="textarea" name="note" cols="40" onChange={handleValue} rows="3" placeholder="Ask host a question:" />
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50 top-[15%]">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full relative">
            <div className='flex justify-end '>
              <i class="fas fa-times px-2 py-1 bg-gray-300 rounded-full text-white cursor-pointer" onClick={() => setIsModalOpen(false)}></i>
            </div>
            {step === 1 && (
              <>
                <h2 className="text-2xl font-bold mb-4 border-b">Booking Details</h2>
                <div className="h-[300px] overflow-y-scroll">
                  {/* Section 1: Nightly Charges */}
                  <div>
                    <h3 className="text-md font-semibold text-gray-900 border-b pb-2">Nightly Charges</h3>
                    <div className="flex justify-between text-gray-800 mt-2">
                      <h4 className="text-sm font-medium !m-0"> Rental for {totalDays} nights</h4>
                      <p className="text-sm !m-0">${+totalNightlyAmount + +customPeriodAmount}</p>
                    </div>
                    {/* {customPeriodAmount > 0 && (
                      <div className="flex justify-between text-gray-800 mt-1">
                        <h4 className="text-sm font-medium !m-0">Custom Period Amount</h4>
                        <p className="text-sm !m-0">${customPeriodAmount}</p>
                      </div>
                    )} */}
                  </div>

                  {/* Section 2: Fees */}
                  <div>
                    <h3 className="text-md font-semibold text-gray-900 border-b pb-2">Fees</h3>
                    <div className="flex justify-between text-gray-800 mt-2">
                      <h4 className="text-sm font-medium !m-0">Cleaning Fee</h4>
                      <p className="text-sm !m-0">${cleaning_fee}</p>
                    </div>
                    <div className="flex justify-between text-gray-800 mt-1">
                      <h4 className="text-sm font-medium !m-0">City Fee</h4>
                      <p className="text-sm !m-0">${city_fee}</p>
                    </div>
                    <div className="flex justify-between text-gray-800 mt-1">
                      <h4 className="text-sm font-medium !m-0">Platform Fee</h4>
                      <p className="text-sm !m-0">${platformFee}</p>
                    </div>
                  </div>

                  {/* Section 3: Additional Guests */}
                  <div>
                    <h3 className="text-md font-semibold text-gray-900 border-b pb-2">Additional Guests</h3>
                    <div className="flex justify-between text-gray-800 mt-2">
                      <h4 className="text-sm font-medium !m-0">Number of Additional Guests</h4>
                      <p className="text-sm !m-0">{additionalGuestsQuantity}</p>
                    </div>
                    <div className="flex justify-between text-gray-800 mt-1">
                      <h4 className="text-sm font-medium !m-0">Additional Guests Charge</h4>
                      <p className="text-sm !m-0">${additionalGuestsPrice}</p>
                    </div>
                  </div>

                  {/* Section 4: Extra Services */}
                  {totalPrice > 0 && (
                    <div>
                      <h3 className="text-md font-semibold text-gray-900 border-b pb-2">Extra Services</h3>
                      <div className="flex justify-between text-gray-800 mt-2">
                        <h4 className="text-sm font-medium !m-0">Extra Services Price</h4>
                        <p className="text-sm !m-0">${totalPrice}</p>
                      </div>
                    </div>
                  )}

                  {/* Section 5: Summary */}
                  <div>
                    <h3 className="text-md font-semibold text-gray-900 border-b pb-2">Summary</h3>
                    <div className="flex justify-between text-gray-800 mt-2">
                      <h4 className="text-sm font-medium !m-0">Total before taxes</h4>
                      <p className="text-sm !m-0">${totalAmount}</p>
                    </div>
                    <div className="flex justify-between text-gray-800 mt-1">
                      <h4 className="text-sm font-medium !m-0">Taxes</h4>
                      <p className="text-sm !m-0">${taxAmount}</p>
                    </div>
                    <div className="flex justify-between text-gray-800 mt-1 font-semibold">
                      <h4 className="text-base !m-0">Total</h4>
                      <p className="text-base !m-0">${amountAfterTax}</p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end text-gray-800 border-t">
                  <button
                    className="bg-[#8ec639] text-white px-4 py-2 rounded mt-4"
                    onClick={() => handleStep(2)}
                    disabled={loading || prohibitedError}
                  >
                    {loading ?
                      "Loading...."
                      : "Next"}
                  </button>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <h2 className="text-2xl font-bold mb-4 border-b">Select Payment Method</h2>
                <div className="space-y-2 h-[300px] overflow-scroll">
                  {paymentMethod?.length > 0 ? (
                    <ul>
                      {paymentMethod.map(
                        (method) =>
                          method.type === "card" && (
                            <li
                              key={method.id}
                              className={`flex justify-between items-center mb-2 p-3 border border-b ${selectedPaymentMethod === method?.id && 'bg-gray-200'}`}
                            >
                              <div className={`flex items-center `}>
                                <input
                                  type="radio"
                                  name="selectedPaymentMethod"
                                  id={`payment-method-${method.id}`}
                                  value={method.id}
                                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                  className="!mr-4"
                                />
                                <label htmlFor={`payment-method-${method.id}`} className="flex items-center">
                                  <FaCreditCard className="inline mr-2" />
                                  **** **** **** {method?.card?.last4} - {method?.billing_details?.name}
                                </label>
                              </div>
                            </li>
                          )
                      )}
                    </ul>
                  ) : (
                    <h3 className="text-xl font-semibold mb-2 text-left">No Payment Method Found!</h3>
                  )}
                </div>
                <div className='flex justify-between'>
                  <Link to={'/account-settings/payments'} className='decoration-[none]'>
                    <button
                      className="bg-gray-200 hover:bg-gray-100 px-4 py-2 mt-4 text-black rounded-md"
                    >
                      Add Credit Card
                    </button>
                  </Link>
                  <button
                    disabled={loading || prohibitedError || !selectedPaymentMethod}
                    onClick={() => submitPayment(selectedPaymentMethod)}
                    className="bg-[#8ec639] hover:bg-[#7fb134] px-4 py-2 mt-4 text-white rounded-md "
                  >
                    {loading ? "Loading..." : "Confirm Booking"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

    </div>
  )
}