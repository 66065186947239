import React, { useState } from "react";
import Layout from "../Components/layout/Layout";

const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left p-4 text-lg font-medium text-gray-900 flex justify-between items-center"
      >
        {title}
        <span className={`transform transition-transform ${isOpen ? "rotate-180" : ""}`}>
          ▼
        </span>
      </button>
      {isOpen && (
        <div className="p-4">
          {children}
        </div>
      )}
    </div>
  );
};

function FAQ() {
  return (
    <Layout>
      <div className="container mx-auto md:px-12 py-12">
        <div className="section-title">
          <h2 className="!text-left text-3xl font-semibold mb-4">FAQ</h2>
          <p className="!max-w-full">
            The FAQ is where we answer your questions in depth so the entire Fly-Inn Family can benefit! If your question isn't answered here, we would love to add it to our FAQ library of videos. Please ask us your questions by using our contact page, calling us, or sending us an email to PIC@fly-inn.com with "Suggestions" in the subject line. You SQUAWK, We WILCO.
          </p>
        </div>
        <div className="space-y-4">
          <AccordionItem title="How to add a new listing on Fly-Inn.com">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/BlXa5iIUCWA?si=-5sYTjQ5Y1o9SFHz"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </AccordionItem>
          <AccordionItem title="April 11, 2024">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/_dTY5DbOCWM?si=dcXusRnE6-dVpH7v"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </AccordionItem>
          <AccordionItem title="How to sync Fly Inn com's iCal to other platforms">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/tClgM28kTsU?si=BlfrFPQrQ1sGMraa"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </AccordionItem>
          <AccordionItem title="How to Register on the Fly-Inn.com Platform">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/QfT_wgBOh3g?si=ACWbzXvwsMu3_YjV"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </AccordionItem>
        </div>
      </div>
    </Layout>
  );
}

export default FAQ;
