import React from "react";
import { useListing } from "../../providers";

export const FeatureDetails = () => {
    const { listingDetails } = useListing();

    const {
        features
    } = listingDetails;


    return (

        <div className="block !bg-transparent">
            <div className="block-section">
                <div className="block-body !px-0 flex flex-col">
                    <div className="block-left mb-6">
                        <h3 className="text-lg font-bold text-start pb-6">Features</h3>
                    </div>
                    {/* block-left */}
                    <div >
                        {features?.map((feature) => (
                            <div key={feature.id} className="mb-8">
                                <p className="font-bold text-gray-800">{feature.name}</p>
                                <ul className="detail-list grid grid-cols-2 gap-4 mt-2">
                                    {feature.sub_features?.map((subFeature) => (
                                        <li
                                            key={subFeature.id}
                                            className="flex items-center space-x-2 text-gray-700"
                                        >
                                            <i className="fas fa-check-circle text-green-500"></i>
                                            <span>{subFeature.sub_heading}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    {/* block-right */}
                </div>
                {/* block-body */}
            </div>
            {/* block-section */}
        </div>
    );
};
