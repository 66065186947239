import React from 'react';
import '../css/css-styling-options.css';
import '../css/css-post-8728.css';
import '../css/cirt.css';
import '../css/homey-style.css';
import '../css/homey-child-style.css';
import '../css/css-frontend.min.css';
import '../css/css-main.css';
import Layout from '../../../../Components/layout/Layout';

export const ContentAndCopyrightPolicy = () => {
    return (
        <Layout>
            <div id="section-body">
                <div id="has_social_account" style={{ display: "none" }} data-has-social-account=""></div>
                <section className="main-content-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <div>
                                    <div className="block-top-title">
                                        <h1 className="listing-title">Content and Copyright Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container bg-white">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="page-wrap">
                                    <div className="article-main">
                                        <article id="post-8795" className="single-page-article block post-8795 page type-page status-publish hentry">
                                            <div className="article-detail block-body">
                                                <div data-elementor-type="wp-page" data-elementor-id="8795" className="elementor elementor-8795" data-elementor-post-type="page">
                                                    <div className="elementor-element elementor-element-3a71b800 e-flex e-con-boxed e-con e-parent" data-id="3a71b800" data-element_type="container">
                                                        <div className="e-con-inner">
                                                            <div className="elementor-element elementor-element-54e43d8c elementor-widget elementor-widget-text-editor" data-id="54e43d8c" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <p>All Member-submitted &ldquo;Content&rdquo;, including, but not limited to, Listing titles, Listing descriptions, reviews, and the responses thereof, Guest and Host profiles, Squawks posts, and messages, whether it is text, audio, video, photographic, or photos of art, video, or photography, are the views and opinions of the Member who posted them, not FlyInn.</p>
                                                                    <p>We reserve the right to remove any Member-submitted Content for any reason, including, in our sole discretion, lack of adherence to the guidelines outlined in our Terms of Service, our CIRTainly Policy, or this policy. By posting your Content on Fly-Inn, you are asserting that you agree to adhere to these Terms and Policies.</p>
                                                                    <p><strong>Content that is allowed and/or expected:</strong></p>
                                                                    <ol>
                                                                        <li>
                                                                            General
                                                                            <ol>
                                                                                <li>
                                                                                    Must be truthful, fair, accurate, and posted in the spirit of creating greater good for the community
                                                                                </li>
                                                                                <li>
                                                                                    Must have the legal right or proper authorization to post the Content
                                                                                </li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>
                                                                            Listings
                                                                            <ol>
                                                                                <li>
                                                                                    Titles and descriptions must be relevant to the Listing
                                                                                </li>
                                                                                <li>Titles must only contain letters and the characters visible on a standard keyboard, without using such characters to create text emojis</li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>
                                                                            Reviews
                                                                            <ol>
                                                                                <li>
                                                                                    Must reflect the Guest&rsquo;s or the Host&rsquo;s experience with the transaction that took place between them.
                                                                                </li>
                                                                                <li>Must be honest, genuine, useful, relevant, and posted in the spirit of creating greater good for the community, for example, to sincerely and kindly educate the person whom you are addressing, or to congratulate. Such communication edifies the receiver and informs the community thereby enabling them to make educated choices when it comes to choosing which Hosts or Guests to do business with on the Platform</li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>
                                                                            Squawks
                                                                            <ol>
                                                                                <li>
                                                                                    Relevant content that stays on the topic being discussed and either imparts or seeks knowledge
                                                                                </li>
                                                                            </ol>
                                                                        </li>
                                                                    </ol>
                                                                    <p><strong>Content that is not allowed:</strong></p>
                                                                    <ol>
                                                                        <li>
                                                                            General
                                                                            <ol>
                                                                                <li>
                                                                                    Any information that is false, misleading, or fraudulent, especially true of profiles and Listings
                                                                                </li>
                                                                                <li>
                                                                                    Spam of any kind
                                                                                </li>
                                                                                <li>Advertising of any kind including, but not limited to, business names, logos, slogans, or hyperlinks to any website other than fly-inn.com</li>
                                                                                <li>Impersonation of any kind</li>
                                                                                <li>Illegal Content or Content that violates another&rsquo;s rights, including intellectual property rights</li>
                                                                                <li>Discrimination of any kind</li>
                                                                                <li>Bullying, harassing, discriminatory, or threatening Content</li>
                                                                                <li>Sexually explicit, violent, or graphic Content</li>
                                                                                <li>Sharing anyone&rsquo;s private information explicitly or sharing enough information that someone can find the private information</li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>
                                                                            Listings
                                                                            <ol>
                                                                                <li>
                                                                                    Fraudulent, false, or misleading information, especially material information
                                                                                </li>
                                                                                <li>Giving any clues as to the address or location of a Listing, including displaying the house number in the photography</li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>
                                                                            Reviews
                                                                            <ol>
                                                                                <li>
                                                                                    Disclosing the address or location of a Listing whether it be in the review itself or the responses to the reviews
                                                                                </li>
                                                                                <li>Coercing the other party to pay amounts owed via a review</li>
                                                                                <li>Coercing the other party to leave a positive review</li>
                                                                                <li>Reviewing a property that you own, manage, or are somehow affiliated with</li>
                                                                                <li>Reviewing a property that you compete with</li>
                                                                                <li>Misleading, deceitful reviews</li>
                                                                                <li>Threatening the other party if they leave a negative review</li>
                                                                                <li>Reviewing a Property you have never stayed in</li>
                                                                                <li>Purchasing a review for any valuable consideration</li>
                                                                                <li>Reviewing a property you have stayed in outside of 14 days after your stay</li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>
                                                                            Squawks
                                                                            <ol>
                                                                                <li>
                                                                                    Bullying, harassment, or disrespect of any kind
                                                                                </li>
                                                                                <li>Irrelevant Content that strays off the topic being discussed and neither imparts nor seeks knowledge</li>
                                                                            </ol>
                                                                        </li>
                                                                    </ol>
                                                                    <p><strong>Additional Information</strong></p>
                                                                    <p>We explicitly disclaim any and all liability arising from the purported accuracy or legitimacy of any Content submitted by Members.<br />FlyInn reserves the right to remove any Content that we, at our sole discretion, determine to be in violation of our Terms of Service and Policies herein incorporated by reference. <br /></p>
                                                                    <p>FlyInn reserves the right to suspend or delete any account in violation, especially repeated violation of these provisions.<br /></p>
                                                                    <p>If you would like to remove a review that you wrote, send us an email to&nbsp;<a href="mailto:help@fly-inn.com" target="_blank" rel="noreferrer">help@fly-inn.com</a>&nbsp;requesting it be removed. You must include the Listing&rsquo;s URL and the URL for your profile and a brief statement asking us to remove it. Please note that we can only remove reviews, we cannot edit them.</p>
                                                                    <p><strong>Reporting Policy Violations</strong></p>
                                                                    <p>Should you become aware of any Content violations of our Content and Copyright Policy, please contact us by sending us an email to&nbsp;<a href="mailto:violations@fly-inn.com" target="_blank" rel="noreferrer">violations@fly-inn.com</a>. Please include as much information as possible.</p>
                                                                    <p>If you become aware of a violation that is specifically a Copyright violation, please email us at&nbsp;<a href="mailto:legal@fly-inn.com" target="_blank" rel="noreferrer">legal@fly-inn.com</a>. Our legal team will be in touch promptly to inform you of the next steps.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
