import React from 'react';
import '../css/css-styling-options.css';
import '../css/css-post-8728.css';
import '../css/cirt.css';
import Layout from '../../../../Components/layout/Layout';

export const ServiceFeePolicy = () => {
    return (
        <Layout>
            <div id="section-body">
                <div id="has_social_account" style={{ display: "none" }} data-has-social-account=""></div>
                <section class="main-content-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <div class="page-title">
                                    <div class="block-top-title">
                                        <h1 class="listing-title">Service Fees Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="page-wrap">
                                    <div class="article-main">
                                        <article id="post-10884" class="single-page-article block post-10884 page type-page status-publish hentry">
                                            <div class="article-detail block-body">
                                                <div data-elementor-type="wp-page" data-elementor-id="10884" class="elementor elementor-10884" data-elementor-post-type="page">
                                                    <div class="elementor-element elementor-element-413af666 e-flex e-con-boxed e-con e-parent" data-id="413af666" data-element_type="container">
                                                        <div class="e-con-inner">
                                                            <div class="elementor-element elementor-element-41994ee6 elementor-widget elementor-widget-text-editor" data-id="41994ee6" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <h4>General:</h4>
                                                                    <p>Service fees are indispensable to keep the Platform running, for your service. The fees cover the costs of wages for our employees, ongoing maintenance of the Platform, keeping transactions secure, and all the rest of our overhead.</p>
                                                                    <h4>Hosts:</h4>
                                                                    <p>Hosts don&rsquo;t pay a service fee for use of the Fly-Inn Platform. Hosts simply pay the service fee that the credit card companies charge&ndash;3%. There is no other fee for Hosts. This is our way of thanking you for trusting your Rental Property to your fellow Members of the Community&mdash; your Fly-Inn Family.. Thank you for providing your fellow Members a way to have the most fun possible with their aircraft. </p>
                                                                    <h4>Guests:</h4>
                                                                    <p>Guests pay a service fee that is calculated as a percentage (11%) of the subtotal of the reservation amount which equals all fees for Goods and Services offered in the Listing and any other fees the Host may charge. This subtotal excludes taxes, transient fees, and approved off-the-platform fees as per our Off-the-Platform Fees Policy.<br />If you used a currency other than USD, we must add 2% to your service fee.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
