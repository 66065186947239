/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ModalLogin from "../Pages/ModalLogin";
import { fetchData, ThemeContext } from "../context/ContextFile";
import { FiFacebook, FiTwitter, FiYoutube } from "react-icons/fi";
import { FaInstagram, FaRegCircle } from "react-icons/fa6";
import RegisterModal from "./RegisterModal";
import { LODGING_TYPES_DROPDOWN, SERVER_IMAGES_URL, SPACE_TYPES_DROPDOWN } from "../constants";

function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsDisabled(true);
    }
  }, []);


  const [data, setdata] = useState(JSON.parse(localStorage.getItem("data")));
  // console.log(data?.image, "image");


  const logOut = () => {
    localStorage.clear("data");
    localStorage.clear("token");
    sessionStorage.clear();
    Swal.fire({
      position: "center",
      html: `
              <div style="display: flex; flex-direction: column; align-items: center;">
   <svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                  <p style="margin: 0; font-size: 1.2em;">Logout successful.</p>
              </div>
          `,
      showConfirmButton: false,
    })
    setTimeout(() => {
      // .then((result) => {
      //   if (result.isConfirmed) {
      window.location.href = "/";
      //   }
      // })
    }, 1000);
  };



  const { isModalOpen, setIsModalOpen, isRegister, setIsRegister } = useContext(ThemeContext);
  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };


  const GetData = (name, key) => {
    // const formData = new FormData();
    // formData.append(key, name);

    // axios
    //   .post(`${BASE_URL}/listing-search`, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     SetTheme(res?.data);
    navigate(`/map_listing/${name}/${key}`);
    // })
    // .catch((err) => {
    //   SetTheme(err);
    // });
  };


  return (
    <>
      <div className="myst !z-[100]">
        <div className="header-upper !h-auto">
          <div className="footer-social">
            <ul style={{ width: "240px", display: "flex", justifyContent: "space-between", marginLeft: "20px" }} className="no-list-style">
              <li>
                <Link to="https://www.facebook.com/FlyInnLLC/" target="_blank">
                  <FiFacebook style={{ fontSize: "20px", color: "#4f5962" }} />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/FlyInnLLC" target="_blank">
                  <FiTwitter style={{ fontSize: "20px", color: "#4f5962" }} />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/flyinnllc/" target="_blank">
                  <FaInstagram style={{ fontSize: "20px", color: "#4f5962" }} />
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/@FLY-INN" target="_blank">
                  <FiYoutube
                    style={{ fontSize: "20px", color: "#4f5962" }} />
                </Link>
              </li>
            </ul>
          </div>
          <div style={{ marginLeft: data ? "-135px" : "39px" }}>
            <Link to="/">
              <img
                className="w-36 h-auto md:w-44" // Responsive sizes
                src="/image/fly-inn-logo.svg"
                alt="Fly Inn Logo"
              />
            </Link>

          </div>
          <div style={{ display: "flex" }}>

            <div
              className="header-user-menu"
              style={{
                marginLeft: 0,
                marginRight: 35,
                position: "relative",
                top: data ? "16px" : "21px",
              }}
            >
              <div
                className={`truncate header-user-namehu-menu-visdec${open ? 'hu-menu-visdec' : ''} `}
                style={{
                  display: data ? "flex" : "flex",
                  justifyContent: data ? "space-between" : "space-between",
                  alignItems: data ? "center" : "center",
                  // width: data ? "135px" : "40px",
                  cursor: data ? "pointer" : "pointer",
                  paddingTop: data ? "0" : "11px",
                  fontWeight: data ? "900" : "900",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={localStorage.getItem("token") ? () => {
                  setOpen(!open);
                }
                  : () => setIsModalOpen(true)
                }

              >
                {data?.username ? data?.username : "Log In"}
                {
                  data && (

                    <span>
                      <img
                        style={{
                          height: "38px",
                          width: "42px",
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                        src={data?.image !== null ? `${SERVER_IMAGES_URL}/profile/${data?.image}` : "images/avatar/avatar-bg.png"}
                        alt=""
                      />
                    </span>
                  )
                }

              </div>
              {data &&
                <ul className={open ? 'hu-menu-vis' : null}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} >
                  <li>
                    <Link to="/dashboard"> Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/profile"> Profile</Link>
                  </li>
                  <li>
                    <Link to="/my_listing"> My Listings</Link>
                  </li>
                  <li>
                    <Link to="/booking"> My Bookings</Link>
                  </li>
                  <li>
                    <Link to="/trip"> My Trips</Link>
                  </li>
                  <li>
                    <Link to="/my_wallets"> My Wallet</Link>
                  </li>
                  <li>
                    <Link to="/air_mail"> AirMail   <span
                      style={{
                        fontSize: "8px",
                        verticalAlign: "super",
                        position: "relative",
                        top: "-5px",
                        marginLeft: "-5px",
                        color: "#3b4249",
                        background: "none",
                        fontWeight: "400",
                      }}
                    >
                      TM
                    </span></Link>
                  </li>
                  {/* <li>
                  <Link to="/payouts"> Payouts</Link>
                </li> */}
                  <li>
                    <Link to="/invoice"> Invoices</Link>
                  </li>
                  <li>
                    <Link to="/review"> Reviews</Link>
                  </li>
                  <li>
                    <Link to="/favorite">My Favorites</Link>
                  </li>
                  <li onClick={logOut}>
                    <Link to="/">Log out</Link>
                  </li>

                </ul>
              }
            </div>
            {data ? null : (
              <>
                <div style={{
                  position: "absolute",
                  right: "273px",
                  top: "46px",
                }}>
                  <FaRegCircle />

                </div>
                <div onClick={() => setIsRegister(!isRegister)} style={{ marginLeft: "0px", marginRight: "35px", top: "32px", cursor: 'pointer', fontWeight: "900" }} className="header-user-menu" >Register</div>
                <div>
                  <Link style={{ color: "#fff", backgroundColor: "#af2322" }}
                    to="/login"
                    className={`add-list color-bg`}
                  >
                    Become a Host
                    <span>
                      <i className="fal fa-layer-plus"></i>
                    </span>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>


        <header className="main-header">
          <div className="nav-holder main-menu">
            <nav>
              <ul className="no-list-style">
                {/* <li>
                <Link style={{color: "#3B4249"}} to="/dashboard" className=" link">
                 Dashboard
                </Link>            
              </li> */}
                <li>
                  <Link to="/" className="link">
                    Home
                  </Link>
                </li>
                {/* <li>
                  <Link to="/map" className="link">
                    Map
                  </Link>
                </li> */}
                <li>
                  <Link to="#" className="">
                    Explore <i className="fa fa-caret-down" />
                  </Link>
                  {/*second level */}
                  <ul>
                    {/* <li>
                      <Link to="/by-state-airport">
                        By State / Airport
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/restaurants">
                        Restaurants
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        By Lodging
                        <i className="fa fa-caret-down" />
                      </Link>
                      <ul
                        className="specific-ul !h-auto overflow-y-scroll"
                      >
                        {LODGING_TYPES_DROPDOWN.map((item, index) => (
                          <li key={index}>
                            <Link to={`/map_listing/${item.type}/${item.name}`}>
                              {item.text || item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>


                    </li>
                    <li>
                      <Link to="#">
                        By Space
                        <i className="fa fa-caret-down" />
                      </Link>
                      {/*third  level  */}
                      <ul>
                        {SPACE_TYPES_DROPDOWN.map((item, index) => (
                          <li key={index}>
                            <Link to={`/map_listing/${item.type}/${item.name}`}>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    onClick={() => fetchData()}
                    to="/listing"
                    className="link"
                  >
                    Stays
                  </Link>
                </li>
                <li>
                  <Link to="#" className="">
                    Info <i className="fa fa-caret-down" />
                  </Link>
                  <ul>
                    <li>
                      <Link to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq">
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to="/philanthropy">
                        Philanthropy
                      </Link>
                    </li>
                    <li>
                      <Link to="/squawks">
                        Squawks
                      </Link>
                    </li>
                    <li>
                      <Link to="/agreements">
                        Agreements
                        <i className="fa fa-caret-down" />
                      </Link>
                      <ul>
                        <li>
                          <Link

                            to={"/terms-of-service"}
                          >
                            Terms of Service
                          </Link>
                        </li>
                        <li>
                          <Link

                            to={"/privacy_policy"}
                          >
                            Privacy Policy
                          </Link>
                        </li>
                      </ul>
                      {/*third  level end*/}
                    </li>
                    <li>
                      <Link to="/gallery">
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to="/testimonials">
                        Testimonials
                      </Link>
                    </li>
                  </ul>
                  {/*second level end*/}
                </li>

                <li>
                  <Link

                    to="/contact"
                    className="link"
                  >
                    Contact
                  </Link>
                </li>
                <li>



                  <div>
                    <Link style={{ color: "#fff", backgroundColor: "#af2322", top: "0px" }}
                      to="/login"
                      className={`add-list color-bg `}
                    >
                      Become a Host
                      <span style={{ paddingLeft: "0px" }}>
                        <i
                          style={{ color: "#fff" }}
                          className="fal fa-layer-plus"
                        ></i>
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          {/* navigation  end */}
          {/* header-search_container */}
          <div className="header-search_container header-search vis-search">
            <div className="container small-container">
              <div className="header-search-input-wrap fl-wrap">
                {/* header-search-input */}
                <div className="header-search-input">
                  <label>
                    <i className="fal fa-keyboard" />
                  </label>
                  <input
                    type="text"
                    placeholder="What are you looking for ?"
                    defaultValue=""
                  />
                </div>
                {/* header-search-input end */}
                {/* header-search-input */}
                <div className="header-search-input location autocomplete-container">
                  <label>
                    <i className="fal fa-map-marker" />
                  </label>
                  <input
                    type="text"
                    placeholder="Location..."
                    className="autocomplete-input"
                    id="autocompleteid2"
                    defaultValue=""
                  />
                  <Link to="#">
                    <i className="fal fa-dot-circle" />
                  </Link>
                </div>
                {/* header-search-input end */}
                {/* header-search-input */}
                <div className="header-search-input header-search_selectinpt ">
                  <select
                    data-placeholder="Category"
                    className="chosen-select no-radius"
                  >
                    <option>All Categories</option>
                    <option>All Categories</option>
                    <option>Shops</option>
                    <option>Hotels</option>
                    <option>Restaurants</option>
                    <option>Fitness</option>
                    <option>Events</option>
                  </select>
                </div>
                {/* header-search-input end */}
                <button
                  className="header-search-button green-bg"
                  onClick="window.location.to='#'"
                >
                  <i className="far fa-search" /> Search{" "}
                </button>
              </div>
              <div className="header-search_close color-bg">
                <i className="fal fa-long-arrow-up" />
              </div>
            </div>
          </div>
          {/* header-search_container  end */}
          {/* wishlist-wrap*/}
          <div className="header-modal novis_wishlist">
            {/* header-modal-container*/}
            <div
              className="header-modal-container scrollbar-inner fl-wrap"
              data-simplebar=""
            >
              {/*widget-posts*/}
              <div className="widget-posts  fl-wrap">
                <ul className="no-list-style">
                  <li>
                    <div className="widget-posts-img">
                      <Link to="#">
                        <img src="images/gallery/thumbnail/1.png" alt="" />
                      </Link>
                    </div>
                    <div className="widget-posts-descr">
                      <h4>
                        <Link to="#">
                          Iconic Cafe
                        </Link>
                      </h4>
                      <div className="geodir-category-location fl-wrap">
                        <Link to="#">
                          <i className="fas fa-map-marker-alt" /> 40 Journal
                          Square Plaza, NJ, USA
                        </Link>
                      </div>
                      <div className="widget-posts-descr-link">
                        <Link to="#">
                          Restaurants{" "}
                        </Link>{" "}
                        <Link to="#">
                          Cafe
                        </Link>
                      </div>
                      <div className="widget-posts-descr-score">4.1</div>
                      <div className="clear-wishlist">
                        <i className="fal fa-times-circle" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="widget-posts-img">
                      <Link to="#">
                        <img src="images/gallery/thumbnail/2.png" alt="" />
                      </Link>
                    </div>
                    <div className="widget-posts-descr">
                      <h4>
                        <Link to="#">
                          MontePlaza Hotel
                        </Link>
                      </h4>
                      <div className="geodir-category-location fl-wrap">
                        <Link to="#">
                          <i className="fas fa-map-marker-alt" /> 70 Bright St
                          New York, USA{" "}
                        </Link>
                      </div>
                      <div className="widget-posts-descr-link">
                        <Link to="#">
                          Hotels{" "}
                        </Link>
                      </div>
                      <div className="widget-posts-descr-score">5.0</div>
                      <div className="clear-wishlist">
                        <i className="fal fa-times-circle" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="widget-posts-img">
                      <Link to="#">
                        <img src="images/gallery/thumbnail/3.png" alt="" />
                      </Link>
                    </div>
                    <div className="widget-posts-descr">
                      <h4>
                        <Link to="#">
                          Rocko Band in Marquee Club
                        </Link>
                      </h4>
                      <div className="geodir-category-location fl-wrap">
                        <Link to="#">
                          <i className="fas fa-map-marker-alt" />
                          75 Prince St, NY, USA
                        </Link>
                      </div>
                      <div className="widget-posts-descr-link">
                        <Link to="#">
                          Events
                        </Link>{" "}
                      </div>
                      <div className="widget-posts-descr-score">4.2</div>
                      <div className="clear-wishlist">
                        <i className="fal fa-times-circle" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="widget-posts-img">
                      <Link to="#">
                        <img src="images/gallery/thumbnail/4.png" alt="" />
                      </Link>
                    </div>
                    <div className="widget-posts-descr">
                      <h4>
                        <Link to="#">
                          Premium Fitness Gym
                        </Link>
                      </h4>
                      <div className="geodir-category-location fl-wrap">
                        <Link to="#">
                          <i className="fas fa-map-marker-alt" /> W 85th St, New
                          York, USA
                        </Link>
                      </div>
                      <div className="widget-posts-descr-link">
                        <Link to="#">
                          Fitness
                        </Link>{" "}
                        <Link to="#">
                          Gym
                        </Link>{" "}
                      </div>
                      <div className="widget-posts-descr-score">5.0</div>
                      <div className="clear-wishlist">
                        <i className="fal fa-times-circle" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* widget-posts end*/}
            </div>
            {/* header-modal-container end*/}
            <div className="header-modal-top fl-wrap">
              <h4>
                Your Wishlist :{" "}
                <span>
                  <strong /> Locations
                </span>
              </h4>
              <div className="close-header-modal">
                <i className="far fa-times" />
              </div>
            </div>
          </div>
          {isModalOpen ? (
            <>
              <ModalLogin isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} isRegister={isRegister} setIsRegister={setIsRegister} />
              {/* <div
                style={{
                  position: "relative",
                  zIndex: "9",
                  backgroundColor: "black",
                }}
              ></div> */}
            </>
          ) : null}
          {isRegister ? (
            <>
              <RegisterModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} isRegister={isRegister} setIsRegister={setIsRegister} />
              {/* <div
                style={{
                  position: "relative",
                  zIndex: "9",
                  backgroundColor: "black",
                }}
              ></div> */}
            </>
          ) : null}
          {/*wishlist-wrap end */}
        </header>
      </div>
    </>
  );
}

export default Header;
