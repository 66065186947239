// Resize file function
export const resizeFile = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const maxSize = 50 * 1024; // 50KB
                const ctx = canvas.getContext('2d');
                let width = img.width;
                let height = img.height;

                // Scale image to reduce file size while maintaining aspect ratio
                if (img.width > img.height) {
                    if (img.width > 500) {
                        width = 500;
                        height = (img.height * 500) / img.width;
                    }
                } else {
                    if (img.height > 500) {
                        height = 500;
                        width = (img.width * 500) / img.height;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                    (blob) => {
                        if (blob.size <= maxSize) {
                            resolve(blob);
                        } else {
                            resolve(file); // If resizing doesn't make it small enough, return the original file
                        }
                    },
                    'image/jpeg',
                    0.7
                );
            };
        };
    });
};