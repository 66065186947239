import React from "react";
import "./header.css";
import SidebarLayout from "../Components/sidebar/SidebarLayout";
import "../styles/Profile.css";
import { ProfileFragment } from "../fragments";

function Profile() {
  return (
    <>
      <SidebarLayout>
        <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset]">
          <ProfileFragment />
        </div>
      </SidebarLayout>
    </>
  );
}

export default Profile;
