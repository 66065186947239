/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import Header from "../Header";
import { BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import TailwindHeader from "./TailwindHeader";
import TopHeader from "./TopHeader";
import TailwindFooter from "./TailwindFooter";
import { useListing } from "../../providers/ListingsProvider";
import { SERVER_IMAGES_URL } from '../../constants';

const Layout = ({ children }) => {
  const navigate = useNavigate();

  const { compareList, toggleCompare } = useListing();

  const compareListings = useCallback(async () => {
    navigate('/compare');
  }, [])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, [window.location.pathname]);

  return (
    <>
      <div>
        {/* Show TopHeader and TailwindHeader on mobile devices */}
        <div className="block lg:hidden">
          <TopHeader />
          <hr />
          <TailwindHeader />
          <hr />
        </div>

        {/* Show Header on non-mobile devices */}
        <div className="hidden lg:block">
          <Header />
        </div>
        <>
          <input type="checkbox" id="UniqueMenuToggle" />
          <aside className="unique-sidebar shadow-2xl border-0">
            <nav className="h-[fit-content] p-4">
              <h2 className="text-xl font-bold mb-4">Stays</h2>
              <div className="listing-item-grid_container overflow-scroll h-[520px] !mb-2">
                <div className="row mx-0">
                  {compareList?.map((e) => (
                    <div className="w-full mb-4" key={e.id}>
                      <div className="relative p-4 bg-white shadow rounded-lg">
                        {/* Listing Image */}
                        <div className="relative h-40 w-full rounded-lg overflow-hidden">
                          <img
                            src={
                              e?.listing_images?.[0]?.image
                                ? `${SERVER_IMAGES_URL}/listing/${e.listing_images[0].image}`
                                : e?.images?.[0]?.image
                                  ? `${SERVER_IMAGES_URL}/listing/${e.images[0].image}`
                                  : "https://via.placeholder.com/150"
                            }
                            alt={e.title || "Listing Image"}
                            className="object-cover w-full h-full"
                          />
                          {/* Listing Title Overlay */}
                          <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-40 text-white text-center py-2">
                            <span className="text-sm font-semibold text-white">{e.title}</span>
                          </div>
                        </div>

                        {/* Trash Icon */}
                        <div className="absolute top-6 right-6 bg-gray-200 bg-opacity-50 rounded-full p-2 cursor-pointer">
                          <BsTrash
                            className="text-red-500"
                            onClick={() => toggleCompare(e)}
                            size={16}
                          />
                        </div>
                      </div>
                    </div>
                  ))}


                </div>
              </div>

              {/* Buttons Section */}
              <div className="flex flex-col items-center space-y-4 my-6">
                <button
                  onClick={compareListings}
                  className="w-48 py-2 text-white bg-green-500 font-bold text-sm rounded-md hover:bg-green-600"
                >
                  Compare
                </button>

                <button
                  onClick={() => document.getElementById("UniqueMenuToggle").click()}
                  className="w-48 py-2 text-white bg-red-500 font-bold text-sm rounded-md border border-gray-300"
                >
                  Close
                </button>
              </div>
            </nav>
          </aside>

          <main className="unique-right top-[-20px] lg:top-[210px] !z-[unset]">
            <label htmlFor="UniqueMenuToggle" className="unique-toggle-icon">
              <span className="absolute top-[-14px] left-[-14px] text-xs text-gray-700 font-bold bg-[#8EC639] rounded-full p-1 w-6 h-6 text-center">
                {compareList?.length}
              </span>
              <i className="fas fa-bars text-2xl bg-black text-white px-3 py-1 rounded" />

            </label>
            <div className="unique-content">{children}</div>
          </main>
        </>
        <TailwindFooter />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Layout;
