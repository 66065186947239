import React from 'react'

function Bannerfour() {
  return (
    <>
      <section style={{ backgroundColor: "#54c4d9" }} className="gradient-bg hidden-section" data-scrollax-parent="true">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="colomn-text  pad-top-column-text fl-wrap">
                <div className="colomn-text-title">
                  <h3>OUR APP IS COMING SOON!</h3>
                  <span style={{ color: "#fff", fontSize: "15px", display: "flex", textAlign: "start" }}>
                    With our app, you'll be able to access our services quickly and efficiently from the convenience of your mobile device.
                  </span>
                  <button className=" down-btn color3-bg">
                    <i className="fab fa-apple" /> Apple Store{" "}
                  </button>
                  <button className=" down-btn color3-bg">
                    <i className="fab fa-android" /> Google Play{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="collage-image">
                <img src="images/api.png" className="main-collage-image" alt="" />

                <div
                  className="images-collage_icon green-bg"
                  style={{ right: "-20px", top: 120 }}
                >
                  <i className="fal fa-thumbs-up" />
                </div>
                <div className="collage-image-min cim_1">
                  <img src="images/api/1.jpg" alt="" />
                </div>
                <div className="collage-image-min cim_2">
                  <img src="images/api/2.jpg" alt="" />
                </div>
                <div className="collage-image-btn green-bg">Booking now</div>
                <div className="collage-image-input">
                  Search <i className="fa fa-search" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-bg-figure" style={{ right: "-30px", top: 10 }} />
        <div className="gradient-bg-figure" style={{ left: "-20px", bottom: 30 }} />
        <div
          className="circle-wrap"
          style={{ left: 270, top: 120 }}
          data-scrollax="properties: { translateY: '-200px' }"
        >
          <div className="circle_bg-bal circle_bg-bal_small" />
        </div>
        <div
          className="circle-wrap"
          style={{ right: 420, bottom: "-70px" }}
          data-scrollax="properties: { translateY: '150px' }"
        >
          <div className="circle_bg-bal circle_bg-bal_big" />
        </div>
        <div
          className="circle-wrap"
          style={{ left: 420, top: "-70px" }}
          data-scrollax="properties: { translateY: '100px' }"
        >
          <div className="circle_bg-bal circle_bg-bal_big" />
        </div>
        <div className="circle-wrap" style={{ left: "40%", bottom: "-70px" }}>
          <div className="circle_bg-bal circle_bg-bal_middle" />
        </div>
        <div className="circle-wrap" style={{ right: "40%", top: "-10px" }}>
          <div
            className="circle_bg-bal circle_bg-bal_versmall"
            data-scrollax="properties: { translateY: '-350px' }"
          />
        </div>
        <div className="circle-wrap" style={{ right: "55%", top: 90 }}>
          <div
            className="circle_bg-bal circle_bg-bal_versmall"
            data-scrollax="properties: { translateY: '-350px' }"
          />
        </div>
      </section>
      {/*section end*/}
      {/*section  */}


    </>

  )
}

export default Bannerfour