import * as Yup from 'yup';
// Yup validation schemas
export const CREDIT_CARD_SCHEMA = Yup.object().shape({
    cardNumber: Yup
        .string()
        .required("Card number is required")
        .matches(/^\d{16}$/, "Card number must be 16 digits")
        .max(16, "Card number must not exceed 16 digits"),
    cardholder: Yup.string().required("Cardholder name is required"),
    expiryDate: Yup
        .string()
        .required("Expiry date is required")
        .matches(
            /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
            "Expiry date must be in MM/YY format"
        )
        .test('is-not-expired', 'Card is expired', (value) => {
            if (value) {
                const enteredDate = value.replace('/', '');
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-indexed
                const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year

                const enteredMonth = parseInt(enteredDate.slice(0, 2), 10);
                const enteredYear = parseInt(enteredDate.slice(2, 4), 10);

                if (enteredYear < currentYear || (enteredYear === currentYear && enteredMonth < currentMonth)) {
                    return false;
                }
            }
            return true;
        }),
    cvv: Yup
        .string()
        .required("CVV is required")
        .matches(/^\d{3,4}$/, "CVV must be 3 or 4 digits"),
});

export const BANK_ACCOUNT_SCHEMA = Yup.object().shape({
    accountNumber: Yup
        .string()
        .required("Account number is required")
        .matches(/^\d{6,17}$/, "Account number must be between 6 and 17 digits"),
    routingNumber: Yup
        .string()
        .required("Routing number is required")
        .matches(/^\d{9}$/, "Routing number must be exactly 9 digits"),
    accountHolderName: Yup.string().required("Account holder name is required"),
    bankName: Yup.string().required("Bank name is required"),
});
