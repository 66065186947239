/* eslint-disable */
import React from "react";
import { FaDotCircle } from "react-icons/fa";
import { useFormContext } from "../../providers";
import { ListingFilters } from "../Listings/Filter";

const SearchLocation = () => {

  const { setValue, watch } = useFormContext();

  return (
    <>
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-7 absolute bottom-4 md:bottom-20 bg-[#ffffffcf] rounded-lg gap-2 md:gap-0 p-2 md:p-4">
          <ListingFilters />
        </div>
      </div>

      <div className="absolute top-0 w-full">
        <div className="md:flex hidden flex-wrap justify-evenly items-center px-3 bg-white md:w-[80%] mx-auto">
          <div>
            <h3 className="text-md text-nowrap font-bold !m-0">
              Distance From Runway
            </h3>
          </div>
          <button
            className={watch('closest_runway') === 0 ? 'custom-button !bg-gray-300 !w-auto' : `custom-button !w-auto`}
            onClick={() => setValue('closest_runway', 0)}
          >
            <FaDotCircle className="icon" color="#8ec639" size={"20px"} />
            <span className="button-text">0 miles away</span>
          </button>
          <button
            className={watch('closest_runway') === 1 ? 'custom-button !bg-gray-300 !w-auto' : `custom-button !w-auto`}
            onClick={() => setValue('closest_runway', 1)}
          >
            <FaDotCircle className="icon" color="#ffff00" size={"20px"} />
            <span className="button-text">Within 1 Mile</span>
          </button>
          <button
            className={watch('closest_runway') === 3 ? 'custom-button !bg-gray-300 !w-auto' : `custom-button !w-auto`}
            onClick={() => setValue('closest_runway', 3)}
          >
            <FaDotCircle className="icon" color="#f17489" size={"20px"} />
            <span className="button-text">Within 3 Miles</span>
          </button>
          <button
            className={watch('closest_runway') === 7 ? 'custom-button !bg-gray-300 !w-auto' : `custom-button !w-auto`}
            onClick={() => setValue('closest_runway', 7)}
          >
            <FaDotCircle className="icon" color="#ff9900" size={"20px"} />
            <span className="button-text">Within 7 Miles</span>
          </button>
          <button
            className={watch('closest_runway') === 8 ? 'custom-button !bg-gray-300 !w-auto' : `custom-button !w-auto`}
            onClick={() => setValue('closest_runway', 8)}
            style={{ borderRadius: "0px 0px 7px 0px" }}
          >
            <FaDotCircle className="icon" color="#52c4d7" size={"20px"} />
            <span className="button-text">Over 7 Miles away</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SearchLocation;
