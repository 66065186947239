import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './custom_stripe.css';

const validationSchema = Yup.object().shape({
  pay_you_card_name: Yup.string().required('Your name as it appears on the card is required'),
  pay_you_zip_code: Yup.string().required('ZIP / Postal code is required'),
});

export const CreditCardForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (data) => {
    if (!stripe || !elements) {
      console.error("Stripe has not loaded");
      return;
    }

    const card = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
      billing_details: {
        name: data.pay_you_card_name,
        address: {
          postal_code: data.pay_you_zip_code,
        },
      },
    });

    if (error) {
      console.error(error.message);
    } else {
      console.log("Payment method created:", paymentMethod);
      // Process payment with paymentMethod.id
    }
  };

  return (
    <div className="profile-edit-container fl-wrap block_box">
      <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
        <div className="dashboard-title">
          <h3>How You Pay Us</h3>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <label>Your name as it appears on the card</label>
            <input
              type="text"
              {...register('pay_you_card_name')}
              placeholder="Your name as it appears on the card"
            />
            {errors.pay_you_card_name && <span className="error-text">{errors.pay_you_card_name.message}</span>}
          </div>

          <div className="col-sm-12 mt-3">
            <label>Card Number</label>
            <div className="stripe-input col-sm-12">
              <CardNumberElement
                options={{
                  style: {
                    base: { fontSize: "16px", color: "#424770", "::placeholder": { color: "#aab7c4" } },
                    invalid: { color: "#9e2146" },
                  },
                }}
              />
            </div>
          </div>

          <div className="col-sm-6 mt-3">
            <label>Expiration Date</label>
            <div className="stripe-input col-sm-12">
              <CardExpiryElement
                options={{
                  style: {
                    base: { fontSize: "16px", color: "#424770", "::placeholder": { color: "#aab7c4" } },
                    invalid: { color: "#9e2146" },
                  },
                }}
              />
            </div>
          </div>

          <div className="col-sm-6 mt-3">
            <label>CVV</label>
            <div className="stripe-input col-sm-12">
              <CardCvcElement
                options={{
                  style: {
                    base: { fontSize: "16px", color: "#424770", "::placeholder": { color: "#aab7c4" } },
                    invalid: { color: "#9e2146" },
                  },
                }}
              />
            </div>
          </div>

          <div className="col-sm-12 mt-3">
            <label>ZIP / Postal code</label>
            <input
              type="text"
              {...register('pay_you_zip_code')}
              placeholder="ZIP / Postal code"
            />
            {errors.pay_you_zip_code && <span className="error-text">{errors.pay_you_zip_code.message}</span>}
          </div>
        </div>

        <div className="form-actions">
          <button type="submit" className="btn text-white">
            <span className='text-white'>Create Card Entry</span>
          </button>
        </div>
      </form>
    </div>
  );
};
