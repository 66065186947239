import React from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useListing } from "../../providers";
import { SNAZZY_MAP_STYLES } from "../../constants";

export const DetailsMap = () => {
    const { listingDetails } = useListing();

    // needs these missing in response
    const {
        longitude = 0,
        latitude = 0
    } = listingDetails;


    const defaultCenter = { lat: 26.75, lng: 39.45 };
    const center = {
        lat: parseFloat(latitude) || defaultCenter.lat,
        lng: parseFloat(longitude) || defaultCenter.lng,
    };


    return (
        <div className="map-section flex flex-col ">
            <div>
                <h2 className="text-xl font-bold pb-6">Map</h2>
            </div>
            <div className="box-widget">
                <div className="map-container mb-5">
                    <div style={{ height: "100%", width: "100%" }}>
                        <GoogleMap
                            mapContainerStyle={{
                                height: "300px",
                                width: "100%",
                                marginBottom: "30px",
                            }}
                            center={center}
                            zoom={10}
                            options={{
                                styles: SNAZZY_MAP_STYLES, // Apply the Airbnb-like style
                            }}
                        >
                            <MarkerF position={center} />
                        </GoogleMap>
                    </div>
                </div>
            </div>
        </div>
    );
};
