import * as Yup from 'yup';
import { prohibitedPatterns } from '../../helpers';
import { RESERVED_WORDS, RESTRICTED_DOMAINS } from '../../constants';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const PROFILE_VALIDATION_SCHEMA = Yup.object().shape({
    // Personal Information
    first_name: Yup.string()
        .matches(/^[A-Za-z'-]+$/, 'Name can only contain letters, apostrophes, and hyphens')
        .min(2, 'Name must be between 2 and 50 characters')
        .max(50, 'Name must be between 2 and 50 characters')
        .required('First Name is required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z'-]+$/, 'Name can only contain letters, apostrophes, and hyphens')
        .min(2, 'Name must be between 2 and 50 characters')
        .max(50, 'Name must be between 2 and 50 characters')
        .required('Last Name is required'),
    username: Yup.string()
        .matches(/^[A-Za-z0-9-_\.]+$/, 'Username can only contain letters, numbers, hyphens, underscores, and periods.')
        .min(3, 'Username must be between 3 and 16 characters.')
        .max(16, 'Username must be between 3 and 16 characters.')
        .notOneOf(RESERVED_WORDS, 'This username contains a reserved word and cannot be used')
        .test(
            'different-from-given-name',
            'Username and Display Name cannot be the same.',
            function (value) {
                const { given_name } = this.parent; // Access the other field in the schema
                return value !== given_name; // Ensure username is not the same as given_name
            }
        )
        .required('Username is required'),
    given_name: Yup.string()
        .matches(/^[A-Za-z0-9._-]+$/, 'Display name can only contain letters, numbers, hyphens (-), underscores (_), or periods (.)')
        .min(3, 'Display name must be between 3 and 16 characters long.')
        .max(16, 'Display name must be between 3 and 16 characters long.')
        .test(
            'no-first-name-substring',
            'The given_name cannot contain your first_name as a substring.',
            function (value) {
                const { first_name } = this.parent;
                return !value.includes(first_name);
            }
        )
        .test(
            'no-username-substring',
            'The given_name cannot contain your username as a substring.',
            function (value) {
                const { username } = this.parent;
                return !value.includes(username);
            }
        )
        .required('Display Name is required'),
    native_language: Yup.string()
        .required('Native Language is required')
        .test(
            'no-duplicate-languages',
            'You cannot enter the same language twice',
            function (value) {
                const { native_language, other_language } = this.parent;
                return !(native_language && other_language && native_language === other_language);
            }
        ),
    other_language: Yup.string().required('Other Language is required'),
    phone: Yup.string()
        .test('is-valid-phone', 'Please enter a valid phone number', (value) =>
            isValidPhoneNumber(value || '')
        )
        .required('Phone number is required')
        .max(15, 'Phone number cannot exceed 15 characters'), // Restrict length
    other_phone: Yup.string()
        .test('is-valid-phone', 'Please enter a valid phone number', (value) =>
            isValidPhoneNumber(value || '')
        )
        .required('Other Phone number is required')
        .max(15, 'Phone number cannot exceed 15 characters')
        .notOneOf([Yup.ref('phone')], 'Other phone number cannot be the same as the primary phone number'),
    email: Yup.string()
        .email('Invalid email format.')
        .min(5, 'Email must be at least 5 characters long.')
        .max(254, 'Email must be less than 254 characters long.')
        .test(
            'no-restricted-domains',
            'Disposable email addresses are not allowed.',
            (value) => !RESTRICTED_DOMAINS.includes(value?.split('@')[1])
        )
        .required('Email is required'),
    additional_email: Yup.string()
        .email('Invalid email')
        .notOneOf([Yup.ref('email')], 'Additional email cannot be the same as the primary email'),
    bio: Yup.string()
        .min(10, 'Bio must be at least 10 characters long.')
        .test(
            'must-contain-letter-number',
            'Bio must contain at least one letter and one number.',
            (value) => /[a-zA-Z]/.test(value || '') && /[0-9]/.test(value || '')
        )
        .required('Bio is required'),

    // Documents
    air_men: Yup.mixed().required('Airmen Certificate Front is required'),
    air_men_back: Yup.mixed().required('Airmen Certificate Back is required'),
    driving_license: Yup.mixed().required('Driving License Front is required'),
    driving_license_back: Yup.mixed().required('Driving License Back is required'),

    // Address Information
    address: Yup.string().required('Street Address is required'),
    unit_no: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string()
        .required('ZIP / Postal code is required')
        .matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code'),
    neighbourhood: Yup.string(),
    country: Yup.string().required('Country is required'),
    same_as_mailing: Yup.boolean(),

    // Emergency Contact
    contact_name: Yup.string().required('Contact Name is required'),
    contact_relationship: Yup.string()
        .test(
            'no-inappropriate-content',
            'Relationship contains inappropriate content.',
            (value) => !prohibitedPatterns.some(({ pattern }) => pattern.test(value || ''))
        )
        .required('Relationship is required'),
    contact_email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    contact_phone: Yup.string()
        .test('is-valid-phone', 'Please enter a valid phone number', (value) =>
            isValidPhoneNumber(value || '')
        )
        .required('Other Phone number is required')
        .max(15, 'Phone number cannot exceed 15 characters'),
    // Social Media URLs
    facebook_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid Facebook URL')
        .nullable(),
    twitter_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid Twitter URL')
        .nullable(),
    linkedin_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid LinkedIn URL')
        .nullable(),
    google_plus_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?plus\.google\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid Google Plus URL')
        .nullable(),
    instagram_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid Instagram URL')
        .nullable(),
    pinterest_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?pinterest\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid Pinterest URL')
        .nullable(),
    youtube_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?youtube\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid YouTube URL')
        .nullable(),
    vimeo_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?vimeo\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid Vimeo URL')
        .nullable(),
    airbnb_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?airbnb\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid Airbnb URL')
        .nullable(),
    top_advisor_url: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?topadvisor\.com\/[a-zA-Z0-9(.?)?]/, 'Invalid Trip Advisor URL')
        .nullable(),

    // Image Upload
    image: Yup.mixed()
        .required('Image is required')
        .test('fileSize', 'File size is too large (max 3.1 MB)', (value) => {
            return value && value[0] && value[0].size <= 3.1 * 1024 * 1024;
        })
        .test('fileType', 'Unsupported file format', (value) => {
            return (
                value &&
                value[0] &&
                ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(value[0].type)
            );
        }),
});
