import axios from "axios";
import BASE_URL from "../Components/auth/Baseurl";
import { errorPopup } from "./swirlfire";

// Axios instance without token
export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

// Axios instance with token
export const axiosInstanceWithToken = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

// Interceptor to add token dynamically for the instance with token
axiosInstanceWithToken.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token"); // Retrieve token from localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // Handle request errors
        errorPopup("Request Error:", error.message);
        return Promise.reject(error);
    }
);

// Response interceptor for error handling
axiosInstanceWithToken.interceptors.response.use(
    (response) => {
        // Return response if successful
        return response;
    },
    (error) => {
        // Handle response errors
        if (error.response) {
            // Server responded with a status other than 2xx
            errorPopup("", error.response.data.message === 'Unauthenticated.' ? 'Please login first!' : error.response.data.message);
        } else if (error.request) {
            // No response received
            errorPopup("", error.request);
        } else {
            // Something else happened while setting up the request
            errorPopup("Error:", error.message);
        }
        return Promise.reject(error);
    }
);

// Adding similar error handling for axiosInstance (without token)
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            errorPopup("Response Error:", error.response.data.message);
        } else if (error.request) {
            errorPopup("No Response:", error.request);
        } else {
            errorPopup("Error:", error.message);
        }
        return Promise.reject(error);
    }
);
