// FormProvider.jsx
import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import axios from 'axios';
import BASE_URL from '../../Components/auth/Baseurl';
import { axiosInstanceWithToken } from '../../helpers/axios';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useAuthHandler } from '../../hooks/AuthHandlers';
import { resizeFile } from '../../helpers/fileResizer';

const formatPhoneForAPI = (phone) => {
    const phoneNumber = parsePhoneNumber(phone, 'US');
    return phoneNumber ? phoneNumber.format('E.164') : phone; // E.164 format or original value if invalid
};


// Create the context
const FormContext = createContext();

// Custom hook to use the form context
const useFormContext = () => useContext(FormContext);

const FormProvider = ({ children, validationSchema, defaultValues, type }) => {
    // Initialize react-hook-form with Yup validation schema
    const [formData, setFormData] = useState(defaultValues);
    const { getCurrentUser } = useAuthHandler();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        watch,
        getValues,
        trigger
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: formData,
    });


    const fieldRefs = useRef({});


    // useEffect(() => {
    //     if (errors) {
    //         const firstErrorField = Object.keys(errors)[0];

    //         // Check if errors[firstErrorField] is an array
    //         if (Array.isArray(errors[firstErrorField])) {

    //             // Loop through the array of errors for the specific field
    //             for (let i = 0; i < errors[firstErrorField].length; i++) {
    //                 const fieldError = errors[firstErrorField][i];

    //                 // Find the first field with an error in the array (this assumes each element in the array has a field)
    //                 const firstErrorFieldInArray = Object.keys(fieldError).find((field) => fieldError[field]);

    //                 if (firstErrorFieldInArray) {
    //                     // Construct the key for the error field
    //                     const fieldKey = `${firstErrorField}.${i}.${firstErrorFieldInArray}`;
    //                     const fieldRef = fieldRefs.current[fieldKey];

    //                     if (fieldRef) {
    //                         fieldRef.scrollIntoView({
    //                             behavior: "smooth",
    //                             block: "center",
    //                         });
    //                         fieldRef.focus();
    //                         break;  // Focus on the first error field and stop further iterations
    //                     }
    //                 }
    //             }
    //         } else {
    //             // Handle the case when errors[firstErrorField] is not an array
    //             const fieldRef = fieldRefs.current[firstErrorField];
    //             if (fieldRef) {
    //                 fieldRef.scrollIntoView({
    //                     behavior: "smooth",
    //                     block: "center",
    //                 });
    //                 fieldRef.focus();
    //             }
    //         }
    //     }
    // }, [errors, fieldRefs]);



    const setMultipleValues = (values) => {
        Object.entries(values).forEach(([key, value]) => {
            setValue(key, value);  // Sets each field using setValue
        });
    };

    // Generalized handleChange function
    const handleChange = useCallback((e) => {
        const { name, value, type = 'text', checked } = e.target;
        if (type.toString() === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
            setValue(name, checked);
        } else if (name === 'welcome_message_instructions' || name === 'rules_instructions') {
            const newName = name.replace('_instructions', '');
            setFormData((prev) => ({ ...prev, [newName]: getValues(newName) + value }));
            setValue(newName, getValues(newName) + value);
        }
        else {
            // console.log(name, value)
            setFormData((prev) => ({ ...prev, [name]: value }));
            setValue(name, value);
        }
        trigger(name);

    }, [getValues, setValue, trigger]);

    const appendFormData = useCallback((finalData, data, parentKey = '') => {
        // Skip null, undefined, or empty string
        if (data === null || data === undefined || (typeof data === 'string' && data.trim() === '')) {
            return;
        }

        if (Array.isArray(data)) {
            // Handle arrays recursively
            data.forEach((item, index) => {
                appendFormData(finalData, item, `${parentKey}[${index}]`);
            });
        } else if (data instanceof Date) {
            // Handle Date objects correctly
            finalData.append(parentKey, data.toISOString());
        } else if (data instanceof File || data instanceof Blob) {
            // Handle File or Blob objects correctly
            finalData.append(parentKey, data);
        } else if (typeof data === 'object' && data !== null) {
            // Handle objects recursively
            for (const key in data) {
                if (data.hasOwnProperty(key)) {

                    if (key === 'data_url') continue;
                    const value = data[key];
                    if (value !== null && value !== undefined && !(typeof value === 'string' && value.trim() === '')) {
                        const newKey = parentKey ? `${parentKey}[${key}]` : key;
                        appendFormData(finalData, value, newKey);
                    }
                }
            }
        } else {
            // Handle primitives (string, number, boolean)
            finalData.append(parentKey, data);
        }
    }, []);

    // Handle form submission
    const onSubmit = useCallback(async (data, event) => {
        try {
            event.preventDefault();
            // Create a new FormData object
            const finalData = new FormData();

            // console.log(data)

            let updatedImages = data?.images.map(image => {
                const { data_url, ...rest } = image; // Destructure to exclude `data_url`
                return rest;
            });

            data?.airports?.forEach((airport) => {
                airport.surface = airport?.surface?.join(", ");
                airport.fuel = airport?.fuel?.join(", ");
                airport.ctaf_unicom = parseFloat(airport?.ctaf_unicom);
            });

            updatedImages.forEach((image, index) => {
                if (image?.file instanceof File) {
                    if (image.file) {
                        finalData.append(`images[${index}][file]`, image.file);
                    }
                    finalData.append(`images[${index}][description]`, image.description || 'No Name');
                }
            });


            const updatedData = { ...data, host_id: JSON.parse(localStorage.getItem('data'))?.id, instant_booking: getValues('instant_booking') === true ? 1 : 0 }


            if (+getValues('custom_period_pricing') === 0) {
                delete updatedData.custom_periods;
            }
            if (+getValues('extra_services') === 0) {
                delete updatedData.extra_service;
            }
            appendFormData(finalData, updatedData);

            // -----------------------------------------------------------------------------------------------------------

            try {
                await axios.post(`${BASE_URL}/listing`, finalData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });

                Swal.fire({
                    position: "center",
                    html: `
                      <div style="display: flex; flex-direction: column; align-items: center;">
                            <svg
                                viewBox="0 0 1024 1024"
                                height="4em"
                                width="4em"
                                style="margin-bottom: 10px;"
                            >
                                <!-- Outer circle with color #8ec639 -->
                                <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                
                                <!-- Check mark with color #A2c66b -->
                                <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                            </svg>
                          <p style="margin: 0; font-size: 1.2em;"> Listing Created Successfully.</p>
                      </div>
                  `,
                    title:
                        "Your changes have been submitted to Admin. Please stand by for approval.",
                    showConfirmButton: true,
                    timer: 1500,
                });
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Please complete all required fields.",
                    text: error?.responsformData?.data?.message,
                });
            }

            // ------------------------------------------------------------------------------------------------------------

            setFormData(updatedData);
            // console.log('Submitted Data:', updatedData);

        } catch (err) {
            console.log(err)
        }
    }, [appendFormData, getValues]);

    const onProfileSubmit = useCallback(async (data, event) => {
        try {
            event.preventDefault();
            // Create a new FormData object and append only the necessary fields
            const finalData = new FormData();
            // Format phone numbers
            const formattedPhone = formatPhoneForAPI(data.phone);
            const formattedOtherPhone = formatPhoneForAPI(data.other_phone);

            finalData.append("first_name", data.first_name);
            finalData.append("last_name", data.last_name);
            finalData.append("username", data.username);
            finalData.append("given_name", data.given_name);
            finalData.append("email", data.email);
            finalData.append("phone", formattedPhone);
            finalData.append("other_phone", formattedOtherPhone);
            finalData.append("native_language", data.native_language);
            finalData.append("other_language", data.other_language);
            finalData.append("additional_email", data.additional_email);
            finalData.append("bio", data.bio);
            finalData.append("address", data.address);
            finalData.append("unit_no", data.unit_no);
            finalData.append("city", data.city);
            finalData.append("state", data.state);
            finalData.append("zip_code", data.zip_code);
            finalData.append("country", data.country);
            finalData.append("neighbourhood", data.neighbourhood);
            finalData.append("contact_name", data.contact_name);
            finalData.append("contact_relationship", data.contact_relationship);
            finalData.append("contact_email", data.contact_email);
            finalData.append("contact_phone", formatPhoneForAPI(data.contact_phone));
            finalData.append("facebook_url", data.facebook_url || '');
            finalData.append("instagram_url", data.instagram_url || '');
            finalData.append("youtube_url", data.youtube_url || '');
            finalData.append("twitter_url", data.twitter_url || '');
            finalData.append("linkedin_url", data.linkedin_url || '');
            finalData.append("pinterest_url", data.pinterest_url || '');
            finalData.append("vimeo_url", data.vimeo_url || '');
            finalData.append("airbnb_url", data.airbnb_url || '');
            finalData.append("top_advisor_url", data.top_advisor_url || '');
            finalData.append("_method", "PUT");

            if (typeof data?.image !== 'string')
                finalData.append("image", await resizeFile(data.image[0]));


            if (data?.air_men instanceof File) {
                finalData.append("air_men", await resizeFile(data.air_men));
            } else if (data?.air_men instanceof FileList) {
                finalData.append("air_men", await resizeFile(data.air_men[0]));
            }

            if (data?.air_men_back instanceof File) {
                finalData.append("air_men_back", await resizeFile(data.air_men_back));
            } else if (data?.air_men_back instanceof FileList) {
                finalData.append("air_men_back", await resizeFile(data.air_men_back[0]));
            }

            if (data?.driving_license instanceof File) {
                finalData.append("driving_license", await resizeFile(data.driving_license));
            } else if (data?.driving_license instanceof FileList) {
                finalData.append("driving_license", await resizeFile(data.driving_license[0]));
            }

            if (data?.driving_license_back instanceof File) {
                finalData.append("driving_license_back", await resizeFile(data.driving_license_back));
            } else if (data?.driving_license_back instanceof FileList) {
                finalData.append("driving_license_back", await resizeFile(data.driving_license_back[0]));
            }


            await axiosInstanceWithToken.post(`/profile`, finalData).then(async (res) => {
                await getCurrentUser().then(response => {
                    localStorage.setItem('data', JSON.stringify(response?.data?.user))
                })
            });

            Swal.fire({
                position: "center",
                html: `
                      <div style="display: flex; flex-direction: column; align-items: center;">
                            <svg
                                viewBox="0 0 1024 1024"
                                height="4em"
                                width="4em"
                                style="margin-bottom: 10px;"
                            >
                                <!-- Outer circle with color #8ec639 -->
                                <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                
                                <!-- Check mark with color #A2c66b -->
                                <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                            </svg>
                          <p style="margin: 0; font-size: 1.2em;"> Profile Updated Successfully.</p>
                      </div>
                  `,
                title:
                    "Profile has Successfully been updated!",
                showConfirmButton: true,
                timer: 1500,
            });

        } catch (err) {
            Swal.fire({
                icon: "error",
                title: "Please complete all required fields.",
                text: err?.data?.message,
            });
        }
    }, [getCurrentUser]);

    // Handle draft saving (e.g., saving the form data to localStorage)
    const handleDraft = useCallback(async () => {

        const data = { ...getValues(), host_id: JSON.parse(localStorage.getItem('data'))?.id, is_draft: 1, instant_booking: getValues('instant_booking') === true ? 1 : 0 };
        if (getValues('custom_period_pricing') === 0) {
            delete data.custom_periods;
        }
        if (getValues('extra_services') === 0) {
            delete data.extra_service;
        }

        const finalData = new FormData();
        // Populate the FormData object with the key-value pairs from data
        // for (const key in data) {
        //     if (data.hasOwnProperty(key)) {
        //         finalData.append(key, data[key]);
        //     }
        // }

        appendFormData(finalData, data);

        // console.log("Original Data", data);

        // console.log(finalData);

        try {
            await axios.post(`${BASE_URL}/listing`, finalData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            Swal.fire({
                position: "center",
                html: `
                  <div style="display: flex; flex-direction: column; align-items: center;">
                        <svg
                            viewBox="0 0 1024 1024"
                            height="4em"
                            width="4em"
                            style="margin-bottom: 10px;"
                        >
                            <!-- Outer circle with color #8ec639 -->
                            <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                            
                            <!-- Check mark with color #A2c66b -->
                            <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                        </svg>
                      <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
                  </div>
              `,
                title:
                    "Your changes have been submitted to Admin. Please stand by for approval.",
                showConfirmButton: true,
                timer: 1500,
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Please complete all required fields.",
                text: error?.responsformData?.data?.message,
            });
        }


        // localStorage.setItem('formDraft', JSON.stringify(data));
        // console.log('Draft saved:', data);
    }, [appendFormData, getValues]);

    // Context value to provide to children
    const contextValue = {
        register,
        handleSubmit: handleSubmit(onSubmit),
        handleChange,
        onProfileSubmit,
        handleDraft,
        setValue,
        errors,
        formData,
        reset,
        watch,
        trigger,
        getValues,
        setMultipleValues,
        fieldRefs
    };

    return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

export { FormProvider, useFormContext };
