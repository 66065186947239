/* eslint-disable */
import React, { useEffect, useState, useMemo, useContext, useCallback } from "react";
import BASE_URL from "../Components/auth/Baseurl";
import { Link } from "react-router-dom";
import Loading from "./loader/Loading"; // Import Loader component
import { toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../context/ContextFile";
import Swal from "sweetalert2";
import { errorPopup, successPopup, warningPopup } from "../helpers";
import { useListing } from "../providers";
import { axiosInstanceWithToken } from "../helpers/axios";
import { useProfile } from "../hooks";
import { SERVER_IMAGES_URL } from "../constants";

const List = () => {
  const [loading, setLoading] = useState(true);
  const [pageCome, setPageCome] = useState(true);
  const path = useNavigate();
  const [activeButton, setActiveButton] = useState("All");
  const { profileCompleteness } = useProfile();

  const { getListingsByUser } = useListing();
  const [page, setPage] = useState(1);
  const [listings, setListings] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const loadListings = useCallback(async () => {
    if (!hasMore) return;
    setLoading(true);
    try {
      let params = {
        page,
        per_page: 10,
      };

      switch (activeButton) {
        case 'All':
          break;
        case 'Published':
          params.is_published = 1;
          break;
        case 'Drafts':
          params.is_draft = 1;
          break;
        case 'Pending':
          params.is_published = 0;
          break;
        case 'Disabled':
          params.is_disabled = 1;
          break;
      }

      const res = await getListingsByUser(params);
      if (res?.data?.listings?.length > 0) {
        setListings((prevListings) => {
          const newListings = [...prevListings, ...res?.data?.listings];
          return Array.from(new Map(newListings.map((item) => [item?.id, item])).values());
        });
        setPage(res?.data?.current_page);
      } else {
        setHasMore(res?.data?.current_page === res?.data?.last_page);
      }
    } catch (error) {
      console.error("Error fetching listings:", error);
    } finally {
      setLoading(false);
    }
  }, [page, activeButton, hasMore]);

  useEffect(() => {
    setListings([]);
    setPage(1);
    setHasMore(true);
    loadListings();
  }, [activeButton]);

  useEffect(() => {
    if (pageCome) {
      Swal.fire({
        icon: "info",
        text: "Please scroll right and left to view more columns.",
        showConfirmButton: true,
      }).then(() => {
        setPageCome(false);
      });
    }
  }, []);


  const handleDelete = async (id) => {
    try {
      const response = await axiosInstanceWithToken.delete(`${BASE_URL}/listing/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response?.status) {
        successPopup('', response?.message)
      }

      setListings((prevListings) =>
        prevListings.filter((listing) => listing.id !== id)
      );
    } catch (error) {
      toast.error();
      errorPopup('', "Error deleting item")
    }
  };


  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 100,
      },
      {
        accessorKey: "images",
        header: "Image",
        size: 100,
        Cell: ({ row }) => (
          <img
            src={row?.original?.host?.image !== null ? `${SERVER_IMAGES_URL}/profile/${row?.original?.host?.image}` : "images/avatar/avatar-bg.png"}
            alt="Host"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        ),
      },

      {
        accessorKey: "title",
        header: "Title",
        size: 100,
      },
      {
        accessorKey: "listing_type",
        header: "Listing Type",
        size: 150,
      },
      {
        accessorKey: "lodging_type",
        header: "Lodging Type",
        size: 150,
      },
      {
        accessorKey: "type_of_space",
        header: "Type of Space",
        size: 150,
      },
      {
        accessorKey: "nightly_price",
        header: "Price",
        size: 100,
        Cell: ({ row }) => {
          return `$${row.original.nightly_price}`;
        }
      },
      {
        accessorKey: "no_of_bedrooms",
        header: "Bedrooms",
        size: 100,
      },
      {
        accessorKey: "no_of_bathrooms",
        header: "Bathrooms",
        size: 100,
      },
      {
        accessorKey: "no_of_guest",
        header: "Guests",
        size: 100,
      },

      {
        accessorKey: "status",
        header: "Status",
        size: 150,
        Cell: ({ row }) => {
          const status = row.getValue("status");
          const is_draft = row.original.is_draft;
          const is_featured = row.original.is_featured;
          const is_disable = row.original.is_disable;

          // Dynamically setting button background color based on status
          const getButtonStyle = () => {
            if (is_draft === 1)
              return { background: "yellow", color: "black" };
            else if (is_featured === 1)
              return { background: "#54c4d9", color: "white" };
            else if (status === 1)
              return { background: "#8ec639", color: "white" };
            else if (is_disable === 1)
              return { background: "red", color: "white" };
            else
              return { background: "#54C4D9", color: "white" };
          };

          const getStatus = () => {
            if (is_draft === 1)
              return 'Draft';
            else if (is_featured === 1)
              return "Approved"
            else if (status === 1)
              return 'Approved';
            else if (is_disable === 1)
              return 'DEACTIVATED';
            else
              return 'Waiting for Approval';

          };

          return (
            <div>
              <button
                className="truncate"
                style={{
                  ...getButtonStyle(),
                  padding: "7px 10px",
                  borderRadius: "5px",
                }}
              >
                {getStatus()}
              </button>
            </div>
          );
        },
      },

      {
        accessorKey: "action",
        header: "Actions",
        size: 200,
        Cell: ({ row }) => {
          return (
            <label className="dropdown_listing">
              <div className="dd-button">Actions</div>

              <input type="checkbox" className="dd-input" id="test" />

              <ul className="dd-menu">
                <li
                  onClick={() => {
                    window.open(
                      `/detail-my-listing/${row.original.id}`,
                      "_blank"
                    );
                  }}
                >
                  View
                </li>
                <li>
                  <Link
                    to={`/addlisting`}
                    state={{ listId: row.original.id }}
                    className="hover:underline text-black"
                  >
                    Edit
                  </Link></li>

                <li>
                  <Link
                    to={`/addlisting`}
                    state={{ listId: row.original.id, isDuplicate: true }}
                    className="hover:underline text-black"
                  >
                    Duplicate
                  </Link>
                </li>
                <li>Update to featured</li>
                <li>Deactivate</li>
                <li onClick={() => handleDelete(row?.original?.id)}>Delete</li>
              </ul>
            </label>
          )
        },
      },
    ],
    [path]
  );

  const [show, setShow] = useState(false);
  const { listingValue, setListingValue } = useContext(ThemeContext);

  const handleOpenModal = () => {
    if (profileCompleteness === 100) {
      setShow(true);
    } else {
      setTimeout(() => {
        warningPopup(`
          <div>
            <p style="text-align: center">Please complete your profile to 100%. You will not be able to host or book until it is complete.</p>
            <p style="text-align: center">
             Once we have verified your identity, you will receive a message letting you know you are all set.
            </p>
            <p style="text-align: center">
             To complete your profile properly, watch the Registration video on our <br/> <a  target="_blank" style="color: #af2322; text-decoration: underline;" href="https://youtube.com/@fly-inn">YouTube Channel</a>.
            </p>
          </div>
          `);
      }, 1000);
    }
  };

  const handleClose = () => {
    if (listingValue) {
      localStorage.setItem("listingValue", listingValue);
      setShow(false);
      path('/addlisting', { state: { listing_type: listingValue } });
      // path("/addlisting");
    }
  };

  const buttonLabels = ["All", "Published", "Drafts", "Pending", "Deactivated"];


  return (
    <>
      {show && (
        <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full z-10">
            {/* Close button */}
            <div className="text-xl font-semibold px-6 py-4 border-b border-gray-200 flex justify-between">
              <span>Listing Type</span>
              <button
                onClick={() => setShow(false)}
                className="z-[50] w-6 h-6 group hover:text-gray-500 rounded-full shadow-md hover:bg-gray-100 bg-gray-300 flex items-center justify-center relative right-0 top-0"
              >
                <i className="fas fa-times text-white group-hover:text-gray-500"></i>
              </button>
            </div>

            <div className="px-6 py-4">
              <div className="w-full">
                <div className="mb-4">
                  <select
                    value={listingValue}
                    onChange={(e) => setListingValue(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500"
                  >
                    <option disabled value="">
                      All categories
                    </option>
                    <option value={"Short-Term Rental"}>Short-Term Rental</option>
                    <option disabled value={"Adventures"}>
                      Adventure (Coming Soon)
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="px-6 py-4 border-t border-gray-200 flex justify-end w-full">
              <button
                onClick={handleClose}
                className="bg-[#8ec639] text-white px-4 py-2 rounded-md hover:bg-[#7db132]"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}


      {loading ? (
        <Loading />
      ) : (
        <div className="px-4">
          <div style={{ textAlign: "center" }} className="dashboard-title">
            <h3 style={{ color: "#3b4249" }}>My Listings</h3>
          </div>
          <div className="flex my-5">
            <button
              onClick={handleOpenModal}
              className="flex justify-center items-center bg-[#54c4d9] text-md font-medium cursor-pointer text-white py-3 px-8 rounded-md"
            >
              <FaPlus />
              New Listing
            </button>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex gap-4 flex-wrap mb-4">
              {buttonLabels.map((label) => (
                <button
                  key={label}
                  className={`listing_btn ${activeButton === label ? "active" : ""} w-full sm:w-[100px] text-center`}
                  onClick={() => setActiveButton(label)}
                >
                  {label}
                </button>
              ))}

            </div>
          </div>
          <div className="overflow-x-auto max-w-full">
            <MaterialReactTable
              columns={columns}
              data={listings}
              muiPaginationProps={{
                rowsPerPageOptions: [
                  { value: 10, label: "10" },
                  { value: 25, label: "25" },
                  { value: 50, label: "50" },
                  { value: 100, label: "100" },
                  { value: listings?.length, label: "All" },
                ],
                showFirstButton: false,
                showLastButton: false,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default List;
