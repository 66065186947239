import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useListing } from "../../providers";

export default function WPCalender(type = 'display') {
    const { listingDetails } = useListing();
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: moment().add(1, "month").toDate(),
            key: "selection",
        },
    ]);
    const [blockedDates, setBlockedDates] = useState(listingDetails?.blocked_dates || []);

    useEffect(() => {
        const mappedDates = listingDetails?.blocked_dates?.map((dateRange) => ({
            startDate: moment(dateRange.start_date).toDate(),
            endDate: moment(dateRange.end_date).toDate(),
            key: `blocked-${dateRange.id}`, // Unique key for each range
        }));

        setBlockedDates(mappedDates);
    }, [listingDetails]);

    const getStatus = (date) => {
        const { isStart, isEnd } = isRangeBoundary(date);

        // Check if the date is not a boundary (start or end), and it lies between any blocked range
        if (
            !isStart &&
            !isEnd &&
            blockedDates.some((blocked) =>
                moment(date).isBetween(moment(blocked.startDate), moment(blocked.endDate), "day", "[]")
            )
        ) {
            return "booked"; // Mark as booked if it's between a blocked range and not a boundary
        }
        return "available"; // Otherwise, it's available
    };

    const isRangeBoundary = (date) => {
        const isStart = blockedDates.some((blocked) =>
            moment(date).isSame(moment(blocked.startDate), "day")
        );

        const isEnd = blockedDates.some((blocked) =>
            moment(date).isSame(moment(blocked.endDate), "day")
        );

        const isInRange = blockedDates.some((blocked) =>
            moment(date).isBetween(moment(blocked.startDate), moment(blocked.endDate), "day", "[]")
        )

        // Check if the date is inside a blocked range but not the start or end
        const isInRangeNotBoundary = blockedDates.some((blocked) =>
            moment(date).isBetween(moment(blocked.startDate), moment(blocked.endDate), "day", "[]") &&
            !moment(date).isSame(moment(blocked.startDate), "day") &&
            !moment(date).isSame(moment(blocked.endDate), "day")
        );

        return { isStart, isEnd, isInRange, isInRangeNotBoundary };
    };


    const renderDayContents = (day) => {
        const date = moment(day);
        const status = getStatus(date);
        const { isStart, isEnd, isInRangeNotBoundary } = isRangeBoundary(date);

        const isPast = date.isBefore(moment(), "day");
        const isToday = date.isSame(moment(), "day");
        const statusStyles = {
            available: { backgroundColor: "#e4f4db" },
            pending: { backgroundColor: "#fef7da" },
            booked: { backgroundColor: "#fde2e1" },
            past: { backgroundColor: "#eef0f2" },
            today: { backgroundColor: "#e4f4db" }, // Blue background with white text
        };

        // Apply base styles
        const style = isToday
            ? statusStyles.today
            : isPast
                ? statusStyles.past
                : statusStyles[status];

        // Add clip-path dynamically for start and end of range
        const clipPathStyle = isStart
            ? { clipPath: "polygon(98.98% -11.43%, 100% 97.14%, 3.99% 96.91%)" }
            : isEnd
                ? { clipPath: "polygon(0px 0px, 103% 0px, 52.88% 42.86%, -3.99% 100%)" } // Example for end
                : {};

        return (
            <>
                <div
                    style={{
                        ...style,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {date.date()}
                </div>

                {((isStart || isEnd) && !isInRangeNotBoundary) && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            textAlign: 'center',
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#fde2e1",
                            ...clipPathStyle, // Merge the clip-path style
                            pointerEvents: "none", // Ensure it doesn't block interactions
                        }}
                    >
                        {date.date()}
                    </div>
                )}

                {((isStart || isEnd) && isInRangeNotBoundary) && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            textAlign: 'center',
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#fde2e1",
                            pointerEvents: "none", // Ensure it doesn't block interactions
                        }}
                    >
                        {date.date()}
                    </div>
                )}
            </>
        );
    };


    return (
        <div className="calendar-container">
            <DateRange
                ranges={range}
                onChange={(item) => setRange([item.selection])}
                showDateDisplay={false}
                months={2}
                direction="horizontal"
                minDate={new Date()}
                dayContentRenderer={renderDayContents}
            />
            <div className="legend">
                <div className="legend-item">
                    <span className="legend-color available" />
                    Available
                </div>
                <div className="legend-item">
                    <span className="legend-color pending" />
                    Pending
                </div>
                <div className="legend-item">
                    <span className="legend-color booked" />
                    Booked
                </div>

            </div>
        </div>
    );
}
