import React, { useContext, useState } from "react";
import { ThemeContext } from "../../context/ContextFile";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaRegCircle } from "react-icons/fa";
import { SERVER_IMAGES_URL } from "../../constants";

const ProfileMenu = () => {
  const { setIsModalOpen, isRegister, setIsRegister } =
    useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const data = JSON.parse(localStorage.getItem("data"));

  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  const logOut = () => {
    localStorage.clear("data");
    localStorage.clear("token");
    sessionStorage.clear();
    Swal.fire({
      position: "center",
      html: `
      <div style="display: flex; flex-direction: column; align-items: center;">
          <svg
                viewBox="0 0 1024 1024"
                height="4em"
                width="4em"
                style="margin-bottom: 10px;"
            >
                <!-- Outer circle with color #8ec639 -->
                <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                
                <!-- Check mark with color #A2c66b -->
                <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
            </svg>
          <p style="margin: 0; font-size: 1.2em;">Logout successful.</p>
      </div>
      `,

      showConfirmButton: false,
    });
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  return (
    <>
      <div className="flex justify-end">
        <div className="header-user-menu">
          <div
            className={`header-user-namehu-menu-visdec flex items-center gap-2${open ? "hu-menu-visdec" : ""
              } `}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={
              localStorage.getItem("token")
                ? () => {
                  setOpen(!open);
                }
                : () => setIsModalOpen(true)
            }
          >
            <h2 className="text-xl font-bold !m-0">
              {data?.username ? data?.username : ""}
            </h2>
            {data && (
              <span
                className="overflow-hidden h-[38px] w-[42px] rounded-full"
              >
                <img
                  className="h-[38px] w-[42px] rounded-lg"
                  src={data?.image !== null ? `${SERVER_IMAGES_URL}/profile/${data?.image}` : "images/avatar/avatar-bg.png"}
                  alt=""
                />
              </span>
            )}
          </div>
          {data && (
            <ul
              className={`left-0 ${open ? "hu-menu-vis" : null}`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <li className="hover:bg-gray-100">
                <Link to="/dashboard"> Dashboard</Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/profile"> Profile</Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/my_listing"> My Listings</Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/booking"> My Bookings</Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/trip"> My Trips</Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/my_wallets"> My Wallet</Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/air_mail">
                  {" "}
                  AirMail{" "}
                  <span
                    style={{
                      fontSize: "8px",
                      verticalAlign: "super",
                      position: "relative",
                      top: "-5px",
                      marginLeft: "-5px",
                      color: "#3b4249",
                      background: "none",
                      fontWeight: "400",
                    }}
                  >
                    TM
                  </span>
                </Link>
              </li>{" "}
              <li className="hover:bg-gray-100">
                <Link to="/invoice"> Invoices</Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/review"> Reviews</Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/favorite">My Favorites</Link>
              </li>
              <li onClick={logOut} className="hover:bg-gray-100">
                <Link to="/">Log out</Link>
              </li>
            </ul>
          )}
        </div>
        {data ? null : (
          <>
            <div className="flex items-center gap-5 cursor-pointer">
              <div
                className="text-md font-bold"
                onClick={
                  localStorage.getItem("token")
                    ? () => {
                      setOpen(!open);
                    }
                    : () => setIsModalOpen(true)
                }
              >
                Log In
              </div>
              <div>
                <FaRegCircle />
              </div>
              <div
                className="text-md font-bold"
                onClick={() => setIsRegister(!isRegister)}
              >
                Register
              </div>
              <div>
                <Link
                  className="bg-[#af2322] text-white py-4 px-6 relative h-[40px] rounded-md font-medium text-md transition-all flex items-center
                                "
                  to="/login"
                >
                  Become a Host
                  <span>
                    <i className="fal fa-layer-plus text-white ms-2"></i>
                  </span>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProfileMenu;
