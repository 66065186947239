/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AuthModal } from "../Components/Authentication/AuthModal";
import { LoginForm } from "../Components/Authentication/LoginForm";
import { ForgetPassword } from "../Components/Authentication/ForgetPassword";

const ModalLogin = ({ setIsModalOpen, setIsRegister }) => {
  const [showForgetPasswordForm, setShowForgetPasswordForm] = useState(false);

  // forget password//
  const toggleForgetPasswordForm = () => {
    setShowForgetPasswordForm(!showForgetPasswordForm);
  };


  return (
    <>
      <AuthModal setIsModalOpen={setIsModalOpen}>
        <div className="custom-form">
          {!showForgetPasswordForm && (
            <LoginForm />
          )}

          {!showForgetPasswordForm && (
            <div>
              <Link
                to="#"
                style={{ color: "#A42322" }}
                className="show-lpt"
                onClick={toggleForgetPasswordForm}
              >
                Forgot Password?
              </Link>
            </div>
          )}

          {/*  Forget Password here */}
          {showForgetPasswordForm && (
            <ForgetPassword toggleForgetPasswordForm={toggleForgetPasswordForm} />
          )}

        </div>
        <p>Don't have an account yet? <span style={{ cursor: "pointer", color: "#af2322" }} onClick={() => { setIsModalOpen(false); setIsRegister(true) }}>Register.</span></p>

      </AuthModal>
    </>
  );
};

export default ModalLogin;
