import React, { useRef, useState, useEffect } from "react";
import { useFormContext } from "../../../../../providers";
import { useLocation } from "react-router-dom";

export const ElevationInput = ({ index }) => {
    const { watch, setValue, errors } = useFormContext();
    const location = useLocation();
    const { listId = "" } = location.state || {};
    const airports = watch('airports');

    const [feetX, setFeetX] = useState("");
    const [feetY, setFeetY] = useState("");
    const [metersX, setMetersX] = useState("");
    const [metersY, setMetersY] = useState("");

    const feetYRef = useRef(null);
    const metersXRef = useRef(null);
    const metersYRef = useRef(null);

    useEffect(() => {
        if (listId) {
            if (airports[index]) {
                const elevationFeet = airports[index].elevation_feets || 0;
                const elevationMeters = airports[index].elevation_meters || 0;

                const [fX, fY = ""] = elevationFeet.toString().split(".");
                const [mX, mY = ""] = elevationMeters.toString().split(".");

                setFeetX(fX.trim());
                setFeetY(fY.trim());
                setMetersX(mX.trim());
                setMetersY(mY.trim());
            }
        }
    }, [listId, index, airports]);

    const handleFeetXChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setFeetX(value);
            if (value.length === 4) {
                feetYRef.current.focus();
            }
        }
    };

    const handleFeetYChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,1}$/.test(value)) {
            setFeetY(value);
            if (value.length === 1) {
                metersXRef.current.focus();
            }
        }
    };

    const handleMetersXChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setMetersX(value);
            if (value.length === 4) {
                metersYRef.current.focus();
            }
        }
    };

    const handleMetersYChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,1}$/.test(value)) {
            setMetersY(value);
            updateFinalValue(feetX, feetY, metersX, value);
        } else {
            updateFinalValue(feetX, feetY, metersX, metersY);
        }
    };

    const updateFinalValue = (feetX, feetY, metersX, metersY) => {
        const finalFeetValue = parseFloat(`${feetX}.${feetY}`);
        const finalMeterValue = parseFloat(`${metersX}.${metersY}`);

        const fields = [...watch("airports")];
        fields[index]["elevation_feets"] = finalFeetValue;
        fields[index]["elevation_meters"] = finalMeterValue;

        setValue("airports", fields);
    };

    return (
        <>
            <label>Elevation</label>
            <div className="flex border border-input rounded-md gap-4 items-center px-2">
                <input
                    type="number"
                    value={feetX}
                    onChange={handleFeetXChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span style={{ fontWeight: "bolder" }}>.</span>
                <input
                    type="number"
                    ref={feetYRef}
                    value={feetY}
                    onChange={handleFeetYChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>ft./</span>
                <input
                    type="number"
                    ref={metersXRef}
                    value={metersX}
                    onChange={handleMetersXChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>.</span>
                <input
                    type="number"
                    ref={metersYRef}
                    value={metersY}
                    className="h-10 border-none shadow-none z-0 w-10"
                    onChange={handleMetersYChange}
                />
                <span>m</span>
            </div>
            {(errors?.airports?.[index]?.elevation_feets ||
                errors?.airports?.[index]?.elevation_meters ||
                watch("airports")?.[index]?.elevation_feets === 0 ||
                watch("airports")?.[index]?.elevation_meter === 0) && (
                    <p style={{ color: "red" }}>
                        {errors?.airports?.[index]?.elevation_feets ||
                            errors?.airports?.[index]?.elevation_meters ||
                            "Airport Elevation is required."}
                    </p>
                )}
        </>
    );
};
