import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getIconClass } from '../../helpers';

// Define validation schema with Yup
const validationSchema = Yup.object().shape({
  pay_we_bank_name: Yup.string().required('Name of bank is required'),
  pay_we_routing_number: Yup.string()
    .required('Routing number is required')
    .matches(/^\d{9}$/, 'Routing number must be 9 digits'),
  pay_we_acc_number: Yup.string()
    .required('Account number is required')
    .matches(/^\d{1,17}$/, 'Account number must be between 1 to 17 digits'),
});

export const BankAccountForm = () => {

  const { register, formState: { errors: formErrors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <div className="profile-edit-container fl-wrap block_box">
      <div className="custom-form">
        <div style={{ display: 'flex', justifyContent: 'center' }} className="dashboard-title">
          <h3>How We Pay You</h3>
        </div>
        <div className="row">
          {/* Bank Name Field */}
          <div className="col-sm-6">
            <label>
              Name of Bank <i className={getIconClass('Name of Bank')} />
            </label>
            <input
              type="text"
              {...register('pay_we_bank_name')}
              placeholder="Name of Bank"
            />
            {formErrors.pay_we_bank_name && <span style={{ color: 'red' }}>{formErrors.pay_we_bank_name.message}</span>}
          </div>

          {/* Routing Number Field */}
          <div className="col-sm-6">
            <label>
              Routing Number <i className={getIconClass('Routing number')} />
            </label>
            <input
              type="text"
              {...register('pay_we_routing_number')}
              placeholder="Routing Number"
              maxLength={9}
            />
            {formErrors.pay_we_routing_number && (
              <span style={{ color: 'red' }}>{formErrors.pay_we_routing_number.message}</span>
            )}
          </div>

          {/* Account Number Field */}
          <div className="col-sm-12" style={{ marginTop: '12px' }}>
            <label>
              Account Number <i className={getIconClass('Account number')} />
            </label>
            <input
              type="text"
              {...register('pay_we_acc_number')}
              placeholder="Account Number"
              maxLength={17}
            />
            {formErrors.pay_we_acc_number && (
              <span style={{ color: 'red' }}>{formErrors.pay_we_acc_number.message}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
