import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { axiosInstance } from '../../../helpers/axios';
import { errorPopup, successPopup } from '../../../helpers';

export const VerifyAccount = () => {
    const location = useLocation(); // Get the current URL location
    const queryParams = new URLSearchParams(location.search); // Parse the query parameters
    const token = queryParams.get('token'); // Get the token from the URL
    const navigate = useNavigate(); // To navigate to other pages

    useEffect(() => {
        if (token) {
            // Create a new FormData object
            const formData = new FormData();
            formData.append('token', token); // Append the token to the FormData object

            // Send the token to the API for verification
            axiosInstance.post('/auth/verify', formData)
                .then((res) => {
                    successPopup('Verification successful:', res.data?.message);
                })
                .catch((error) => {
                    errorPopup('', 'Error during verification:');
                })
                .finally(() => {
                    // Wait for 1 second and then redirect to the homepage
                    setTimeout(() => {
                        navigate('/'); // Redirect to the homepage after 1 second
                    }, 1000); // 1 second delay
                });
        }
    }, [token, navigate]); // Re-run the effect when the token changes (e.g., if the URL changes)

    return (
        <Layout>
            <div className="flex items-center justify-center h-screen flex-col gap-5">
                <img
                    className="w-36 h-auto md:w-44" // Responsive sizes
                    src="/image/fly-inn-logo.svg"
                    alt="Fly Inn Logo"
                />
                <div className="text-2xl font-bold">Please Wait while we load your data!</div>
            </div>
        </Layout>
    );
};
