/* eslint-disable no-unused-expressions */
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Components/layout/Layout";
import { ThemeContext } from "../context/ContextFile";
import { errorPopup } from "../helpers";
import "../styles/BecomeHost.css";

const Login = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("register");
  const data = [
    {
      path: "images/avatar/swiper1.jpg",
      title: "Runway!",
      des: "Every listing on Fly-Inn either has a landing strip or helipad conveniently located on the property or is situated within 10 minutes of an airport you are likely to pilot into yourself. Most homes are either hangar homes, airpark homes or have their own private runway. Some homes even offer a water landing!",
    },
    {
      path: "images/avatar/swiper2.jpg",
      title: "Aircraft Storage!",
      des: "Whether it be access to a hangar, tie-downs, etc. on the property itself, or whether it be storage services the nearby airport offers, you are sure to have a place where you can securely store your aircraft.",
    },
    {
      path: "images/avatar/swiper3.jpg",
      title: "Cars!",
      des: "What good is it to land if you can’t get around once you arrive? All our hosts know this and provide either a free loaner or a nice car to rent or at least transportation to a nearby car rental.If the airport is a few minutes from the property, transportation will be made available to you at the airport upon your arrival.",
    },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const userdata = localStorage.getItem("data");
  const user = JSON.parse(userdata);

  const token = localStorage.getItem("token");
  const handleClick = () => {
    if (token) {
      console.log(token, "token");
      navigate("/addlisting");
      window.scrollTo({ top: 0 });
    } else {

      errorPopup('', "You must first login or register.");

    }
  };
  let { setIsRegister, setIsModalOpen } = useContext(ThemeContext);
  return (
    <>
      <Layout>
        <div className="container mx-auto md:px-20 px-4">
          <div className="mt-40 grid md:grid-cols-12 grid-cols-1 min-h-[300px]">
            <div className="md:col-span-6">
              <div className="fl-wrap">
                <h2
                  className="host-header"
                >
                  Become a Host
                </h2>
                <h4
                  className="host-sub-title"
                >
                  Calendar sync is here!
                  Dynamic pricing coming soon!
                </h4>

                <button
                  className="host-btn"
                  onClick={() => handleClick()}
                  style={{

                  }}
                >
                  List Your Space
                </button>
              </div>
            </div>


            {!user ? (
              <div className="md:col-span-6">
                <div className={`bg-white py-4 rounded-md ${activeTab === "register" ? "current" : ""}`}>
                  <Link
                    style={{
                      color: "black",
                      fontWeight: 900,
                      width: "370px",
                      paddingLeft: "28px",
                      fontSize: "20px",
                    }}
                    to="#tab-2"
                    onClick={() => handleTabChange("register")}
                  >
                    {"  "}
                    Please{" "}
                    <span
                      className="when_login_register"
                      onClick={() => {
                        token ? "" : setIsModalOpen(true);
                      }}
                    >
                      Login
                    </span>{" "}
                    or{" "}
                    <span
                      className="when_login_register"
                      onClick={() => {
                        token ? "" : setIsRegister(true);
                      }}
                    >
                      Register
                    </span>{" "}
                    to list your space.
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="container mx-auto md:px-0 px-4">
          <div className="container px-20 pb-14">
            <h2
              className="host-sub-heading"
            >
              What Hosts Promise in Addition to Accommodations:
            </h2>
            <div className="grid md:grid-cols-3 gap-10 grid-cols-1">
              {/* team-item */}
              {data.map((e) => {
                return (
                  <>
                    <div>
                      <div className="team-photo">
                        <img src={e?.path} alt="" className="respimg" />
                      </div>
                      <div className="team-info fl-wrap">
                        <h3
                          style={{
                            fontSize: "18px",
                            lineHeight: "24px",
                            fontWeight: "700",
                            fontFamily: "Quicksand",
                          }}
                        >
                          {e?.title}
                        </h3>
                        <p
                          style={{
                            fontFamily: '"Quicksand", sans-serif',
                            fontSize: "18px",
                            lineHeight: "24px",
                            fontWeight: 500,
                          }}
                        >
                          {e.des}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div
            className="container-fluid bg-[#FFFF] py-28 md:px-20">
            <h1 className="font-bold text-center mb-5 leading-10 text-3xl">
              How To Become a Fly-Inn Host
            </h1>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-10">
              <div>
                <div style={{ marginLeft: "21px" }}>
                  <img
                    src="/images/avatar/icon1.webp"
                    alt="ww"
                    style={{ display: "flex", justifyContent: "center" }}
                  />

                  <h1
                    style={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: "700",
                      textAlign: "start",
                      marginTop: "20px",
                    }}
                  >
                    Fly-Inn Platform
                  </h1>
                  <p
                    style={{
                      fontWeight: "400",
                      marginTop: "13px",
                      fontFamily: "Quicksand",
                    }}
                  >
                    Fly-Inn is the only travel platform devoted entirely to
                    aviation enthusiasts. We are a tight-knit community of
                    like-minded people who understand each other and follow
                    the Golden Rule.
                  </p>
                </div>
              </div>
              <div>
                <div style={{ marginLeft: "21px" }}>
                  <img
                    src="/images/avatar/icon2.webp"
                    alt="ww"
                    style={{ display: "flex", justifyContent: "center" }}
                  />

                  <h1
                    style={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: "700",
                      textAlign: "start",
                      marginTop: "20px",
                    }}
                  >
                    Easy Hosting
                  </h1>
                  <p
                    style={{
                      fontWeight: "400",
                      marginTop: "13px",
                      fontFamily: "Quicksand",
                    }}
                  >
                    Not only it is easy for customers to book, it is just as
                    easy for you to host. The site is intuitive and
                    straightforward and it makes creating earnings a breeze.
                    Easily turn your spare space into an income stream.
                  </p>

                  <p style={{ fontWeight: "300" }}>
                    <strong>DYNAMIC PRICING </strong> is coming soon!
                    <br />
                    <strong>CALENDAR SYNC</strong> is Already Here!
                    <br />
                    Give it a try!
                  </p>
                </div>
              </div>
              <div>
                <div style={{ marginLeft: "21px" }}>
                  <img
                    src="/images/avatar/icon3.webp"
                    alt="ww"
                    style={{ display: "flex", justifyContent: "center" }}
                  />

                  <h1
                    style={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: "700",
                      textAlign: "start",
                      marginTop: "20px",
                    }}
                  >
                    Easy Booking
                  </h1>
                  <p
                    style={{
                      fontWeight: "400",
                      marginTop: "13px",
                      fontFamily: "Quicksand",
                    }}
                  >
                    Fly-Inn makes it easy for your customers to book your
                    space. This ease of use translates to earnings for you.
                    The clean design and the intuitive nature of the site
                    makes customers familiar in a snap and makes them want to
                    return again and again!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container" style={{ marginTop: "90px" }}>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: 'wrap',
                  marginTop: "15px",
                }}
              >
                <div className="col-md-4">
                  <img src="/images/avatar/men1.jpg" alt="ww" />
                </div>
                <div className="col-md-4">
                  <div style={{ marginLeft: "21px" }}>
                    <h1
                      style={{
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: "700",
                        textAlign: "start",
                      }}
                    >
                      1. Know Local Laws and Ordinances
                    </h1>
                    <p style={{ fontWeight: "300" }}>
                      It is essential to know what local laws and ordinances
                      apply to you and how they will affect your business. You
                      may also want to consult with your accounting firm to make
                      sure they have you covered when it comes to tax filing.
                      Although this step may seem daunting, it gets taken care
                      of in less than an hour with a couple of easy phone
                      calls–one to your town hall and one to your accountant!
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: 'wrap',
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <div className="col-md-4">
                  <div style={{ marginLeft: "21px" }}>
                    <h1
                      style={{
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: "700",
                        textAlign: "start",
                      }}
                    >
                      2. Calculate Your Desired Profit
                    </h1>
                    <p style={{ fontWeight: "300" }}>
                      Setting your price is a balancing act between maximizing
                      profits and remaining at a low enough price to attract
                      customers. The best way to figure out your ideal price is
                      to add up all costs needed to operate your business. Be
                      sure to include startup costs which include all the things
                      you need to buy to get going such as linens, furniture,
                      etc. Also include variable costs such as shampoo, etc.
                      Finally, look at costs like insurance, mortgage payments,
                      maintenance, repairs, property and income taxes, etc. Try
                      to figure out what all of these costs would be on a per
                      night basis. This is the minimum you should charge just to
                      break even. Now add an amount for your profit and your
                      price is set!
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src="/images/avatar/men2.jpg" alt="ww" />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: 'wrap',
                  marginTop: "15px",
                }}
              >
                <div className="col-md-4">
                  <img src="/images/avatar/men3.jpg" alt="ww" />
                </div>

                <div className="col-md-4">
                  <div style={{ marginLeft: "21px" }}>
                    <h1
                      style={{
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: "700",
                        textAlign: "start",
                      }}
                    >
                      3. Research Prices Others Charge
                    </h1>
                    <p style={{ fontWeight: "300" }}>
                      Look at Fly-Inn’s listings to get an idea of what other
                      hosts are charging for their spaces and what they are
                      offering in return. Keep in mind that customers are always
                      looking for the greatest value when they book. Follow the
                      Golden Rule– treat them like royalty, the way you would
                      like to be treated yourself, and watch your earnings soar!
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: 'wrap',
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <div className="col-md-4">
                  <div style={{ marginLeft: "21px" }}>
                    <h1
                      style={{
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: "700",
                        textAlign: "start",
                      }}
                    >
                      4. List Your Space
                    </h1>
                    <p style={{ fontWeight: "300" }}>
                      Now all you need is some fantastic photos and a great
                      description that is both accurate and informative. It is a
                      terrific idea to invest in professional real estate
                      photography in order to increase revenue. Professional
                      real estate photographers will charge you anywhere from
                      $150 to $300 depending on the number of photos your
                      listing requires. You will make that back usually within
                      the first month alone due to increased sales! Now you’re
                      in business!
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src="/images/avatar/men4.jpg" alt="ww" />
                </div>
              </div>
            </div>
          </div>

          <div
            className="container"
            style={{
              marginTop: "100px",
              backgroundColor: "#F7F8F9",
              paddingTop: "100px",
              paddingBottom: "100px",
            }}
          >
            <div className="row">
              <h1
                style={{
                  fontWeight: "700",
                  fontSize: "40px",
                  lineHeight: "60px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                All the Tools You Need
              </h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: 'wrap',
                  marginTop: "15px",
                  gap: "20px",
                }}
              >
                <div className="col-md-3">
                  <div style={{ marginLeft: "21px" }}>
                    <img
                      src="/images/avatar/tvicon.png"
                      alt="ww"
                      style={{ display: "flex", justifyContent: "center" }}
                    />

                    <h1
                      style={{
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: "700",
                        textAlign: "start",
                        marginTop: "20px",
                      }}
                    >
                      Listing Management
                    </h1>
                    <p style={{ fontWeight: "300", marginTop: "13px" }}>
                      Easily manage all of your listings with Fly-Inn’s simple,
                      straightforward platform.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div style={{ marginLeft: "21px" }}>
                    <img
                      src="/images/avatar/icon3.webp"
                      alt="ww"
                      style={{ display: "flex", justifyContent: "center" }}
                    />

                    <h1
                      style={{
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: "700",
                        textAlign: "start",
                        marginTop: "20px",
                      }}
                    >
                      Booking System
                    </h1>
                    <p style={{ fontWeight: "300", marginTop: "13px" }}>
                      Your guests will love how easy it is to choose your
                      listing and proceed to check out.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div style={{ marginLeft: "21px" }}>
                    <img
                      src="/images/avatar/icontimer.png"
                      alt="ww"
                      style={{ display: "flex", justifyContent: "center" }}
                    />

                    <h1
                      style={{
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: "700",
                        textAlign: "start",
                        marginTop: "20px",
                      }}
                    >
                      Messaging System
                    </h1>
                    <p style={{ fontWeight: "300", marginTop: "13px" }}>
                      Fly-Inn’s built-in messaging system makes it easy for
                      guests and hosts to communicate with each other and keep
                      all communication in one place.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="p-20"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
              backgroundColor: '#FFFF'
            }}
          >
            <h1 style={{ fontWeight: "700", fontSize: "36px" }}>
              List Your Space!
            </h1>

            <h6
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "300",
                lineHeight: "30px",
                marginTop: "14px",
              }}
            >
              If you have any questions, please visit our FAQ (Frequently Asked
              Questions)
              <br /> section under "Info" in the menu bar, or give us a call and
              we will be happy
              <br /> to assist you... If you're all set, click the button below!
            </h6>
            <Link to={"/addlisting"}>
              <button
                type="submit"
                className="fontquick"
                style={{
                  fontFamily: '"Quicksand", sans-serif',
                  cursor: "pointer",
                  padding: "15px",
                  borderRadius: "6px",
                  display: "flex",
                  fontSize: "16px",
                  lineHeight: "16px",
                  marginTop: "19px",
                  fontWeight: "400",
                  paddingLeft: "35px",
                  paddingRight: "35px",
                  backgroundColor: "#AF2322",
                  outline: "none",
                  color: "white",
                }}
              >
                List Your Space
              </button>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Login;
