import React, { useState } from 'react';
import { useListing } from '../../providers';
import { SERVER_IMAGES_URL } from '../../constants';

export const DetailsImages = () => {
    const { listingDetails } = useListing();
    const { images } = listingDetails;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-10">
                <div className="h-[40vh] md:h-[51vh]">
                    {images?.[0] && (
                        <img
                            src={`${SERVER_IMAGES_URL}/listing/${images[0]?.image}`}
                            alt=""
                            className="w-full h-full rounded-lg object-cover cursor-pointer"
                            onClick={() => handleImageClick(images[0])}
                        />
                    )}
                </div>

                <div className="grid grid-cols-2 md:grid-rows-2 md:grid-cols-2 gap-2">
                    {images?.slice(1, 5).map((image, index) => (
                        <div key={index} className="h-[20vh] md:h-[25vh]">
                            <img
                                src={`${SERVER_IMAGES_URL}/listing/${image?.image}`}
                                alt=""
                                className="w-full h-full rounded-lg object-cover cursor-pointer"
                                onClick={() => handleImageClick(image)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="absolute !top-[30%] max-w-3xl w-full bg-white rounded-lg">
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-6 text-white z-[100] text-2xl text-bold"
                        >
                            X
                        </button>
                        <img
                            src={`${SERVER_IMAGES_URL}/listing/${selectedImage?.image}`}
                            alt=""
                            className="w-full h-auto rounded-lg object-cover"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
