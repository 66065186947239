import React from "react";
import Layout from "../../Components/layout/Layout";


const MostFun = () => {

  return (
    <Layout>
      <div className="content">
        <section id="sec1" data-scrollax-parent="true">
          <div className="container">
            <div className="section-title">
              <h2> The Most Fun Fly-Ins in America</h2>
              <div className="section-subtitle">who we are</div>
              <span className="section-separator" />
              <p>
                a, Aircraft, Aviation Events, Aviation Industry, Aviation
                Interests, b, c, Cities, Fun Destinations, Searching and
                booking, Travel Industry
              </p>
            </div>
            {/*about-wrap */}
            <div className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/staticPage.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>In order of popularity…</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      As we all know, fly-ins are opportunities for us pilots to
                      showcase our aircraft, attend workshops, connect with
                      fellow aviation enthusiasts, share our passion for flying,
                      and just have a groovy time! Here are some of the most fun
                      fly-ins we have attended in the United States:
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "20px" }} className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA AirVenture Oshkosh:</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      (Oshkosh, Wisconsin):  Oshkosh is held annually in the summer. The next one will take place on July 22-28, 2024.
                    </p>
                    <p>
                      While its considered the worlds premier airshow, Oshkosh is also a significant fly-in event. Thousands of aircraft are tied down on the grounds for display. You will find everything from homebuilts to vintage to contemporary models when you arrive at Wittman Regional Airport and adjacent Pioneer Airport. July 2023 OshKosh convention saw almost 700,000 people, making it hands down the largest aviation celebration in the world.
                    </p>
                    <p>
                      Among the attractions at OshKosh, the following stand out, in alphabetical order:
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/staticPage1.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
            <div style={{ paddingTop: "20px" }} className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>AeroEducate Center</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      a free, self-paced online activity program for children K-12 to explore how fun aviation can be. In addition to daily forums from industry leaders and young professionals, AeroEducate gives youth hands-on activities to immerse them in the world of aviation. For an hour and a half, they will have the opportunity to bend sheet metal using rivets and aircraft tools, learn about wiring diagrams and electrical theory, fly in a simulator, and do a simulated walk-around of an aircraft!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Air Shows</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      See your favorite pilots perform daring feats of speed and aerobatics all week long! Monday – Saturday daily from 2:30 pm to 6:30 pm, Sunday from 1:00 pm to 4:30 pm. Nighttime shows on Wednesday and Saturday start at 8:00 pm.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Air Shows</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      See your favorite pilots perform daring feats of speed and aerobatics all week long! Monday – Saturday daily from 2:30 pm to 6:30 pm, Sunday from 1:00 pm to 4:30 pm. Nighttime shows on Wednesday and Saturday start at 8:00 pm.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Aircraft Anniversaries</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      For notable aircraft. Awww!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Aircraft Areas</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Aircraft are divided into “neighborhoods” (or categories) named after the aircraft on display. The name describes exactly the type of aircraft you would expect to see there. The different neighborhoods are Warbirds, Boeings, Home-builts, Vintage, Aerobatic, Seaplane, Museum, and Ultralight/LSA/Rotorcraft. Expect to see history, innovation, craftsmanship, rarity, and uniqueness as you stroll through the countless aircraft!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Aircraft Rides </h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Take a joyride on the Ford Trip-Motor 5-AT-B, the world’s first mass-produced airliner; the B-25 Berlin Express WWII bomber; the Bell 47 Helicopter made popular by the hit television show M*A*S*H; or all three!                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Authors’ Corner</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Meet your favorite aviation authors, get your book signed, and snap a selfie!                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Aviator’s Club (and Wing’s Club new in 2024!) </h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Relax, cool down, and watch the air show from some of the best seats in the house, right on the flightline! Breakfast and lunch are served daily with dinner also served for Wednesday and Saturday evening shows. Snacks and non-alcoholic beverages are available during the day. The best air show performers and special guests visit routinely where you may converse with them freely. Charging stations and bag storage are also available as well as flush toilets! Sold! Choose from Aviator Club or Wings Club as tickets are specific to the location purchased. Access to the clubs is for ticketed members only and tickets sell out fast so it is advised to buy them early. Open Monday, Tuesday, Thursday, and Friday from 8:00 am – 6:00 pm. Wednesday and Saturday from 8:00 am – 10:00 pm. Open late on Wednesdays and Saturdays because of the evening airshows.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Aviation Gateway Park</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Presented by Piper Aircraft, several companies and organizations immerse you in the experience of aviation as a career. AeroEducate Center (see above), EAA’s WomenVenture Center, EAA Education and Career Center, and EEA Career Center can all be found here with their various offerings. WomenVenture gives women the opportunity to connect, network, learn and be supported in the different areas of aviation. The Education and Career Center allows those interested to visit colleges, universities, and tech schools offering aviation, science, technology, engineering, math, or STEM studies. The Career Center extends to prospective aviators an opportunity to network and interview with several renowned aviation companies and organizations looking to offer career placement.                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Aviation Job Fair</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Meet representatives of companies that are presently hiring great people to join their groovy teams!                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Aviation Tech</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Children and teens in grades 6-12 get to learn about what it takes to engineer an aircraft. They have even designed and built a model of the F-35! Pilots and engineers are there to answer questions and share what having a career in aviation is like! The activity begins promptly at 9:00 am, Central Daylight Time. Early registration is advised as it sells out quickly. Registration opens the beginning of June                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Awards</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Since 1954, the (Charles Lindbergh) “Lindy” Award has been given to aircraft builders in recognition of their outstanding craftsmanship in the following categories of aircraft: home-built, vintage, warbirds, ultralight/light-sport, rotorcraft, and seaplanes. Be aware of when your category’s registration closes and give the judges ample time to review your aircraft by having it at the airport and registered at least 24-36 hours before registration closes. The ceremony starts Saturday night at 6:30 pm.                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA Activities Center</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      From rug hooking to tole painting, there is something for everyone to learn and enjoy! Open all week.                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA Aviation Museum</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Open all year, the museum is home to countless exhibits, tours, and events. This is not to be missed while at OshKosh!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA Blue Barn</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      The Blue Barn offers forums, displays for best practices, a map of where EAA chapters are located, and more. Learn how you can become involved with the programs. Plus, sign up for a chance to win a new Lincoln TIG welder!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA GirlVenture Camp</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      In the GirlVenture Camp, EAA seeks to show young women how to make their aviation dreams a reality. For four days, young ladies are offered hands-on activities and workshops, plus the opportunity to hear speakers, meet special guests, connect with mentors and influential people in aviation, and make lifelong friendships with other young women who share the same passion. If the weather allows, they will enjoy an exclusive flight. Daily breakfast and lunch plus a special dinner on Tuesday night are all included in the fee. Young women are also allowed to participate in the Women in Aviation International Breakfast and WomenVenture Power Lunch. Each girl receives a backpack, a fresh T-shirt daily, a water bottle, and more.                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA Learn to Fly Center</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Perfect for guests who want to learn how to fly. Certified flight instructors (CFIs) and pilots, both fresh and seasoned, will be available to answer your questions about flight training.
                    </p>
                    <p>
                      Take an introductory flight lesson! One-hour flight lessons are offered in flight simulators. You will have your first hour logged in your logbook before you ever leave AirVenture!
                    </p>
                    <p>
                      Participants must prove US citizenship with a passport or with a birth certificate + photo ID. There will also be tips on how to pass your checkride, how to save money and shorten your length of time in flight training, FAA medical certification, and much, much more! Open Monday through Sunday, 8:00 am – 5:00 pm.
                    </p>


                  </div>
                </div>

                <div className="col-md-6">
                  <div className="ab_text">

                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA Pilot Proficiency Center</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Using Master Flight Instructor Rich Stowell’s “Nine Principles of Light Aircraft Flying” which include his principles on mindset, motion, and mechanics, the Pilot Proficiency Center offers a one-of-a-kind educational experience for those who aspire to improve their flying and/or teaching skills. Among their instructors are, “US national aerobatic champions, a former NASA astronaut and Air Force test pilot, FAA Designated Pilot Examiners, National and Regional Flight Instructors of the Year, and representatives from organizations like the National Air Traffic Controllers Association (NATCA), the Society of Aviation and Flight Educators (SAFE), and the National Association of Flight Instructors (NAFI)…”(1) Amazing!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Fly-In Theater</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Bring a lawn chair or blanket and enjoy free popcorn while watching Hollywood classics on a five-story-high projection screen! Shows begin each evening at approximately 8:30 pm, weather permitting. All are complimentary with your admission wristband.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>International Federal Pavilion</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Federal agencies and organizations from the U.S., Canada, and the Bahamas are present at the pavilion. They will be leading forums and answering questions on everything you would like to know about personal travel to and from their countries and an infinite number of other topics too large to cover here. Organizations in attendance include the following: Air and Marine Operations (AMO), Bird Strike Committee USA, National Transportation Safety Board (NTSB), North American Aerospace Defense Command, NOAA/NWS, SeeAndAvoid.org, U.S. Customs and Border Protection, The U.S. National Park Service (NPS) and, USDA Forest Service USFWS National Aviation Management Branch from the U.S.A. From the Bahamas, we have the Royal Bahamas Police Force / Customs / Civil Aviation Authority. From Canada NAV Canada, Canadian Owners & Pilots Association (COPA), and Transport Canada will be present.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>International Visitors Tent</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Catering to the thousands of visitors AirVenture receives each year from countries all over the world, the tent offers visitors a place to relax and get information about AirVenture and OshKosh. Dozens of languages are interpreted by friendly volunteers serving wonderful patrons from every continent short of Antarctica, many of whom have traveled here in their own airplanes! What a treat to meet them! Aviation makes our world very small indeed!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>KidVenture</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Youth learn aviation history, how to fly a radio-controlled airplane, and experience a B-25 simulated flight! There are several aircraft-building activities–booths for riveting, engine, electronic troubleshooting, prop-shaping, and wooden rib building. As they complete each station, kids get their work cards punched! These booths cover necessary topics such as regulations, weather, navigation, air traffic control, aircraft design, pre-flight inspection, and post-flight debriefing. Kids who complete the Future A&P booths get a Future A&P pin. The first thousand kids to complete all the skills even get a tool kit! In addition, there are life-size replicas of the Mercury 7 and Gemini 7 space capsules. Kids even get to talk on a HAM radio to people around the world!
                    </p>
                    <p>
                      Open Monday through Saturday from 9:00 am to 3:30 pm, and Sunday from 9:00 am to 2:00 pm. Held at the Museum. Shuttles from the bus park run all day.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>RC Flying Field </h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Fly your own or watch others fly their radio-controlled aircraft or drone. A safety officer will be onsite at all times. Open Monday through Saturday, 6:30 pm to 9:00 pm. Friday it ends at 8:15 pm.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Runway 5K Run</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      This fun charity run/walk starts promptly at 7:00 am and everyone is encouraged to participate. The community cheers you on as you travel through a kaleidoscope of amazing aircraft. Showers and gear check are available, but be sure to gather your belongings before 9:30 am when the gear check closes. All gear left behind will be turned into lost and found after 9:30 am.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Teacher Day</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Teachers from across the state gather on Friday morning and discuss ways in which aviation and STEM can be integrated into teaching curricula. This day is loaded with presentations and activities. Compliments of Gulfstream Aerospace Corporation, teacher Day is free. In addition, educators receive a box lunch and an AirVenture wristband as a special thank you for participating. We love our teachers!

                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>The Gathering</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Presented by Textron Aviation, makers of Cessna and Beechcraft, The Gathering is a special fundraising gala and dinner. Evening cocktail attire is recommended.

                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Theater in the Woods</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      This is the perfect conclusion for a busy day. Included with all admission levels. More information to come.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Twilight Flight Fest</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Held at the Fun Fly Zone, less traditional forms of aviation are displayed including ultralights, powered parachutes, weight shift trikes, homebuilt helicopters, and gyroplanes. The fest is held Monday, Tuesday, Thursday, and Friday evenings at 8:00 pm. Additionally, a huge hot air balloon launch will be held on Saturday morning at 6:00 am, if the weather allows. Visit the EAA website for procedures on how to fly on the runway.

                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Veterans’ Events</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      As a special thanks to our veterans for their service and sacrifice, Friday is AirVenture’s Salute to Veterans Day. A breakfast, a parade, and a flight will be held in their honor. In 2023’s AirVenture, the flight was put on by Northeast Wisconsin’s Old Glory Honor Flight Organization.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Youth Welcome Center</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Grab a map and see where all the kid activities and opportunities to learn about aviation are located. Kids can take their picture in an F-100 Super Sabre cockpit and take the “Plane Spotter” model plane challenge! There is a registration area for AeroEducate (see above) and also one for EAA’s Young Eagle Program where kids can sign up for a free flight and begin their immersion in the love of aviation!

                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Workshops and Forums</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Presented by Aircraft Spruce and Specialty Co., attendees are afforded the opportunity to learn about homebuilding, flying, repairing, restoring, and maintaining aircraft through forums and hands-on lessons in the areas of sheet metal construction and riveting, fabric construction, composite construction, building of wood truss wing ribs, and oxyacetylene and TIG welding.
                    </p>
                    <p>
                      Admission to OshKosh is free for EAA (Experimental Aircraft Association) members and tickets range from $12 to $15 for everyone else
                    </p>
                    <p>
                      For more information, visit the EAA’s AirVentrue section of their website.
                    </p>

                  </div>
                </div>

              </div>
            </div>

            <div className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/static2.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>SUN ‘n FUN Aerospace Expo:</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      (Lakeland, Florida): Sun ‘n Fun is held annually. The next one will take place on April 9 – 14, 2024. (9:00 am to 6:00 pm, daily and 9:00 am -10:00 pm on Wednesday and Saturday).
                    </p>
                    <p>
                      Much like Oshkosh, SUN ‘n FUN is both an airshow and a major fly-in event. There is so much to be enjoyed at Sun ‘n Fun! Among the activities and attractions are the following, in alphabetical order:
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "20px" }} className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Aircraft Judging</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Judging begins on Tuesday, the very first day, and goes until Friday. Winners are awarded at the on-site ceremony on Saturday evening. Be sure to let the registration team know that you would like to register your aircraft when you buy your tickets. Categories are homebuilt, light, rotorcraft, seaplane, ultralight, vintage and warbirds. The vintage category is divided into antique (built prior to August 31, 1945), classic (built between September 1, 1945 and December 31, 1955) and contemporary (built between January 1, 1956 and December 31, 1970).
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Airshows</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Masterful pilots perform amazing feats including everyone’s favorite – aerobatics! Check their website early in 2024 for updates on the schedule and who the pilots will be. In addition, there is a mass balloon launch from the main flightline on Saturday from 6:30 am to 8:00 am. There will be the traditional “Hare and Hound” race that everyone loves! One airship is chosen as the Hare and the rest are the Hounds who all follow the Hare shortly after it has taken off. The winner is the one that lands closest to where the Hare lands! Fun! There is also the Balloon Glow during the evening airshows. Just magical!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Amateur (HAM) Radio Special Event Radio Station (WS4) </h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Stop by the station and experience what it is like to connect with people around the country or even the world! What a great way to serve during times of emergency!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Authors’ Corner</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Your favorite authors come to speak, sell, and sign your favorite books! Snap a selfie with them!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Auction Fundraiser (The Bash)</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Enjoy cocktails and a scrumptious dinner as you support ACE’s STEM education and contribute toward scholarships for students pursuing careers in aviation and aerospace. Both a silent and a live auction will be held, as well as a Fund-A-Need Auction (Hands to the Sky!)
                    </p>
                    <p>
                      Join the fun on April 11, 2024, 6:00 pm – 9:00 pm in the Florida Air Museum. Prices are only $150 per person or $1,500 for a group of 8. What kind of math is that? LOL!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Bahamas Getaway</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      If you’re already in Florida, why not take a very short flight to the Bahamas?! The closest airport is only 50 miles away, and there are many, many more airports that are convenient to land in. See the Sun n’ Fun website for more information.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Career Fair</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Your General Admission ticket grants you entrance into this five-day event happening Tuesday through Saturday, 9:00 am to 5:00 pm. Exhibitors pre-schedule on-site meetings and interviews! What a perfect opportunity for the prospective employee!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Educational Forums </h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Held on all three air-conditioned floors of the Central Florida Aerospace Academy, forums are offered on practically every aviation subject available!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Flyin’ Flix</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      ACE plays an aviation-themed movie on their large, outdoor screen every night of the week, starting the Sunday before Sun n’ Fun! Join your fellow aviation enthusiasts nightly and enjoy the available popcorn and refreshments. Shows start at approximately 8:00 pm.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Innovation Preview</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Sun n’ Fun aims to be the gold standard for all news and information on all topics covering aviation. They aim to give reliable information to anyone involved in the industry in order to afford them the ability to plan ahead based on new products that will be coming on the market. Now THAT’s accountability! Their 2023 convention featured products from renowned aviation companies such as Aero-Leds, Aeromarine LSA, Aero-Tech, Inc., Aerox Oxygen Systems, Air Plains Services, Bob Hoover Legacy Foundation, Cub Crafters, CompAir Aviation, Daher, Diamond Aircraft, eSportPlane Resource Guide, Extra Aircraft, Aeropup USA, Hartzell Prop/Hartzell Engine Technologies, King Schools, Lightspeed Aviation, Super Petrel USA, uAvionix, US SportPlanes, and WACO Aircraft.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>International Visitors Tent</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Volunteers are ready to answer any questions you may have and they do so in your choice of languages! Visitors from countries all over the world flock to Sun n’ Fun annually and many languages are interpreted here.
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Kids’ Activities</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Since 1954, the (Charles Lindbergh) “Lindy” Award has been given to aircraft builders in recognition of their outstanding craftsmanship in the following categories of aircraft: home-built, vintage, warbirds, ultralight/light-sport, rotorcraft, and seaplanes. Be aware of when your category’s registration closes and give the judges ample time to review your aircraft by having it at the airport and registered at least 24-36 hours before registration closes. The ceremony starts Saturday night at 6:30 pm.                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA Activities Center</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Junior ACEs (Aerospace Center for Excellence) have an education area for kids ages 7-17. Kids’ activities are all hands-on and they include building radios, carving propellers, designing a digital airport, STEM laboratory activities, Florida Air Museum activities, hearing from a jet team at a youth symposium, and taking classes inside a 727 which functions as their classroom! The Lakeland Aero Club, a non-profit high school flying club, is also in attendance to help the youth foster an interest in aviation.
                    </p>
                    <p>
                      The Aerospace Center for Excellence is putting on its 50th annual SUN ‘n FUN Aerospace Expo. It is open to schools and homeschools of 15 students or more. Registration is online. Self-guided field trips are available Tuesday through Friday. Admission is free for students.
                    </p>
                    <p>
                      The Aerospace Center for Excellence is also putting on “Project: STRATOS”, an aviation-centric digital media program in which high school juniors and seniors are chosen for Squadron 9 by previous winners. Judgment is based on merit earned by the applicants’ individual video submission. They recommend that videos be unique, enthralling, and informative enough to let the viewers know what this opportunity would mean to them. Project: STRATOS students aim to show participants what life is like in aviation through visiting various aerospace-related organizations, vlogging about their experiences, and being distinguished as an ACE Ambassador. This golden opportunity promotes students through ACE’s social media channels earning them recognition at the Aerospace Expo, and giving them the opportunity to interact with aviation’s best in the coming school year! The winner is announced at “The Bash” and awarded a flight in a P-51 Mustang! Even Semi-finalists and finalists receive passes, swag and interviews on the Sun ‘n Fun radio!
                    </p>







                  </div>
                </div>

                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Military Displays and Activities</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      In addition to the static aircraft displays (above), the following may be of interest to veterans and non-veterans alike:
                    </p>
                    <p>
                      Veterans Florida Plaza has a welcome tent that offers military displays, war reenactment, VA and Veteran services, and more.
                    </p>
                    <p>
                      Compliments of the Victory’s Arsenal Theater, all are welcome to enjoy stories of pilots and aircraft that made history!
                    </p>
                    <p>
                      Last but certainly not least are the demo flights… Come and see F-35A, F-35B, F-16, F-86, KC-135 demos as well as performances by Baron, the SOCOM Jump Team and…drumroll…The Blue Angels!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Part Exchange (PX) Cosign</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      How groovy is that?! You get to drop off your spare parts and get cash for them if they sell! Simply bring your parts, purchase a tag or tie for 50 cents and leave it there to be sold while you go on to enjoy Sun n’ Fun! Open to the public from 9:00 am on Tuesday – 12:00 pm on Sunday, so be sure to drop off your parts on Monday. (Note that they don’t accept batteries and they only accept tires if they are mounted on aircraft wheel assemblies and described as such on the tags.)
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>EAA Activities Center</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Junior ACEs (Aerospace Center for Excellence) have an education area for kids ages 7-17. Kids’ activities are all hands-on and they include building radios, carving propellers, designing a digital airport, STEM laboratory activities, Florida Air Museum activities, hearing from a jet team at a youth symposium, and taking classes inside a 727 which functions as their classroom! The Lakeland Aero Club, a non-profit high school flying club, is also in attendance to help the youth foster an interest in aviation.
                    </p>
                    <p>
                      The Aerospace Center for Excellence is putting on its 50th annual SUN ‘n FUN Aerospace Expo. It is open to schools and homeschools of 15 students or more. Registration is online. Self-guided field trips are available Tuesday through Friday. Admission is free for students.
                    </p>
                    <p>
                      The Aerospace Center for Excellence is also putting on “Project: STRATOS”, an aviation-centric digital media program in which high school juniors and seniors are chosen for Squadron 9 by previous winners. Judgment is based on merit earned by the applicants’ individual video submission. They recommend that videos be unique, enthralling, and informative enough to let the viewers know what this opportunity would mean to them. Project: STRATOS students aim to show participants what life is like in aviation through visiting various aerospace-related organizations, vlogging about their experiences, and being distinguished as an ACE Ambassador. This golden opportunity promotes students through ACE’s social media channels earning them recognition at the Aerospace Expo, and giving them the opportunity to interact with aviation’s best in the coming school year! The winner is announced at “The Bash” and awarded a flight in a P-51 Mustang! Even Semi-finalists and finalists receive passes, swag and interviews on the Sun ‘n Fun radio!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
Our Awesome Team <span>Story</span>
</h3> */}
                      <h3>Lakeland (LAL) Airport 5K Run</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Weather permitting, the race takes place on Saturday during the Aerospace Expo. Head over to the Terminal Ramp to start your race and see over twenty hot air balloons take flight while you run! Enjoy a one-of-a-kind experience!

                    </p>
                    <p>
                      Proceeds benefit the Lakeland AeroClub, a high school flying club that focuses on teaching students to build, restore and fly airplanes, as well as the Heart of Florida Chapter of Women in Aviation.
                    </p>


                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
Our Awesome Team <span>Story</span>
</h3> */}
                      <h3>Military Displays and Activities</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      In addition to the static aircraft displays (above), the following may be of interest to veterans and non-veterans alike:
                    </p>
                    <p>
                      Veterans Florida Plaza has a welcome tent that offers military displays, war reenactment, VA and Veteran services, and more.
                    </p>
                    <p>
                      Compliments of the Victory’s Arsenal Theater, all are welcome to enjoy stories of pilots and aircraft that made history!
                    </p>
                    <p>
                      Last but certainly not least are the demo flights… Come and see F-35A, F-35B, F-16, F-86, KC-135 demos as well as performances by Baron, the SOCOM Jump Team and…drumroll…The Blue Angels!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
Our Awesome Team <span>Story</span>
</h3> */}
                      <h3>Part Exchange (PX) Cosign</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      How groovy is that?! You get to drop off your spare parts and get cash for them if they sell! Simply bring your parts, purchase a tag or tie for 50 cents and leave it there to be sold while you go on to enjoy Sun n’ Fun! Open to the public from 9:00 am on Tuesday – 12:00 pm on Sunday, so be sure to drop off your parts on Monday. (Note that they don’t accept batteries and they only accept tires if they are mounted on aircraft wheel assemblies and described as such on the tags.)
                    </p>

                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
Our Awesome Team <span>Story</span>
</h3> */}
                      <h3>Showcase Fly-Bys</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Flaunt your aircraft for free, in flight! Showcase in the Fly-By pattern and have a description of your aircraft read to the audience over the PA system! This is one of Sun ‘n Fun’s most famous attractions
                    </p>

                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Static Aircraft Displays</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Static aircraft displays are displays of airplanes that are not in flight 😉. Types include cargo, drone, fighter, helo, surveillance, tanker, and trainer. There are so many to see!

                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Teachers’ Pre-Flight Experience</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      For a mere $30 admission, teachers of grades pre-K through college are welcome to a day designed specifically for them. Teachers get to enjoy a hot, catered breakfast while they listen to the keynote speaker. Four teachers’ names are drawn for a rare opportunity to fly with the AeroShell Aerobatic Team! Teachers can go on a flight-line tour, meet the airshow performers, and peruse around the warbird aircraft. They are then treated to a catered lunch with special guests. To cap a perfect day, they can now join their loved ones and enjoy the airshow from premier seating along with them! Will your favorite teachers win the drawing and perform? Be sure to nominate them early!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Type Clubs</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Wouldn’t it be fun to meet and be part of a group of fellow aircraft-type enthusiasts?  These groups are called “type clubs” and roughly 20 of them participate in SUN ‘n FUN. Come on down and see what their clubs are all about!
                    </p>
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Workshops</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Hands-on workshops are available in just about anything you would like to learn about when it comes to building an airplane. All the staff is made up of either amateur and/or professional instructors who have vast experience in the areas they are teaching about. Many even have FAA Airframe and Power Plant Mechanic ratings.
                    </p>
                    <p>
                      Learn all about woodworking, metal fabrication, metal shaping, oxyfuel welding, TIG/MIG welding, avionics/electrical installation, electrical noise and troubleshooting, aircraft fabric covering, rib stitching, composite construction, safety wiring and cable swaging, engines and firewall forward installations.
                    </p>
                    <p>
                      Many of their workshops are even FAA-approved for IA renewal! Join the fun daily from 9:00 am – 4:00 pm.
                    </p>
                    <p>
                      Learn more by visiting the Sun ‘n Fun website
                    </p>





                  </div>
                </div>


                <div style={{ paddingTop: "20px" }} className="about-wrap">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="ab_text">
                        <div className="ab_text-title fl-wrap">
                          {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                          <h3>Triple Tree Aerodrome Fly-In:</h3>

                          <span className="section-separator fl-sec-sep" />
                        </div>
                        <p>
                          (Woodruff, South Carolina): Triple Tree is held annually at the end of September. The next one will take place on September 23-29, 2024.
                        </p>
                        <p>
                          Triple Tree Fly-In is the fastest-growing aviation event in the nation! Located in one of the most beautiful parts of the country, Triple Tree’s airport is known for its immaculate 7,000-foot, grass runway. Southern hospitality, fun, and camaraderie abound –  home-cooked meals are served daily! Afternoon activities and evening entertainment begin on Thursday. Throughout the week there are special speakers, maintenance and restoration workshops, karaoke, military history, HAM radio special events, and more!
                        </p>
                        <p>
                          The event takes place from Monday to Sunday. The airport opens at 7:30 am on Monday and closes on Sunday afternoon. Premium camping is available and includes water and power hookups. Happy camping!
                        </p>
                        <p>
                          For more information, visit the Triple Tree Aerodrome website.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="list-single-main-media fl-wrap"
                        style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                      >
                        <img
                          src="/image/static3.webp"
                          className="respimg"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="about-wrap">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="list-single-main-media fl-wrap"
                            style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                          >
                            <img
                              src="/image/static4.webp"
                              className="respimg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="ab_text">
                            <div className="ab_text-title fl-wrap">
                              {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                              <h3>AOPA (Aircraft Owners and Pilots Association) Fly-Ins:</h3>

                              <span className="section-separator fl-sec-sep" />
                            </div>
                            <p>
                              AOPA hosts several regional fly-ins each year across the country, offering seminars, aircraft displays, and networking opportunities.
                            </p>
                            <p>
                              (Buckeye, Arizona): AOPA Fly-In at the Buckeye Air Fair is held annually at the Buckeye Municipal Airport (KBXK). The next one will take place on February 16-18, 2024.
                            </p>


                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>




              </div>
            </div>
            <div style={{ paddingTop: "20px" }} className="about-wrap">
              <div className="about-wrap">
                <div className="row">
                  <div className="col-md-6">
                    <div className="ab_text">
                      <div className="ab_text-title fl-wrap">
                        {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                        <h3>Exhibitors</h3>

                        <span className="section-separator fl-sec-sep" />
                      </div>
                      <p>
                        Visit all the numerous Air Fair booths on site! Including OURS! Come and look for us at Fly-Inn’s booth, pick up some free swag, and say “hello!” Let’s snap some selfies! We will be in booth number 7, right across from our pals at AOPA!
                      </p>
                    </div>
                    <div className="ab_text">
                      <div className="ab_text-title fl-wrap">
                        {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                        <h3>Seminars</h3>

                        <span className="section-separator fl-sec-sep" />
                      </div>
                      <p>
                        AOPA’s spacious indoor Exhibit Hall to learn from leading experts in the industry, to learn about aviation safety, and many other topics. The nice folks at the You Can Fly seminar tent will lead you in your flight training journey no matter where along that path you may be. Audit a seminar in one of their three seminar venues including the Pilot Town Hall with Mark Baker on Saturday!
                      </p>
                    </div>
                    <div className="ab_text">
                      <div className="ab_text-title fl-wrap">
                        {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                        <h3>Aircraft on Display</h3>

                        <span className="section-separator fl-sec-sep" />
                      </div>
                      <p>
                        Visit their colossal aircraft display (insiders told us there will be 500 aircraft on display this year!)
                      </p>


                    </div>


                  </div>
                  <div className="col-md-6">
                    <div className="ab_text">
                      <div className="ab_text-title fl-wrap">
                        {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                        <h3>For Children</h3>

                        <span className="section-separator fl-sec-sep" />
                      </div>
                      <p>
                        Fun for the whole family! Buckeye Air Fair will have lots of activities for kids of all ages. There is the Buckeye Aviation Academy area including the Arizona SciTech area (free) and the Kids Zone. AIR SHOW – Enjoy the Air Show on Saturday including the Twilight Airshow and Fireworks! The air show is also on Sunday.
                      </p>


                    </div>
                    <div className="ab_text">
                      <div className="ab_text-title fl-wrap">
                        {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                        <h3>Members Only</h3>

                        <span className="section-separator fl-sec-sep" />
                      </div>
                      <p>
                        Meet other AOPA Members, senior AOPA leadership, and your favorite influencers in the Members Only AOPA Flightline Chalet! Enjoy the best seats for watching the air show, great giveaways, food and drinks, and meet and greets with influencers and AOPA Leadership. Validate your membership and get your VIP wristband at the Exhibit Hall. AOPA will host a Friday night members-only reception and a Saturday evening 85th-anniversary party sponsored by Aerospace Reports! Chalet Hours: Friday 4 pm-7:30 pm (private viewing of airshow practice), Saturday 11 am-8 pm (Evening Airshow and Fireworks), Sunday 11 am-4 pm
                      </p>
                      <p>
                        For more information, visit the Events Calendar on the AOPA website. (Go to Events on the main menu and then select AOPA Events Calendar.)
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div style={{ paddingTop: "20px" }} className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/static5.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>B-17 Gathering & Big Bird Fly-In, Radio Controlled (RC):</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      (Monaville, Texas): The B-17 gathering & Big Bird Fly-In is held annually. The dates of the next one have not yet been announced.
                    </p>
                    <p>
                      If you love RC, this annual fly-in, hosted by the Academy of Model Aeronautics, is for you. It is held in Bomber Field, USA, which was built for everyone’s enjoyment by BB Weber, a long-time RC lover. Small and with a family feel, this fly-in focuses on warbirds, especially B-17s, and other large aircraft. All Big Bird aircraft may participate, even turbines, as long as they are replicas of full-scale aircraft. They have an awards ceremony for Best Electric, Best Pre-WWII, Best WWII, Best Jet, Best Multi-Engine, Best B-17, and Best of Show. They even have a raffle for many prizes including clothing, koozies, a charger, a set of Hitec D645MW & HS-645MG servos, and a 125″ B-17 with Robart Scale Retracts / Wheel!
                    </p>
                    <p>
                      “Bomber Burgers” are cooked fresh daily. Ice cream and beverages are available, too, and for the pilots, they even have a free lunch consisting of an 8-foot pizza (!) to stay true to the large-scale theme.
                    </p>
                    <p>
                      RV parking is available for those who are camping during their stay.
                    </p>
                    <p>
                      It is a three-day event that goes from Thursday through Saturday. Pilots must be AMA-certified and pay $30 to participate. General admission is only $5 and children 12 and under are free.
                    </p>
                    <p>
                      For more information, visit the Academy of Model Aeronautics website.
                    </p>

                  </div>
                </div>



              </div>
            </div>
            <div style={{ paddingTop: "20px" }} className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>The Texas Antique Airplane Association Fly-In:</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      (Gainesville, Texas): The Texas Antique Airplane Association is held annually. The next one will take place on September 20 and 21, 2024.
                    </p>
                    <p>
                      Since its inception in 1962, the Fall Festival of Flight, hosted by the Texas Chapter, Antique Airplane Association, has been drawing fans of the aircraft of yesteryear and affording them the opportunity to see the planes and meet the pilots who whole-heartedly keep them. It is also an opportunity to make new friends and reminisce with old ones who share in the love of these beautiful airplanes.
                    </p>
                    <p>
                      It is held on the second Friday and Saturday in October each year in the Gainesville Municipal Airport (KGLE). Registration begins at 1:00 pm on Friday and reopens at 8:00 am on Saturday. Aircraft must be registered to be eligible for judging, free fuel drawing, awards, and door prizes.
                    </p>
                    <p>
                      Participating airplanes are Antiques, Classics, Warbirds, Replicas, and Homebuilts. These will be on display during the fly-in, but all aircraft are welcome. Aircraft judging takes place Friday and Saturday and awards are presented at 5:00 pm on Saturday. Award categories are:
                    </p>
                    <p>
                      -Grand Champion
                    </p>
                    <p>
                      -Best Antique before 1942
                    </p>
                    <p>
                      -Best Classic 1942 through 1955
                    </p>
                    <p>
                      -Best Neo-Classic 1956 through 1970
                    </p>
                    <p>-Best War Bird</p>
                    <p>-Best Home Built</p>
                    <p>-Youngest Pilot in Command</p>
                    <p>
                      There will also be static displays, group fly-bys, many flying activities, exhibits, forums, as well as fun educational opportunities for kids. There will be door prizes and discounts on fuel and even a fly-mart. Who knows what parts you might find?!
                    </p>
                    <p>
                      To see their full schedule, visit the Texas Chapter, Antique Airplane Association website.
                    </p>

                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/static6.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/static7.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>West Coast Piper Cub Fly-In:</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      (Lompoc, California): The West Coast Piper Cub Fly-In is held annually each summer. The next one will take place on July 12-14, 2024.
                    </p>
                    <p>
                      As the name suggests, this fly-in event centers around the beloved Piper Cub and similar aircraft. The West Coast Cub Fly-in has been a fun, family-friendly aviation event at the Lompoc Airport since 1984. The event draws in many Lockhaven-yellow Piper Cubs, vintage biplanes, and aviation enthusiasts from all around. The fly-in is free for spectators and all are welcome to attend, view all the aircraft, speak with the pilots, and watch the flying events, including spot landing and flour drop contests!
                    </p>
                    <p>
                      A little trivia about the Piper cub: Piper Aircraft manufactured the model between 1937 and 1947 for use in WWII and it has become Piper’s most-produced model since then.
                    </p>
                    <p>
                      The fly-in is possible thanks to EAA Chapter 275, Explore Lompoc, the City of Lompoc, the Lompoc Airport, West Coast Cub Fly-in volunteers and the late founders Monte Finley and Bruce Fall.
                    </p>
                    <p>
                      Registration for this weekend-long event opens at 10:00 am on Friday. Lunch and dinner are served on Friday, all three meals on Saturday and breakfast is served on Sunday.
                    </p>
                    <p>
                      Saturdays feature a lighthouse tour at 10:00 am, weather permitting, followed by flight proficiency games at 1:00 pm.
                    </p>
                    <p>
                      More information is available on the West Coast Cub Fly-in website.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "20px" }} className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Valdez STOL (Short Take-Off and Landing) Fly-In:</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      (Valdez, Alaska): The Valdez STOL Fly-In is held annually. The next one will take place on May 10-12, 2024.
                    </p>
                    <p>
                      The Valdez Fly-In & Air Show is the ultimate bush plane fly-in in the United States! The three-day, weekend event takes place in May each year starting on a Friday. The event is considered by Alaskan pilots to mark the end of winter and the beginning of a very short-lived summer, which is nonetheless greatly appreciated! The setting is surreal–Pioneer Field, Valdez Airport (KVDZ), is nestled amidst the Chugach Mountains, known for their extensive glaciation, near the top of a profound fjord in Prince William Sound. It’s situated opposite the Port of Valdez, with the Valdez Glacier positioned east of its runway. The beauty of the setting alone makes this an event not to be missed!
                    </p>
                    <p>
                      Of course, there is a main reason people from all over the world flock to The Valdez Fly-In each year–the world-famous competition for short takeoff and landing (STOL). This unique event showcases the skills of pilots who take off and land in incredibly short distances, an especially crucial skill to possess in the rugged terrains of Alaska. World-renowned as the grandfather of STOL competitions, Valdez lives up to this honorary title by setting enviable STOL records. In 2018, for example, the Valdez record for STOL takeoff was set by Fred Knapp who lifted his Piper Cub off the ground in 11 feet! In the same event, Dan Reynolds set a new Valdez landing record of 9.5 feet!
                    </p>
                    <p>
                      Besides the STOL competition, there are many aviation-related events and activities for spectators to enjoy. Among favorites are the aerobatic performances of seasoned pilots showing off their skill and talent. There is also the balloon bust competition in which pilots aim for a floating balloon and pop it mid-air! The flour bombing is also fun for everyone to watch. Additionally, there are pilot round tables, a beach landing fly-out, and static (non-flying) aircraft displays spectators can visit.
                    </p>
                    <p>
                      Best of all, Valdez is a very laid-back event where one can enjoy camaraderie with fellow aviation enthusiasts and pilots.
                    </p>
                    <p>
                      Visit the Valdez Fly-In & Air Show website for more information.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/static8.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/static9.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>Recreational Aviation Foundation (RAF) Backcountry Fly-Ins:</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      RAF’s mission statement is, “Recreational Aviation Foundation preserves, improves, and creates airstrips for recreational access.” True to its name, RAF hosts various fly-ins throughout the year in scenic, backcountry airstrips, celebrating the joys of bush flying and promoting the preservation of such airstrips.
                    </p>
                    <p>
                      Too many fly-ins to list, so do check out RAF’s events calendar.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "20px" }} className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      <h3>The International Seaplane Fly-In:</h3>

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      (Greenville, Maine): The International Seaplane Fly-In is held annually in September, the weekend after Labor Day. The next one will take place on September 5-8, 2024.
                    </p>
                    <p>
                      Since 1973, the four-day event has drawn thousands of people to the area every year to enjoy the quaint surrounding towns, the beauty of autumnal Maine, and, of course, the spectacular aviation event on the southern shore of Moosehead Lake. Spectators admire hundreds of low fly-bys performed by rare, classic, and experimental planes all day, every day. In addition, there is live music, an outdoor market, and seaplane competitions and challenges including spot landings, short field takeoffs, water bombings, and, to top it all off, the float plane parade right on the water! All of this is set against some of the most beautiful scenery in the world!
                    </p>
                    <p>
                      The Fly-In will be celebrating its 50th event in 2024. We’re not missing it, so we’ll see you there!
                    </p>
                    <p>
                      For more information about the event and about the organization, visit The International Seaplane Fly-In website.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      src="/image/static10.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="about-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >

                    <img
                      src="/image/static11.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      <h3>Below: SUN n’ FUN, Florida.</h3>



                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      These are just a handful of the many fly-ins that occur throughout the United States. Each offers such a unique atmosphere and experience, that we really try to make it to as many as we can each year. Will we see you at the next one? We surely hope so! Look for our information desk or just see us walking around wearing our hard-to-miss shirts.
                    </p>
                    <p>
                      Did we miss one of your favorite fly-ins? Write us a comment and share which one you would like to see added here!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* about-wrap end  */}
            <span className="fw-separator" />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default MostFun;
