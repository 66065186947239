import React, { useRef, useState } from 'react';
import {
    AirportForm,
    BedroomForm,
    CustomDateSelectionCalendar,
    FeatureForm,
    ImagesForm,
    InformationForm,
    ListingTypeSelect,
    LocationForm,
    PricingForm,
    SubmitAndDraftSection,
    TermsAndRules,
} from './components';
import { useFormContext } from '../../../providers';
import './listing.css';
// import WPCalenderPicker from '../../Calenders/WPCalenderPicker';

export const ListingTypeForm = () => {
    const { handleSubmit, watch } = useFormContext();
    const autoCompleteRef = useRef(null);

    console.log(watch())

    const [drag, setDrag] = useState({
        lat: 0,
        lng: 0,
    });

    const [selectedLocation, setSelectedLocation] = useState({
        lat: 0,
        lng: 0,
    });

    return (
        <form onSubmit={handleSubmit} className="p-6">
            <div className="mt-1">
                <ListingTypeSelect />
                <LocationForm
                    setSelectedLocation={setSelectedLocation}
                    selectedLocation={selectedLocation}
                    drag={drag}
                    setDrag={setDrag}
                    autoCompleteRef={autoCompleteRef}
                />
                <ImagesForm />
                <InformationForm />
                <BedroomForm />
                <AirportForm />
                <PricingForm />
                <FeatureForm />
                <TermsAndRules />
                {/* <WPCalenderPicker /> */}
                <CustomDateSelectionCalendar />

                <SubmitAndDraftSection />
            </div>
        </form>
    );
};
