/* eslint-disable */
import React, { useCallback, useRef, useState } from "react";
import { GoogleMap, InfoWindow, MarkerF, useLoadScript } from "@react-google-maps/api";
import SearchLocation from "../search/SearchLocation";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import "swiper/css"; // Import Swiper core CSS
import "swiper/css/navigation"; // Import Swiper navigation styles
import "swiper/css/pagination"; // Import Swiper pagination styles
import { Navigation } from "swiper/modules";
import './custom-tooltip.css';
import { useListing } from "../../providers";
import { Link } from "react-router-dom";
import { SNAZZY_MAP_STYLES, SERVER_IMAGES_URL } from "../../constants";

const libraries = ["places"]; // Add more libraries if needed, e.g., 'geometry', 'drawing'

const LocationMap = () => {
  // const [filterItem, setFilterItem] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  // let { data } = useContext(ThemeContext);
  const { filteredListings } = useListing();
  const [center, setCenter] = useState({
    lat: 39.5,
    lng: -100.0,
  });

  const [zoom, setZoom] = useState(3.6);

  const getLabelColor = (distance) => {
    const location = parseInt(distance);
    if (location === 0) {
      return "8ec639";
    } else if (location > 0 && location <= 1) {
      return "ffff00";
    } else if (location > 1 && location <= 3) {
      return "f17489";
    } else if (location > 3 && location <= 7) {
      return "ff9900";
    } else {
      return "52c4d7";
    }
  };

  const createSVGIcon = (color, price) => {
    const svgTemplate = `
      <svg width="100" height="50" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
        <!-- Rectangular background with rounded corners -->
        <rect x="10" y="5" width="60" height="30" rx="16" ry="16" fill="${color}" />
        <!-- Text for price -->
        <text x="40" y="25" text-anchor="middle" font-size="12" font-weight="bold" fill="black" font-family="Arial, sans-serif">
          $${Math.ceil(price)}
        </text>
      </svg>
    `;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgTemplate)}`;
  };

  const mapRef = useRef(null);
  const onLoad = useCallback((map) => {
    mapRef.current = map;

    const mapTypeControl = mapRef.current.getDiv().querySelector('.gm-style-mtc');
    if (mapTypeControl) {
      mapTypeControl.style.maxWidth = '100px'; // Adjust this value as needed
    }
    // Create custom zoom controls
    const zoomControlDiv = document.createElement('div');
    zoomControlDiv.style.display = 'flex';
    zoomControlDiv.style.marginTop = '70px';
    zoomControlDiv.style.marginLeft = '-179px';

    const buttonStyle = {
      fontSize: '18px',
      width: '40px',
      height: '40px',
      display: 'flex',
      borderRadius: "4px 4px 4px 4px",
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#24393b',
      color: 'white',
      border: 'none',
      marginRight: '5px',
      cursor: 'pointer'
    };

    const zoomInButton = document.createElement('button');
    zoomInButton.textContent = '+';

    Object.assign(zoomInButton.style, buttonStyle);

    const zoomOutButton = document.createElement('button');
    zoomOutButton.textContent = '-';
    Object.assign(zoomOutButton.style, buttonStyle);

    zoomControlDiv.appendChild(zoomInButton);
    zoomControlDiv.appendChild(zoomOutButton);

    // Append custom controls to the map
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(zoomControlDiv);

    // Add event listeners for zooming
    zoomInButton.addEventListener('click', () => {
      map.setZoom(map.getZoom() + 1);
    });

    zoomOutButton.addEventListener('click', () => {
      map.setZoom(map.getZoom() - 1);
    });
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8",
    libraries, // Include libraries here
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="h=[93vh] relative w-full bg-none">
      <GoogleMap
        mapContainerStyle={{ height: "80vh", width: "100%" }}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
        options={{
          zoomControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_LEFT,
          },
          styles: SNAZZY_MAP_STYLES, // Apply the Airbnb-like style
        }}
        ref={mapRef}
        className="custom-map-type-control"
      >
        {filteredListings?.map((marker, index) => (
          <MarkerF
            key={index}
            position={{ lat: parseFloat(marker.fake_latitude || marker.latitude), lng: parseFloat(marker.fake_longitude || marker.longitude) }}
            onClick={() => setSelectedMarker(marker)}
            icon={{
              url: createSVGIcon(
                `#${getLabelColor(
                  marker.closest_runway_distance === ""
                    ? 0
                    : marker.closest_runway_distance
                )}`,
                marker.nightly_price
              ),
              scaledSize: new window.google.maps.Size(80, 50),
            }}
          />
        ))}

        {selectedMarker && (
          <InfoWindow
            position={{
              lat: parseFloat(selectedMarker.fake_latitude || selectedMarker.latitude),
              lng: parseFloat(selectedMarker.fake_longitude || selectedMarker.longitude),
            }}
            options={{
              pixelOffset: new window.google.maps.Size(0, -30), // Adjust position relative to marker
            }}
            onCloseClick={() => setSelectedMarker(null)} // If you still want the default close behavior
          >
            <div className="w-72 rounded-xl overflow-hidden shadow-lg bg-white">

              <Link to={`/details/${selectedMarker?.id}`}>
                {/* Image Section */}
                <div className="w-full h-32">
                  {/* Swiper Slider */}
                  <Swiper
                    modules={[Navigation]}
                    navigation
                    pagination={{ clickable: true }}
                    className="w-full h-44"
                  >
                    {selectedMarker.images?.map((image, index) => (
                      <SwiperSlide key={index}>
                        <img
                          // src={image.url}
                          src={`${SERVER_IMAGES_URL}/listing/${image.image || "/image/inovate6.jpg"}`}
                          alt={`Image ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>

                </div>

                {/* Info Section */}
                <div className="py-2 px-4">
                  <h4 className="text-sm font-semibold text-gray-800 !m-0">
                    {selectedMarker.city}, {selectedMarker.country}
                  </h4>
                  <p className="text-xs text-gray-500 mt-1 truncate !p-0 !m-0">
                    Stay at {selectedMarker.title}
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="text-md font-bold text-gray-800">
                      ${selectedMarker.nightly_price}
                      <span className="text-xs text-gray-500 font-medium">
                        {" "}
                        / night
                      </span>
                    </div>
                    {/* <div className="text-sm text-gray-500">Jan 2 – 7</div> */}
                  </div>
                </div>

              </Link>
              {/* Bottom Close Button */}
              <button
                className="z-[50] w-6 h-6 group hover:text-gray-500 rounded-full shadow-md hover:bg-gray-100 bg-gray-300 flex items-center justify-center absolute right-3 top-3"
                onClick={() => setSelectedMarker(null)}
              >
                <i className="fas fa-times text-white group-hover:text-gray-500"></i>
              </button>

            </div>
          </InfoWindow>

        )}
      </GoogleMap>
      <SearchLocation />
    </div>
  );
};

export default LocationMap;
