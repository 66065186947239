const labelIcons = {
    Title: "fal fa-id-badge",
    "Number of Guests (N)": "fal fa-user-friends",
    "Number of Bedrooms": "fal fa-bed",
    "Number of Beds (N)": "fal fa-bed",
    "Type of food": "fal fa-utensils",
    "Number of Bathrooms": "fal fa-toilet",
    "Number of Rooms": "fal fa-door-open",
    "Size (N)": "fal fa-ruler",
    "Nightly Price (N)": "fal fa-moon-stars", // Icon for nightly price (moon)
    "Apply Weekend Price": "fal fa-calendar-week", // Icon for apply weekend price (calendar week)
    "Weekend Nightly Price": "fal fa-sun", // Icon for weekend nightly price (sun)
    "Nightly Price (if 7+ nights)": "fal fa-calendar-alt", // Icon for 7+ nights (calendar-alt)
    "Nightly Price (if 30+ nights)": "fal fa-calendar-check", // Icon for 30+ nights (calendar-check)
    "Bedroom Name": "fal fa-bed", // Icon for bedroom name
    "Number of Guests": "fal fa-user-friends", // Icon for guests
    "Number of Beds": "fal fa-bed", // Icon for number of beds
    "Bed Type": "fal fa-couch", // Icon for bed type (couch is a good generic choice)
    "First Name": "fal fa-user",
    "Last Name": "fal fa-user",
    "Username": "fal fa-user",
    "Display Name": "fal fa-user",
    "Native / Fluent Languages": "fal fa-language",
    "Other Languages": "fal fa-language",
    "Cell Phone Number": "fal fa-phone",
    "Other Phone Number": "fal fa-phone",
    "Email": "fal fa-envelope",
    "Additional Email (optional)": "fal fa-envelope",
    "Airmen Certificate": "fal fa-id-badge",
    "Driver License": "fal fa-id-card",
    "Bio": "fal fa-info-circle",
    "Street Address": "fal fa-map-marker-alt",
    "Apt, Suite": "fal fa-building",
    "City": "fal fa-city",
    "State": "fal fa-map",
    "ZIP / Postal code": "fal fa-mail-bulk",
    "Neighborhood": "fal fa-home",
    "Country": "fal fa-globe",
    "Contact Name": "fal fa-user",
    "Relationship": "fal fa-handshake",
    "Phone": "fal fa-phone",
    "Your name as it appears on the card": "fal fa-user",
    "Credit card number": "fal fa-credit-card",
    "Expiration date": "fal fa-calendar",
    "CVV": "fal fa-lock",
    // eslint-disable-next-line no-dupe-keys
    "ZIP / Postal code": "fal fa-mail-bulk",
    "Name of Bank": "fal fa-university",
    "Routing number": "fal fa-random",
    "Account number": "fal fa-money-check",
    "Facebook URL": "fab fa-facebook-f",
    "Instagram URL": "fab fa-instagram",
    "Airbnb URL": "fab fa-airbnb", // stays as is
    "VRBO URL": "far fa-house-damage", // lighter home-like icon for VRBO
    "YouTube URL": "fab fa-youtube-square", // lighter YouTube icon
    "X (Formerly Twitter) URL": "fab fa-x", // updated to the new X logo
    "LinkedIn URL": "fab fa-linkedin-in", // lighter LinkedIn
    "Pinterest URL": "fab fa-pinterest-p", // lighter Pinterest
    "Vimeo URL": "fab fa-vimeo-v", // lighter Vimeo
    "Trip Advisor URL": "fas fa-compass", // cleaner Trip Advisor icon
};

export const getIconClass = (label) => labelIcons[label] || "fal fa-question-circle";