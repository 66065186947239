import axios from "axios";
import React, { useEffect, useState } from "react";
import BASE_URL from "./auth/Baseurl";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import BookingEditModal from "./BookingEditModal";
function BokkingR() {
  const [data, setData] = useState([]);
  const [isOpen2, setIsOpen2] = useState(false);
  const path = useNavigate();
  // const [pagination, setPagination] = useState({ rowsPerPage: 10, page: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/booking-user`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log(response.data?.data, "values get");
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const date = new Date()
  console.log(date, "currentDate");

  const [selectedId, setSelectedId] = useState(null);

  const handleOpenModal = (id) => {
    setSelectedId(id);
    setIsOpen2(true);
  };
  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      size: 150,
    },
    {
      accessorKey: "user_username",
      header: "Guest",
      size: 150,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 150,
      Cell: ({ row }) => {
        const status = row.getValue("status");

        // Dynamically setting button background color based on status
        const getButtonStyle = () => {
          switch (status) {
            case "Booked":
              return {
                background: "#8ec639",
                color: "white",
                fontWeight: "700",
              };
            case "Waiting for payment":
              return {
                background: "#8ec639",
                color: "white",
                fontWeight: "700",
              };
            case "New":
              return {
                background: "#54c4d9",
                color: "white",
                fontWeight: "700",
              };
            default:
              return { background: "#54c4d9", color: "white" };
          }
        };

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                ...getButtonStyle(),
                padding: "7px 10px",
                borderRadius: "5px",
              }}
            >
              {status}
            </button>
          </div>
        );
      },
    },
    {
      accessorKey: "listing.created_at",
      header: "Date Created",
      size: 150,
    },

    {
      accessorKey: "listing.address",
      header: "Address",
      size: 150,
    },
    {
      accessorKey: "listing.check_in_after",
      header: "Check in",
      size: 150,
    },
    {
      accessorKey: "listing.check_out_before",
      header: "Check out",
      size: 150,
    },
    {
      accessorKey: "no_of_guest",
      header: "Additional Guests $",
      size: 150,
    },
    {
      accessorKey: "listing.pet_allowed",
      header: "Pet Fee",
      size: 150,
    },
    {
      accessorKey: "listing.additional_service_book",
      header: "Additional Services $",
      size: 150,
    },
    {
      accessorKey: "listing.cleaning_fee",
      header: "Cleaning Fee",
      size: 150,
    },
    {
      accessorKey: "listing.city_fee",
      header: "City Fee",
      size: 200,
    },
    {
      accessorKey: "listing.tax",
      header: "Taxes",
      size: 150,
    },
    {
      accessorKey: "sub_total",
      header: "Total You Receive",
      size: 150,
    },
    {
      accessorKey: "actions",
      header: "Actions",
      size: 150,
      Cell: ({ row }) => {
        const isConfirm = row.original.status === "New";

        const buttonStyle = {
          backgroundColor: isConfirm ? "#8ec639" : "#54c4d9",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          fontWeight: "700",
          cursor: "pointer",
        };

        return (
          <button
            style={buttonStyle}
            onClick={() =>
              path("/booking_view", { state: { row: row.original } })
            }
          >
            {isConfirm ? "Confirm" : "Details"}
          </button>
        );
      },
    },
    {
      accessorKey: "review",
      header: "Reviews",
      size: 150,
      Cell: ({ row }) => {
        const buttonStyle = {
          backgroundColor: "#8ec639",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          fontWeight: "700",
          cursor: "pointer",
        };

        return (
          <>
            {row.original.review ? null : (
              date > row.original.depart_date && (
                <button
                  style={buttonStyle}
                  onClick={() => handleOpenModal(row.original.id)}
                >
                  Review
                </button>
              )
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      {isOpen2 && (
        <BookingEditModal
          selectedId={selectedId}
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
        />
      )}

      <div className="col-md-12" style={{ marginTop: "30px" }}>
        <div className="dashboard-title">
          <h3>My Bookings</h3>
        </div>

        <div className="mt-5">
          <MaterialReactTable
            columns={columns}
            data={data}
            initialState={{
              pagination: { pageSize: 50 },
            }}
            muiPaginationProps={{
              rowsPerPageOptions: [
                { value: 10, label: "10" },
                { value: 25, label: "25" },
                { value: 50, label: "50" },
                { value: 100, label: "100" },
                { value: data?.length, label: "All" },
              ],
              showFirstButton: false,
              showLastButton: false,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default BokkingR;
