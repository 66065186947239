import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import '../../../styles/PhoneInputCustom.css';
import { getIconClass } from '../../../helpers';
import { useFormContext } from '../../../providers';
import { SERVER_IMAGES_URL } from '../../../constants';


export const ProfileSection = () => {
    const {
        register,
        setValue,
        errors,
        watch,
        getValues,
        fieldRefs
    } = useFormContext();


    const watchFields = {
        air_men: watch('air_men'),
        air_men_back: watch('air_men_back'),
        driving_license: watch('driving_license'),
        driving_license_back: watch('driving_license_back'),
    };


    const renderPreview = (file) => {
        if (file && file[0]) {
            return <img className='!h-[350px] w-auto' src={typeof file !== 'string' ? URL.createObjectURL(file[0]) : `${SERVER_IMAGES_URL}/profile/${file}`} alt="Preview" />;
        }
        return (
            <div className="fu-text w-auto h-[250px] flex justify-center items-center flex-col">
                <span>
                    <i className={getIconClass("Document Upload")} /> Click here or drop files to upload
                </span>
                <div className="photoUpload-files fl-wrap" />
            </div>
        );
    };



    return (
        <div className="profile-edit-container fl-wrap block_box">
            <div className="custom-form">
                <div className="dashboard-title" style={{ display: 'flex', justifyContent: 'center' }}>
                    <h3>Information</h3>
                </div>

                <div className="row">
                    {/* First Name */}
                    <div className="col-sm-6" ref={(el) => (fieldRefs.current["first_name"] = el)}>
                        <label>
                            First Name <i className={getIconClass('First Name')} />
                        </label>
                        <input type="text"
                            {...register('first_name', { onChange: (e) => setValue('first_name', e.target.value, { shouldValidate: true }) })}
                            placeholder="First Name" />
                        {errors.first_name && <span style={{ color: 'red' }}>{errors.first_name.message}</span>}
                    </div>

                    {/* Last Name */}
                    <div className="col-sm-6" ref={(el) => (fieldRefs.current["last_name"] = el)}>
                        <label>
                            Last Name <i className={getIconClass('Last Name')} />
                        </label>
                        <input type="text"
                            {...register('last_name', { onChange: (e) => setValue('last_name', e.target.value, { shouldValidate: true }) })}
                            placeholder="Last Name" />
                        {errors.last_name && <span style={{ color: 'red' }}>{errors.last_name.message}</span>}
                    </div>

                    {/* Username */}
                    <div className="col-sm-6" ref={(el) => (fieldRefs.current["username"] = el)}>
                        <label>Username</label>
                        <input type="text"
                            {...register('username', { onChange: (e) => setValue('username', e.target.value, { shouldValidate: true }) })}
                            placeholder="Username" />
                        {errors.username && <span style={{ color: 'red' }}>{errors.username.message}</span>}
                    </div>

                    {/* Display Name */}
                    <div className="col-sm-6" ref={(el) => (fieldRefs.current["given_name"] = el)}>
                        <label>Display Name</label>
                        <input type="text"
                            {...register('given_name', { onChange: (e) => setValue('given_name', e.target.value, { shouldValidate: true }) })}
                            placeholder="Display Name" />
                        {errors.given_name && <span style={{ color: 'red' }}>{errors.given_name.message}</span>}
                    </div>

                    {/* Native Language */}
                    <div className="col-sm-6" ref={(el) => (fieldRefs.current["native_language"] = el)}>
                        <label>Native Language</label>
                        <input type="text"
                            {...register('native_language', { onChange: (e) => setValue('native_language', e.target.value, { shouldValidate: true }) })}
                            placeholder="Native Language" />
                        {errors.native_language && <span style={{ color: 'red' }}>{errors.native_language.message}</span>}
                    </div>

                    {/* Other Language */}
                    <div className="col-sm-6" ref={(el) => (fieldRefs.current["other_language"] = el)}>
                        <label>Other Language</label>
                        <input type="text"
                            {...register('other_language', { onChange: (e) => setValue('other_language', e.target.value, { shouldValidate: true }) })}
                            placeholder="Other Language" />
                        {errors.other_language && <span style={{ color: 'red' }}>{errors.other_language.message}</span>}
                    </div>

                    {/* Phone Number */}
                    <div className="col-sm-6 mt-3 flex flex-col" ref={(el) => (fieldRefs.current["phone"] = el)}>
                        <label>Phone Number</label>
                        <PhoneInput
                            {...register('phone')}
                            placeholder="Enter phone number"
                            defaultCountry="US"
                            value={getValues('phone') || ''}
                            onChange={(value) => {
                                if (!value || value.length <= 15) {
                                    setValue('phone', value, { shouldValidate: true });
                                }
                            }}
                        />
                        {errors.phone && <span style={{ color: 'red' }}>{errors.phone.message}</span>}
                    </div>

                    {/* Other Phone Number */}
                    <div className="col-sm-6 mt-3 flex flex-col" ref={(el) => (fieldRefs.current["other_phone"] = el)}>
                        <label>Other Phone Number</label>
                        <PhoneInput
                            {...register('other_phone')}
                            placeholder="Enter phone number"
                            defaultCountry="US"
                            value={getValues('other_phone') || ''}
                            onChange={(value) => {
                                if (!value || value.length <= 15) {
                                    setValue('other_phone', value, { shouldValidate: true });
                                }
                            }}
                        />
                        {errors.other_phone && <span style={{ color: 'red' }}>{errors.other_phone.message}</span>}
                    </div>

                    {/* Email */}
                    <div className="col-sm-6 mt-3" ref={(el) => (fieldRefs.current["other_phone"] = el)}>
                        <label>Email</label>
                        <input type="email" {...register('email')} placeholder="Email" disabled />
                        {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
                    </div>

                    {/* Additional Email */}
                    <div className="col-sm-6 mt-3" ref={(el) => (fieldRefs.current["other_phone"] = el)}>
                        <label>Additional Email</label>
                        <input type="email" {...register('additional_email')} placeholder="Additional Email" />
                        {errors.additional_email && <span style={{ color: 'red' }}>{errors.additional_email.message}</span>}
                    </div>

                    <>
                        {/* Airmen Certificate Front */}
                        <div className="col-sm-6" style={{ marginTop: '12px' }} ref={(el) => (fieldRefs.current["air_men"] = el)}>
                            <label>Airmen Certificate Front</label>
                            <div className="listsearch-input-item fl-wrap">
                                <div className="fuzone">
                                    <form>
                                        {renderPreview(watchFields.air_men)}
                                        <input
                                            type="file"
                                            className="upload"
                                            {...register('air_men')}
                                        />
                                        {errors.air_men && (
                                            <span style={{ color: 'red' }}>{errors.air_men.message}</span>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>

                        {/* Airmen Certificate Back */}
                        <div className="col-sm-6" style={{ marginTop: '12px' }} ref={(el) => (fieldRefs.current["air_men_back"] = el)}>
                            <label>Airmen Certificate Back</label>
                            <div className="listsearch-input-item fl-wrap">
                                <div className="fuzone">
                                    <form>
                                        {renderPreview(watchFields.air_men_back)}
                                        <input
                                            type="file"
                                            className="upload"
                                            {...register('air_men_back')}
                                        />
                                        {errors.air_men_back && (
                                            <span style={{ color: 'red' }}>{errors.air_men_back.message}</span>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>

                        {/* Driving License Front */}
                        <div className="col-sm-6" style={{ marginTop: '12px' }} ref={(el) => (fieldRefs.current["driving_license"] = el)}>
                            <label>Driving License Front</label>
                            <div className="listsearch-input-item fl-wrap">
                                <div className="fuzone">
                                    <form>
                                        {renderPreview(watchFields.driving_license)}
                                        <input
                                            type="file"
                                            className="upload"
                                            {...register('driving_license')}
                                        />
                                        {errors.driving_license && (
                                            <span style={{ color: 'red' }}>{errors.driving_license.message}</span>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>

                        {/* Driving License Back */}
                        <div className="col-sm-6" style={{ marginTop: '12px' }} ref={(el) => (fieldRefs.current["driving_license_back"] = el)}>
                            <label>Driving License Back</label>
                            <div className="listsearch-input-item fl-wrap">
                                <div className="fuzone">
                                    <form>
                                        {renderPreview(watchFields.driving_license_back)}
                                        <input
                                            type="file"
                                            className="upload"
                                            {...register('driving_license_back')}
                                        />
                                        {errors.driving_license_back && (
                                            <span style={{ color: 'red' }}>{errors.driving_license_back.message}</span>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>

                    {/* Bio Section */}
                    <div className="col-sm-12 mt-3" ref={(el) => (fieldRefs.current["bio"] = el)}>
                        <label>Bio</label>
                        <textarea {...register('bio')} placeholder="Write your bio here..." />
                        {errors.bio && <span style={{ color: 'red' }}>{errors.bio.message}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
