import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import { useFormContext } from "../../../../providers";
import { ModalImage, ImageCard } from "./ImageComponents";
// import { handleConvert } from "../../../../helpers/imageconverter";
import { useLocation } from "react-router-dom";

export const ImagesForm = () => {
    const location = useLocation();
    const { listId = "" } = location.state || {};
    const maxNumber = 70;

    const { setValue, watch, errors, fieldRefs } = useFormContext();
    const images = watch("images") || []; // Watching the images field from the form context
    const deletedImages = watch('deleted_images') || [];
    const [featuredIndex, setFeaturedIndex] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [description, setDescription] = useState("");

    // Update images in the form context
    const onChange = (imageList) => {
        setValue("images", imageList);
    };

    const moveImage = (fromIndex, toIndex) => {
        const updatedImages = [...images];
        const [movedImage] = updatedImages.splice(fromIndex, 1);
        updatedImages.splice(toIndex, 0, movedImage);
        setValue("images", updatedImages);
        setFeaturedIndex(toIndex);
    };

    const handleDelete = (index, id) => {
        if (listId && id) {
            setValue("deleted_images", [...deletedImages, id]);
        }
        const updatedImages = images.filter((_, i) => i !== index);
        setValue("images", updatedImages);
    };

    const handleStarClick = (index) => {
        if (index === 0) return;
        moveImage(index, 0);
    };

    const handleDescriptionClick = (image, index) => {
        setSelectedImage({ ...image, index });
        setDescription(image.description || "");
        setIsModalOpen(true);
        window.scrollTo({ top: 1500, behavior: "smooth" });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    const saveDescription = () => {
        if (selectedImage) {
            const updatedImages = [...images];
            updatedImages[selectedImage.index] = {
                ...selectedImage,
                description: description,
            };
            setValue("images", updatedImages);
        }
        closeModal();
    };

    return (
        <>
            <div className="col-md-12" ref={(el) => (fieldRefs.current["images"] = el)}>
                <h2 className="text-2xl font-bold mb-4 text-center">Media</h2>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg", "jpeg", "png", "webp"]}
                >
                    {({ imageList, onImageUpload, isDragging, dragProps }) => (
                        <div className="upload__image-wrapper">
                            <div
                                className="fu-text"
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                <div className="add-list-media-wrap">
                                    <div className="listsearch-input-item fl-wrap">
                                        <div className="fuzone">
                                            <div className="fu-text">
                                                <span>
                                                    <i className="fal fa-images" /> PLEASE BE PATIENT WHILE
                                                    YOUR PHOTOS UPLOAD :)<br />
                                                    Drag and drop the images to customize the gallery
                                                    order.<br />
                                                    Click on the star icon to set the featured image.<br />
                                                    <span>(Minimum size 1440 x 900 px)</span>
                                                </span>
                                                <div className="photoUpload-files fl-wrap" />
                                                <div className="!text-center pt-[27px]">
                                                    <span className="custom_image_button">
                                                        Select and upload
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="photoUpload-files fl-wrap" />
                            </div>

                            <div
                                className="row"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-start",
                                }}
                            >
                                {imageList.map((image, index) => (
                                    <ImageCard
                                        key={index}
                                        image={image}
                                        index={index}
                                        moveImage={moveImage}
                                        onDelete={handleDelete}
                                        isFeatured={index === featuredIndex}
                                        onStarClick={handleStarClick}
                                        description={description}
                                        onDescriptionClick={handleDescriptionClick}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </ImageUploading>

                {isModalOpen && (
                    <ModalImage
                        saveDescription={saveDescription}
                        closeModal={closeModal}
                        selectedImage={selectedImage}
                        description={description}
                        isModalOpen={isModalOpen}
                        setDescription={setDescription}
                    />
                )}

                {errors.images && <p className="text-red-500 mt-1">{errors.images.message}</p>}
            </div>
        </>
    );
};
