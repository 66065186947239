import React, { useContext, useEffect } from 'react'
import { useListing } from '../../providers';
import { ThemeContext } from '../../context/ContextFile';
import LocationMap from '../../Components/map/LocationMap';
import Feature from '../../Pages/Feature';
import DwellingImg from '../../Components/DwellingImg';
import FindUsImage from '../../Components/ui/FindUsImage';
import Bannerfour from '../../Components/Bannerfour';

export const HomeFragment = () => {

    let { setData, setFilterListing } = useContext(ThemeContext);
    const token = localStorage.getItem("token");

    const { allListings } = useListing();

    useEffect(() => {
        setData(allListings);
        const filter = allListings?.filter((fil) => fil?.is_compare === 0);
        setFilterListing(filter);
    }, [allListings, setData, setFilterListing, token]);

    return (
        <>
            <LocationMap />
            <Feature />
            <DwellingImg />
            <FindUsImage />
            <Bannerfour />
        </>

    )
}
