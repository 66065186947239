import * as Yup from 'yup';

export const SEARCH_MAP_VALIDATION = Yup.object().shape({
  destination: Yup.string()
    .required('Destination is required'),

  arrival_date: Yup.date()
    .nullable()
    .required('Arrival date is required')
    .typeError('Invalid date'),

  departure_date: Yup.date()
    .nullable()
    .required('Departure date is required')
    .typeError('Invalid date')
    .min(
      Yup.ref('arrival_date'),
      'Departure date must be after the arrival date'
    ),

  no_of_guest: Yup.number()
    .required('Number of guests is required')
    .min(1, 'At least 1 guest is required'),

  no_of_rooms: Yup.number()
    .required('Number of rooms is required')
    .min(1, 'At least 1 room is required'),

  lodging_type: Yup.string()
    .required('Lodging type is required'),

  type_of_space: Yup.string()
    .required('Type of space is required'),

  no_of_bedrooms: Yup.number()
    .required('Number of bedrooms is required')
    .min(1, 'At least 1 bedroom is required'),

  closest_runway: Yup.number()
    .required('Closest runway distance is required')
    .min(0, 'Runway distance cannot be negative'),

  price_min: Yup.number()
    .required('Minimum price is required')
    .min(0, 'Price must be at least 0'),

  price_max: Yup.number()
    .required('Maximum price is required')
    .min(
      Yup.ref('price_min'),
      'Maximum price must be greater than or equal to minimum price'
    ),

  features: Yup.array()
    .of(Yup.string())
    .ensure()
    .required('At least one feature must be selected'),

  sub_features: Yup.array()
    .of(Yup.string())
    .ensure()
    .required('At least one sub-feature must be selected'),
});
