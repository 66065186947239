import React, { useEffect, useRef, useState } from "react";
import { useFormContext, useListing } from "../../../providers";
import DatePicker from "react-datepicker";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import SearchModal from "./SearchModal";
import { LODGING_TYPES } from "../../../constants";

export const ListingFilters = () => {
    const { setValue, watch, register } = useFormContext();
    const { getFilteredListings } = useListing();

    const [filters, setFilters] = useState(false);
    const [predictions, setPredictions] = useState([]); // Store autocomplete predictions
    const autoCompleteServiceRef = useRef(null); // Reference for AutocompleteService

    const filtersWatch = watch();

    // Initialize Google Places Autocomplete Service
    useEffect(() => {
        if (window.google && !autoCompleteServiceRef.current) {
            autoCompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
        }
    }, []);

    // Handle input change for destination
    const handleDestinationChange = async (e) => {
        const inputValue = e.target.value;
        setValue("destination", inputValue); // Update form context with the input value

        if (autoCompleteServiceRef.current && inputValue) {
            // Fetch place predictions
            autoCompleteServiceRef.current.getPlacePredictions(
                { input: inputValue, types: ["geocode"] },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setPredictions(predictions);
                    } else {
                        setPredictions([]);
                    }
                }
            );
        } else {
            setPredictions([]);
        }
    };

    // Handle selection of a prediction
    const handlePredictionSelect = (place) => {
        setValue("destination", place.description); // Set selected place description
        setPredictions([]); // Clear predictions after selection
    };

    const increment = () => {
        setValue("no_of_guest", parseInt(filtersWatch.no_of_guest || 1) + 1);
    };

    const decrement = () => {
        setValue("no_of_guest", Math.max(1, parseInt(filtersWatch.no_of_guest || 1) - 1));
    };

    const handleFilterSubmit = async () => {
        const data = watch();
        await getFilteredListings(data, true)
        // Call your API or filtering logic here
    };


    return (
        <>

            {/* Destination */}
            <div className="main-search-input-item location autocomplete-container relative">
                <input
                    type="text"
                    placeholder="Destination"
                    className="autocomplete-input pac-target-input border"
                    {...register("destination")}
                    onChange={handleDestinationChange}
                />
                {/* Suggestions Dropdown */}
                {predictions.length > 0 && (
                    <ul className="absolute top-full left-0 w-full bg-white border border-gray-200 rounded-md shadow-lg z-50 list-none">
                        {predictions.map((prediction) => (
                            <li
                                key={prediction.place_id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handlePredictionSelect(prediction)}
                            >
                                {prediction.description}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* Arrival Date */}
            <div className="main-search-input-item">
                <DatePicker
                    selected={filtersWatch.arrival_date}
                    onChange={(date) => setValue("arrival_date", date)}
                    className="border"
                    placeholderText="Arrival Date"
                />
            </div>

            {/* Departure Date */}
            <div className="main-search-input-item">
                <DatePicker
                    selected={filtersWatch.departure_date}
                    onChange={(date) => setValue("departure_date", date)}
                    className="border"
                    placeholderText="Departure Date"
                    minDate={filtersWatch.arrival_date}
                />
            </div>

            {/* Number of Guests */}
            <div className="main-search-input-item input-containers">
                <input
                    type="number"
                    {...register("no_of_guest")}
                    className="input-field"
                    placeholder="Guests"
                    value={filtersWatch.no_of_guest || ""}
                    onChange={(e) => setValue("no_of_guest", Math.max(1, Number(e.target.value)))}
                />
                <div className="custom-buttons-second">
                    <button type="button" className="increment" onClick={increment}>
                        <IoMdArrowDropup />
                    </button>
                    <button type="button" className="decrement" onClick={decrement}>
                        <IoMdArrowDropdown />
                    </button>
                </div>
            </div>

            {/* Lodging Type */}
            <div className="main-search-input-item">
                <select
                    {...register("lodging_type")}
                    className="nice-select chosen-select no-search-select border !z-[unset]"
                >
                    <option value="">Lodging Type</option>
                    {LODGING_TYPES.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}

                </select>
            </div>

            {/* Filter Button */}
            <div className="main-search-input-item">
                <button
                    type="button"
                    className="color2-bg mt-0 text-white bg-[#8EC639] rounded-md font-medium w-full h-full"
                    onClick={() => setFilters(!filters)}
                >
                    Filter
                </button>
            </div>

            {/* Search Button */}
            <div className="flex justify-center items-center">
                <button
                    type="button"
                    className="color2-bg mt-0 text-white bg-[#8EC639] rounded-md font-medium w-full h-full flex justify-center items-center md:py-0 py-3"
                    onClick={handleFilterSubmit}
                >

                    <IoSearchOutline className="mr-3" />
                    Search
                </button>
            </div>
            <SearchModal filters={filters} setFilters={setFilters} />
        </>
    );
};
