import React from 'react';
import '../css/css-styling-options.css';
import '../css/css-post-8728.css';
import '../css/cirt.css';
import Layout from '../../../../Components/layout/Layout';

export const OffThePlatFormFeePolicy = () => {
    return (
        <Layout>
            <div id="section-body">
                <div id="has_social_account" style={{ display: "none" }} data-has-social-account=""></div>
                <section class="main-content-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <div class="page-title">
                                    <div class="block-top-title">
                                        <h1 class="listing-title">Off-the-Platform Fees Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="page-wrap">
                                    <div class="article-main">
                                        <article id="post-7702" class="single-page-article block post-7702 page type-page status-publish hentry">
                                            <div class="article-detail block-body">
                                                <div data-elementor-type="wp-page" data-elementor-id="7702" class="elementor elementor-7702" data-elementor-post-type="page">
                                                    <div class="elementor-element elementor-element-413af666 e-flex e-con-boxed e-con e-parent" data-id="413af666" data-element_type="container">
                                                        <div class="e-con-inner">
                                                            <div class="elementor-element elementor-element-41994ee6 elementor-widget elementor-widget-text-editor" data-id="41994ee6" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">

                                                                    <p>You may not collect any other fees or charges off the Platform without express written permission from FlyInn, except in the case of hotels, motels, inns, resorts, etc. in charging and processing certain payments on their payment systems. Hotels use these terms interchangeably, so, for example, what one hotel may call an incidental hold another may call a security deposit.</p>
                                                                    <p>Following is a list of off-the-platform fees for which hotel hosts do not need to obtain our written permission:</p>
                                                                    <ol>
                                                                        <li><b>Security deposit</b> &ndash; an amount held by the hotel temporarily to to cover potential incidental costs during a guest&rsquo;s stay or to secure a room booking</li>
                                                                        <li><b>Incidental hold</b> &ndash; an amount that represents the cost of amenities and services that are not included in the hotel&rsquo;s room rates. It can include costs such as repairs for any damage caused to the room by the guest, valet parking, self-parking, shuttle fees, room service, minibar items, spa treatments, internet access, etc.</li>
                                                                        <li><b>Cleaning fee</b> &ndash; an amount that is usually included in the room rate. If you see a cleaning fee, it might be due to excessive cleaning necessary when a room is left in an excessively dirty or messy condition that requires the cleaning crew to go well beyond the amount of time it normally takes to clean that room</li>
                                                                        <li><b>Resort fees</b> &ndash; an amount charged for conveniences and amenities such as gym, spa, or pool access; pool towels; your in-room safe; wireless internet access; local calls; newspapers; and more.</li>
                                                                    </ol>


                                                                    <p>All off-the-platform fees must be</p>
                                                                    <ol>
                                                                        <li>Conspicuously outlined in the listing</li>
                                                                        <li>Contain a disclosure that the guest will be charged those fees separately from what they are paying FlyInn and on a separate platform</li>
                                                                        <li>Contain an additional disclosure of when the guest will be required to pay those fees.</li>
                                                                    </ol>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
