import React from 'react'
import { ResetPasswordFragment } from '../../../fragments'
import SidebarLayout from '../../../Components/sidebar/SidebarLayout'

export const ResetPassword = () => {
    return (
        <SidebarLayout>
            <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset] p-8">
                <ResetPasswordFragment />
            </div>
        </SidebarLayout>
    )
}
