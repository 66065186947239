import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { LOGIN_VALIDATION_SCHEMA } from '../../../utils/schema/password_schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthHandler } from '../../../hooks';
import { TbBrandGoogle } from 'react-icons/tb';
import { errorPopup, successPopup } from '../../../helpers';
import { useNavigate } from 'react-router-dom';

export const LoginForm = () => {
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: yupResolver(LOGIN_VALIDATION_SCHEMA),
    });
    const { loginWithGoogle, login } = useAuthHandler();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            // Handle form submission
            setLoading(true);
            login(data).then(response => {
                if (response.data.status) {
                    successPopup('', "Login Successful!");
                    localStorage.setItem('token', response?.data?.token);
                    localStorage.setItem('data', JSON.stringify(response?.data?.user));

                    //  navigate to profile if not complete profile
                    setTimeout(() => {
                        if (response?.data?.user?.profile_completeness < 100) {
                            navigate('/profile');
                            window.location.reload();
                        } else if (!response?.data?.user?.payment_customer_id) {
                            navigate('/account-settings/payments');
                            window.location.reload();
                        } else {
                            window.location.reload();
                        }
                    }, 1000);
                }
            });
        } catch (ex) {
            errorPopup('', 'Login Faiiled!');
        }
        setLoading(false);
    };

    const togglePassword = () => {
        setShowPassword(show => !show);
    }

    return (
        <>
            <div className="w-full max-w-md mx-auto">
                <h4 className="text-lg font-semibold text-center">Log in</h4>
                <div className="grid gap-4">
                    <button
                        className="flex items-center justify-center bg-gray-100 py-3 px-4 rounded-lg shadow-md hover:bg-gray-200 w-full"
                        onClick={() => loginWithGoogle()}
                    >
                        <TbBrandGoogle className="mr-2 w-6 h-6" />
                        Log in with Google
                    </button>
                    <div className="flex justify-center">
                        <h3 className="text-sm text-gray-700">OR</h3>
                    </div>
                    <div className="relative flex justify-center">
                        <div className="absolute inset-0 flex justify-between items-center">
                            <div className="w-1/3 h-px bg-gray-300"></div>
                            <div className="w-1/3 h-px bg-gray-300"></div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                            <div>
                                <label className="text-gray-700 text-sm font-bold" htmlFor="email">
                                    Username or Email Address <span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="email"
                                    type="text"
                                    {...register('email', { required: 'Email is required' })}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {errors.email && <p className="text-red-500 text-xs italic">{errors.email.message}</p>}
                            </div>
                            <div>
                                <label className="text-gray-700 text-sm font-bold !static !float-none" htmlFor="password">
                                    Password <span className="text-red-500">*</span>
                                </label>
                                <div className="relative flex items-center">
                                    <input
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('password', { required: 'Password is required' })}
                                        className="shadow appearance-none border rounded-l w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    />
                                    <span
                                        className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                                        onClick={togglePassword}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                                {errors.password && <p className="text-red-500 text-xs italic">{errors.password.message}</p>}
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="remember"
                                    type="checkbox"
                                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                />
                                <label htmlFor="remember" className="ml-2 block text-sm leading-5 text-gray-900">
                                    Remember me
                                </label>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="bg-[#8EC639] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                                    disabled={isSubmitting}
                                >
                                    {loading ?
                                        <>
                                            <div class="flex items-center justify-center min-h-screen">
                                                <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </>
                                        : 'Login'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </>
    )
}
