import React, { useRef } from 'react';
import { FaCamera } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useFormContext } from '../../../providers';
import { SERVER_IMAGES_URL } from '../../../constants';

export const ImageSection = () => {
    const {
        register,
        setValue,
        watch,
        errors,
        fieldRefs
    } = useFormContext();

    const fileInputRef = useRef(null);
    const imageFile = watch('image');

    const handleBrowseClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setValue('image', [file]); // Ensure the value is set as an array
        }
    };

    const handleDeleteImage = () => {
        setValue('image', null);
    };

    return (
        <div className="profile-edit-container fl-wrap block_box">
            <div className="profile_first flex justify-between">
                <h3>Photo</h3>
                <Link to="/pic" state={{ data: {}, email: '' }}>
                    <h3 className="view_profile">View Profile</h3>
                </Link>
            </div>

            <div className="dashboard-title fl-wrap row">
                <div className="col-md-3">
                    <div className="profile-pic relative">
                        <label className="-label" htmlFor="file" ref={(el) => (fieldRefs.current["image"] = el)}>
                            {!imageFile && (
                                <>
                                    <FaCamera
                                        className="profile_btn_inner absolute top-6 left-6 text-white"
                                    />
                                    <span className="profile_btn_inner text-white">
                                        Change Image
                                    </span>
                                </>
                            )}
                        </label>

                        <input
                            id="file"
                            type="file"
                            {...register('image')}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange} // Handle file change
                            accept=".jpeg, .jpg, .png, .webp" // Specify allowed formats
                        />

                        {imageFile && imageFile[0] && (
                            <img
                                src={typeof imageFile[0] !== 'string' ?
                                    URL.createObjectURL(imageFile[0]) :
                                    `${SERVER_IMAGES_URL}/profile/${watch('image')}`
                                }
                                alt="Profile"
                                className="rounded-md"
                                width="200"
                            />
                        )}
                    </div>
                </div>

                <div>
                    <p>
                        Minimum size 100 x 100 px
                        <br />
                        Maximum size of 3.1 MB
                        <br />
                        Allowed *.jpeg, *.jpg, *.png, *.webp
                    </p>

                    <div className="flex justify-between gap-4 mt-4">
                        <button
                            type="button"
                            className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                            onClick={handleBrowseClick}
                        >
                            Browse
                        </button>

                        <button
                            type="button"
                            className="border border-[#3b4249] font-medium bg-[#3B4249] rounded-md text-white px-8 py-3"
                            onClick={handleDeleteImage}
                        >
                            Delete
                        </button>
                    </div>

                    {errors.image && (
                        <span style={{ color: 'red' }}>{errors.image.message}</span>
                    )}
                </div>
            </div>
        </div>
    );
};
