import React from 'react'
import { Link } from 'react-router-dom'

export const GeneralAgreement = ({ blog }) => {
    return (
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Terms of Service</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/terms-of-service"}>
                        <p className="text-sm text-black underline">
                            Terms of Service
                        </p>
                    </Link>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Privacy Policies</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/most-popular-air-shows-in-the-united-states"}>
                        <p className="text-sm text-black underline">
                            Privacy Policy
                        </p>
                    </Link>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Fly-Inn Family Standards</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/cirt-policy"}>
                        <p className="text-sm text-black underline">
                            CIRT Policy
                        </p>
                    </Link>
                    <Link to={"/neighborhood-nuisance-and-disruptive-behavior-policy"}>
                        <p className="text-sm text-black underline">
                            Neighborhood Nuisance and Disruptive Behavior Policy
                        </p>
                    </Link>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Ethical Standards</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/fair-housing-policy"}>
                        <p className="text-sm text-black underline">
                            Fair Housing Policy
                        </p>
                    </Link>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Trademark and Copyright</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/content-and-copyright-policy"}>
                        <p className="text-sm text-black underline">
                            Content and Copyright Policy
                        </p>
                    </Link>
                    <Link to={"/trademark-policy"}>
                        <p className="text-sm text-black underline">
                            Trademark Policy
                        </p>
                    </Link>

                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Payments and Fees</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/off-the-platform-fees-policy"}>
                        <p className="text-sm text-black underline">
                            Off-the-Platform Fees Policy
                        </p>
                    </Link>
                    <Link to={"/service-fees-policy"}>
                        <p className="text-sm text-black underline">
                            Service Fees Policy
                        </p>
                    </Link>
                </div>
            </div>

            {/* {blog?.map((e) => (
                <div className="w-full md:w-1/3 p-4" key={e?.id}>
                    <div className="ab_text">
                        <div className="ab_text-title fl-wrap">
                            <h3>{e?.heading}</h3>
                            <span className="section-separator fl-sec-sep" />
                        </div>
                        <Link
                            to={"/most-popular-air-shows-in-the-united-states"}
                            state={{ data: e }}
                        >
                            <p className="text-sm text-black underline">{e?.sub_heading}</p>
                        </Link>
                    </div>
                </div>
            ))} */}
        </div>
    )
}
