import React from 'react';
import '../css/css-styling-options.css';
import '../css/css-post-8728.css';
import '../css/cirt.css';
import Layout from '../../../../Components/layout/Layout';

export const NeighborhoodNuisancePolicy = () => {
    return (
        <Layout>
            <div id="section-body">
                <div id="has_social_account" style={{ display: "none" }} data-has-social-account="">
                </div>
                <section class="main-content-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <div class="page-title">
                                    <div class="block-top-title">
                                        <h1 class="listing-title">Neighborhood Nuisance and Disruptive Behavior Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="page-wrap">
                                    <div class="article-main">
                                        <article id="post-7746" class="single-page-article block post-7746 page type-page status-publish hentry">
                                            <div class="article-detail block-body">
                                                <div data-elementor-type="wp-page" data-elementor-id="7746" class="elementor elementor-7746" data-elementor-post-type="page">
                                                    <div class="elementor-element elementor-element-51d9322a e-flex e-con-boxed e-con e-parent" data-id="51d9322a" data-element_type="container">
                                                        <div class="e-con-inner">
                                                            <div class="elementor-element elementor-element-39f30950 elementor-widget elementor-widget-text-editor" data-id="39f30950" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>This Policy applies to Hosts as well as Guests and it encompasses our prohibition on Disruptive Behavior during a Guest&rsquo;s stay anywhere in or on the Rental Property, or in the neighborhood or area where the Rental Property is located.
                                                                        It is difficult even to imagine Members of our Community causing annoyances in the neighborhoods where we are guests, or anywhere else. Needless to say, we all need to behave with decorum and treat everyone with respect and kindness, and this especially applies when we are guests.
                                                                    </p>
                                                                    <h2 class="wp-block-heading">DEFINITION</h2>
                                                                    <p><strong class="privacy-policy-tutorial">&ldquo;Disruptive Behavior&rdquo; </strong>is herein defined as any party, gathering, get-together, or event, collectively <strong class="privacy-policy-tutorial">&ldquo;Event,&rdquo;</strong> that causes any kind of annoyance including, but not limited to</p>
                                                                    <ol>
                                                                        <li>Unreasonable amounts of pollution including air, noise, lights, trash, waste</li>
                                                                        <li>Unreasonable numbers of people</li>
                                                                        <li>Smoking, vaping, alcohol consumption, drug use</li>
                                                                        <li>Creating parking issues</li>
                                                                        <li>Disregard for and disrespect of the Rental Property itself, the surrounding properties, or the neighborhood in which they are situated, including causing any kind of damage</li>
                                                                        <li>Breaking the law with regards to the Rental Property itself, the surrounding properties, or the neighborhood in which they are situated.</li>
                                                                    </ol>
                                                                    <h2 class="wp-block-heading">GUEST AND HOST COMMITMENT</h2>
                                                                    <p><strong class="privacy-policy-tutorial">Guests </strong>are not allowed to participate in such Disruptive Behaviors or actions. FlyInn reserves the right to intervene and cancel a reservation that we, in our sole discretion, determine to be a risk for Disruptive Behavior. We also reserve the right to terminate a Guest&rsquo;s membership if these rules are not followed.</p>
                                                                    <p><strong class="privacy-policy-tutorial">Hosts </strong>are not allowed to list their Rental Property in any way that would lead a Guest to believe that Events are permitted. We especially take note of Listings that invite Guests, inadvertently or otherwise, to use the property as a venue which could lead to Disruptive Behavior. This includes, but is not limited to, advertising the Rental Property as being a venue that is conducive to such events; posting photography of the Rental Property decorated for an Event; posting photography in which an Event is taking place; using words such as &ldquo;party,&rdquo; &ldquo;event,&rdquo; &ldquo;gathering,&rdquo; &ldquo;hangout,&rdquo; &ldquo;graduation,&rdquo; &ldquo;bachelor,&rdquo; &ldquo;bachelorette;&rdquo; setting the number of guests allowed in the listing inordinately high for the amount of space.</p>
                                                                    <p>FlyInn reserves the right to intervene and cancel a reservation that we, in our sole discretion, determine to be a risk for Disruptive Behavior. We also reserve the right to terminate a Host&rsquo;s membership and permanently delete a Listing if these rules are not followed.
                                                                        Not canceling a reservation, terminating a membership, deleting a Listing, or taking any other corrective action does not mean we give up that right.
                                                                    </p>
                                                                    <h2 class="wp-block-heading">INTERVENTION</h2>
                                                                    <p>As Members of this wonderful tight-knit Community of aviation enthusiasts, we ask you to help us keep our Community thriving by ensuring the rules above are followed. The best way to do this is to call us to report any of the above Disruptive Behaviors immediately, especially if it is still happening when you call. This is the time we can intervene most effectively. If you are not a Member of our Community, we still urge you to call us should you see any Disruptive Behavior and we thank you in advance for doing so. We may be reached at 833-I-Fly-Inn or 321-I-Fly-Inn.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
