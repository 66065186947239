import React from 'react'
import { useListing } from '../../providers';

export const PricingDetails = () => {
    const { listingDetails } = useListing();

    const {
        nightly_price,
        nightly_price_seven_plus,
        nightly_price_thirty_plus,
        additional_guest,
        cleaning_fee,
        min_day_booking,
        max_day_booking,
    } = listingDetails;
    return (
        <div className="block !bg-transparent">
            <div className="block-section">
                <div className="block-body !px-0 flex flex-col">
                    <div className="block-left">
                        <h3 className="title text-start pb-30 sub-title-head">
                            Prices
                        </h3>
                    </div>
                    {/* block-left */}
                    <div >
                        <ul className="detail-list detail-list-2-cols">
                            <li>
                                <i className="fa fa-check-circle" />
                                Night :  <strong >${nightly_price}</strong>
                            </li>
                            <li>
                                <i className="fa fa-check-circle" />
                                Cleaning Fee :  <strong >${cleaning_fee}</strong>
                            </li>
                            <li>
                                <i className="fa fa-check-circle" />
                                Weekly (7d+) :
                                <strong >
                                    ${nightly_price_seven_plus}
                                </strong>
                            </li>
                            <li>
                                <i className="fa fa-check-circle" />
                                Monthly (30d+) :
                                <strong >
                                    ${nightly_price_thirty_plus}
                                </strong>
                            </li>
                            <li>
                                <i className="fa fa-users" />
                                Allow additional guests :
                                <strong >
                                    {parseInt(additional_guest) === 1 ? "Yes" : "No"}
                                </strong>
                            </li>
                            <li>
                                <i className="fa fa-check-circle" />
                                Minimum days of a booking :
                                <strong >{min_day_booking}</strong>
                            </li>
                            <li>
                                <i className="fa fa-check-circle" />
                                Maximum days of a booking:{" "}
                                <strong >{max_day_booking}</strong>
                            </li>
                        </ul>
                    </div>
                    {/* block-right */}
                </div>
                {/* block-body */}
            </div>
            {/* block-section */}
        </div>
    )
}
