/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useFeatures, useFormContext } from "../../../../providers";

import BASE_URL from "../../../auth/Baseurl";
import { useLocation } from "react-router-dom";
import { CheckboxList } from "./Features/CheckboxList";
import { warningPopup } from "../../../../helpers";

export const FeatureForm = () => {
    const location = useLocation();
    const { listId = "" } = location.state || {};
    const { features } = useFeatures()
    const [subFeaturesList, setSubFeaturesList] = useState({});
    const [selectedFeatureIDs, setSelectedFeatureIDs] = useState({});
    const { errors, setValue, watch, fieldRefs } = useFormContext();
    const featuresBlock = watch('features');

    const populateFeatures = useCallback(async () => {
        if (featuresBlock?.length > 0 && listId) {
            // Loop through featuresBlock to populate subfeatures and feature IDs
            for (const [index, feature] of featuresBlock.entries()) {
                try {
                    // Set selected feature IDs ensuring uniqueness
                    setSelectedFeatureIDs((prev) => {
                        const updated = Array.isArray(prev) ? [...prev, feature?.feature_id] : [feature?.feature_id];
                        return [...new Set(updated)]; // Remove duplicates
                    });
                    // Fetch subfeatures for the feature
                    const response = await axios.get(`${BASE_URL}/feature/${feature?.feature_id}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    });

                    // Update subfeatures list
                    setSubFeaturesList((prev) => ({
                        ...prev,
                        [index]: response?.data?.data?.feature_detail || [],
                    }));
                } catch (error) {
                    console.error("Error fetching feature details:", error);
                }
            }
        }
    }, [])

    useEffect(() => {
        featuresBlock?.length > 0 && listId && populateFeatures();
    }, []);

    // Add a new block
    const addBlock = () => {
        const newBlock = {
            id: '',
            sub_features: [],
        };
        setValue("features", [...featuresBlock, newBlock]);
    };

    // Handle feature selection change
    const handleBlockChange = useCallback(async (e) => {
        const value = e.target.value;
        const index = parseInt(e.target.dataset.index, 10);

        setSelectedFeatureIDs((prev) => ({
            ...prev,
            [index]: value,
        }));

        try {
            const response = await axios.get(`${BASE_URL}/feature/${value}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            setSubFeaturesList((prev) => ({
                ...prev,
                [index]: response.data.data.feature_detail || [],
            }));
        } catch (error) {
            console.error("Error fetching feature details:", error);
        }

        // Update the selected feature ID in the features block
        const updatedBlocks = [...featuresBlock];
        updatedBlocks[index].id = value;
        setValue('features', updatedBlocks);

    }, [featuresBlock]);

    const handleSubblockChange = useCallback((e, index) => {
        e.preventDefault();
        const checked = e.target.checked;
        const subFeatureID = parseInt(e.target.id);
        const updatedBlocks = [...featuresBlock];
        const checkboxes = updatedBlocks[index].sub_features;


        if (!updatedBlocks[index]?.sub_features) {
            updatedBlocks[index].sub_features = [];
        }

        if (checked) {
            // Add the sub-feature if checked
            checkboxes?.push(subFeatureID);
        } else {
            // Remove the sub-feature if unchecked
            if (updatedBlocks[index].sub_features?.length === 1) {
                warningPopup('At least one feature must be selected from this category!');
                return
            }
            updatedBlocks[index].sub_features = checkboxes.filter(
                (id) => id.toString() !== subFeatureID.toString()
            );
        }

        setValue("features", updatedBlocks);
    }, [featuresBlock, setValue]);


    // Remove a block
    const removeBlock = (blockId) => {
        const updatedBlocks = featuresBlock.filter((block) => block.id !== blockId);
        setValue("features", updatedBlocks);
        // Clean up the states related to the removed block
        setSelectedFeatureIDs((prev) => {
            const newState = { ...prev };
            delete newState[blockId];
            return newState;
        });

        setSubFeaturesList((prev) => {
            const newState = { ...prev };
            delete newState[blockId];
            return newState;
        });
    };


    const renderedFeatureBlocks = useMemo(() => {
        return featuresBlock?.map((block, index) => (
            <div key={block.id} className="md:col-span-12" ref={(el) => (fieldRefs.current[`features.${index}.${block.id}`] = el)}>
                {/* Feature Selector */}
                <div className="text-left">
                    <label>Feature</label>
                    <select
                        value={selectedFeatureIDs[index] || block?.id}
                        onChange={handleBlockChange}
                        data-index={index}
                        className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                    >
                        <option disabled value="">
                            All categories
                        </option>
                        {features?.map((feature) => {
                            return (
                                <option
                                    key={feature.id}
                                    value={feature.id}
                                    disabled={featuresBlock.some((b) => b.id === feature.id && b.id !== block.id)}
                                >
                                    {feature.heading}
                                </option>
                            )
                        })}
                    </select>
                    {errors?.features?.[index]?.id && (
                        <p className="text-red-500">{errors?.features?.[index]?.id.message}</p>
                    )}
                </div>

                {/* Checkbox List for Subfeatures */}
                <CheckboxList
                    subFeaturesList={subFeaturesList}
                    featuresBlock={featuresBlock}
                    index={index} // Pass the index of the current feature block
                    handleSubblockChange={handleSubblockChange}
                    errors={errors}
                />


            </div>
        ));
    }, [featuresBlock, selectedFeatureIDs, handleBlockChange, subFeaturesList, handleSubblockChange, errors, features]);

    // Use renderedFeatureBlocks in the return statement
    return (
        <div className="grid md:grid-cols-12 bg-white p-4 rounded-lg gap-4">
            <h3 className="text-xl font-bold text-center md:col-span-12">Features</h3>
            {renderedFeatureBlocks}
            {/* Add and Remove Block Buttons */}
            <div className="md:col-span-12 text-left flex justify-end items-end gap-4">
                {featuresBlock.length > 1 && (
                    <button
                        type="button"
                        onClick={() => removeBlock(block.id)}
                        className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                    >
                        Remove
                    </button>
                )}

                <button
                    type="button"
                    onClick={addBlock}
                    className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                >
                    Add New Category
                </button>
            </div>
        </div>
    );

};
